import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'
import utils from './utils'
import axios from 'axios'
import DateTime from 'react-datetime';

class TalkToMeHistory extends Component {
  state = {
    fromDateTime: moment().subtract(1, 'w'),
    toDateTime: moment(),
  }

  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className='row'>
            <label htmlFor='from-datetime-selector'>起始时间</label>
            <DateTime
              id='from-datetime-selector'
              value={this.state.fromDateTime}
              onChange={selectedDateTime => this.setState({fromDateTime: selectedDateTime})}
            />
          </div>
          <div className='row'>
            <label htmlFor='to-datetime-selector'>截止时间</label>
            <DateTime
              id='to-datetime-selector'
              value={this.state.toDateTime}
              onChange={selectedDateTime => this.setState({toDateTime: selectedDateTime})}
            />
          </div>
        </div>
        <div className='row'>
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              try {
                if (this.state.fromDateTime.isValid() && this.state.toDateTime.isValid()) {
                  axios.get(utils.getUrl(`talktome/admin/messages/`), {
                    params: {from: this.state.fromDateTime.unix(), to: this.state.toDateTime.unix()},
                    headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')},
                  }).then(response => {
                    let labels = new Set();
                    response.data.forEach(msg => {
                      Object.keys(msg).forEach(key => labels.add(key));
                    });
                    labels = Array.from(labels)
                    let csvContent = `data:text/csv;charset=utf-8,${labels.join(',')}\n`;
                    response.data.forEach(msg => {
                      labels.forEach(key => {
                        csvContent += `${msg[key]},`
                      })
                      csvContent += '\n'
                    })
                    let link = document.createElement('a')
                    link.setAttribute('href', encodeURI(csvContent))
                    link.setAttribute('download', `${this.state.fromDateTime} - ${this.state.toDateTime}.csv`)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                  }).catch(err => {
                    alert(err)
                  })
                }
              } catch(err) {
                console.log(err)
                alert('Unknown Err')
              }
            }}
          >Download</button>
        </div>
      </div>
    );
  }
}

export default withRouter(TalkToMeHistory);
