import React, {Component} from 'react'
import OAuthGOOGLE from './OAuthGOOGLE'
import {GoogleMapFrame, GoogleMapAutoComplete} from "./GoogleMap";
import RichContent from "./RichContent"
import CommentBox from "./CommentBox"
import QandABox from "./QandABox"
import OAuthFaceBook from "./OAuthFaceBook";
import AboutUsEdit from "./AboutUsEdit"
import ScheduleConfig from './ScheduleConfig';
import ScheduleAppointment from './ScheduleAppointment';
import RichEditBox from './RichEditBox';
import PaymentTest from './PaymentTest';

export default class TestPage extends Component{
    state = {
        val:"233",
        val2:"uploadHandler"
    }
    componentDidMount() {
        window.debug_test=this;
    }

    render() {
        return (
            <div >
                {/*<OAuthGOOGLE></OAuthGOOGLE>*/}
                {/*<GoogleMapFrame></GoogleMapFrame>*/}
                {/*<GoogleMapAutoComplete></GoogleMapAutoComplete>*/}
                {/*<RichContent action="update" src_id="3737829206"/>*/}
                {/*<CommentBox/>*/}
                {/*<QandABox/>*/}
                {/*<QandABox action={"update"} src_id={3737822844}/>*/}
                {/*<div>*/}
                {/*<OAuthFaceBook/>*/}
                {/*<AboutUsEdit src_id={3737818547}/>*/}
                {/*<AboutUsEdit src_id={3737818356}/>*/}
                {/*</div>*/}
                {<ScheduleConfig userId={3737795729} locationId={''}/>}
                {<ScheduleAppointment userId={3737795729} locationId={''}/>}
                <RichEditBox value={this.state.val} onChange={(c)=>this.setState({val2:c})}/>
                <PaymentTest/>
                {/*Must not Set the props in On Change !!! or it may crash*/}
            </div>
        );
    }

}
