import React, { Component } from 'react'
import axios from 'axios'
import {Col, Row, Container, Pagination} from 'react-bootstrap'
import avatar from '../assets/website_logo.png'
import utils from './utils'
import ReactLoading from 'react-loading';
import BannerHorizontal from './bannerHorizontal'
import BannerVertical from './bannerVertical'
import AboutUsEdit from "./AboutUsEdit";

const pageSize= 10;

export default class BlogForum extends Component {
  constructor(props) {
    super(props);
    this.orderSelect = React.createRef();
    this.adminOrderSelect = React.createRef();
  }
  state = {
    blogList: [],
    allTags: [],
    currentCategory: null,
    isLoading: false,
    adminCate: 10,
    order: '-time',
    bannerList: [],
    start: 0,
    end: 10,
    count: 0,
    totalPage: 1,
    currPage: 0,
    favList: [],
  }

  componentDidMount = async() => {
    this.props.setDisplayURL();
    this.getTagList()
    this.getBlogList()
    this.getBannerList()
    // create banner
    // await axios.post(`https://dev.findself.org/api/v1/admin/cms/banners/create/`, {banner_id: 'talk_top'},
    // {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
    // .then(response =>{
      
    // }).catch(err => {alert(err)})
  }

  // search for blogs
  getBlogList = async(page) => {
    this.setState({isLoading: true})
    this.getAlreadyFav()
    if(page){
      await this.setState({start: pageSize*(page-1), end: pageSize * page, currPage: page})
    }
    // admin request
    if(parseInt(localStorage.getItem('user_type'))<=2){
      const tempData = {
        type: [0],
        with_extra:1,
        categories: !this.state.currentCategory?[]:[this.state.currentCategory],
        status: this.state.adminCate===4?[0,1,2,10]:[this.state.adminCate],
        order: this.state.order,
        start: this.state.start,
        end: this.state.end
      }
      await axios.post(utils.getUrl(`cms/blog/query/`), tempData,
      {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
      .then(response => {
        const currPage = Math.ceil(response.data.start / pageSize) + 1;
        const totalPage = Math.ceil(response.data.count / pageSize) || 1;
        
        this.setState({blogList: response.data.data, isLoading: false, count: response.data.count, 
          totalPage: totalPage, currPage: currPage})
      })
      .catch(err => {
        // alert(err)
      })
    }else{
      const tempData = {
      type: [0],
      with_extra:1,
      order: this.state.order,
      categories: !this.state.currentCategory?[]:[this.state.currentCategory],
      start: this.state.start,
      end: this.state.end
      }
      await axios.post(utils.getUrl(`cms/blog/query/`), tempData)
      .then(response => {
        const currPage = Math.ceil(response.data.start / pageSize) + 1;
        const totalPage = Math.ceil(response.data.count / pageSize) || 1;
        
        this.setState({blogList: response.data.data, isLoading: false, count: response.data.count, 
          totalPage: totalPage, currPage: currPage})
      })
      .catch(err => {
        // alert(err)
      })
    }
  }

  // forward to a specific blog page
  forwardBlog = (post_id) => {
    const url = `/blog/:${post_id}/show/`
    this.props.history.push(url)
  }
  // change element position in array
  array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  getTagList = async ()=>{
    await utils.loadTagList();
    this.setState({allTags: window.tagList?.areaList || []})
    // 将[其他]移到最后
    let tempList = this.state.allTags
    const pos = tempList.map(function(e) { return e.tag_id; }).indexOf(200001);
    this.array_move(tempList, pos, tempList.length-1)
    this.setState({allTags: tempList})
  }
  // handle blog category
  handleCategory = async(tag_id, event) =>{
    let nodeList = document.querySelectorAll('.forum-tag-selected')
    nodeList.forEach(node=>{
      node.classList.remove('forum-tag-selected')
      node.classList.add('forum-tag')
    })
    if(event.target.classList.contains('forum-tag')){
      event.target.classList.remove('forum-tag')
      event.target.classList.add('forum-tag-selected')
    }
    await this.setState({currentCategory: parseInt(tag_id)})
    this.getBlogList()
  }
  // handle blog display category for admin
  handleAdminCate = async() =>{
    const node = this.adminOrderSelect.current;
    await this.setState({adminCate: parseInt(node.value)})
    this.getBlogList()
  }
  // handle blog display order
  handleOrder = async() =>{
    const node = this.orderSelect.current;
    await this.setState({order: node.value})
    this.getBlogList()
  }
  // 跳转广告管理界面
  forwardAdManage = (banner_id) =>{
    this.props.history.push({pathname: `/admanage/:${banner_id}`})
  }

  getBannerList = async() =>{
    await axios.get(utils.getUrl('public/banners/list/'))
    .then(response => {
      this.setState({bannerList: response.data.data})
    }).catch(err => {
      // alert(err)
    })
  }

  getPager = ()=>{
    const pagearray = [];
    let i;
    for (i = 1; i <= this.state.totalPage; i++) {
        pagearray.push(i);
    }
    const afterarray = [];
    for (i = this.state.totalPage-4; i <= this.state.totalPage; i++) {
        afterarray.push(i);
    }
    const beforearray = [];
    for (i = 1; i <= 5; i++) {
        beforearray.push(i);
    }
    if(this.state.totalPage==1){
        return <Pagination><Pagination.Item active>{1}</Pagination.Item></Pagination>
    } else if (this.state.totalPage <=5){
        return <Pagination>{pagearray.map((val) => <Pagination.Item
                active={val == this.state.currPage}
                onClick={() => {
                    this.getBlogList(val)
                }}
        >{val}</Pagination.Item>)}</Pagination>
             }  else if (this.state.currPage>= this.state.totalPage - 4) {
        return <Pagination>
                    <Pagination.First
                    onClick={() => {
                    this.getBlogList(1)
                    }}/>
                    <Pagination.Prev
                    onClick={() => {
                    this.getBlogList(this.state.currPage - 1)
                    }}/>
                    <Pagination.Item onClick={() => {
                    this.getBlogList(1)
                    }}>{1}</Pagination.Item>
                    <Pagination.Ellipsis/>
            {afterarray.map((val) => <Pagination.Item active={val == this.state.currPage} onClick={()=>{this.getBlogList(val)}}>{val}</Pagination.Item>)}
                    <Pagination.Next onClick={() => {
                    this.getBlogList(this.state.currPage+1)
                    }}/>
                    <Pagination.Last onClick={() => {
                    this.getBlogList(this.state.totalPage)
                    }}/>
                    </Pagination>

    } else if (this.state.currPage <= 5) {
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.getBlogList(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.getBlogList(this.state.currPage - 1)
                }}/>


            {beforearray.map((val) => <Pagination.Item active={val == this.state.currPage} onClick={() => {
                this.getBlogList(val)
            }}>{val}</Pagination.Item>)}
            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.getBlogList(this.state.totalPage)
            }}>{this.state.totalPage}</Pagination.Item>


            <Pagination.Next onClick={() => {
                this.getBlogList(this.state.currPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.getBlogList(this.state.totalPage)
            }}/>
        </Pagination>
    } else{
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.getBlogList(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.getBlogList(this.state.currPage - 1)
                }}/>
            <Pagination.Item onClick={() => {
                this.getBlogList(1)
            }}>{1}</Pagination.Item>
            <Pagination.Ellipsis/>

            <Pagination.Item onClick={() => {
                this.getBlogList(this.state.currPage - 2)
            }}>{this.state.currPage - 2}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.getBlogList(this.state.currPage - 1)
            }}>{this.state.currPage - 1}</Pagination.Item>
            <Pagination.Item active>{this.state.currPage}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.getBlogList(this.state.currPage + 1)
            }}>{this.state.currPage + 1}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.getBlogList(this.state.currPage + 2)
            }}>{this.state.currPage + 2}</Pagination.Item>

            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.getBlogList(this.state.totalPage)
            }}>{this.state.totalPage}</Pagination.Item>
            <Pagination.Next onClick={() => {
                this.getBlogList(this.state.currPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.getBlogList(this.state.totalPage)
            }}/>
        </Pagination>
    }
  }

  getAlreadyFav = async()=>{
    if(!localStorage.getItem('loginToken')){
      return
    }
    await axios.post(utils.getUrl(`cms/blog/query/`), 
    {favourite_of: parseInt(localStorage.getItem('user_id')), start: 0, end: 100}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      
      let dataList = response.data.data
      let tempList = []
      dataList.map(elem => {tempList.push(elem.post_id)})
      this.setState({favList: tempList})
    }).catch(err=>{
      alert(err)
    })
  }

  handleFav = (post_id, event)=>{
    event.stopPropagation()
    const user_id = localStorage.getItem('user_id')
    const config = {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}
    if (!user_id || !post_id){
        alert('请先登录！')
        return
    }
    let favList = this.state.favList
    let blogList = this.state.blogList
    const liked = favList.includes(post_id)?true:false
    const url = utils.getUrl(`cms/favourites/${user_id}/`)
    if (!liked){
        axios.post(url,
            {action:"add",post_id: post_id}, config).then((response)=>{
                alert("收藏成功")
            favList.push(post_id)
            blogList.map(blog=>{
              if(blog.post_id === post_id){
                blog.likes += 1
              }
            })
            this.setState({favList, blogList})
        }).catch((err)=>{
            alert("请求错误")
        })
    } else {
        axios.post(url,
            {action: "drop", post_id: post_id}, config).then((response) => {
            alert("取消收藏成功")
            favList.splice(favList.indexOf(post_id), 1)
            blogList.map(blog=>{
              if(blog.post_id === post_id){
                blog.likes -= 1
              }
            })
            this.setState({favList, blogList})
        }).catch((err) => {
            alert("请求错误")
        })
    }
}

  render() {
    return (
      <div>
        <Container fluid>
          {/* title */}
          <Row className='my_title'>
            <Col md={2}></Col>
            <Col md={4}><h1 style={{fontWeight: '400', marginLeft: -15}}>找我论坛</h1></Col>
          </Row>
          {/* <Row style={{textAlign: 'center'}}><AboutUsEdit src_id="blog_forum"/></Row> */}
        </Container>
        <Container fluid>
          <Col md={2}></Col>
          <Col md={7}>
          <Row className="flex-block" style={{marginBottom: 25, marginLeft: 0}}>
            <div className='forum-tag-selected' onClick={(event)=>this.handleCategory(null, event)}>全部</div>
            {
              this.state.allTags.map(
                ele => <div className='forum-tag' value={ele.tag_id} onClick={(event)=>this.handleCategory(ele.tag_id, event)}>{ele.value_cn}</div>
              )                
            }
          </Row>
          {/* <Row style={{textAlign: 'left', margin: '10px 0'}} className='forum-nav navbar-collapse collapse' id='bsx'>
            <ul className="nav navbar-nav">
              <li><a href='javascript:;' onClick={()=>this.handleCategory()}>全部</a></li>
              {
                            this.state.allTags.map(
                                (ele) => <li><a href='javascript:;' value={ele.tag_id} onClick={()=>this.handleCategory(ele.tag_id)}>{ele.value_cn}</a></li>
                            )
              }
            </ul>
          </Row> */}
          {/* {localStorage.getItem('user_type')&&localStorage.getItem('user_type')<=2?
            (<Row style={{marginBottom: '20px'}}>
              <Col md={2}>
                <div style={{color: '#abb4bc', margin: '10px'}}>分类:</div>
                <select name="order" id="order" ref={this.adminOrderSelect} onChange={this.handleAdminCate}>
                        <option value="10">Published</option>
                        <option value="0" >Saved</option>
                        <option value="1" >Submitted</option>
                        <option value="2" >Rejected</option>
                        <option value="4" >All</option>
                </select>
              </Col>
              <Col md={2}>
                <div style={{color: '#abb4bc', margin: '10px'}}>排序:</div>
                <select name="order" id="order" ref={this.orderSelect} onChange={this.handleOrder}>
                          <option value="-time">编辑时间↓</option>
                          <option value="+time">编辑时间↑</option>
                          <option value="+visits" >访问量↑</option>
                          <option value="-visits" >访问量↓</option>
                          <option value="+comments" >留言数↑</option>
                          <option value="-comments" >留言数↓</option>
                </select>
              </Col>
              </Row>
            ):(<Row style={{marginBottom: '20px'}}>
                <div style={{color: '#abb4bc', margin: '10px'}}>排序:</div>
                  <select name="order" id="order" ref={this.orderSelect} onChange={this.handleOrder}>
                          <option value="-time">编辑时间↓</option>
                          <option value="+time">编辑时间↑</option>
                          <option value="+visits" >访问量↑</option>
                          <option value="-visits" >访问量↓</option>
                          <option value="+comments" >留言数↑</option>
                          <option value="-comments" >留言数↓</option>
                  </select>
            </Row>)
          } */}
          {/* content */}
          {this.state.isLoading?(<Row style={{paddingLeft: '35%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
            this.state.blogList.length===0?(<Row style={{height: '100px', textAlign: 'center'}}>暂时没有内容</Row>):
                  this.state.blogList.map(blog => {
                    return(
                    <Row style={{marginBottom: '30px', height: '115px', width: '100%'}} className="forum-post" onClick={()=>this.forwardBlog(blog.post_id)}>
                      <Col md={9} className='flex-block4' style={{marginRight: 24}}>
                        <div style={{fontSize: 24, fontWeight: '500', marginBottom: 8}}>{blog.subject}</div>
                        <div className='blog-des' style={{marginBottom: 8}}>{blog.description}</div>
                        <div className='flex-block'>
                          {this.state.favList.includes(blog.post_id)?
                            (<div style={{position: 'relative', cursor: 'pointer', marginRight: 24}} onClick={(event) => this.handleFav(blog.post_id, event)} >
                              <button className="events-btn-4" style={{height: '36px', width: '108px', textAlign: 'left', padding: '0px 24px'}} ><span id='events-a'>已收藏</span><span id='events-b'>取消收藏</span></button>
                              <svg width="24" height="24" className='heart-icon-3' viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.0011 17.25C11.0011 17.25 1.6261 12 1.6261 5.62501C1.62629 4.49826 2.01671 3.40635 2.73098 2.53493C3.44526 1.66351 4.43929 1.06636 5.54409 0.845015C6.64888 0.623669 7.79624 0.791788 8.79109 1.32079C9.78594 1.84979 10.5669 2.70702 11.0011 3.74673L11.0011 3.74673C11.4353 2.70702 12.2163 1.84979 13.2111 1.32079C14.206 0.79179 15.3533 0.62367 16.4581 0.845015C17.5629 1.06636 18.5569 1.66351 19.2712 2.53493C19.9855 3.40635 20.3759 4.49826 20.3761 5.62501C20.3761 12 11.0011 17.25 11.0011 17.25Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>):
                              (<div style={{position: 'relative', cursor: 'pointer', marginRight: 24}} onClick={(event) => this.handleFav(blog.post_id, event)} >
                              <input className="events-btn-2" style={{height: '36px', width: '108px', textAlign: 'left', padding: '0px 24px'}} type="button" value={blog.likes+"收藏"} />
                              <svg width="24" height="24" className='heart-icon' viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.0011 17.25C11.0011 17.25 1.6261 12 1.6261 5.62501C1.62629 4.49826 2.01671 3.40635 2.73098 2.53493C3.44526 1.66351 4.43929 1.06636 5.54409 0.845015C6.64888 0.623669 7.79624 0.791788 8.79109 1.32079C9.78594 1.84979 10.5669 2.70702 11.0011 3.74673L11.0011 3.74673C11.4353 2.70702 12.2163 1.84979 13.2111 1.32079C14.206 0.79179 15.3533 0.62367 16.4581 0.845015C17.5629 1.06636 18.5569 1.66351 19.2712 2.53493C19.9855 3.40635 20.3759 4.49826 20.3761 5.62501C20.3761 12 11.0011 17.25 11.0011 17.25Z" stroke="#43B0B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>)}
                          <div style={{marginRight: 24}}><svg width="24" height="25" style={{position: 'relative', top: '5px', left: '0'}} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.25892 17.0997C3.14073 15.2147 2.74913 12.9863 3.15765 10.8331C3.56618 8.67976 4.74672 6.74966 6.47761 5.40519C8.2085 4.06072 10.3707 3.39436 12.5581 3.53125C14.7455 3.66813 16.8077 4.59884 18.3575 6.1486C19.9073 7.69837 20.838 9.76058 20.9749 11.948C21.1118 14.1354 20.4454 16.2976 19.101 18.0285C17.7565 19.7594 15.8264 20.9399 13.6731 21.3485C11.5198 21.757 9.29146 21.3654 7.40646 20.2472L7.40648 20.2471L4.29805 21.1353C4.16944 21.172 4.03335 21.1737 3.90387 21.1401C3.7744 21.1066 3.65625 21.039 3.56167 20.9445C3.4671 20.8499 3.39953 20.7317 3.36598 20.6023C3.33243 20.4728 3.33412 20.3367 3.37086 20.2081L4.25898 17.0996L4.25892 17.0997Z" stroke="#43B0B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>{blog.comments}条评论</div>
                          <div style={{marginRight: 24}}><svg width="24" height="25" style={{position: 'relative', top: '5px', left: '0'}} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19.711 3.86996L2.24279 8.7969C2.09583 8.83835 1.96503 8.92366 1.86786 9.04144C1.77069 9.15922 1.71178 9.30385 1.69901 9.45601C1.68624 9.60816 1.72021 9.76059 1.79639 9.89292C1.87257 10.0252 1.98733 10.1312 2.12532 10.1965L10.1513 13.9983C10.3079 14.0725 10.4339 14.1985 10.5081 14.3551L14.3099 22.3811C14.3752 22.5191 14.4812 22.6338 14.6135 22.71C14.7458 22.7862 14.8982 22.8202 15.0504 22.8074C15.2025 22.7946 15.3472 22.7357 15.465 22.6385C15.5827 22.5414 15.6681 22.4106 15.7095 22.2636L20.6364 4.79539C20.6726 4.66708 20.674 4.53144 20.6403 4.40244C20.6066 4.27343 20.5392 4.15574 20.4449 4.06147C20.3507 3.9672 20.233 3.89976 20.104 3.8661C19.975 3.83243 19.8393 3.83377 19.711 3.86996Z" stroke="#43B0B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10.3934 14.1118L14.636 9.86914" stroke="#43B0B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>分享</div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <img src={blog.cover_url?blog.cover_url:avatar} alt='blog_cover' style={{width: 120, height: 120}}></img>
                      </Col>
                    </Row>
                  )})
          }
          {/* {this.state.isLoading?(<Row style={{paddingLeft: '35%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
            this.state.blogList.length===0?(<Row style={{height: '100px', textAlign: 'center'}}>暂时没有内容</Row>):
                  this.state.blogList.map(blog => {
                    let blog_date = new Date(blog.post_date)
                    blog_date = blog_date.getFullYear()+'-' + (blog_date.getMonth()+1) + '-'+blog_date.getDate()+' '+blog_date.getHours()+':'+blog_date.getMinutes()
                    return(
                    <Row style={{marginBottom: '20px'}}>
                      <Col md={10} className='blog-forum-blog' onClick={()=>this.forwardBlog(blog.post_id)}>
                        <Col md={6} style={{textAlign:"center"}}><img src={blog.cover_url?blog.cover_url:avatar} alt='blog_cover' className='blog-forum-cover'></img></Col>
                        <Col md={6} className='blog-forum-info'>
                          <Row style={{fontWeight: 'bold', fontSize: '20px'}}>{blog.subject}</Row>
                          <Row style={{height: '168px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{blog.description}</Row>
                          <Row style={{color: '#707070'}}>{blog_date}</Row>
                          <Row style={{color: '#7070705d'}}>访问量：{blog.visits}</Row>
                        </Col>
                      </Col>
                    </Row>
                  )})
          } */}
          {
            this.getPager()
          }
          {/* <Row>
          <BannerHorizontal banner_id='blogforum_bottom' forward={this.forwardAdManage}></BannerHorizontal>
          </Row> */}
          </Col>
          <Col md={3}>
            <BannerVertical banner_id='blogforum_right' forward={this.forwardAdManage}></BannerVertical>
          </Col>
        </Container>
      </div>
    )
  }
}
