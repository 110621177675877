import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import {Loader} from "@googlemaps/js-api-loader"

const loader = new Loader({
    apiKey: "AIzaSyBBQRhVGiiczP5Bck0TItM7ogviJq39LNw",
    version: "weekly",
    libraries: ["places"]
});

const loadGoogleMap = () =>{
    if (window.google?.maps){
        return
    }
    return loader.load()
}
class GoogleMapFrame extends Component{
    /* props:
    * lat,lng,zoom : map center location and zoom level
    * height, width, the size of component, default to 400X400
    *  */
    constructor(props) {
        super(props);
        this.map = React.createRef();
    }


    state = {
        mapObject:null
    }
    change_cordinate(lat, lng) {
        this.state.mapObject?.panTo({lat: lat, lng: lng});
    }
    loadMap = ()=>{
        const {lat,lng,zoom, placeName, addMarker} = this.props;
        const node = this.map.current;
        const center = {lat: lat, lng: lng};
        const map = new window.google.maps.Map(node, {
            center: center,
            zoom: zoom,
        })
        this.setState({mapObject: map})
        if (addMarker){
            const marker = new window.google.maps.Marker({
                position: center,
                map,
                title: placeName,
            });
        }

    }
    componentDidMount() {
        if (window.google?.maps) {
            this.loadMap();
            return
        }
        loader.load().then(this.loadMap)
    }
    render(){
        return(
            <div ref={this.map} style={{height:this.props.height,width:this.props.width}}></div>
        )
    }
}

GoogleMapFrame.defaultProps = {
    height: "400px",
    width: "400px",
    lat: -34.397,
    lng: 150.644,
    placeName: "Hello World!",
    addMarker:true,
    zoom:8,
};

class GoogleMapAutoComplete extends Component {
    /* props:
    * onPlaceChange a function which takes in google place object if there is a match,
    * and an stub place object (with only name field as user input) if the match does not exist
    * default to (place)=>{console.log(place)}
    * {
    *   address_components:xxxxx,
    *   geometry:
    * }
    * */
    constructor(props) {
        super(props);
        this.autocomplete = React.createRef();
    }

    state = {
        mapObject: null
    }
    handlePlaceChange = ()=>{
        const place = this.state.mapObject.getPlace();
        return this.props.onPlaceChange(place);
    }
    loadMap = () => {
        const {lat, lng} = this.props;
        const node = this.autocomplete.current;
        const map = new window.google.maps.places.Autocomplete(
            node,
            {types: ["geocode"]}
        )
        map.setFields=['address_components','geometry']
        map.addListener("place_changed", this.handlePlaceChange);
        this.setState({mapObject: map})
    }
    componentDidMount() {
        if (window.google?.maps) {
            this.loadMap();
            return
        }
        loader.load().then(this.loadMap)
    }

    render() {
        return(
        <input
            className="form-control"
            ref={this.autocomplete}
            placeholder="输入地址"
            // onFocus="geolocate()"
            type="text"
        />
        )}
}


GoogleMapAutoComplete.defaultProps = {
    onPlaceChange:(place)=>{}
};

export {GoogleMapAutoComplete,GoogleMapFrame}
