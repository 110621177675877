import React, { Component } from 'react';
import AboutUsEdit from "./AboutUsEdit";

export default class UserTerms extends Component {
    render() {
        return (
            <div>
                <AboutUsEdit src_id="extra_4" />
            </div>
        )
    }
}
