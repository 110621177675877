import React, { Component } from 'react'
import {Switch, Route, Redirect } from "react-router-dom"
import TopNavBar from './top-nav-bar'
import Footer from './footer'
import Home from './home'
import AboutUs from './aboutus'
import AboutUsEn from './aboutus_en'
import AlertModal from "./alertModal"
import SearchConsultant from './searchConsultant'
import Signup from './signup'
import SignupTherapist from './signupTherapist'
import Verification from './verification'
import TherapistProfileView from './therapistProfileView'
import TherapistProfileEdit from './therapistProfileEdit'
import TherapistEditProfile from './therapistEditProfile'
import AdminPage from './adminPage'
import WebuserProfile from './webuserProfile'
import TherapistProfileRegister from './therapistProfileRegister'
import Login from './login'
import Blog from './Blog'
import BlogForum from './blogForum'
import TherapistPremium from './therapistPremium'
import ForgetPassword from './forgetPassword'
import AdManage from './adManage'
import NotFoundPage from './notFoundPage'
import Events from './events'
import EventDetail from './eventDetail'
import EventCreate from './eventCreate'
import EventEdit from './eventEdit'
import PaymentProgress from './paymentProgress'
import PaymentSuccess from './paymentSuccess'
import PaymentFail from './paymentFail'
import CreateAppointment from './createAppointment'
import PostOffice from './postOffice'
import CrisisIntervent from './crisisIntervent'

import Privacy from './Privacy';
import Agreements from './Agreements';
import UserTerms from './UserTerms';

import TalkToPage from './TalkToPage'
import TalkToEmbeded from './TalkToEmbedded'
import TestPage from './testPage'
import userHomepage from './userHomepage'
import Questions from './Questions'
import utils from './utils'
import Cookie from './cookie'
import TalkTimeTable from './TalkTimeTable'
import VolunteerChangePwd from './volunteerChangePwd'
import _ from './locale'
import ReactGA from 'react-ga'

import $ from 'jquery'
import PaymentQuickPay from './paymentQuickPay'
import AppointmentPage from './appointment'
import TicketManager from './ticketManager'
import TalkToMe from './TalkToMe'
import { TalkToMePage } from './TalkToMePage'
import { TalkToMePortal } from './TalkToMePortal'
import TalkToMeHistory from './TalkToMeHistory'
import TherapistInformation from './TherapistInformation'



export default class App extends Component {
  constructor(props) {
    super(props);
    this.nav = React.createRef();
  }

  state={
    modalText:"",
    showModal: false,
    hasGap: true,
    displayedURL: '/home',
    callback:()=>{}
  }

  setDisplayURL = () => {
    this.setState({displayedURL: window.location.href});
  }

  cancelGap = () =>{
    this.setState({hasGap: false})
  }

  addGap = () =>{
    this.setState({hasGap: true})
  }

  refresh = () => {
    this.setState({})
  }

  showAlert= (e,callback=()=>{})=>{
      let stringMsg = e?.response?.data?.msg ||
          e?.response?.data?.non_field_errors ||
          (String(e)==="popup_closed_by_user"?"登陆失败":undefined)||
          String(e)||
          ""
      stringMsg = _(String(stringMsg))
      let ignore = e?.response?.status === 404
      ignore = ignore || (stringMsg?.indexOf('setContents')!==-1)
      if (!ignore && stringMsg) {
          if (this.state.showModal){
              const currCallback = this.state.callback
              const newCallback = ()=>{
                  currCallback();
                  callback()
              }
              const newText = stringMsg;
              this.setState({modalText: newText, callback: newCallback})

          } else {
              this.setState({modalText: stringMsg, showModal: true, callback: callback})
          }
      }
  }

  componentDidMount() {
    // GA - Initailize - Start
    ReactGA.initialize('UA-209659191-1', {
      gaOptions: {
        siteSpeedSampleRate: 100
      }
    });
    // GA - Initailize - End

    window.$ = $
    window.findSelfApp = this
    window.alert = this.showAlert
    let timeStamp = Math.floor(Date.now() / 1000);
    if (localStorage.getItem("loginToken")){
      const logInTime = localStorage.getItem('logInTime')
      if (timeStamp - parseInt(logInTime || 0) < 60 * 60 * 10) {
          return
      }
    alert("登录过期")
      utils.logout();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    window.findSelfApp = this
    window.alert = this.showAlert
  }

  render() {
    return (
      <div>
        <AlertModal modalText={this.state.modalText} showModal={this.state.showModal}
                    extraCallBack={() => {
                      this.setState({showModal: false})
                    this.state?.callback()}
                    }
        ></AlertModal>
        <TopNavBar ref={this.nav}></TopNavBar>
        {/* content section */}
        <Switch>
          <Route exact path='/home/' render={()=> <Home cancelGap={this.cancelGap} addGap={this.addGap} setDisplayURL={this.setDisplayURL}></Home>}></Route>
          <Route exact path='/aboutus/cn' render={(props)=> <AboutUs {...props} setDisplayURL={this.setDisplayURL}></AboutUs>}></Route>
          <Route exact path='/aboutus/en' component={AboutUsEn}></Route>
          <Route exact path='/crisisintervention' component={CrisisIntervent}></Route>
          <Route exact path='/search/therapists' render={(props)=> <SearchConsultant {...props} setDisplayURL={this.setDisplayURL}></SearchConsultant>}></Route>
          <Route exact path='/signup/webuser' render={(props)=> <Signup {...props} cancelGap={this.cancelGap} addGap={this.addGap} setDisplayURL={this.setDisplayURL}></Signup>}></Route>
          <Route exact path='/login' render={(props)=> <Login {...props} cancelGap={this.cancelGap} addGap={this.addGap} isModalLogIn={false} setDisplayURL={this.setDisplayURL}></Login>}></Route>
          <Route exact path='/signup/therapist' render={(props)=> <SignupTherapist {...props} cancelGap={this.cancelGap} addGap={this.addGap} setDisplayURL={this.setDisplayURL}></SignupTherapist>}></Route>
          <Route exact path='/therapist/profile/register' component={TherapistProfileRegister}></Route>
          <Route exact path='/verification' component={Verification}></Route>
          <Route exact path='/therapist/profile/:user_id/:profile_id' component={TherapistEditProfile}></Route>
          <Route exact path='/therapist/view/:profile_id' render={(props) => <TherapistProfileView {...props} cancelGap={this.cancelGap} addGap={this.addGap} setDisplayURL={this.setDisplayURL}></TherapistProfileView>}></Route>
          <Route exact path='/therapist/edit/:user_id/:profile_id' component={TherapistProfileEdit}></Route>
          <Route exact path='/webuser/profile/:user_id' component={WebuserProfile}></Route>
          <Route exact path='/blog/:post_id/show/' render={routeProps=><Blog.BlogShow key={routeProps.match.params.post_id } {...routeProps}/>}/>
          <Route exact path='/blog/:post_id/edit/' component={Blog.BlogEdit}></Route>
          <Route exact path='/blog/create/' component={Blog.BlogCreate}></Route>
          <Route exact path='/forum/' render={(props)=> <BlogForum {...props} setDisplayURL={this.setDisplayURL}></BlogForum>}></Route>
          <Route exact path='/admin' component={AdminPage}></Route>
          <Route exact path='/test/' component={TestPage}></Route>
          <Route exact path='/questions/' render={(props)=> <Questions {...props} setDisplayURL={this.setDisplayURL}></Questions>}></Route>
          <Route exact path='/userhomepage/:user_id' component={userHomepage}></Route>
          <Route exact path='/counselorsunder30/' render={(props)=> <TherapistPremium {...props} setDisplayURL={this.setDisplayURL}></TherapistPremium>} />
          <Route exact path='/forgetpassword' component={ForgetPassword}></Route>
          <Route exact path='/admanage/:banner_id' component={AdManage}></Route>
          <Route exact path='/404' component={NotFoundPage}></Route>
          <Route exact path='/talk/' render={(props)=> <TalkToPage {...props} setDisplayURL={this.setDisplayURL}></TalkToPage>}></Route>
          <Route exact path='/talking/' component={TalkToEmbeded}></Route>
          <Route exact path='/talktome/test' component={TalkToMe}></Route>
          <Route exact path='/talktome/history' component={TalkToMeHistory}></Route>
          <Route exact path='/talktome' render={(props)=><TalkToMePortal {...props}/>}></Route>
          <Route exact path='/payment/progress/' component={PaymentProgress}></Route>
          <Route exact path='/payment/success/' component={PaymentSuccess}></Route>
          <Route exact path='/payment/fail/' component={PaymentFail}></Route>
          <Route exact path='/quickpay/' component={PaymentQuickPay}></Route>
          <Route exact path='/postoffice/' component={PostOffice}></Route>
          <Route exact path='/appointment/create/' component={CreateAppointment}></Route>
          <Route exact path='/appointment' component={AppointmentPage}></Route>
          <Route exact path='/order' component={TicketManager}></Route>
          <Route exact path='/events/' render={routeProps=> <Events key={routeProps.match.params.event_id } {...routeProps} cancelGap={this.cancelGap} addGap={this.addGap} setDisplayURL={this.setDisplayURL}></Events>}></Route>
          <Route exact path='/event/create/' render={routeProps=> <EventCreate key={routeProps.match.params.event_id } {...routeProps} cancelGap={this.cancelGap} addGap={this.addGap} setDisplayURL={this.setDisplayURL}></EventCreate>}></Route>
          <Route exact path='/event/edit/:event_id' render={routeProps=> <EventEdit key={routeProps.match.params.event_id } {...routeProps} cancelGap={this.cancelGap} addGap={this.addGap} setDisplayURL={this.setDisplayURL}></EventEdit>}></Route>
          <Route exact path='/event/detail/:event_id' render={routeProps=> <EventDetail key={routeProps.match.params.event_id } {...routeProps} cancelGap={this.cancelGap} addGap={this.addGap} setDisplayURL={this.setDisplayURL}></EventDetail>}></Route>
          <Route exact path='/agreements' component={Agreements}></Route>
          <Route exact path='/privacy' component={Privacy}></Route>
          <Route exact path='/userTerms' component={UserTerms}></Route>
          <Route exact path='/talktimetable' component={TalkTimeTable}></Route>
          <Route exact path='/volunteer/change-password' component={VolunteerChangePwd}></Route>
          <Route path='/therapistInformation' component={TherapistInformation}></Route>
          <Redirect exact path='/' to='/home/'></Redirect>
          <Route path='*' exact component={NotFoundPage} />
        </Switch>
        <Footer></Footer>
        <Cookie/>
      </div>
    )
  }
}
