import React, {Component} from 'react'
import {NavLink, Redirect} from 'react-router-dom'
import utils from './utils'
import axios from 'axios'
import {Col, Row} from "react-bootstrap";
import OAuthGOOGLE from "./OAuthGOOGLE";
import OAuthFaceBook from "./OAuthFaceBook";

export default class OAuthInterface extends Component{
    state = {
        'google':'',
        'facebook':'',
        'loaded':false,
    }
    componentDidMount() {
        this.updateStatus(this.props.userId)
    }

    postCredential = (guri,party) => {
        axios.post(utils.getUrl('permission/oauth/validate/'),
            {"identification": guri, "user_id": this.props.userId},).then(response => {
                if (response.data.data.exist) {
                    alert('此第三方账号已被绑定')
                } else {
                    axios.post(utils.getUrl('permission/oauth/add/'),
                        {"credential": guri, "user_id": this.props.userId},
                        {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
                        alert('成功！')
                        this.setState({[party]:guri})
                    }).catch(err => {
                        // alert(err)
                    })
                }
            }
        ).catch(err => {
            alert(err?.response?.data?.msg || err)
        })
    }
    handleAddGoogleCredential = (profile) => {
        const google_id = profile.getId();
        const guri = `findself-google://${google_id ^ 0xB906ABCA}`;
        // alert(`DEBUG: Google ID: ${google_id}`)
        this.postCredential(guri,'google')

    }
    handleAddFaceBookCredential = (response) => {
        let facebook_id = response?.authResponse?.userID;
        if (!facebook_id) {
            alert('无效的账号！')
            return
        }
        facebook_id = parseInt(facebook_id)
        const guri = `findself-facebook://${facebook_id ^ 0xB906ABCA}`;
        //alert(`DEBUG: Facebook ID: ${facebook_id}`)
        this.postCredential(guri,'facebook')
    }

    updateStatus (userId){
        if (userId) {
            axios.post(
                utils.getUrl('permission/oauth/get/'),
                {user_id:userId},
                utils.getAxiosConfig()
            ).then(
                (resp)=>{
                    let data = resp.data.data
                    const obj = {}
                    const google = data.filter(ele=>ele?.indexOf('findself-google')==0)[0]
                    const facebook = data.filter(ele => ele?.indexOf('findself-facebook') == 0)[0]
                    this.setState(
                        {
                            google: google||'',
                            facebook: facebook||'',
                            loaded:true,
                        }
                    )
                }
            ).catch(err=>alert(err))

        } else {
            this.setState(
                {
                    'google': '',
                    'facebook': '',
                    'loaded': false,
                }
            )
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.userId != this.props.userId){
            this.updateStatus(this.props.userId)
        }
    }
    removeOAuth(guri){
        axios.post(utils.getUrl('permission/oauth/delete/'),
            {"credential": guri, "user_id": this.props.userId},
            utils.getAxiosConfig()).then(
            (response)=>{
                alert("成功删除")
                this.updateStatus(this.props.userId)
            }

        ).catch(err=>alert(err))
    }

    render(){
        return <Row style={{marginBottom: '20px'}} className="bind-social-media">
            <Col md={4}>绑定社交媒体账号:</Col>
           {this.state.loaded? <React.Fragment><Col md={2} className="oauth-add-credential">
                {this.state.google?
                    <button onClick={()=>this.removeOAuth(this.state.google)}>解除Google绑定</button>
                   : <OAuthGOOGLE key="add" onSignIn={this.handleAddGoogleCredential}/>
                }
                            </Col>
            <Col md={2} className="oauth-add-credential">
                {this.state.facebook ?
                    <button onClick={() => this.removeOAuth(this.state.facebook)}>解除Facebook绑定</button>
                    : <OAuthFaceBook key="add" onSignIn={this.handleAddFaceBookCredential}/>
                }
                            </Col></React.Fragment>:
           <React.Fragment>
               <Col md={2} className="oauth-add-credential"></Col>
               <Col md={2} className="oauth-add-credential"></Col>
           </React.Fragment>}
        </Row>
    }
}
