import { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import utils from './utils'


export default class VolunteerChangePwd extends Component {
  state = {
    newPassword: '',
  };

  handleSubmit = () => {
    axios.post(
      utils.getUrl('volunteer/change-password/'),
      {pwd: this.state.newPassword},
      {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}}
    ).then(response => {
      alert(response.data.data);
      this.setState({newPassword: ''});
    }).catch(err => {
      alert(err);
    })
  };

  render() {
    return (
      <div className='container'>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control type="password" placeholder='New Password for Volunteer' value={this.state.newPassword} onChange={e => this.setState({newPassword: e.target.value})} />
          </Form.Group>
          <Button variant="primary" type="submit" onClick={this.handleSubmit}>
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}
