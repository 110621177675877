import { Component } from "react";
import ReactGA from 'react-ga'
import postoffice from '../imgs/postoffice.png'

export default class PostOffice extends Component {
    componentWillMount = async () => {
        document.title = "FindSelf找我 - 找我邮局";
    }

    render() {
        // GA - PageView - Start
        let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
        ReactGA.pageview(pagePathForGA);
        // GA - PageView - End
        return (
            <div style={{ paddingBottom: 100 }}>
                {/* 开篇 */}
                <div className="container-fluid main-content px-lg-10 py-3">
                    <div className="row g-1 g-lg-4 pt-5">
                        <div className="col-12 col-lg-8">
                            <h2>车马很慢，书信很远<br></br>我们在这里等你</h2>
                            <br/>
                            <p>写信是一种很有温度的交流方式。</p>
                            <p>在现代通讯技术发展之前，信在很长一段时间以来都是我们寄托情感的载体。但是随着时代的进步，如今的我们，已经很少写信了。</p>
                            <p>你是否还记得上一次写信是什么时候，写了什么内容，寄向了何方？</p>
                            <p>如今，一切都太快了。</p>
                            <p>
                                发出的消息，很快会得到回复，然后被遗忘；<br/>
                                心中的情感，来不及发酵，就快要消散；<br/>
                                心底的疑问，还未找到答案，就似乎必须要释怀；<br/>
                                那些预想的对话，来不及深入，就要停止。
                            </p>
                            <p>......太快了，一切都太快了。</p>
                        </div>
                        <div className="col-12 col-lg-4 text-center pb-3">
                            <img src={postoffice} className="card-img pt-5 pt-lg-0" alt="..."></img>
                        </div>
                    </div>
                </div>
                {/* 营业中 */}
                <div className="container-fluid main-content px-lg-10 pt-0 pb-3">
                    <div className="row g-1 g-lg-4 pt-5">
                        <div className="col-12 col-lg-8">
                            <h2>「找我邮局」营业中 </h2>
                            <br/>
                            <p>为了给大家提供一个地方，可以让一切都慢下来，「找我邮局」从今天起正式营业了。</p>
                            <p>你可以把你所有想说的都打成文字一股脑的塞进信封，然后寄往虚空：</p>
                            <p>
                                <strong>
                                可以是你琐碎的，不知该与何人分享的日常；<br />
                                也可以是你最近遇到的开心的事情，或者，不开心的事情；<br />
                                可以是一个问题，你来信寻找答案；<br />
                                也可以是一句吐槽，你并不需要答案，只是想找到一个情绪释放的地方，释放完后，继续生活……
                                </strong>
                            </p>
                            <p>这些所有的事情，正是「找我邮局」存在的意义。</p>
                        </div>
                        <div className="col-12 col-lg-4 text-center pb-3"></div>
                    </div>
                </div>
                {/* 使用方法 */}
                <div className="container-fluid main-content px-lg-10 py-3">
                    <div className="row g-1 g-lg-4 pt-5">
                        <div className="col-12">
                            <h2>使用方法</h2>
                            <br/>
                            <div className="py-2">
                                <p><strong>Q：我该如何给「找我邮局」寄信？</strong></p>
                                <p>当你有任何想要分享的东西，欢迎随时将它塞进虚拟信封，发送至「找我邮局」
                                    <a href="mailto: fs_postoffice@findself.org" style={{textDecoration: 'None', color: '#FFAE42'}}>
                                        <strong>fs_postoffice@findself.org</strong>
                                    </a> 。
                                </p>
                            </div>
                            <div className="py-2">
                                <p><strong>Q：信件寄出后，我该期待什么？</strong></p>
                                <p>在信件寄出的3日之内，我们将回复你的来信，并通过电子邮箱重新寄回到你的手中。</p>
                            </div>
                            <div className="py-2">
                                <p><strong>Q：是谁在回复我的来信？</strong></p>
                                <p>进行收信与回信的是由来自北大心理学系的负责人带领的志愿者团队。他们是来自一群与你素未谋面的温暖陌生人，真诚地在站在你的角度感受你的情绪，给予暖心回复与支持。</p>
                            </div>
                            <div className="py-2">
                                <p><strong>Q：谁能看到我的信件内容？</strong></p>
                                <p>我们深知隐私保护的重要性。你可以在信中畅所欲言，不必担心隐私的泄露或是被人所评判、误解。未经你的允许，除收信回复的志愿者外，信件内容将完全保密。</p>
                                <p><strong>ALL WE CARE IS YOU</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 写在最后 */}
                <div className="container-fluid main-content px-lg-10 py-3">
                    <div className="row g-1 g-lg-4 pt-5">
                        <div className="col-12">
                            <h2> 写在最后 </h2>
                            <br/>
                            <p>「FindSelf找我」正式推出「找我邮局」，希望和「找我说」一起，<strong>以不同的方式陪伴到你</strong>。</p>
                            <p>「找我说」是我们提供的全时段、即时、匿名的线上文字一对一心理支持公益服务。相信有不少小伙伴，在某个难过的瞬间，曾在「找我说」收到了陪伴和温暖。</p>
                            <p>的确，有的时候当情绪突然涌起，当下我们需要的是即时的回复和支持。然而，我们还会有一些话题和情感会期待能够和另一个人<strong>更加深入地分享、探讨，</strong>一起寻找心中那个可能的答案。于是，「找我邮局」诞生了。</p>
                            <p><strong>一个是即时抵达的安慰，一个是深入心灵的温暖。</strong>「找我说」和「找我邮局」将在未来持续给予支持。</p>
                            <div style={{ fontSize: 26, color:'#43B0B1', fontWeight: 400 }}>
                                期待你的来信！希望能与你在「找我邮局」，邂逅一次深入灵魂的共鸣。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

