import React, { Component } from 'react'
import axios from 'axios'
import Message from './utility/message'

import kebabIcon from '../imgs/kebab.png'
import talktobackground from '../imgs/talk-to-background.png'

export default class TalkToMe extends Component {

    state = {
        activeMessage: '',
        messageList: [
            {
                content: '你好',
                source: 'user'  
            }, 
            {
                content: '你欢迎来找我聊天！',
                source: 'consultant'
            },
            {
                content: '我就是最近事情很多',
                source: 'user'
            },
            {
                content: '嗯嗯了解了~最近是发生了什么事情吗?',
                source: 'consultant'
            },
            {
                content: '最近新开始了一项工作，然后感觉精力上有点跟不上',
                source: 'user'
            },
            {
                content: '嗯嗯那你现在的工作是怎么样的呢?是因为新工作的原因让你精力不跟上吗?',
                source: 'consultant'
            },
            {
                content: '现在的工作就是有很多地方要去，每周甚至每天都要去不同的地点',
                source: 'user'
            },
            {
                content: '嗯嗯那这个工作强度还挺大的呢~你有没有考虑过换一份工作呢?',
                source: 'consultant'
            },
            {
                content: '肯定是不行的，只能慢慢适应',
                source: 'user'
            },
            {
                content: '嗯嗯那如果之后还想要换工作的话可以再试试看哦~',
                source: 'consultant'
            },
            {
                content: '工作是肯定不能换的啦',
                source: 'user'
            }
        ],
        combinedMessageString: '',
        messageLoading: true
    }

    componentDidMount = () => {
        this.scrollToBot()
    }

    handleMessageChange = (e) => {
        this.setState({ activeMessage: e.target.value })
    }

    handleEnterEvent = (e) => {
        if (e.key === 'Enter') this.handleMessageSend()
    }

    handleMessageSend = async () => {
        var messageContent = this.state.activeMessage.trim().replace(/^\n|\n$/g, '')
        this.setState({ 
            messageList: [...this.state.messageList, {
                content: messageContent,
                source: 'user'
            }],
            combinedMessageString: this.state.combinedMessageString===''? messageContent:this.state.combinedMessageString + '\t\t' + messageContent,
            activeMessage: ''
        }, ()=> {
            var chatBase = document.getElementById('chatBase')
            chatBase.scrollTop = chatBase.scrollHeight
            this.talkToAi()
        })
    }

    // scroll to bot for incoming message
    scrollToBot =  () => {
        var chatBase = document.getElementById('chatBase')
        if (this.state.messageLoading) {
            chatBase.scrollTop = chatBase.scrollHeight
            this.setState({messageLoading: false})
        } else if (chatBase.clientHeight + chatBase.scrollTop + 100 >= chatBase.scrollHeight) {
            chatBase.scrollTop = chatBase.scrollHeight
        }
    }

    talkToAi = async () => {
        const formData = new FormData()
        formData.append("user_post", this.state.combinedMessageString)
        formData.append("api_token", "7f1DKlPgxxG4wDXh4UQeQbpXAFTy5Ge2")
        axios.post("https://api.ailingxin.cn/esc_eva", formData)
            .then(response => {
                console.log(response.data)
                if (response.data.status === 'success') {
                    this.setState({
                        messageList: [...this.state.messageList, {
                            content: response.data.response,
                            source: 'consultant',
                            uuid: response.data.uuid
                        }],
                        combinedMessageString: this.state.combinedMessageString + '\t\t' + response.data.response,
                    }, this.scrollToBot)
                }
            })
    }

    render() {
        var messageList = this.state.messageList.map((message) => {
            return <Message reversed={message.source==='user'?true:false} message={message.content} />
        })
        return (
            <div className="pt-1 pb-5">
                {/* 开篇 */}
                <div className="container main-content px-lg-10 pt-3 pb-5" style={{ backgroundImage: `url(${talktobackground})` }}>
                    <div className="d-flex flex-row justify-content-between pt-2 mt-3" style={{ color: '#43B0B1', backgroundColor: 'white', borderRadius: '8px 8px 0 0', boxShadow: '3px 3px 5px 1px'}}>
                        <div style={{paddingLeft: 8}}><h3><strong>找我说</strong></h3></div>
                        <div>
                            <img src={kebabIcon} style={{width: 30, paddingTop: 4}} alt='kebab icon'></img>
                        </div>
                    </div>
                    <div id="chatBase" className="d-flex flex-column pt-0 px-3" style={{ backgroundColor: '#f3f3f3', height: 500, overflow: 'auto', boxShadow: '3px 3px 5px 1px'}} >
                        {messageList}
                    </div>
                    <div className="d-flex flex-column pt-0" style={{ position: 'relative'}}>
                        <textarea id="messagebox" placeholder="说点什么" maxlength="200" rows="2" cols="50" value={this.state.activeMessage}
                            style={{ borderRadius: '0 0 8px 8px', borderStyle: 'none', boxShadow: '3px 3px 5px 1px'}} 
                            onChange={this.handleMessageChange} onKeyUp={this.handleEnterEvent}>
                        </textarea>
                        {this.state.activeMessage!==''?
                            <button style={{
                                height: '50%', width: '8%', minWidth: 50, zIndex: 99, position: 'absolute', right: 5, bottom: -35,
                                borderRadius: '8px 8px 8px 8px', borderStyle: 'none', backgroundColor: '#43B0B1', color: 'white'
                            }} onClick={this.handleMessageSend}>发送</button>
                            : ''
                        }        
                    </div>
                </div>
            </div>
        )
    }

}
