import React, { Component } from 'react'
import {NavLink, Redirect} from 'react-router-dom'
import {Container, Row, Col} from 'react-bootstrap'
import axios from 'axios'
import OAuthGOOGLE from './OAuthGOOGLE'
import OAuthFaceBook from "./OAuthFaceBook";
import utils from './utils'
import { withRouter } from 'react-router-dom';
import openedEye from '../imgs/open-eyes.svg';
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from 'react-ga'


class Login extends Component {

  state = {
    email: '',
    password: '',
    userId: null,
    profileId: null,
    displayPassword: false,
      errTimes:0,
  }

  componentDidMount = () => {
    this.props.cancelGap();
    this.props.setDisplayURL();
    this.updateErrorTimes()
  }
  componentWillUnmount = () => {
    this.props.addGap()
  }
  updateErrorTimes = ()=>{
      this.setState({errTimes:utils.getLoginErrorTimes()})
  }
  handleSubmit = async(event) => {
    event.preventDefault()
    //get login token
    await axios.post(utils.getUrl('permission/jwt/auth/'),
      {username: this.state.email, password: this.state.password})
      .then(response => {
        localStorage.setItem('loginToken', response.data.token)
          this.handleSuccessLogin();
      }).catch(err => {
          if(err.response.status === 400){
              alert('用户名或密码错误！')
              const errNum = utils.getLoginErrorTimes() + 1
              utils.setLoginErrorTimes(errNum)
              this.updateErrorTimes()
          }
        })

  }

  handleForgetPassword = () => {
    this.props.history.push({pathname: `/forgetpassword`})
  }

  displayPassword = () => {
    this.setState({displayPassword: !this.state.displayPassword})
  }

  handleSuccessLogin = async()=> {
      // get user id
      let timeStamp = Math.floor(Date.now() / 1000);
      localStorage.setItem("logInTime", timeStamp)
      await axios.get(utils.getUrl('permission/status/'),
          {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
          .then(response => {
              this.setState({userId: response.data.user_id})
              localStorage.setItem('user_id', response.data.user_id)
          }).catch(err => {
              // alert(err)
          })
      // get user info
      await axios.get(utils.getUrl(`profile/${this.state.userId}/`),
          {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
          .then(response => {
              if (response.data.frozen) {
                  utils.logout();
                  alert("账号已被冻结，请联系管理员")
                  return
              }
              localStorage.setItem('user_avatar', response.data.photo_url)
              if(response.data.user_type === 4){
                localStorage.setItem('full_name', response.data.nickname)
                localStorage.setItem('first_name', response.data.nickname)
              }else{
                localStorage.setItem('full_name', response.data.first_name + ' ' + response.data.last_name)
                localStorage.setItem('first_name', response.data.first_name)
              }
              localStorage.setItem('user_type', response.data.user_type)
              localStorage.setItem('user_email', response.data.email)

              this.props.history.push({pathname: '/talktimetable'})
              /*
              if (response.data.profiles.length > 0) {
               this.setState({profileId: response.data.profiles[0].profile_id}, ()=>{
                    // window.findSelfApp.nav.current.reload()
                      // therapists login
                    if (response.data.user_type === 3) {
                          localStorage.setItem('profile_id', this.state.profileId)
                          this.gotoHomePage(3)
                    }
                })
              } else {
                  window.findSelfApp.nav.current.reload()
                  // redirect based on user type
                  this.gotoHomePage(response.data.user_type)
              }

              // Redirect to the original page if logging 
              // in through a modal
              if (this.props.isModalLogIn) {
                window.location.assign(this.props.curUrl);
                window.location.reload();
              }
              */
              
              alert('登录成功')
          }).catch(err => {
              // alert(err)
          })
}
  gotoHomePage = (userType) =>{
      // Force reload to avoid bugs
      if (userType=== 1) {
        //this.props.history.push({pathname: `/admin`})
        window.location.href = '#/admin'
      } else if (userType === 4) {
        //this.props.history.push({pathname: `/webuser/profile/:${this.state.userId}`})
        window.location.href = `#/home`
      } else if (userType === 3){
        window.location.href = `#/webuser/profile/:${this.state.userId}`
      }
  }
  handleEmail = (event) => {
    this.setState({email: event.target.value})
  }
  handlePassword = (event) => {
    this.setState({password: event.target.value})
  }
  handleGoogleLogin= (profile) =>{
      const guri = utils.getGoogleCredential(profile)
      //alert(`DEBUG: Google ID: ${google_id}`)
      axios.post(utils.getUrl('permission/oauth/auth/'),
          {"identification": guri}).then(response => {
          localStorage.setItem('loginToken', response.data.data)
          this.handleSuccessLogin();
      }).catch(err => {
          localStorage.setItem('guri', guri)
          alert('您必须先绑定一个找我账号以使用')
          this.props.history.push('/signup/webuser')
          // alert(err)
      })
  }
  handleFaceBookLogIn = (response) => {
      const guri = utils.getFacebookCredential(response)
        //alert(`DEBUG: Facebook ID: ${facebook_id}`)
        axios.post(utils.getUrl('permission/oauth/auth/'),
            {"identification": guri}).then(response => {
            localStorage.setItem('loginToken', response.data.data)
            this.handleSuccessLogin();
        }).catch(err => {
            // alert(err?.response?.data?.msg || err)
            localStorage.setItem('guri', guri)
            alert('您必须先绑定一个找我账号以使用')
            this.props.history.push('/signup/webuser')
        })
    }

  componentWillMount = async() => {
    document.title = "FindSelf找我 - 登录";
  }

  render() {
    // GA - PageView - Start
    let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
    ReactGA.pageview(pagePathForGA);
    // GA - PageView - End
    if(localStorage.getItem('user_email')){
      return <Redirect to='/home/'></Redirect>
    }else{
    return (
      <div class="container-fluid">
          <div class="row align-self-center justify-content-center">
            <div class="form-card p-3 my-2">
              <form class="">
                <ul class="nav justify-content-center">
                  {/*<li class="nav-item"><NavLink className="nav-link inactive" activeClassName="active" to='/signup/webuser'><h2>注册</h2></NavLink></li>*/}
                  <li class="nav-item"><NavLink className="nav-link" activeClassName="active" to='/login'><h2>登录</h2></NavLink></li>
                </ul>
                <div class="input-group mb-3">
                  <input type="text" name="email" value={this.state.email} onChange={this.handleEmail} placeholder="邮箱" class="form-control" required/>
                </div>
                <div class="input-group mb-3">
                  <input type={this.state.displayPassword?'text':'password'} name="password" value={this.state.password} onChange={this.handlePassword} placeholder="密码" class="form-control border-right" required/>
                  <div class="input-group-append">
                    <span class="input-group-text border-left" onClick={this.displayPassword}>
                      {this.state.displayPassword ? <i class="bi bi-eye"></i> : <i class="bi bi-eye-slash"></i> }
                    </span>
                  </div>
                </div>
                {this.state.errTimes<=5?
                <div class="d-grid pb-3">
                   <button id="submit" type="submit" className="btn btn-primary px-5" onClick={this.handleSubmit}>登录</button>
                </div>:
                <ReCAPTCHA sitekey={"6Ld3cGkaAAAAAOb87--wP7OL3OhIJ7AstH0ZWMhM"}
                    onChange={()=>{
                        utils.setLoginErrorTimes(0)
                        this.updateErrorTimes()
                    }}/>
                }
              </form>

              <div>
                <NavLink class="btn btn-third p-0" to="/forgetpassword">忘记密码?</NavLink>
              </div>

              {/* <div class="row align-items-center px-2 pt-2">
                <hr class="col"/>
                <p class="col text-center">其它登录方式</p>
                <hr class="col"/>
              </div>

              <div class="row mb-4">
                <div class="col text-center"></div>
                <div class="col text-center"><OAuthGOOGLE key="loginGoogle"  onSignIn={this.handleGoogleLogin}/></div>
                <div class="col text-center"><OAuthFaceBook key="loginFacebook"  onSignIn={this.handleFaceBookLogIn}/></div>
                <div class="col text-center"></div>
              </div> */}

              <div>
                <small>登录代表您已同意找我的<NavLink class="text-small text-dark-green" to='/agreements' target='_blank'>《用户服务协议》</NavLink>和<NavLink class="text-small text-dark-green" to='/privacy' target='_blank'>《隐私协议》</NavLink>。</small>
              </div>          
          </div>
        </div>
      </div>
    )
  }
}
}
export default withRouter(Login)
