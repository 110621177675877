import React, {Component} from 'react'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import "@pathofdev/react-tag-input/build/index.css";
import utils from './utils'
import xss, {FilterXSS} from 'xss'
import _ from './locale'
import axios from 'axios'

const xssInstance = new FilterXSS({
    css: false,
    onTagAttr: (tag, name, value, isWhiteAttr) => {
        if (name === "style") {
            return value
        }
    }
    // Parameters are the same with onTag
    // If a string is returned, the tag would be replaced with the string
    // If return nothing, the default measure would be taken (specifies using
    // escape, as described below)
})

const editorOption = {
    plugins: [
        'align',
        'image',
        'font',
        'link',
        'video',
    ],
    defaultStyle: "font-size: 18px;",
    buttonList: [
        ['undo', 'redo'],
        // ['font', 'fontSize', 'formatBlock'],
        // ['fontSize', 'formatBlock', 'fontColor'],
        // ['paragraphStyle', 'blockquote'],
        // ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        // ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        // ['outdent', 'indent'],
        // ['align', 'horizontalRule', 'list', 'lineHeight'],
        // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
        ['table', 'link', 'image', ],
        // ['imageGallery'], // You must add the "imageGalleryUrl".
        // ['fullScreen', 'showBlocks', 'codeView'],
        //['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        // ['save', 'template'],
        // '/', Line break
    ],
    resizingBar: false,
    width: "100%",
    minHeight: "300px",
    height: "auto",
    imageUploadSizeLimit: "5000000",
    imageMultipleFile: false,
    imageAccept: ".png,.jpg",


}


class RichEditBox extends Component {
    state = {

    }

    constructor(props) {
        super(props);
        this.editor = React.createRef();
    }

    getContents = () => (this.editor.current.editor.getContents());

    setContents = (msg) => {
        this.editor.current.editor.setContents(xss(msg || ""));
    }
    handleUpload = (files, info, uploadHandler) => {
        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append("ftype", files[0].name.split('.').reverse()[0]);
        axios.post(utils.getUrl('public/blog/imageUpload/'),
            formData,
            {
                headers: {
                    'Authorization': 'JWT ' + localStorage.getItem('loginToken'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(
            response => {
                uploadHandler(response.data);
            }
        ).catch(err => {
            // alert(err);
            uploadHandler({
                errorMessage: "err",
                result: []
            })

        })
        return undefined;

    }
    handleUploadError = (errorMessage) => {
        alert(errorMessage)
        return true
    }
    componentDidMount() {
        this.setContents(this.props.value || '')
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value){
            this.setContents(this.props.value || '')
        }
    }

    handleChange  = ()=>{
        const content = this.getContents();
        this.props.onChange(content);
    }

    render() {
        return <div className="about-root">
            <SunEditor className="blog-area"
                       ref={this.editor}
                       setOptions={editorOption}
                       onImageUploadBefore={this.handleUpload}
                       handleUploadError={this.handleUploadError}
                       onChange={this.handleChange}
            />
        </div>
    }
}

RichEditBox.defaultProps = {
    value: null,
    onChange: () => {

    }
}
export default RichEditBox;
