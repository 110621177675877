import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket'
import axios from 'axios'
import utils from './utils'
import Message from './utility/message'
import 'react-chat-elements/dist/main.css';
import {
    ChatList,
    SystemMessage,
} from 'react-chat-elements';

import talktobackground from '../imgs/talk-to-background.png'
import chatAvatar from '../imgs/chatAvatar.png'
import { TTMInfoCard } from './TTMInfoCard'
import ReactGA from 'react-ga'
import { ThumbDownOutlined, ThumbUpOutlined } from '@material-ui/icons'
import { Toast, ToastContainer } from 'react-bootstrap'

export const TalkToMePortal = (props) => {

    const [admin, setAdmin] = useState(localStorage.getItem('user_id') === '3737892023'? true : false)
    const location = useLocation()
    const [messagePool, setMessagePool] = useState([])
    const [dialogPool, setDialogPool] = useState(new Map())
    const [nickname, setNickname] = useState(admin ? '志愿者' : location.state.nickname)
    const [userId, setUserId] = useState('');
    const [apiHeaders, setAPIHeaders] = useState({})
    const [messageBoxPlaceHolder, setMessageBoxPlaceHolder] = useState('说点什么')
    const [activeMessage, setActiveMessage] = useState('')
    const [activeAIMessage, setActiveAIMessage] = useState({
        uuid: '',
        aiMsg: ''
    })
    const [currentDialog, setCurrentDialog] = useState(admin?
        {
            userid: "",
            nickname: ""
        }:
        {
            userid: "366",
            nickname: ""
        }
    )
    // create wss connection
    const [socketUrl, setSocketUrl] = useState('wss://dev.findself.org/ws/talktome/chat');
    const {
        sendJsonMessage,
        lastJsonMessage,
        readyState,
    } = useWebSocket(socketUrl, {
        queryParams: {
            "role": admin ? "Volunteer" : "Anonymous",
            "nickname": nickname
        },
        onOpen: () => {
            setConnectionStatus('在线')
            
        },
        onClose: () => {
            setConnectionStatus('离线')
        },
    })

    const [connectionStatus, setConnectionStatus] = useState('连接中...')

    // Admin Mode - Right Panel
    const [rightPanelSection, setRightPanelSection] = useState("GeneralMessage"); 

    // scroll to bottom for incoming message
    const scrollToBot = () => {
        var chatBase = document.getElementById('chatBase')
        chatBase.scrollTop = chatBase.scrollHeight
    }

    // componentDidMount equivalent
    useEffect(() => {
        // GA - PageView - Start
        let pagePathForGA = props.history.location.pathname + props.history.location.search
        ReactGA.pageview(pagePathForGA)
        // GA - PageView - End

        //const interval = setInterval(() => {
        //    fetchDialogs()
        //}, 5000)
        //scroll chat window
        scrollToBot()
    }, [])

    // initial loading for dialog and message
    useEffect(() => {
        if (userId !== '') fetchDialogs()
    }, [userId])

    const generateRandomMessageId = () => {
        return -(Math.floor(Math.random() * 10000))
    }

    useEffect(() => {
        if (lastJsonMessage === null) return
        var message = lastJsonMessage
        console.log(message)
        switch (message['msg_type']) {
            // receive unique user id, initialize chat
            case 0:
                setAPIHeaders(admin ? {
                    Authorization: 'JWT ' + localStorage.getItem('loginToken')
                } : {
                    role: 'Anonymous',
                    id: message.user_pk
                });
                setUserId(message.user_pk);
                break;
            // instant message
            case 3:
                if (dialogPool.has(message.sender)) {
                    setMessagePool(prev => [...prev, {
                        key: message.random_id,
                        id: message.random_id,
                        text: message.text,
                        source: 'remote',
                        sender_nickname: message.sender_nickname,
                        sender: message.sender,
                        recipient: message.receiver,
                        read: false,
                        aiResponse: '',
                        aiResponseId: ''
                    }])
                    updateDialogLocally(message.sender, message)
                } else {
                    //refresh dialog
                    fetchDialogs()
                }
                scrollToBot()
                document.getElementById("messagebox").removeAttribute("readonly")
                break;
            // massage id mapping
            case 8:
                setMessagePool(prev => {
                    const newMessagePool = [...prev]
                    for (let i = 0; i < newMessagePool.length; i++) {
                        if (message.random_id === newMessagePool[i].id) {
                            newMessagePool[i].id = message.db_id
                            newMessagePool[i].key += message.db_id
                            if (newMessagePool[i].sender === currentDialog.userid) markMessageRead(newMessagePool[i])
                        }
                    }
                    return newMessagePool
                })
                break;
            // new unread count
            case 9:
                setDialogPool(prev => new Map(prev).set(message.sender, { ...prev.get(message.sender), unread: message.unread_count}))
                break;
            // AI response
            case 11:
                console.log(message)
                setMessagePool(prev => {
                    return prev.map(msg => (
                        msg.id === message.msg_id? {...msg, key: msg.key+message.uuid, aiResponse: message.text, aiResponseId: message.uuid}:msg
                    ))
                })
                break;
            // user leave chat
            case 2:
                if(!admin) return;
                setDialogPool(prev => {
                    prev.delete(message.user_pk)
                    return prev
                })
                setMessagePool(prev => prev.filter(msg => msg.sender !== currentDialog.userid && msg.recipient !== currentDialog.userid))
                if (message.user_pk === currentDialog.userid) {
                    setCurrentDialog({
                        userid: '',
                        nickname: " [" + currentDialog.nickname + "] 已离开对话"
                    })
                    document.getElementById("messagebox").setAttribute("readonly", true)
                    setActiveMessage('')
                }
                break;
            default:
                break;
        }
    }, [lastJsonMessage])

    const markMessageRead = (message) => {
        if (message.sender === undefined || message.id === undefined || message.id <= 0) return
        const data = {
            msg_type: 6,
            user_pk: message.sender,
            message_id: message.id
        }
        sendJsonMessage(data)
        setMessagePool(prev => {
            const newMessagePool = [...prev]
            for (let i = 0; i < newMessagePool.length; i++) {
                if (newMessagePool[i].id === message.id) {
                    newMessagePool[i].read = true
                }
            }
            return newMessagePool
        })
    }

    useEffect(() => {
        scrollToBot()
    }, [messagePool, currentDialog, scrollToBot])

    const handleMessageChange = (e) => {
        setActiveMessage(e.target.value)
    }

    const handleEnterEvent = (e) => {
        if (e.key === 'Enter') handleMessageSend()
    }

    const handleMessageSend = () => {
        const text = activeMessage.trim().replace(/^\n|\n$/g, '')
        if (text === "" || text === undefined) return;
        const randomMessageId = generateRandomMessageId()
        var data = {
            key: randomMessageId,
            msg_type: 3,
            text: text,
            random_id: randomMessageId,
            user_pk: currentDialog.userid,
            recipient: currentDialog.userid,
            source: "local"
        }
        sendJsonMessage(data);
        setMessagePool(prev => [...prev, data])
        updateDialogLocally(currentDialog.userid, data)
        // handle ai response feedback
        if (activeAIMessage.uuid !== '') {
            var aiFeedbackData = {
                msg_type: 12,
                uuid: activeAIMessage.uuid,
                response: activeAIMessage.aiMsg
            }    
            if (activeAIMessage.aiMsg === activeMessage) {
                aiFeedbackData.usage = 1
                aiFeedbackData.score = 10
            } else {
                aiFeedbackData.usage = 2
                aiFeedbackData.score = 5
            }
            sendJsonMessage(aiFeedbackData);
        }
        // reset activeMessage and activeAIMessage
        setActiveAIMessage({
            uuid: '',
            aiMsg: ''
        })
        setActiveMessage('')
    }

    const sendFirstMessageFromUser = (message) => {
        const randomMessageId = generateRandomMessageId()
        var data = {
            key: randomMessageId,
            msg_type: 3,
            text: message,
            random_id: randomMessageId,
            user_pk: currentDialog.userid,
            recipient: currentDialog.userid,
            source: "local"
        }
        sendJsonMessage(data);
    }

    const fetchDialogs = () => {
        axios.get(utils.getUrl('talktome/dialogs/'),
            { headers: apiHeaders })
            .then((response) => {
                const tempDialogPool = new Map();
                for (const dialog of response.data.data) {
                    tempDialogPool.set(dialog.other_user_id, {
                        avatar: chatAvatar,
                        alt: 'findself',
                        title: dialog.nickname,
                        subtitle: dialog.last_message.text,
                        date: new Date(dialog.last_message.sent * 1000),
                        unread: dialog.unread_count,
                        userid: dialog.other_user_id
                    })
                }
                if (!admin) tempDialogPool.set( "366", {})
                setDialogPool(tempDialogPool)
                fetchMessage()
            }).catch(err => {
                console.log(err)
            })
    }

    const fetchMessage = () => {
        axios.get(utils.getUrl('talktome/messages/'),
            { headers: apiHeaders })
            .then((response) => {
                const tempMessagePool = []
                for (const message of response.data.data) {
                    tempMessagePool.unshift({
                        key: message.id,
                        id: message.id,
                        text: message.text,
                        source: message.out ? 'local' : 'remote',
                        sent_time: new Date(message.sent * 1000),
                        sender_nickname: message.sender_nickname,
                        sender: message.sender,
                        recipient: message.recipient,
                        read: message.read,
                        aiResponse: message.ai_suggestion ? message.ai_suggestion.suggestion:'',
                        aiResponseId: message.ai_suggestion ? message.ai_suggestion.uuid : ''
                    })
                }
                setMessagePool(tempMessagePool)
                // send user's first message
                if (!admin) sendFirstMessageFromUser("你好")
            }).catch(err => {
                console.log(err)
            })
    }

    const selectDialog = (dialog) => {
        setCurrentDialog({
            userid: dialog.userid,
            nickname: dialog.title
        })
        document.getElementById("messagebox").removeAttribute("readonly")
        // mark all messages in open dialog as read
        const unreadMsgInSelectedDialog = messagePool.filter(msg => msg.sender === dialog.userid && msg.read === false && msg.source === 'remote')
        for (let i = 0; i < unreadMsgInSelectedDialog.length; i++) {
            markMessageRead(unreadMsgInSelectedDialog[i])
        }
    }

    const updateDialogLocally = (userid, message) => {
        // mark read
        var dialog = dialogPool.get(userid)
        if (message.source === "remote" && userid === currentDialog.userid) {
            markMessageRead(message)
        }
        dialog.subtitle = message.text
        dialog.date = new Date()
    }

    const populateTextarea = (aiResponse, aiResponseId) => {
        if (aiResponse&&aiResponseId) {
            setActiveMessage(aiResponse)
            setActiveAIMessage({
                uuid: aiResponseId,
                aiMsg: aiResponse
            })
        }
    }


    return (
        <div className="pt-1">
            {/* 开篇 */}
            <div className="container-fluid main-content px-lg-5 pt-3 pb-5" style={{ backgroundImage: `url(${talktobackground})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
                <div className='row justify-content-center'>
                    {!admin ? "" :
                        <div className='col-10 col-md-2 px-0' style={{ height: 609, overflow: 'auto', backgroundColor: '#f3f3f3', borderRadius: '8px 8px 8px 8px', boxShadow: '3px 3px 5px 1px' }}>
                            <ChatList
                                onClick={(item, i, e) => selectDialog(item)}
                                className='chat-list'
                                dataSource={[...dialogPool.values()]} />
                        </div>
                    }
                    <div className='col-md-6 col-12'>
                        <div className="d-flex flex-row justify-content-between align-items-center py-2" style={{ color: '#43B0B1', backgroundColor: 'white', borderRadius: '8px 8px 0 0', boxShadow: '3px 3px 5px 1px' }}>
                            <div style={{ paddingLeft: 8 }}><h3><strong>找我说 {admin?currentDialog.nickname:nickname}</strong></h3></div>
                            <span class="badge rounded-pill me-3" style={{ backgroundColor: '#43B0B1' }}><strong>{connectionStatus}</strong></span>
                        </div>
                        <div id="chatBase" className="d-flex flex-column pt-0 px-3" style={{ backgroundColor: 'rgba(243, 243, 243, 0.8)', height: 500, overflow: 'auto', boxShadow: '3px 3px 5px 1px' }} >
                            {admin?'':
                                <div>
                                    <SystemMessage text={"欢迎来到找我说，正在为您匹配小伙伴。"} />
                                    <SystemMessage text={"等待期间，您可以了解我们的其他服务："} />
                                    <TTMInfoCard showTTM={false} />
                                </div>
                            }
                            {messagePool.filter(msg => msg.sender === currentDialog.userid || msg.recipient === currentDialog.userid )
                                .map((message) => <Message key={message.key} reversed={message.source === 'local' ? true : false}
                                    message={message.text} aiResponse={message.aiResponse} aiResponseId={message.aiResponseId}
                                    populateTextarea={populateTextarea} admin={admin} />)}
                        </div>
                        <div className="d-flex flex-column pt-0" style={{ position: 'relative' }}>
                            <textarea id="messagebox" placeholder={messageBoxPlaceHolder} maxLength="200" rows="2" cols="50" value={activeMessage}
                                style={{ borderRadius: '0 0 8px 8px', borderStyle: 'none', boxShadow: '3px 3px 5px 1px' }}
                                onChange={handleMessageChange} onKeyUp={handleEnterEvent} readOnly={true}>
                            </textarea>
                            {activeMessage !== '' ?
                                <button id="sendMessage" style={{
                                    height: '50%', width: '8%', minWidth: 50, zIndex: 99, position: 'absolute', right: 5, bottom: -35,
                                    borderRadius: '8px 8px 8px 8px', borderStyle: 'none', backgroundColor: '#43B0B1', color: 'white'
                                }} onClick={handleMessageSend} >发送</button>
                                : ''
                            }
                        </div>
                    </div>
                    {!admin ? "" :
                        <div className='col-10 col-md-2 p-2' style={{ height: 609, overflow: 'auto', backgroundColor: 'white', borderRadius: '8px 8px 8px 8px', boxShadow: '3px 3px 5px 1px' }}>
                            <div className='d-flex flex-row justify-content-around'>
                                <div className={"flex-sm-fill text-sm-center nav-link " + (rightPanelSection === "GeneralMessage" ? 'active':'')} onClick={()=>setRightPanelSection("GeneralMessage")}>通用</div>
                                <div className={"flex-sm-fill text-sm-center nav-link " + (rightPanelSection === "CustomMessage" ? 'active' : '')}>自定义</div>
                                <div className={"flex-sm-fill text-sm-center nav-link " + (rightPanelSection === "Tutorial" ? 'active' : '')} onClick={() => setRightPanelSection("Tutorial")}>教程</div>
                            </div>

                            {rightPanelSection !== "GeneralMessage" ? "" :
                                <div id="GeneralMessage">
                                    <div className='mt-4'>
                                        <div className="" style={{ fontSize: 12, backgroundColor: '#F4F4F4', margin: 5, padding: 10 }}>
                                            你好，欢迎来找我说，有什么想和猫猫聊聊的吗？
                                        </div>
                                        <div className='d-flex flex-row justify-content-between' style={{ padding: 5, fontSize: 15 }}>
                                            <ThumbUpOutlined />
                                            <ThumbDownOutlined />
                                            <div>
                                                复制
                                            </div>
                                            <div>
                                                发送
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-4'>
                                        <div className="" style={{ fontSize: 12, backgroundColor: '#F4F4F4', margin: 5, padding: 10 }}>
                                            欢迎来到找我说～我是找我说小伙伴，为你提供私密的线上文字支持，每次支持时间为60分钟左右。我们并不具备处理危机的能力。。。
                                        </div>
                                        <div className='d-flex flex-row justify-content-between' style={{ padding: 5, fontSize: 15 }}>
                                            <ThumbUpOutlined />
                                            <ThumbDownOutlined />
                                            <div>
                                                复制
                                            </div>
                                            <div>
                                                发送
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-4'>
                                        <div className="" style={{ fontSize: 12, backgroundColor: '#F4F4F4', margin: 5, padding: 10 }}>
                                            你好，欢迎来找我说。
                                        </div>
                                        <div className='d-flex flex-row justify-content-between' style={{ padding: 5, fontSize: 15 }}>
                                            <ThumbUpOutlined />
                                            <ThumbDownOutlined />
                                            <div>
                                                复制
                                            </div>
                                            <div>
                                                发送
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                            {rightPanelSection !== "Tutorial" ? "" :
                                <div id="Tutorial">
                                    <ToastContainer className='mt-4'>
                                        <Toast>
                                            <Toast.Header closeButton={false}>
                                                <strong className="me-auto">TIPS</strong>
                                                <small className="text-muted">Findself</small>
                                            </Toast.Header>
                                            <Toast.Body>欢迎使用找我说咨询师界面: 咨询者结束对话后对话框会自动消失</Toast.Body>
                                        </Toast>
                                        <Toast>
                                            <Toast.Header closeButton={false}>
                                                <strong className="me-auto">TIPS</strong>
                                                <small className="text-muted">Findself</small>
                                            </Toast.Header>
                                            <Toast.Body>
                                                咨询者主信息会带有副信息(为AI根据主信息生成的反馈), 点击可以直接复制到对话框使用:
                                                <Message reversed={false}
                                                    message={'今天上学遇到了一些问题'} aiResponse={'可以具体说说吗'}
                                                    populateTextarea={populateTextarea} admin={admin} />
                                            </Toast.Body>
                                        </Toast>
                                    </ToastContainer>
                                </div>
                            }

                        </div>
                    }
                </div>
            </div>
        </div>
    )
}