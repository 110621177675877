import React, { Component } from 'react'
import axios from 'axios'
import {NavLink, withRouter} from 'react-router-dom'
import {Container, Row, Col, Button, ListGroupItem, ListGroup, Modal} from 'react-bootstrap'
import avatar from '../assets/website_logo.png'
import ReactLoading from 'react-loading';
import { NavHashLink } from 'react-router-hash-link';
import {Loader} from "@googlemaps/js-api-loader"
import utils from './utils'
import $ from 'jquery'
import 'react-dropdown/style.css';

import '../css/search-consultant.css'


const loader = new Loader({
    apiKey: "AIzaSyBBQRhVGiiczP5Bck0TItM7ogviJq39LNw",
    version: "weekly",
    libraries: ["places"]
});
const pageSize = 10;

class SearchConsultant extends Component {
  constructor(props) {
    super(props);
    this.orderSelect = React.createRef();
    this.loadMore = React.createRef();
  }
  checkboxRefs = []
  dropdownRefs = []
  state = {
    allTags: [],
    selectedTagList: [], // checked tags
    therapists: [], // search results
    areaList: [], // 擅长领域checkbox
    methodList: [{'value': 'Voice', id: '1', 'value_cn': '语音咨询'}, {'value': 'video', id: '2', 'value_cn': '视频咨询'}, {'value': 'Onsite', id: '3', 'value_cn': '线下咨询'}], // 咨询方式checkboxes
    cityList: [], // 城市checkbox
    genderList: [{'value': 'male', 'id': 1, 'value_cn': '男'}, {'value': 'female', id: 2, 'value_cn': '女'}], // 性别checkboxes
    groupList: [], // 面向群体checkbox
    langList: [], // 语言CheckBox
    distanceList: [{'id': 1, 'value_cn': '不限距离', 'value': null}, {'id': 2, 'value_cn': '5 Mile以内', 'value': [0,5]}, {'id': 3, 'value_cn': '5-20 Mile', 'value': [5,20]}, {'id': 4, 'value_cn': '20-100 Mile', 'value': [20,100]}],
    isAreaDisplay: 'none',
    isMethodDisplay: 'none',
    isCityDisplay: 'none',
    isGenderDisplay: 'none',
    isGroupDisplay: 'none',
    isLangDisplay: 'none',
    blankDisplay: 'block',
    searchKeyword: '',
    cityInput: '',
    geo:{},
    citySelected: [],
    stateSelected: [],
    suggestions: [],
    start: 0, // pagination
    end: pageSize, // pagination
    isSearched: false,
    isLoading: false,
    isLoadMore: false,
    isQuestionLoading: false,
    order: '-visits', // search results order
    admin_order: 2, // profile status filter
    distance: [0, 5],
    showModal: false,
    modalText: '',
    questions: [],
    exact: true,
    searchCount: 0,
    scrollPosition: 0,
    displayAllArea: window.innerWidth > 1360,
    displayAllGroup: window.innerWidth > 1360,
    displayCitySelect: false,
    displayTherapistSelect: false,
    isDistanceOrder: false,
    isDistanceAscending: false,
    isVisitsOrder: true,
    isVisitsAscending: false,
    isPriceOrder: false,
    isPriceAscending: false,
    q_a_height: 500,
  }
  // when a tag is selected or unselected
  handleFilter = (event) => {
    let tempList = this.state.selectedTagList
    if(event.target.id.includes('distance')){
      document.querySelectorAll('#distance-filter input').forEach(elem=>{
        if(elem.id!==event.target.id){
          elem.checked = false
          tempList = tempList.filter((value) => {
            return value.tag_id !== elem.id
          })
        }
      })
    }
    console.log(event.target.name);
    console.log(event.target.id);
    console.log(event.target.dataset.number);
    if(event.target.checked){
      tempList.push({'value': event.target.name, 'tag_id': event.target.id, 'number': parseInt(event.target.dataset.number)})
    }else{
      tempList = tempList.filter((value) => {
        return value.tag_id !== event.target.id
      })
    }
    this.setState({selectedTagList: tempList.sort((a,b) => (a.value).localeCompare(b.value))})
  }
  resetFilter = async() => {
    this.state.selectedTagList.forEach(elem =>{
      this.checkboxRefs[elem.tag_id].click()
    })
    await this.setState({selectedTagList: []})
    this.handleSearch(true);
  }
  // delete a selected tag
  deleteTag = (event) => {
    let name = event.target.title
    this.checkboxRefs[name].click()
  }
  // 显示更多
  handlePagination = async() => {
    if(this.state.isLoading){return}

    // 滑到底部加载更多
    var top = document.querySelector("body").scrollTop
    var height = window.innerHeight
    var total = $(document).height()
    if(total - height - top > 10){
      console.log(total)
      console.log(height)
      console.log(top)
      console.log("return1")
      return
    }
    if(!this.state.isSearched){
      return false;
    }

    if(this.state.end+pageSize > this.state.totalResults){
      return false;
    }
    let currentStart = this.state.start
    let currentEnd = this.state.end
    await this.setState({start: currentStart+pageSize, end: currentEnd+pageSize, isLoadMore: true}, () => {this.handleSearch(false)})
  }
  // change the display order of search results
  handleOrder = async() => {
    const node = this.orderSelect.current;
    await this.setState({order: node.value})
    this.handleSearch(true)
  }
  handleAdminOrder = async() => {
    const node = this.orderSelect.current;
    await this.setState({admin_order: parseInt(node.value)})
    this.handleSearch(true)
  }
  // open a therapist' s profile page
  openProfilePage = (profile_id, user_id) => {
    const url = `/therapist/view/:${profile_id}`
    this.props.history.push({pathname: url, state: {user_id: user_id}})
  }

  setGeo = async(keyword)=> {
      if (!window.google) {
          loader.load().then(()=>{this.setGeo(keyword)})
          return
      }

      const geocoder = new window.google.maps.Geocoder()
      const buffer = this
      await geocoder.geocode({'address': keyword}, function (results, status) {
          if (status == 'OK' && results[0]) {
              const geo = [results[0]?.geometry.location.lng(), results[0].geometry.location.lat()]
            const fmt_addr = results[0]?.address_components.filter((ele)=>ele?.types?.includes('political')).map(
                (ele)=>ele?.long_name
            ).join()
              buffer.setState({geo: geo, fmt_addr: fmt_addr})
          } else {
              buffer.setState({geo: [], fmt_addr:""})
          }
      })
  }

  handleSearch = async(isReset, event) => {
    if(event){event.preventDefault()}
    // reset pagination
    if(isReset){this.setState({start: 0, end: pageSize, therapists: []})}

    // display loading animation
    this.setState({isLoading: true})
    // 分类tag list
    let tempList = []
    let tempContactList = []
    let tempGenderList = []
    let tempCityList = []
    let tempDistance = []

    this.state.selectedTagList.map((tag) => {
      if(tag.tag_id.includes('contact')){
        tempContactList.push(tag.number)
      }
      else if(tag.tag_id.includes('gender')){
        tempGenderList.push(tag.number)
      }
      else if(tag.tag_id.includes('city-')){
        tempCityList.push(tag.value)
      }
      else if(tag.tag_id.includes('distance')){
        if(tag.value === '2'){tempDistance = [0,5]}
        else if(tag.value === '3'){tempDistance = [5,20]}
        else if(tag.value === '4'){tempDistance = [20,100]}
        else{tempDistance = null}
      }
      else{
        tempList.push(tag.tag_id)
      }

    })

    if(tempDistance && tempDistance.length>0 && this.state.cityInput===''){
      alert('请输入一个城市！')
    }

    // request search result
    if(localStorage.getItem('user_type') && localStorage.getItem('user_type') <= 2){
      await axios.post(utils.getUrl('profile/approval-list/'),
      {'tags': tempList, 'contact_method': tempContactList, 'gender': tempGenderList, 'citystates': this.state.cityInput,
      'keyword_name': this.state.searchKeyword, 'start': this.state.start, 'end': this.state.end, 'distance': this.state.cityInput===''?null:[0,20],
      'profile_status': (this.state.admin_order===10)?[0,1,2,3,4,5]:[this.state.admin_order]},
      {headers:{'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
      .then(response => {
        var dataObject = response.data.data
        if(dataObject.length <= 0) {
          alert('未找到任何咨询师！')
          this.setState({isLoading: false, totalResults: 0})
        }else{
          let userList = []
          if(!isReset){userList = this.state.therapists}
          // 判断title
          dataObject.map(elem => {
            if(elem.title === 1){elem.title = 'Dr. '}
            else if(elem.title === 2){elem.title = 'Prof. '}
            else{elem.title = ''}
            userList.push(elem) // append user
            return false;
          })
          this.setState({therapists: userList, isSearched: true, isLoading: false, isLoadMore:false, totalResults: response.data.total,
              exact:response.data.exact})
        }
      }).catch(err => {
        this.setState({isLoading: false, totalResults: 0})
      })
    }else{
      await axios.post(utils.getUrl('public/filter-by-tag/'),
      {'tags': tempList, 'contact_method': tempContactList, 'gender': tempGenderList, 'citystates': this.state.cityInput, 'distance': this.state.cityInput===''?null:[0,20],
      'keyword_name': this.state.searchKeyword, 'start': this.state.start, 'end': this.state.end, 'order': this.state.order})
      .then(response => {
        var dataObject = response.data.data
        
        if(dataObject.length <= 0) {
          alert('未找到任何咨询师！')
          this.setState({isLoading: false, totalResults: 0})
        }else{
          let userList = []
          if(!isReset){userList = this.state.therapists}
          // 判断title
          dataObject.map(elem => {
            if(elem.title === 1){elem.title = 'Dr. '}
            else if(elem.title === 2){elem.title = 'Prof. '}
            else{elem.title = ''}
            userList.push(elem) // append user
            return false;
          })
          this.setState({therapists: userList, isSearched: true, isLoading: false, isLoadMore: false, totalResults: response.data.total,
              exact: response.data.exact})

        }
      }).catch(err => {
        this.setState({isLoading: false, totalResults: 0})
      })
    }
    if(!isReset){ // scroll window position
      const offset = document.querySelector('#searchResults')?.getBoundingClientRect() || 0
      document.querySelector("body").scrollTo(0, this.state.scrollPosition)
      this.setState({scrollPosition: offset.height})
    }

    var element = document.getElementById('searchResults')
    if (element) {
      this.setState({q_a_height: element.offsetHeight})     
    }

    return false
  }

  getPosition = (element)=> {
    var xPosition = 0;
    var yPosition = 0;

    while(element) {
        xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }

    return yPosition;
  }

  handleSearchInput = async(event) => {
    this.setState({searchKeyword: event.target.value})
    if(this.state.searchKeyword.length > 0){
      this.setState({displayTherapistSelect: true})
    }else{
      this.setState({displayTherapistSelect: false})
    }
    // this.setGeo(event.target.value)
  }

  handleAreaDropdown = () => {
    if(this.state.isAreaDisplay === 'none'){
      this.setState({isAreaDisplay: 'block', isMethodDisplay: 'none', isGroupDisplay: 'none', isGenderDisplay: 'none', isCityDisplay: 'none', isLangDisplay: 'none'})
    }else{
      this.setState({isAreaDisplay: 'none'})
    }
  }
  handleMethodDropdown = () => {
    if(this.state.isMethodDisplay === 'none'){
      this.setState({isMethodDisplay: 'block', isAreaDisplay: 'none', isGroupDisplay: 'none', isGenderDisplay: 'none', isCityDisplay: 'none', isLangDisplay: 'none'})
    }else{
      this.setState({isMethodDisplay: 'none'})
    }
  }
  handleCityDropdown = () => {
    if(this.state.isCityDisplay === 'none'){
      this.setState({isCityDisplay: 'block', isMethodDisplay: 'none', isGroupDisplay: 'none', isGenderDisplay: 'none', isAreaDisplay: 'none', isLangDisplay: 'none'})
    }else{
      this.setState({isCityDisplay: 'none'})
    }
  }
  handleGenderDropdown = () => {
    if(this.state.isGenderDisplay === 'none'){
      this.setState({isGenderDisplay: 'block', isMethodDisplay: 'none', isGroupDisplay: 'none', isAreaDisplay: 'none', isCityDisplay: 'none', isLangDisplay: 'none'})
    }else{
      this.setState({isGenderDisplay: 'none'})
    }
  }
  handleGroupDropdown = () => {
    if(this.state.isGroupDisplay === 'none'){
      this.setState({isGroupDisplay: 'block', isMethodDisplay: 'none', isAreaDisplay: 'none', isGenderDisplay: 'none', isCityDisplay: 'none', isLangDisplay: 'none'})
    }else{
      this.setState({isGroupDisplay: 'none'})
    }
  }
  handleLangDropdown = () => {
    if(this.state.isLangDisplay === 'none'){
      this.setState({isLangDisplay: 'block', isMethodDisplay: 'none', isAreaDisplay: 'none', isGenderDisplay: 'none', isCityDisplay: 'none', isGroupDisplay: 'none'})
    }else{
      this.setState({isLangDisplay: 'none'})
    }
  }

  changeDistanceOrder = async() => {
    if(this.state.isDistanceOrder){
      if(this.state.isDistanceAscending){
        await this.setState({isDistanceAscending: false, order: '-distance'})
      }else{
        await this.setState({isDistanceAscending: true, order: '+distance'})
      }
    }else{
      await this.setState({isDistanceOrder: true, isDistanceAscending: true, order: '+distance',
      isVisitsOrder: false, isPriceOrder: false})
    }
    this.handleSearch(true)
  }

  changeVisitsOrder = async() => {
    if(this.state.isVisitsOrder){
      if(this.state.isVisitsAscending){
        await this.setState({isVisitsAscending: false, order: '-visits'})
      }else{
        await this.setState({isVisitsAscending: true, order: '+visits'})
      }
    }else{
      await this.setState({isVisitsOrder: true, isVisitsAscending: true, order: '+visits',
      isDistanceOrder: false, isPriceOrder: false})
    }
    this.handleSearch(true)
  }

  changePriceOrder = async() => {
    if(this.state.isPriceOrder){
      if(this.state.isPriceAscending){
        await this.setState({isPriceAscending: false, order: '-price'})
      }else{
        await this.setState({isPriceAscending: true, order: '+price'})
      }
    }else{
      await this.setState({isPriceOrder: true, isPriceAscending: true, order: '+price',
      isDistanceOrder: false, isVisitsOrder: false})
    }
    this.handleSearch(true)
  }

  resetForm = (e) => {
    e.preventDefault()
    document.getElementById("domain-search").reset()
    this.setState({searchKeyword: ''})
  }
  resetCity = (e) => {
    e.preventDefault()
    document.getElementById("domain-search").reset()
    this.setState({cityInput: '', citySelected: '', stateSelected: ''})
  }
  // change element position in array
  array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }
  backtoTop = () =>{
    document.querySelector("body").scrollTo(0, 0)
  }
    // 跳转广告管理界面
    forwardAdManage = (banner_id) =>{
      this.props.history.push({pathname: `/admanage/:${banner_id}`})
    }

  componentDidMount = async() => {
    this.props.setDisplayURL();
    window.debug_c = this;
    window.addEventListener('scroll', this.handlePagination);
    // document.body.addEventListener('scroll', this.handlePagination);
    // get all tags
    await utils.loadTagList();
    this.setState(
        {allTags:window.tagList.allTags}
    )
    // get city list
    await axios.get(utils.getUrl('public/cities/'))
    .then(response => {
      let tempCity = response.data.data
      tempCity.map(city => {
        if(city === ''){
          tempCity.splice(tempCity.indexOf(city), 1)
        }
      })
      this.setState({cityList: tempCity.sort()})
    }).catch(err => {
      // alert(err)
    })

    // 分类tag list
    let list = this.state.allTags
    let tempArea = this.state.areaList
    let tempGroup = this.state.groupList
    let tempLang = this.state.langList
    list.map(tag => {
      var cate = ((""+tag.tag_id).split(""))[0]
      if(cate === '2'){
        tempArea.push(tag)
      }else if(cate === '3'){
        tempGroup.push(tag)
      }else if(cate === '7'){
        tempLang.push(tag)
      }
      return null
    })
    // 将[其他]移到最后
    const area_pos = tempArea.map(function(e) { return e.tag_id; }).indexOf(200001);
    this.array_move(tempArea, area_pos, tempArea.length-1)
    const group_pos = tempGroup.map(function(e) { return e.tag_id; }).indexOf(300001);
    this.array_move(tempGroup, group_pos, tempGroup.length-1)

    this.setState({areaList: tempArea, groupList: tempGroup, langList: tempLang, isQuestionLoading: true})

    //get q&a Questions
    await axios.get(utils.getUrl('cms/questions/list/critical/?lang=cn'))
    .then(response => {
      this.setState({questions: response.data.data.data, isQuestionLoading: false})
    }).catch(err => {
      // alert(err)
    })
    this.handleSearch(false)

    window.addEventListener('resize', this.updateDisplayStatus);
  }

  toggleAllArea = ()=>{
    this.setState({displayAllArea: !this.state.displayAllArea})
  }
  toggleAllGroup = ()=>{
    this.setState({displayAllGroup: !this.state.displayAllGroup})
  }

  getSuggestions = () => {
    const inputValue = this.state.cityInput.trim().toLowerCase()
    const inputLength = inputValue.length;
    let suggestions = inputLength === 0 ? [] : this.state.cityList.filter(elem =>
      elem.city.toLowerCase().slice(0, inputLength) === inputValue ||
      elem.state.toLowerCase().slice(0, inputLength) === inputValue
    )
    this.setState({suggestions: suggestions})
  }
  handleCityInputChange = async(event) => {
    await this.setState({cityInput: event.target.value})
    this.getSuggestions()
    if(this.state.cityInput.length>0){
      this.setState({displayCitySelect: true})
    }else{
      this.setState({displayCitySelect: false})
    }
  }
  handleCitySelect = (city, state) => {
    let tempCity = []
    tempCity.push(city+', '+state)
    this.setState({cityInput: city+', '+state, citySelected: tempCity, displayCitySelect: false})
  }

  updateDisplayStatus = async(event) => {
    if (window.innerWidth > 1360) {
      this.setState({displayAllArea: true})
      this.setState({displayAllGroup: true})
    } else {
      this.setState({displayAllArea: false})
      this.setState({displayAllGroup: false})
    }
  }

  render() {
    let tempCity = []
    let area_index = 0
    let group_index = 0
    let arealist = this.state.areaList
    let methodlist = this.state.methodList
    let genderlist = this.state.genderList
    let grouplist = this.state.groupList
    let distanceList = this.state.distanceList
    return (
      <div>
        {/* title */}
        <div className="container-fluid px-lg-10 pt-5 pb-2">
          <div className="row g-1 g-lg-4"><div className="col-12"><h1>找我咨询</h1></div></div>
        </div>

        {/* search bar */}
        <div class="container-fluid grey-box px-lg-10 py-4">
          <div class="row">
            <div class="input-group mb-3 max-width-960">
              <span class="input-group-text border-color hide-mobile"><i class="bi bi-geo-alt"></i></span>
              <input type="text" class="form-control border-color max-width-30" placeholder="城市/邮编" value={this.state.cityInput} onChange={this.handleCityInputChange}/>
              <div className="dropdown-content" style={{display: this.state.displayCitySelect?'block':'none'}}>
                  {(this.state.suggestions===[]?this.state.cityList:this.state.suggestions).map(elem => <a href='javascript:;' onClick={() => this.handleCitySelect(elem.city, elem.state)}>{elem.city}, {elem.state}</a>)}
              </div>
              <span class="input-group-text border-color">|</span>
              <span class="input-group-text border-color hide-mobile"><i class="bi bi-search"></i></span>
              <input type="text" class="form-control border-color" placeholder="咨询师名字" onChange={(event) => this.handleSearchInput(event)}/>
              <span class="input-group-text border-color">
                <button onClick={(event)=> this.handleSearch(true, event)} class="btn btn-primary hide-mobile">立即搜索</button>
                <button onClick={(event)=> this.handleSearch(true, event)} class="btn btn-primary hide-desktop">搜索</button>
              </span>
              <button onClick={this.resetFilter} class="btn btn-third text-small mx-2">清除全部</button>
            </div>
          </div>

          {/* 擅长领域  */}
          <div className="row mb-3">
            <div className="col-lg-1 col-12 hide-desktop">擅长领域</div>
            <div className="col-lg-11 col-12">
                <span class="hide-mobile me-2">擅长领域&nbsp;</span>
              {
                arealist.map((tag) => {
                  return(
                    <span className="d-inline-block me-2">
                      <input type="checkbox" onClick={event => this.handleFilter(event)} ref={instance => this.checkboxRefs[tag.tag_id] = instance}
                      name={tag.value_cn} className="hidden" id={tag.tag_id} key={tag.tag_id}/>
                      <label for={tag.tag_id} className='badge rounded-pill pill-text-outline'>{tag.value_cn}</label>
                    </span>
                )})
              }
            </div>   
          </div>

          {/* 面向群体 */}
          <div className="row mb-3">
            <div className="col-lg-1 col-12 hide-desktop">面向群体</div>
            <div className="col-lg-11 col-12">
                <span class="hide-mobile me-2">面向群体&nbsp;</span>
              {
                grouplist.map((tag) => {
                  return(
                    <span className="d-inline-block me-2">
                      <input type="checkbox" onClick={event => this.handleFilter(event)} ref={instance => this.checkboxRefs[tag.tag_id] = instance}
                      name={tag.value_cn} data-number={tag.tag_id} className="hidden" id={tag.tag_id} key={tag.tag_id}/>
                      <label for={tag.tag_id} className='badge rounded-pill pill-text-outline'>{tag.value_cn}</label>
                    </span>
                )})
              }
            </div>   
          </div>

          {/* 咨询方式 */}
          <div className="row mb-3">
            <div className="col-lg-1 col-12 hide-desktop">咨询方式</div>
            <div className="col-lg-11 col-12">
                <span class="hide-mobile me-2">咨询方式&nbsp;</span>
              {
                methodlist.map((tag) => {
                  return(
                    <span className="d-inline-block me-2">
                      <input type="checkbox" onClick={event => this.handleFilter(event)} ref={instance => this.checkboxRefs['contact'+tag.id] = instance}
                        name={tag.value_cn} data-number={tag.id} className="hidden" id={'contact' + tag.id} key={tag.tag_id}/>
                      <label for={'contact' + tag.id} className='badge rounded-pill pill-text-outline'>{tag.value_cn}</label>
                    </span>
                )})
              }
            </div>   
          </div>

          {/* 咨询师性别 */}
          <div className="row mb-3">
            <div className="col-lg-1 col-12 hide-desktop">咨询师性别</div>
            <div className="col-lg-11 col-12">
                <span class="hide-mobile me-2">咨询师性别&nbsp;</span>
              {
                genderlist.map((tag) => {
                  return(
                    <span className="d-inline-block me-2">
                      <input type="checkbox" onClick={event => this.handleFilter(event)} ref={instance => this.checkboxRefs['gender'+tag.id] = instance}
                        name={tag.value_cn} data-number={tag.id} className="hidden" id={'gender' + tag.id} key={tag.tag_id}/>
                        <label for={'gender' + tag.id} className='badge rounded-pill pill-text-outline'>{tag.value_cn}</label>
                    </span>
                )})
              }
            </div>   
          </div> 
        </div>

        <div class="container-fluid px-lg-10 py-5 ms-2 ms-lg-0">
          <div class="row align-items-start">
            <div class="row col-12 col-lg-8">
              <div class="col-lg-6 col-12">
                <small>为您搜索到{this.state.totalResults}个咨询师</small>
              </div>

              {(localStorage.getItem('user_type') && localStorage.getItem('user_type') <= 2)?(
                  <div className='custom-select'>
                    <span>分类:</span>
                    <select name="order" id="order" ref={this.orderSelect} onChange={this.handleAdminOrder}>
                      <option value="2" >Approved</option>
                      <option value="0" >Saved</option>
                      <option value="1" >Submitted</option>
                      <option value="3" >Denied</option>
                      <option value="4" >Frozen</option>
                      <option value="5">Invited</option>
                      <option value="10" >All</option>
                    </select></div>) : ""}

              {/* <div class="col-lg-6 col-12 btn-group btn-group-sm justify-content-end" role="group" aria-label="...">
                <button type="button" class="btn btn-forth" onClick={this.changeDistanceOrder}>距离<i class="bi bi-arrow-bar-down"></i></button>
                <button type="button" class="btn btn-forth" onClick={this.changeVisitsOrder}>访问量由低到高<i class="bi bi-arrow-bar-down"></i></button>
                <button type="button" class="btn btn-forth" onClick={this.changePriceOrder}>价格由高到低<i class="bi bi-arrow-bar-down"></i></button>
              </div> */}
             
              {this.state.isLoading && !this.state.isLoadMore?<div><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></div>:
              this.state.therapists.map((user,index) => {
                let default_avatar = avatar
                if(user.user__photo_url !== null){ default_avatar = user.user__photo_url}
                tempCity = []
                let tempArea = []
                user.identification__tags.map(tag => {
                    var cate = ((""+tag).split(""))[0]
                    if(cate === '2'){
                      this.state.areaList.map(elem =>{
                        if(elem.tag_id === tag){tempArea.push(elem.value_cn)}
                      })
                    }
                  })
                return (
                  <div class="row col-12 upperline pt-2 search-result-line" onClick={() => this.openProfilePage(user.profile_id, user.user__id)} key={index}>
                    <div class="col-3">
                      <img src={default_avatar} class="list-avatar-img" alt="therapist_avatar"></img>
                    </div>
                    <div class="col-9">
                      <div class="row">
                        <div class="col-9"><h4>{user.title}{user.full_name}, {user.advanced_credential}</h4></div>
                        <div class="col-3 text-end"><p>${user.price_minimum} 起&nbsp;</p></div>
                      </div>
                      <div>
                        {user.premium_status===1?<span>青年咨询师&nbsp;<i class="bi bi-award brand-secondary-color"></i></span>:""}
                      </div>
                      <div>
                        <span class="px-2">
                          <i class="bi bi-geo-alt brand-primary-color"></i>
                          {
                            user.cities.map(elem => {
                              if(tempCity.length === 0){
                                tempCity.push(elem)
                                return (elem)
                              }
                              else{
                                tempCity.push(elem)
                                return (' | '+elem)
                              }
                            })
                          }
                        </span>
                        <span class="px-2"><i class="bi bi-cursor brand-primary-color"></i>{user.visits}</span>
                      </div>
                      <p>{user.to_visitor}</p>
                      <div>
                        {tempArea.map(tag=>(<span class="badge rounded-pill pill-text">{tag}</span>))}
                      </div>
                    </div>
                  </div>
              )})}
            </div>
  
            <div class="row col-12 col-lg-4">
              <div class="grey-border p-4 my-lg-2">
                <h5>咨询指南</h5>
                <ul class="demo">
                  {this.state.isQuestionLoading?(<div><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'20%'} width={'20%'}/></div>):
                    this.state.questions.map(question => (
                      <li class="py-2"><i class="bi bi-chat-left-text brand-primary-color"></i>
                        <NavHashLink className="qa-text" smooth to={`/questions/#${question.post_id}`}>&nbsp;{question.subject}</NavHashLink>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div class="grey-box p-4 align-items-center hide-mobile">
                <h5>您也是一名心理健康工作者?诚邀您加入「FindSelf找我」</h5>
                <div class="row mx-1"><NavLink to="/signup/therapist" class="btn btn-secondary">即刻申请入驻</NavLink></div>
              </div>
            </div>

          </div>
        </div>

        {this.state.isLoadMore?
        <Row>
          <Col md={2}></Col>
          <Col md={6}>
            <div style={{textAlign: 'center', fontSize: '14px'}}>正在加载更多...</div>
          </Col>
        </Row>:<div></div>
        }
      </div>
    )
  }
}

export default withRouter(SearchConsultant)
