import React, { Component } from 'react'
import ReactLoading from 'react-loading'
import avatar from '../assets/website_logo.png'
import {Container, Row} from 'react-bootstrap'
import axios from 'axios'
import AboutUsEdit from "./AboutUsEdit";
import moment from 'moment'
import utils from './utils'
import ReactGA from 'react-ga'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import '../css/therapist-premium.css'

export default class TherapistPremium extends Component {
  constructor(props) {
    super(props);
    this.orderSelect = React.createRef();
  }

  state = {
    isLoading: false,
    therapists: [],
    order: '+price',
    questions: [],
    isQuestionLoading: false,
    timezoneList: utils.timezoneList,
    appointment_length_list: [{value_cn: '快速咨询 (30分钟)', value: '1'}, {value_cn: '普通咨询 (60分钟)', value: '2'}, {value_cn: '初次评估 (90分钟)', value: '3'}, {value_cn: '长时咨询 (120分钟)', value: '4'}],
    appointment_length: '2',
    calendar_weekList: [],
    isCalendarLoading: false,
    timeslot_list: [],
    appointment_timezone: 'America/Los_Angeles',
    current_timeslot: '',
    schedule_table: {},
    appointment_timeList: [],
    appointment_timeList_full: [],
    idList: [],
    therapistList: [],
    currentWeek: 0
  }

  initAppointmentCalendar= () => {
    let timeList = []
    let week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let current_day = moment().day()
    for(let i=0;i<7;++i){
      timeList.push({day: week[(moment().day()+i)%7], date_num: moment().day(current_day+i+7*this.state.currentWeek).date(),
      date: (month[moment().day(current_day+i+7*this.state.currentWeek).month()])+' '+moment().day(current_day+i+7*this.state.currentWeek).date()})
    }
    this.setState({calendar_weekList: timeList})
  }

  handleWeekSwitch = async(index)=>{
    let currentWeek = this.state.currentWeek+index
    if(currentWeek < 0){currentWeek = 0}
    else if(currentWeek > 4){currentWeek = 4}
    await this.setState({currentWeek})
    await this.initAppointmentCalendar()
    this.updateAppointmentCalendar()
  }

   // 咨询种类改变
   handleLengthChange = async(event) => {
    let schedule = this.state.appointment_timeList_full.map(elem=>{
      return elem.slice()
    })
    await this.setState({appointment_length: event.target.value})
    // filter timeslots by length
    for(let i=0;i<schedule.length;i++) {
      if(!schedule[i].includes(true)){
        let test_date = new Date(schedule[i][1]).getTime()
        let length = 1
        let j
        for(j=i+1;j<schedule.length;j++){
          if(!schedule[j].includes(true) && new Date(schedule[j][0]).getTime() === test_date){
            test_date = new Date(schedule[j][1]).getTime()
            length++
          }else{break}
        }
        if(length < parseInt(this.state.appointment_length)){
          for(let z=i;z<j;z++){
            schedule[z].push(true)
          }
        }
      }
    }

    this.setState({appointment_timeList: schedule}, ()=>{this.updateAppointmentCalendar()})
  }

  getSchedule = async() => {
    let start_time = new Date()
    let end_time =  new Date(start_time.getTime() + 27*24*60*60*1000)
    await axios.post(utils.getUrl(`appointment/schedule-merged/`), {
      start_time: start_time.toISOString(), end_time: end_time.toISOString(), offset: 0
    }).then(response=>{

      let data_table = response.data.data
      let timeList = []
      Object.keys(data_table).map(time =>{
        let temp = time.replace("(", "").replace(")", "").replace("\'", '').replace(" '", "").split(",")
        temp[0] = temp[0].slice(0, -1)
        temp[1] = temp[1].slice(0, -1)
        timeList.push(temp)
      })
      this.setState({schedule_table: response.data.data, appointment_timeList: timeList, appointment_timeList_full: timeList},
        ()=>this.updateAppointmentCalendar())
    }).catch(err=>{
      alert(err)
    })
  }

  updateAppointmentCalendar = () => {
    let timeslot_list = []
    for(let i=0;i<7;++i){timeslot_list.push([])}
    this.state.appointment_timeList.map(elem=>{
      if(!elem.includes(true)){
        let start_time = this.state.appointment_timezone===''?new Date(elem[0]):this.resolveTimezone(elem[0])
        if( start_time < new Date(new Date().getTime() + (this.state.currentWeek+1)*7*24*60*60*1000)
        && start_time > new Date(new Date().getTime() + (this.state.currentWeek)*7*24*60*60*1000)){
          let current_day = new Date(new Date().getTime() + (this.state.currentWeek)*7*24*60*60*1000)

          let hour = start_time.getHours() < 12 ? start_time.getHours() : start_time.getHours() - 12;
          let min = start_time.getMinutes() === 0 ? '00' : start_time.getMinutes()
          let suffix = start_time.getHours() < 12 ? ' A.M' : ' P.M'
          let display_time = hour + ':' + min + suffix
          // let display_time = start_time.getHours()+':'+(start_time.getMinutes()===0?'00':start_time.getMinutes())

          let index = 0;
          this.state.calendar_weekList.map(elem=>{
            if(elem.date_num === start_time.getDate()){
              index = this.state.calendar_weekList.indexOf(elem)
            }
          })
          timeslot_list[index].push({display_time: display_time, full_time: elem[0]})
        }
      }
    })
    this.setState({timeslot_list, isCalendarLoading: false})
  }

  // 选择预约时间
  timeslotSelect = async(event, time) => {
    let element = event.target
    let tempList = Array.from(document.querySelectorAll('.timeslot-selected'))
    if(tempList.includes(element)){
      element.classList.remove('timeslot-selected')
      this.setState({current_timeslot: ''})
    }else{
      tempList.forEach(elem =>{
        elem.classList.remove('timeslot-selected')
      })
      element.classList.add('timeslot-selected')
    }
    var idList = []
    Object.keys(this.state.schedule_table).map(elem=>{
      if(elem.includes(time)){
        idList = Object.keys(this.state.schedule_table[elem])
      }
    })
    let therapistList = []
    let promises = idList.map( async(id)=>{
      var obj = {}
      await axios.get(utils.getUrl(`profile/${id}/`)).then(response=>{
        let data = response.data
        obj = {first_name: data.first_name, last_name: data.last_name, photo_url: data.photo_url, user_id: data.id, profile_id: data.profiles[0].profile_id}
        // therapistList.push(obj)
      }).catch(err=>{alert(err)})
      return obj
    })
    therapistList = await Promise.all(promises)
    await this.setState({current_timeslot: time, idList, therapistList})
  }

  // change the display order of search results
  handleOrder = async() => {
    const node = this.orderSelect.current;
    await this.setState({order: node.value})
    this.handleSearch()
  }
  // open a therapist' s profile page
  openProfilePage = (profile_id, user_id) => {
    const url = utils.getUrl(`/therapist/view/:${profile_id}`)
    this.props.history.push({pathname: url, state: {user_id: user_id}})
  }
  // search premium therapists
  handleSearch = async() => {
    this.setState({isLoading: true, isQuestionLoading: true})
    await axios.post(utils.getUrl('public/filter-by-tag/'),
      {'order': this.state.order, 'premium_status': [1], 'end': 100})
      .then(response => {
        let tempList = response.data.data
        let userList = []
        if(tempList.length > 0){
          tempList.map(elem => {
            if(elem.title === 1){elem.title = 'Dr.'}
            else if(elem.title === 2){elem.title = 'Prof.'}
            else{elem.title = ''}
            userList.push(elem)
            return false;
          })
        }
        this.setState({isLoading: false, therapists: userList})
      }).catch(err => {
        // alert(err)
      })
  }

  // 跳转广告管理界面
  forwardAdManage = (banner_id) =>{
    this.props.history.push({pathname: `/admanage/:${banner_id}`})
  }

  forwardProfile = (profile_id) =>{
    this.props.history.push(`/therapist/view/:${profile_id}`)
  }

  setTimeZone = (event) =>{
    this.setState({appointment_timezone: event.target.value}, ()=>{
      this.updateAppointmentCalendar()
    })
  }

  // convert time string to a certain timezone date
  resolveTimezone = (time) =>{
    return this.convertTZ(utils.resolveOffset(utils.parseLocalDateTimeString(time)).toUTCString(), this.state.appointment_timezone)
  }

  convertTZ = (date, tzString)=> {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}))
  }

  componentDidMount = async() => {
    /*
    this.props.setDisplayURL();
    //get q&a Questions
    await axios.get(utils.getUrl('cms/questions/list/critical/?lang=cn&tag=premium_question'))
    .then(response => {
      this.setState({questions: response.data.data.data, isQuestionLoading: false})
    }).catch(err => {
      // alert(err)
    })
    this.initAppointmentCalendar()
    await this.getSchedule().then(() =>{
      let schedule = this.state.appointment_timeList_full.map(elem=>{
        return elem.slice()
      })
      for(let i=0;i<schedule.length;i++) {
        if(!schedule[i].includes(true)){
          let test_date = new Date(schedule[i][1]).getTime()
          let length = 1
          let j
          for(j=i+1;j<schedule.length;j++){
            if(!schedule[j].includes(true) && new Date(schedule[j][0]).getTime() === test_date){
              test_date = new Date(schedule[j][1]).getTime()
              length++
            }else{break}
          }
          if(length < parseInt(this.state.appointment_length)){
            for(let z=i;z<j;z++){
              schedule[z].push(true)
            }
          }
        }
      }

      this.setState({appointment_timeList: schedule}, ()=>{this.updateAppointmentCalendar()})
    })
    */
  }

  calendar = () => {
    return (
      <div class="row grey-box align-items-start p-4">
          <div class="row col-lg-8 white-box overflow-x p-2 me-lg-2">
            <div class="col-6 p-1">
              <select class="form-select" id='timezone' onChange={this.setTimeZone}>
                <option value='' disabled>时区选择</option>
                {this.state.timezoneList.map(
                  elem => {
                    if(elem.id === 'America/Los_Angeles'){
                      return <option value={elem.id} selected>{elem.value_cn}</option>}
                    else{
                      return <option value={elem.id}>{elem.value_cn}</option>
                    }
                  })
                }
              </select>
            </div>
            <div class="col-6 p-1">
            <select class="form-select" aria-label="Default select example">
                <option value='' disabled>咨询种类</option>
                {this.state.appointment_length_list.map(
                  length => {
                      if(length.value === '2'){
                        return <option value={length.value} selected>{length.value_cn}</option>
                      }
                      else{
                        return <option value={length.value}>{length.value_cn}</option>
                      }
                    })
                }
              </select>
            </div>
            <div class="col-12 overflow-y">
              <table class="table table-borderless">
                <thead class="align-middle">
                  <tr>
                    <th scope="col" onClick={() =>this.handleWeekSwitch(-1)}><i class="bi bi-chevron-left"></i></th>
                    {this.state.calendar_weekList.map(elem=>{
                      return <th scope="col">{elem.day}<br />{elem.date}</th>
                    })}
                    <th scope="col" onClick={() =>this.handleWeekSwitch(1)}><i class="bi bi-chevron-right"></i></th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr>
                    <th scope="row"></th>
                    {this.state.timeslot_list.map(elem=>{
                      return (
                      <td>
                        {elem.map(time=>{
                          return <p class="calendar-cell p-1" onClick={(event) =>this.timeslotSelect(event, time.full_time)}>{time.display_time}</p>                          })}
                      </td>)
                    })}
                  </tr>
                </tbody>
              </table>        
            </div>
          </div>
          <div class="col-lg-4 col-12 list-item white-box p-2">
            <ul class="list-group">
              <li class="no-bullet pb-2">可预约咨询师：</li>
              {this.state.therapistList.map(elem=>{
                return (
              <li class="list-group-item" aria-current="true" onClick={() =>this.forwardProfile(elem.profile_id)}>
                <div class="row align-items-center">
                  <div class="col-2">
                    <img src={elem.photo_url===''?avatar:elem.photo_url} alt="avatar" className="avatar"></img>
                  </div>
                  <div class="col-6  col-lg-7">
                    <h6>{elem.first_name} {elem.last_name}</h6>
                    {/* <p>60 min 快捷咨询</p> */}
                  </div>
                  <div class="col-4 col-lg-3">
                      <button type="button" class="btn btn-primary">立即<br/>预约</button>
                  </div>
                </div>
              </li>
              )})
              }
            </ul>
          </div>
        </div>
    )
  }

  componentWillMount = async () => {
    document.title = "FindSelf找我 - 青年咨询师";
  }

  counselorProfile = ( title, desc_1, desc_2, avatarUrl, reversed ) => {
    return (
      <Row className="py-5">
        <div className={ "col-md-3 d-flex justify-content-center " + (reversed ? "order-md-2 order-1" : "") }>
          <img src={ "https://findselfuseravatar.s3.us-west-1.amazonaws.com/post_img/counselorsunder30_" + avatarUrl + "_profile.png" }
            alt="Counselor Profile"
            className="profile-image" />
        </div>
        <div className={ "col-md-9 " + (reversed ? "order-md-1 order-2" : "") }>
          <div className="row pb-2 text-center text-md-start" style={{ fontSize: 24 }}><strong>{title}</strong></div>
          <div className="row">
            <span className="pb-2 detail-description"> {desc_1} </span>
            <span className="pb-2 detail-description"> {desc_2} </span>
          </div>
        </div>
      </Row>
    )
  }

  counselorEnhancedProfile = (name, title, desc_1, desc_2, avatarUrl, reversed) => {
    desc_2 = desc_2.length > 160 ? desc_2.substring(0, 120) + " ... " : desc_2
    return (
      <div className='col-12 col-md-6 col-lg-4 p-3'>
        <div className='profileCard d-flex flex-column align-items-center'>
            <img src={"https://findselfuseravatar.s3.us-west-1.amazonaws.com/post_img/counselorsunder30_" + avatarUrl + "_profile.png"}
              alt="Counselor Profile"
              className="profile-image" />
          <div style={{ fontSize: 22, fontWeight: 300 }} >{name}</div>
          <div className="py-1" style={{ fontSize: 16, fontWeight: 500, color: '#43B0B1' }}>{title}</div>
          <div style={{ marginLeft: -15 }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="ms-3" color="#43B0B1" /> Boston, MA
          </div>
          <div className='row w-100 my-3'>
            <div className='col-7 p-0'>
              <div style={{ fontWeight: 500 }}>个人及家庭咨询</div>
              <div style={{ fontSize: 14 }}>擅长领域</div>
            </div>
            <div className='col-5 p-0'>
              <div style={{ fontWeight: 500 }}>1000+小时</div>
              <div style={{ fontSize: 14 }}>咨询经验</div>
            </div>
          </div>
          <span className="pb-2 detail-description"> {desc_1} </span>
          <span className="pb-2 detail-description"> {desc_2} </span>
        </div>
      </div>
    )
  }

  render() {
    // GA - PageView - Start
    let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
    ReactGA.pageview(pagePathForGA);
    // GA - PageView - End
    let tempCity = []
    return (
      <div className="container-fluid py-5 px-sm-5 px-lg-10">
        {/* 快速预约 */}
        <Row><strong style={{fontSize: "30px"}}>青年咨询师</strong></Row>
        <Row className="pt-3">
          <span className="pb-2 ps-3" style={{ fontSize: "17px" }}>
            — — 严选海内外优质年轻一代心理咨询师，为您提供专业、可靠、高性价比的心理咨询服务。
          </span>
          <span className="pb-2 ps-3" style={{ fontSize: "17px" }}>
            行业内往往缺少年轻一代心理咨询师的身影。他们一直被“初出茅庐”或是“没有经验”等标签质疑、束缚着。
          </span>
          <span className="pb-2 ps-3" style={{ fontSize: "17px" }}>
            但其实，“年轻”也可能成为一位咨询师的先天优势。在心理咨询中，普遍认为「咨访关系」是影响咨询质量的关键因素。除咨询师的经验和专业程度以外，受访者和咨询师是否匹配、能否互相信任，常常对咨询效果起到关键作用。由此，「青年咨询师 - Counselors under 30™」应运而生。
          </span>
          <span className="pb-2 ps-3" style={{ fontSize: "17px" }}>
            我们的青年咨询师都拥有过硬的学术背景，同时能够很好地站在你的角度倾听你的诉说、感知你的情绪、理解你的想法、分析你的问题，以年轻却同样专业的视角伴你走出困境
          </span>
        </Row>
        <Row className="pt-5 pb-5">
          <span className="young-counselor-intro-title-text">青年咨询师介绍</span>
        </Row>

        <Row className="gx-5 gy-0">
          {this.counselorEnhancedProfile('张沁韵 May Zhang', 'M.S.Ed., M.Phil.Ed.',
            '宾夕法尼亚大学心理咨询硕士，匹兹堡大学心理学学士，2000+小时咨询经验，目前在纽约的一所社区非营利机构与华人移民家庭进行个人及家庭咨询。',
            '我认为真正有价值的心理咨询，咨询师和来访者的咨询关系都需要是动态的、被走近去观察和探索的。我也相信咨询师和来访者的关系对于是否会为来访者带来帮助有着非常关键的作用。'
            + '同时，自我接纳(self-acceptance)和自我慈悲(self-compassion)是我提供咨询的重大主题，荣格说过“We cannot change anything unless we accept it" ，'
            + '我坚信任何改变的发生都需要从接纳过去及接纳自己开始。',
            'zqy',
            false)}

          {this.counselorEnhancedProfile('唐心远', 'M.S.Ed., M.Phil.Ed.',
            '宾夕法尼亚大学心理咨询硕士，上海同济大学英语专业学士，2000+小时咨询经验，目前在费城的一所社区机构进行个人咨询。',
            '我的咨询取向是认知行为疗法、辩证行为疗法、情绪聚焦疗法、正念减压以及结合精神动力取向的整合疗法，我希望能够为不同年龄段、不同主诉的来访者提供循证实践。'
            + '我目前在费城的一家社区机构为儿童和成人提供一对一心理咨询，也定期接受美国持证咨询师的督导。',
            'txy',
            true)}

          {this.counselorEnhancedProfile('Carol Dong', 'LMHC',
            '纽约大学心理咨询硕士，Univerisity of Buckingham心理学学士，3000+小时咨询经验，目前在纽约一所家庭辅导中心担任家庭辅导咨询师。',
            '专职为移民家庭提供个人，夫妻，以及家庭心理谘询，同时在纽约市日落高中（Sunset Park High School) 担任团体谘商老师，带领高中生进行躖体辅导，以及教学课程。至今累積了超過3000小時的個案時數。每週會有額外4 小時在紐約市日落高中（Sunset Park High School) 擔任團體諮商老師，帶領18名10年級高中生進行躖體輔導，以及教學課程。'
            + '现在的你/妳可能在茫然的十字路口很徬徨不知道该往左还是右，感情事业情绪都乱哄哄, 但没关係你找到了findself～我擅长营造支持性的咨询互动，让你在自我探索的过程中感到放心安全，我相信支持性的环境和正向情绪可以使人更有效的去面对此时的情绪和困难。让我们一起疗癒我们的内在小孩，掌握自己的情绪和生活，是改变的第一步唷～',
            'caroldong',
            false)}

          {this.counselorEnhancedProfile('周浩然', 'M.S.Ed., M.Phil.Ed.',
            '宾夕法尼亚大学心理咨询硕士，华中师范大学应用心理学学士，500+小时咨询经验，目前即将在费城的一所家庭治疗机构任职。',
            '先后在中美两国的中学任学校咨询师，我对青少年成长，文化适应，学业与人际压力，个人与家庭关系等话题有较深入的接触。'
            + '咨询上我个人较多使用人本（person-centered）和心理动力学（psychodynamic）的疗法，着重于与来访一同探索过去与当下的经历，以寻找来访困扰的根源并确立最适合当下境遇的应对方法。我期待着与你一起成长，共同成为更好的自己。',
            'zhr',
            true)}

          {this.counselorEnhancedProfile('王妙韵', 'M.S.',
            '香港城市大学应用心理学硕士，华东理工大学管理学学士，150+小时咨询经验，目前为上海平安健康互联网心理科咨询师。',
            '动力学导向咨询师，以客体关系理论为主，擅长心理成长及自我探索的深度咨询。'
            + '具有丰富的哲学知识储备，咨询风格：真实、真诚，带有理智平和的思辨色彩。“迷茫可以是成长的养料，伤口是光照进去的地方。”',
            'wmy',
            false)}

          {this.counselorEnhancedProfile('孙金磊', 'M.S.',
            '上海交通大学 应用心理硕士，南京师范大学 心理专业学士，1000+小时咨询经验，目前为南京三甲医院心理科心理治疗师。',
            '专职心理治疗师，上海交通大学应用心理专业硕士研究生，国家二级心理咨询师。擅长CBT疗法、家庭治疗等。',
            'sjl',
            true)}

          {this.counselorEnhancedProfile('陈思琪', 'M.S.Ed., M.Phil.Ed.',
            '曾任职于美国费城的精神卫生系统和高中；熟练掌握认知行为疗法、和情绪聚焦疗法，根据来访者的特点灵活运用各类心理咨询技术；'
            + '希望为来访提供一个安全，和信任的环境。通过咨询，和来访一同探索未知的道路。擅长焦虑，抑郁等常见情绪问题、个人成长和实现、情绪困扰、亲密关系、人际关系问题的心理咨询。',
            '主要运用接纳承诺疗法（ACT）、焦点解决疗法（SFBT）、认知行为疗法（CBT）等。',
            'csq',
            false)}

          {this.counselorEnhancedProfile('蒋逸柔', 'M.Sc.',
            '美国临床心理博士在读，英国心理学硕士，心理治疗师，在国内有两年全职咨询师经历，临床小时数1000+，曾在上海三甲医院心理科工作实习半年。',
            '对情绪和症状评估、青年群体的学业压力和情感危机等较为擅长。主要使用焦点解决疗法与短期整合疗法。',
            'jyr',
            true)}

          {this.counselorEnhancedProfile('孙冠贤', 'LSW',
            '我是一名受训方向为心理治疗的临床社会工作者，擅长从包括来访者的社会关系，家庭环境等方面对来访者进行全面的理解，并与来访者一同寻找其力量源泉与支持系统。',
            '我会用亲和力与共情能力在咨询过程中作为稳定而温和的陪伴者与来访者一同寻找问题，确立目标，并在这段旅途中共同成长。',
            'sgx',
            false)}

        </Row>
        
        {/*<Row><AboutUsEdit src_id="extra_1"/></Row>*/}
        {/* {this.calendar()} */}

        {/*<Row><AboutUsEdit src_id="search_premium"/></Row>*/}
      </div>
    )
  }
}
