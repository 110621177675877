/* 咨询师或admin编辑咨询师主页的页面 */
import React, { Component } from 'react'
import axios from 'axios'
import {Container, Row, Col, Modal, Button} from 'react-bootstrap'
import {GoogleMapFrame, GoogleMapAutoComplete} from "./GoogleMap"
import OAuthGOOGLE from "./OAuthGOOGLE";
import avatar from '../assets/website_logo.png'
import ImageUploader from 'react-images-upload'
import OAuthFaceBook from "./OAuthFaceBook";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AboutUsEdit from "./AboutUsEdit";
import { withRouter } from 'react-router';
import utils from './utils'

export default class TherapistEditProfile extends Component {

  state = {
    profile_id: null,
    user_id: null,
    additional_info: '',
    to_visitor: '',
    advanced_credential: '',
    len_exp_hr: 0,
    title: '',
    self_intro: '',
    gender: 0,
    first_name: '',
    last_name: '',
    photo_url: null,
    selectedTags: [],
    address: [],
    hourly_price: [],
    license: [],
    experience: [],
    allTags: [],
    isMethodDisplay: 'none',
    isRoleDisplay: 'none',
    isLangDisplay: 'none',
    isAreaDisplay: 'none',
    isGroupDisplay: 'none',
    selectedPaymentList: [],
    paymentList: [],
    roleList: [],
    areaList: [],
    groupList: [],
    langList: [],
    currentAvatar: null,
    avatar_type: 'png',
    mental_health_role: 10006, // 默认持证精神分析师
    license_number: '',
    license_has_passed: false,
    license_expiration: '',
    license_selectedDate: null,
    license_state: '',
    supervisor_name: '',
    supervisor_license: '',
    experience_type: 1, // 默认教育
    experience_name: '',
    start_date: '',
    startDate_selectedDate: null,
    end_date: '',
    endDate_selectedDate: null,
    contact_method: 1, // 默认语音
    price_value_low:'',
    price_value_high:'',
    currency: 'USD',
    address_name: '',
    street_1: '',
    street_2: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    latitude: 0,
    longitude: 0,
    phone_number: '',
    email_address: '',
    profile_status: 0,
    noTitle: false,
    titleDr: false,
    titleProf: false,
    titleList: ['', 'Dr. ', 'Prof. '],
    genderList: [{value: 'Male', value_cn: '男', id: 1},
    {value: 'Female', value_cn: '女', id: 2}, {value: 'Other', value_cn: '其他', id: 3}],
    stateList: [
      {
          "name": "Alabama",
          "abbreviation": "AL"
      },
      {
          "name": "Alaska",
          "abbreviation": "AK"
      },
      {
          "name": "American Samoa",
          "abbreviation": "AS"
      },
      {
          "name": "Arizona",
          "abbreviation": "AZ"
      },
      {
          "name": "Arkansas",
          "abbreviation": "AR"
      },
      {
          "name": "California",
          "abbreviation": "CA"
      },
      {
          "name": "Colorado",
          "abbreviation": "CO"
      },
      {
          "name": "Connecticut",
          "abbreviation": "CT"
      },
      {
          "name": "Delaware",
          "abbreviation": "DE"
      },
      {
          "name": "District Of Columbia",
          "abbreviation": "DC"
      },
      {
          "name": "Federated States Of Micronesia",
          "abbreviation": "FM"
      },
      {
          "name": "Florida",
          "abbreviation": "FL"
      },
      {
          "name": "Georgia",
          "abbreviation": "GA"
      },
      {
          "name": "Guam",
          "abbreviation": "GU"
      },
      {
          "name": "Hawaii",
          "abbreviation": "HI"
      },
      {
          "name": "Idaho",
          "abbreviation": "ID"
      },
      {
          "name": "Illinois",
          "abbreviation": "IL"
      },
      {
          "name": "Indiana",
          "abbreviation": "IN"
      },
      {
          "name": "Iowa",
          "abbreviation": "IA"
      },
      {
          "name": "Kansas",
          "abbreviation": "KS"
      },
      {
          "name": "Kentucky",
          "abbreviation": "KY"
      },
      {
          "name": "Louisiana",
          "abbreviation": "LA"
      },
      {
          "name": "Maine",
          "abbreviation": "ME"
      },
      {
          "name": "Marshall Islands",
          "abbreviation": "MH"
      },
      {
          "name": "Maryland",
          "abbreviation": "MD"
      },
      {
          "name": "Massachusetts",
          "abbreviation": "MA"
      },
      {
          "name": "Michigan",
          "abbreviation": "MI"
      },
      {
          "name": "Minnesota",
          "abbreviation": "MN"
      },
      {
          "name": "Mississippi",
          "abbreviation": "MS"
      },
      {
          "name": "Missouri",
          "abbreviation": "MO"
      },
      {
          "name": "Montana",
          "abbreviation": "MT"
      },
      {
          "name": "Nebraska",
          "abbreviation": "NE"
      },
      {
          "name": "Nevada",
          "abbreviation": "NV"
      },
      {
          "name": "New Hampshire",
          "abbreviation": "NH"
      },
      {
          "name": "New Jersey",
          "abbreviation": "NJ"
      },
      {
          "name": "New Mexico",
          "abbreviation": "NM"
      },
      {
          "name": "New York",
          "abbreviation": "NY"
      },
      {
          "name": "North Carolina",
          "abbreviation": "NC"
      },
      {
          "name": "North Dakota",
          "abbreviation": "ND"
      },
      {
          "name": "Northern Mariana Islands",
          "abbreviation": "MP"
      },
      {
          "name": "Ohio",
          "abbreviation": "OH"
      },
      {
          "name": "Oklahoma",
          "abbreviation": "OK"
      },
      {
          "name": "Oregon",
          "abbreviation": "OR"
      },
      {
          "name": "Palau",
          "abbreviation": "PW"
      },
      {
          "name": "Pennsylvania",
          "abbreviation": "PA"
      },
      {
          "name": "Puerto Rico",
          "abbreviation": "PR"
      },
      {
          "name": "Rhode Island",
          "abbreviation": "RI"
      },
      {
          "name": "South Carolina",
          "abbreviation": "SC"
      },
      {
          "name": "South Dakota",
          "abbreviation": "SD"
      },
      {
          "name": "Tennessee",
          "abbreviation": "TN"
      },
      {
          "name": "Texas",
          "abbreviation": "TX"
      },
      {
          "name": "Utah",
          "abbreviation": "UT"
      },
      {
          "name": "Vermont",
          "abbreviation": "VT"
      },
      {
          "name": "Virgin Islands",
          "abbreviation": "VI"
      },
      {
          "name": "Virginia",
          "abbreviation": "VA"
      },
      {
          "name": "Washington",
          "abbreviation": "WA"
      },
      {
          "name": "West Virginia",
          "abbreviation": "WV"
      },
      {
          "name": "Wisconsin",
          "abbreviation": "WI"
      },
      {
          "name": "Wyoming",
          "abbreviation": "WY"
      }
    ]
  }

  saveProfile = async() => {
    let title
    if(this.state.title === 'Dr. '){
      title = 1
    }else if(this.state.title === 'Prof. '){
      title = 2
    }else{
      title = 0
    }

    const profile = {
      title: title,
      advanced_credential: this.state.advanced_credential,
      len_exp_hr: this.state.len_exp_hr,
      self_intro: this.state.self_intro,
      to_visitor: this.state.to_visitor,
      additional_info: this.state.additional_info,
      payment_method: this.state.selectedPaymentList
    }
    const tags = {
      tags: this.state.selectedTags
    }
    const user = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      gender: this.state.gender
    }
    // update basic profile info
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/update/`),
    {'profile': profile, 'identification': tags, 'user': user}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('保存成功！')
    })
    .catch(err=>{alert(err)})

    if(localStorage.getItem('user_type')&&localStorage.getItem('user_type')<= 2){
      this.props.history.push(`/therapist/view/:${this.state.profile_id}`)
    }else{
      this.props.history.push(`/webuser/profile/:${this.state.user_id}`)
    }

  }

  componentDidMount = async() => {
    // get tag list
    await axios.get(utils.getUrl('tags/list/'))
    .then(response => {
      
      this.setState({allTags: response.data.results})
      // set payment list
      let tempPayment = []
      let tempArea = []
      let tempGroup = []
      let tempRole = []
      let tempLang = []
      this.state.allTags.map((tag) => {
        var cate = ((""+tag.tag_id).split(""))[0]
        if(cate === '5'){
          tempPayment.push(tag)
        }else if(cate === '2'){
          tempArea.push(tag)
        }else if(cate === '3'){
          tempGroup.push(tag)
        }else if(cate === '1'){
          tempRole.push(tag)
        }else if(cate === '7'){
          tempLang.push(tag)
        }
      })
      this.setState({langList: tempLang, paymentList: tempPayment, areaList: tempArea.sort((a,b) => (a.value_cn).localeCompare(b.value_cn)),
        groupList: tempGroup.sort((a,b) => (a.value_cn).localeCompare(b.value_cn)), roleList: tempRole.sort((a,b) => (a.value_cn).localeCompare(b.value_cn))})
    }).catch(err => {
      // alert(err)
    })
    // set profile id
    await this.setState({profile_id: parseInt(this.props.match.params.profile_id.slice(1)), user_id: parseInt(this.props.match.params.user_id.slice(1))})
    // get info
    await axios.get(utils.getUrl(`public/${this.state.profile_id}/homepage/`), {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then((response) => {
      const data = response.data
      this.setState({additional_info: data.additional_info, advanced_credential: data.advanced_credential, len_exp_hr: data.len_exp_hr,
      title: data.title, self_intro: data.self_intro, to_visitor: data.to_visitor, gender: data.user.gender, first_name: data.user.first_name, last_name: data.user.last_name,
      email: data.user.email, verified_phone: data.user.verified_phone, photo_url: data.user.photo_url,
      selectedPaymentList: data.payment_method, selectedTags: data.identification.tags, address: data.address, hourly_price: data.hourly_price,
      license: data.license, experience: data.experience, profile_status: data.profile_status})
      if(data.invitation){
        this.setState({first_name: data.invitation.first_name, last_name: data.invitation.last_name, email: data.invitation.email})
      }
      let title
      if(this.state.title === 1){
        title = 'Dr. '
        this.setState({titleDr: true, titleProf: false, noTitle: false})
      }
      else if(this.state.title === 2){
        title = 'Prof. '
        this.setState({titleProf: true, titleDr: false, noTitle: false})
      }
      else{
        title = ''
        this.setState({noTitle: true, titleDr: false, titleProf: false})
      }
      this.setState({title: title})
    }).catch(err => {
      // alert(err)
    })

  }

  // when a tag is selected or unselected
  handleFilter = (event) => {
    const id = parseInt(event.target.id)
    let tempList = this.state.selectedTags
    let tempPayment = this.state.selectedPaymentList
    if(event.target.checked){
      if(((""+id).split(""))[0] === '5'){
        if(!tempPayment.includes(parseInt(event.target.id))){
          tempPayment.push(parseInt(id))
        }
      }else{
        if(!tempList.includes(parseInt(event.target.id))){
          tempList.push(parseInt(id))
        }
      }
    }else{
      if(((""+id).split(""))[0] === '5'){
        tempPayment = tempPayment.filter((value) => {
          return value !== id
        })
      }else{
        tempList = tempList.filter((value) => {
        return value !== id
        })
      }

    }

    this.setState({selectedPaymentList: tempPayment, selectedTags: tempList})
  }
  // handle avatar pick
  onDrop = async(pictureFile, pictureDataURL) => {
    if(!pictureFile[0]){
      this.setState({currentAvatar: avatar, avatar_type: 'png'})
    }else{
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(pictureFile[0].name)[1];
      this.setState({currentAvatar: pictureFile[0], avatar_type: ext})
    }

  }
  // handle avatar upload
  uploadAvatar = async() => {
    var formData = new FormData()
    formData.append('file', this.state.currentAvatar)
    formData.append('ftype', this.state.avatar_type)
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/upload_avatar/`),
    formData, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('上传成功！')
    }).catch(err => {
      // alert(err)
    })
  }
  // handle google map address
  handlePlaceChange = (place) => {
    const format_address = place.formatted_address.split(',')
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    const street_1 = format_address[0]
    // let street_2 = ''
    // if(format_address.length > 4){street_2 = format_address[1]}
    const country = place.address_components[place.address_components.length-2].short_name
    const state = place.address_components[place.address_components.length-3].short_name
    const city = place.address_components[place.address_components.length-5].short_name
    let zip_code = place.address_components[place.address_components.length-1].short_name
    if(zip_code.length === 4){
      zip_code = place.address_components[place.address_components.length-2].short_name + '-' + zip_code
    }
    this.setState({latitude: lat, longitude: lng, street_1: street_1, country: country, state: state, city: city, zip_code: zip_code})
  }

  handleTitleChange = (event) => {
    const title = event.target.value
    this.setState({title: title})

  }
  handleFirstNameChange = (event) => {
    this.setState({first_name: event.target.value})
  }
  handleLastNameChange = (event) => {
    this.setState({last_name: event.target.value})
  }
  handleCredentialChange = (event) => {
    this.setState({advanced_credential: event.target.value})
  }
  handleGenderChange = (event) => {
    this.setState({gender: parseInt(event.target.value)})
  }
  handleExpHrChange = (event) => {
    this.setState({len_exp_hr: event.target.value})
  }
  handleIntroChange = (event) => {
    this.setState({self_intro: event.target.value})
  }
  handleAdditionalInfoChange = (event) => {
    this.setState({additional_info: event.target.value})
  }
  handleToVisitorChange = (event) => {
    this.setState({to_visitor: event.target.value})
  }

  handleRoleChange = (event) => {
    this.setState({mental_health_role: parseInt(event.target.value)})
  }
  licenseNumberChange = (event) => {
    this.setState({license_number: event.target.value})
  }
  handleLicenseHasPassed = () => {
    const prev = this.state.license_has_passed
    this.setState({license_has_passed: !prev})
  }
  licenseExpirationChange = (date) => {
    let formatted_date = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate()
    this.setState({license_expiration: formatted_date, license_selectedDate: date})
  }
  licenseStateChange = (event) => {
    this.setState({license_state: event.target.value})
  }
  supervisorNameChange = (event) => {
    this.setState({supervisor_name: event.target.value})
  }
  supervisorLicenseChange = (event) => {
    this.setState({supervisor_license: event.target.value})
  }
  handleExperienceType = (event) => {
    this.setState({experience_type: parseInt(event.target.value)})
  }
  experienceNameChange = (event) => {
    this.setState({experience_name: event.target.value})
  }
  experienceDescriptionChange = (event) => {
    this.setState({experience_description: event.target.value})
  }
  startDateChange = (date) => {
    let formatted_date = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate()
    this.setState({start_date: formatted_date, startDate_selectedDate: date})
  }
  endDateChange = (date) => {
    let formatted_date = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate()
    this.setState({end_date: formatted_date, endDate_selectedDate: date})
  }
  handleContactMethod = (event) => {
    this.setState({contact_method: parseInt(event.target.value)})
  }
  priceValueLowChange = (event) => {
    this.setState({price_value_low: parseFloat(event.target.value)})
  }
  priceValueHighChange = (event) => {
    this.setState({price_value_high: parseFloat(event.target.value)})
  }
  currencyChange = (event) => {
    this.setState({currency: event.target.value})
  }
  addressNameChange = (event) => {
    this.setState({address_name: event.target.value})
  }
  emailAddressChange = (event) => {
    this.setState({email_address: event.target.value})
  }
  phoneNumberChange = (event) => {
    this.setState({phone_number: event.target.value})
  }
  streetTwoChange = (event) => {
    this.setState({street_2: event.target.value})
  }

  handleMethodDropdown = () => {
    if(this.state.isMethodDisplay === 'none'){
      this.setState({isMethodDisplay: 'block', isLangDisplay: 'none'})
    }else{
      this.setState({isMethodDisplay: 'none'})
    }
  }
  handleLangDropdown = () => {
    if(this.state.isLangDisplay === 'none'){
      this.setState({isLangDisplay: 'block', isMethodDisplay: 'none'})
    }else{
      this.setState({isLangDisplay: 'none'})
    }
  }
  handleRoleDropdown = () => {
    if(this.state.isRoleDisplay === 'none'){
      this.setState({isRoleDisplay: 'block'})
    }else{
      this.setState({isRoleDisplay: 'none'})
    }
  }
  handleAreaDropdown = () => {
    if(this.state.isAreaDisplay === 'none'){
      this.setState({isAreaDisplay: 'block', isMethodDisplay: 'none', isGroupDisplay: 'none', isRoleDisplay: 'none'})
    }else{
      this.setState({isAreaDisplay: 'none'})
    }
  }
  handleGroupDropdown = () => {
    if(this.state.isGroupDisplay === 'none'){
      this.setState({isAreaDisplay: 'none', isMethodDisplay: 'none', isGroupDisplay: 'block', isRoleDisplay: 'none'})
    }else{
      this.setState({isGroupDisplay: 'none'})
    }
  }


  postCredential = (guri)=>{
    axios.post(utils.getUrl('permission/oauth/validate/'),
        {"identification": guri, "user_id": this.state.user_id},).then(response => {
          if (response.data.data.exist) {
            alert('此第三方账号已被绑定')
          } else {
            axios.post(utils.getUrl('permission/oauth/add/'),
                {"credential": guri, "user_id": this.state.user_id},
                {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
                  alert('成功！')
            }).catch(err => {
              // alert(err)
            })
          }
        }
    ).catch(err => {
      alert(err?.response?.data?.msg || err)
    })
  }
  handleAddGoogleCredential= (profile)=> {
    const google_id = profile.getId();
    const guri = `findself-google://${google_id ^ 0xB906ABCA}`;
    // alert(`DEBUG: Google ID: ${google_id}`)
    this.postCredential(guri)
  }
  handleAddFaceBookCredential = (response) => {
    let facebook_id = response?.authResponse?.userID;
    if (!facebook_id) {
      alert('无效的账号！')
      return
    }
    facebook_id = parseInt(facebook_id)
    const guri = `findself-facebook://${facebook_id ^ 0xB906ABCA}`;
    //alert(`DEBUG: Facebook ID: ${facebook_id}`)
    this.postCredential(guri)
  }
  isValidDate = (dateString) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    console.log(dateString);
    if(!dateString.match(regEx)) return false;  // Invalid format
    return true;    
//    var d = new Date(dateString);
//    var dNum = d.getTime();
//    if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
//    return d.toISOString().slice(0,10) === dateString;
  }

  handleLicenseCreate = async() => {
    let isValid = true
    //pre validation
    if(!this.state.mental_health_role){
      isValid = false
      alert('请选择一个咨询师身份！')
    }else if(!this.state.license_number){
      isValid = false
      alert('请填写证书编号!')
    }else if(!this.state.license_expiration){
      isValid = false
      alert('请填写证书过期日!')
    }else if(!this.state.license_state){
      isValid = false
      alert('请填写证书所在州!')
    }else if(!this.isValidDate(this.state.license_expiration)){
      isValid = false
      alert('请填写有效的过期日(yyyy-mm-dd)!')
    }else if(!this.state.license_has_passed && !this.state.supervisor_name){
      isValid = false
      alert('请填写督导师姓名!')
    }else if(!this.state.license_has_passed && !this.state.supervisor_license){
      isValid = false
      alert('请填写督导师执照编号!')
    }

    if(!isValid){return false}

    const tempLicense = {
      mental_health_role: this.state.mental_health_role,
      license_number: this.state.license_number,
      has_passed: this.state.license_has_passed,
      license_expiration: this.state.license_expiration,
      license_state: this.state.license_state,
      supervisor_name: this.state.supervisor_name,
      supervisor_license: this.state.supervisor_license,
      profile: this.state.profile_id
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/license/create/`),
    tempLicense, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('添加执照成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  updateLicense = async(event) => {
    let license_id = parseInt(event.target.value)
    let isValid = true
    //pre validation
    if(!this.state.mental_health_role){
      isValid = false
      alert('请选择一个咨询师身份！')
    }else if(!this.state.license_number){
      isValid = false
      alert('请填写证书编号!')
    }else if(!this.state.license_expiration){
      isValid = false
      alert('请填写证书过期日!')
    }else if(!this.state.license_state){
      isValid = false
      alert('请填写证书所在州!')
    }else if(!this.isValidDate(this.state.license_expiration)){
      isValid = false
      alert('请填写有效的过期日(yyyy-mm-dd)!')
    }else if(!this.state.license_has_passed && !this.state.supervisor_name){
      isValid = false
      alert('请填写督导师姓名!')
    }else if(!this.state.license_has_passed && !this.state.supervisor_license){
      isValid = false
      alert('请填写督导师执照编号!')
    }

    if(!isValid){return false}

    const tempLicense = {
      mental_health_role: this.state.mental_health_role,
      license_number: this.state.license_number,
      has_passed: this.state.license_has_passed,
      license_expiration: this.state.license_expiration,
      license_state: this.state.license_state,
      supervisor_name: this.state.supervisor_name,
      supervisor_license: this.state.supervisor_license,
      profile: this.state.profile_id
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/license/${license_id}/update/`),
    tempLicense, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('更新执照成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  deleteLicense = async(event) => {
    let license_id = parseInt(event.target.value)
    if(!window.confirm('确定要删除该执照吗?')){
      return false
    }
    await axios.delete(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/license/${license_id}/update/`),
    {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('删除执照成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  handleExperienceCreate = async() => {
    let isValid = true
    //pre validation
    if(!this.state.experience_type){
      isValid = false
      alert('请选择一种经历种类!')
    }else if(!this.state.experience_name){
      isValid = false
      alert('请填写经历名称!')
    }else if(!this.state.experience_description){
      isValid = false
      alert('请填写经历描述!')
    }else if(!this.state.start_date){
      isValid = false
      alert('请填写开始时间!')
    }else if(!this.state.end_date){
      isValid = false
      alert('请填写结束时间!')
    }else if(this.state.startDate_selectedDate > this.state.endDate_selectedDate){
      isValid = false
      alert('开始时间不能大于结束时间!')
    }

    if(!isValid){return false}

    const tempExperience = {
      'experience_type': this.state.experience_type,
      'experience_name': this.state.experience_name,
      'description': this.state.experience_description,
      'start_date': this.state.start_date,
      'end_date': this.state.end_date,
      'profile': this.state.profile_id
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/experiences/create/`),
    tempExperience, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('添加经历成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  updateExperience = async(event) => {
    let exp_id = parseInt(event.target.value)
    let isValid = true
    //pre validation
    if(!this.state.experience_type){
      isValid = false
      alert('请选择一种经历种类!')
    }else if(!this.state.experience_name){
      isValid = false
      alert('请填写经历名称!')
    }else if(!this.state.experience_description){
      isValid = false
      alert('请填写经历描述!')
    }else if(!this.state.start_date){
      isValid = false
      alert('请填写开始时间!')
    }else if(!this.state.end_date){
      isValid = false
      alert('请填写结束时间!')
    }else if(this.state.startDate_selectedDate > this.state.endDate_selectedDate){
      isValid = false
      alert('开始时间不能大于结束时间!')
    }

    if(!isValid){return false}

    const tempExperience = {
      'experience_type': this.state.experience_type,
      'experience_name': this.state.experience_name,
      'description': this.state.experience_description,
      'start_date': this.state.start_date,
      'end_date': this.state.end_date,
      'profile': this.state.profile_id,
      'experience_id': exp_id
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/experiences/${exp_id}/update/`),
    tempExperience, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('更新经历成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  deleteExperience = async(event) => {
    let exp_id = parseInt(event.target.value)
    if(!window.confirm('确定要删除该经历吗？')){
      return false
    }
    await axios.delete(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/experiences/${exp_id}/update/`),
    {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('删除经历成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  handlePriceCreate = async() => {
    let isValid = true
    //pre validation
    if(!this.state.contact_method){
      isValid = false
      alert('请选择一种咨询方式!')
    }else if(!this.state.price_value_low){
      isValid = false
      alert('请填写价格!')
    }else if(this.state.currency!=='USD' && this.state.currency!=='CNY'){
      isValid = false
      alert('货币单位必须为USD或CNY!')
    }

    if(!isValid){return false}

    const tempPrice = {
      contact_method: this.state.contact_method,
      price_value_low: this.state.price_value_low,
      currency: this.state.currency,
      profile: this.state.profile_id
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/price/hourly/create/`),
    tempPrice, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('添加价格成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  updatePrice = async(event) => {
    let price_id = parseInt(event.target.value)
    let isValid = true
    //pre validation
    if(!this.state.contact_method){
      isValid = false
      alert('请选择一种咨询方式!')
    }else if(!this.state.price_value_low){
      isValid = false
      alert('请填写价格!')
    }else if(this.state.currency!=='USD' && this.state.currency!=='CNY'){
      isValid = false
      alert('货币单位必须为USD或CNY!')
    }

    if(!isValid){return false}

    const tempPrice = {
      contact_method: this.state.contact_method,
      price_value_low: this.state.price_value_low,
      currency: this.state.currency,
      profile: this.state.profile_id
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/price/hourly/${price_id}/update/`),
    tempPrice, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('更新价格成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  deletePrice = async(event) => {
    let price_id = parseInt(event.target.value)
    if(!window.confirm('确定要删除该价格吗？')){
      return false
    }
    await axios.delete(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/price/hourly/${price_id}/update/`),
    {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('删除价格成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  handleAddressCreate = async() => {
    let isValid = true
    //pre validation
    if(!this.state.address_name){
      isValid = false
      alert('请填写地址名称!')
    }else if(!this.state.street_1){
      isValid = false
      alert('请填写具体地址!')
    }

    if(!isValid){return false}

    const tempAddress = {
      address_name: this.state.address_name,
      street_1: this.state.street_1,
      street_2: this.state.street_2,
      email_address: this.state.email_address,
      phone_number: this.state.phone_number,
      longitude: this.state.longitude,
      latitude: this.state.latitude,
      zip_code: this.state.zip_code,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      user: this.state.user_id
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/contact/create/`),
    tempAddress, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('添加地址成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  updateAddress = async(event) => {
    let address_id = parseInt(event.target.value)
    let isValid = true
    //pre validation
    if(!this.state.address_name){
      isValid = false
      alert('请填写地址名称!')
    }else if(!this.state.street_1){
      isValid = false
      alert('请填写具体地址!')
    }

    if(!isValid){return false}

    const tempAddress = {
      address_name: this.state.address_name,
      street_1: this.state.street_1,
      street_2: this.state.street_2,
      email_address: this.state.email_address,
      phone_number: this.state.phone_number,
      longitude: this.state.longitude,
      latitude: this.state.latitude,
      zip_code: this.state.zip_code,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      user: this.state.user_id
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/contact/${address_id}/update/`),
    tempAddress, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('更新地址成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  deleteAddress = async(event) => {
    let address_id = parseInt(event.target.value)
    if(!window.confirm('确定要删除该地址吗？')){
      return false
    }
    await axios.delete(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/contact/${address_id}/update/`),
    {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('删除地址成功！')
    }).catch(err => {
      // alert(err)
    })
  }

  render() {
    let user_avatar = avatar
    if(this.state.photo_url){user_avatar = this.state.photo_url}
    this.state.paymentList.map(tag => {
      let tag_value = ''
    })
    return (
      <div class="container-fluid px-lg-10 row">
       <div class="col-lg-2 col-12"></div>
       <div class="col-lg-12 col-12">
        <div>
          {/* title */}
          <div className='my_title'>
              <div></div>
              <div><h2>修改咨询师信息</h2></div>
            </div>
        </div>
        <div>
          <div class="row">
            <div className="col-lg-6 col-12 p-5" id='edit_basic_info'><div className='fixed-flow-elem'>
              <div class="mb-2">
                <span class="fs-3 box-shadow">基本信息</span>
              </div>
              <div>
                {/* 上传头像 */}
                <div class="row align-items-center">
                    <span>上传头像</span>
                    <div class="col-lg-3"><img src={user_avatar} alt="user_avatar" className="avatar-big"></img></div>
                    <div class="col">
                        <a className='extendImage'>
                            <ImageUploader
                                withIcon={false}
                                buttonText='点击选择图片'
                                onChange={this.onDrop}
                                imgExtension={['.jpg', '.png']}
                                maxFileSize={524288}
                                label='图片大小不超过500kb, 支持jpg｜png'
                                withPreview={true}
                                singleImage={true}
                            />
                        </a>
                    </div>
                    <div class="col-lg-3">
                        <button class="btn btn-secondary" onClick={this.uploadAvatar}>上传</button>
                    </div>
                </div>
                {/* 基本信息 */}
                <div>
                  <div>
                    <span>职称：</span>
                    <select class="form-select mb-3"name="title" id="title" onChange={this.handleTitleChange}>
                    {this.state.titleList.map((title) => {
                      if(title === this.state.title){
                        return <option value={title} selected={true}>{title}</option>
                      }else{
                        return <option value={title}>{title}</option>
                      }
                    })
                    }
                    </select>
                  </div>
                  <div>
                    <span>姓氏：</span>
                    {localStorage.getItem('user_type')&&localStorage.getItem('user_type')<=2?
                    <input class="form-control mb-3" type="text" value={this.state.first_name} onChange={this.handleFirstNameChange} placeholder="姓氏"></input>:
                    <span>{this.state.first_name}</span>
                    }
                    &nbsp;&nbsp;&nbsp;
                    <span>名字：</span>
                    {localStorage.getItem('user_type')&&localStorage.getItem('user_type')<=2?
                    <input class="form-control mb-3" type="text" value={this.state.last_name} onChange={this.handleLastNameChange} placeholder="名字"></input>:
                    <span>{this.state.last_name}</span>
                    }
                  </div>
                  <div>
                    （如有需要，请联系管理员更改姓名）
                  </div>
                  <div>
                    <span>学历：</span>
                    <input class="form-control mb-3" type="text" value={this.state.advanced_credential} onChange={this.handleCredentialChange} placeholder="P.h.D/M.S./M.D."></input>
                  </div>
                  <div>
                    <span>性别：</span>
                    <select class="form-select mb-3" name="gender" id="gender" onChange={this.handleGenderChange}>
                      {
                        this.state.genderList.map(gender => {
                          if(gender.id === this.state.gender){
                            return <option value={gender.id} selected={true}>{gender.value_cn}/{gender.value}</option>
                          }else{
                            return <option value={gender.id}>{gender.value_cn}/{gender.value}</option>
                          }
                        })
                      }
                    </select>
                  </div>
                  <div>
                    <span>咨询经验(小时)：</span>
                    <input class="form-control mb-3" type="text" value={this.state.len_exp_hr} onChange={this.handleExpHrChange} placeholder="咨询时间"></input>
                  </div>
                  <div class="mb-3">
                    <div>付款方式</div>
                    <div>
                        <input className="form-select" href="javascript:;" onClick={() => this.handleMethodDropdown()} type="text" placeholder="付款方式/Speciality" readOnly />
                                <ul className="dropdown-menu" style={{display: this.state.isMethodDisplay, border: 'none'}}>
                                  <div>付款方式</div>
                                  {
                                    this.state.paymentList.map((tag) => {
                                      return (<li>
                                        <input class="form-control mb-3" type="checkbox" onClick={this.handleFilter}
                                        name={tag.value_cn} class="hno-radiobtn" id={tag.tag_id} key={tag.tag_id} checked={this.state.selectedPaymentList.includes(parseInt(tag.tag_id))}/>
                                        <label for={tag.tag_id}>{tag.value_cn}</label>
                                      </li>)
                                    })
                                  }
                                </ul>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div>语言</div>
                    <div>
                        <input className="form-select" href="javascript:;" onClick={() => this.handleLangDropdown()} type="text" placeholder="语言/Language" readOnly />
                                <ul className="dropdown-menu" style={{display: this.state.isLangDisplay, border: 'none'}}>
                                  <div>语言</div>
                                  {
                                    this.state.langList.map((tag) => {
                                      return (<li>
                                        <input class="form-control mb-3" type="checkbox" onClick={this.handleFilter}
                                        name={tag.value_cn} class="hno-radiobtn" id={tag.tag_id} key={tag.tag_id} checked={this.state.selectedTags.includes(parseInt(tag.tag_id))}/>
                                        <label for={tag.tag_id}>{tag.value_cn}</label>
                                      </li>)
                                    })
                                  }
                                </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>简介:</div>
              </div>
              <div>
                <input class="form-control mb-3" type="text" value={this.state.self_intro} onChange={this.handleIntroChange} placeholder="一句话简介"></input>
              </div>
              <div>
                <div>额外信息</div>
              </div>
              <div>
              <textarea class="form-control mb-3" type="text" value={this.state.additional_info} onChange={this.handleAdditionalInfoChange} placeholder="额外信息"></textarea>
              </div>
              <div>
                <div>
                  {/* 给来访者的话 */}
                  <div>
                    <div>给来访者的话</div>
                    <div>
                      <textarea class="form-control mb-3" type="text" value={this.state.to_visitor} onChange={this.handleToVisitorChange} placeholder="给来访者的话"></textarea>
                    </div>
                  </div>
                  {/* 咨询师身份 */}

                  <div>
                    <div>咨询师身份</div>
                    <div>
                      <select class="form-select mb-3" name="mental_health_role" id="mental_health_role" required>
                        {
                          this.state.roleList.map(role => (
                            <option value={role.tag_id} onClick={this.handleRoleChange}>{role.value_cn}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  {/* 擅长领域 */}
                  <div class="mb-3">
                    <div>擅长领域</div>
                    <div>
                        <input className="form-select" href="javascript:;" onClick={() => this.handleAreaDropdown()} type="text" placeholder="擅长领域/Speciality" readOnly />
                        <ul className="dropdown-menu" style={{display: this.state.isAreaDisplay, border: 'none'}}>
                        <div>擅长领域</div>
                        {
                            this.state.areaList.map((tag) => (
                                <li>
                                    <input class="form-control mb-3" type="checkbox" onClick={this.handleFilter}
                                        name={tag.value_cn} class="hno-radiobtn" id={tag.tag_id} key={tag.tag_id} checked={this.state.selectedTags.includes(parseInt(tag.tag_id))} />
                                    <label for={tag.tag_id}>{tag.value_cn}</label>
                                </li>
                            ))
                        }
                        </ul>
                    </div>
                  </div>
                  {/* 面向群体 */}
                  <div>
                    <div>面向群体</div>
                    <div>
                        <input className="form-select" href="javascript:;" onClick={() => this.handleGroupDropdown()} type="text" placeholder="面向群体/Target" readOnly />
                                <ul className="dropdown-menu" style={{display: this.state.isGroupDisplay, border: 'none'}}>
                                  <div>面向群体</div>
                                  {
                                    this.state.groupList.map((tag) => (
                                      <li>
                                        <input class="form-control mb-3" type="checkbox" onClick={this.handleFilter}
                                        name={tag.value_cn} class="hno-radiobtn" id={tag.tag_id} key={tag.tag_id} checked={this.state.selectedTags.includes(parseInt(tag.tag_id))} />
                                        <label for={tag.tag_id}>{tag.value_cn}</label>
                                      </li>
                                    ))
                                  }
                                </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div></div>
            <div className='col-lg-6 col-12 p-5 profile-right-content' id='edit_advanced_info'>
              <div class="mt-4 mb-2">
                <span class="fs-3 box-shadow">详细信息</span>
              </div>
              <div className="mainpage_info">
                <div className='edit-more-info'>
                  {/* 相关执照 */}
                  <div>
                  <div class="mb-2">
                    <h4>编辑执照</h4>
                  </div>
                  <div>
                    <div>执照编号</div>
                    <div>
                      <input class="form-control mb-3" type="text" name="license_number" onChange={this.licenseNumberChange} required/>
                    </div>
                  </div>
                  <div>
                    <div class="mb-3">
                      <input type="checkbox" id="has_passed" name="has_passed" value="has_passed" onClick={this.handleLicenseHasPassed}/>
                      <label for="has_passed"> 是否已经获取</label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div>执照过期日</div>
                    <div>
                      <DatePicker className="form-control" selected={this.state.license_selectedDate} onChange={date => this.licenseExpirationChange(date)} />
                    </div>
                  </div>
                  <div>
                    <div>执照所在州</div>
                    <div>
                      <select class="form-select mb-3"name="license_state" id="license_state" onChange={this.licenseStateChange} required>
                        {this.state.stateList.map(state => (
                          <option value={state.abbreviation}>{state.name}</option>))
                        }
                    </select>
                    </div>
                  </div>
                  <div>
                    <div>督导师姓名</div>
                    <div>
                      <input class="form-control mb-3" type="text" name="supervisor_name" onChange={this.supervisorNameChange}/>
                    </div>
                  </div>
                  <div>
                    <div>督导师执照编号</div>
                    <div>
                      <input class="form-control" type="text" name="supervisor_license" onChange={this.supervisorLicenseChange}/>
                    </div>
                  </div>
                  <div className="edit-profile-submitbtn">
                    <button id="submit" type="submit" class="btn btn-third" onClick={this.handleLicenseCreate}>添加执照<i class="hno hno-arrow-right"></i></button>
                  </div>
                  {this.state.license.map(elem => {
                    let isPassed
                    if(elem.has_passed){isPassed = '是'}
                    else(isPassed = '获取中')
                    let tag_value = ''
                    this.state.allTags.map(tag=>{
                      if(elem.mental_health_role === tag.tag_id){
                        tag_value = tag.value_cn
                      }
                    })
                    return (<div>
                              <div> 执照编号：{elem.license_number}</div>
                              <div> 咨询师身份：{tag_value}</div>
                              <div> 是否已经获取：{isPassed}</div>
                              <div> 执照所在州：{elem.license_state}</div>
                              <div> 执照过期日：{elem.license_expiration}</div>
                              <div> 督导师姓名：{elem.supervisor_name}</div>
                              <div> 督导师执照编号：{elem.supervisor_license}</div>
                              <div>
                                <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={elem.license_id} onClick={this.updateLicense}>更新<i class="hno hno-arrow-right"></i></button></div>
                                <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={elem.license_id} onClick={this.deleteLicense}>删除<i class="hno hno-arrow-right"></i></button></div>
                              </div>
                            </div>)
                  })}
                  </div>
                  {/* 相关经历 */}
                  <div>
                  <div class="mb-2">
                    <h4>编辑经历</h4>
                  </div>
                  <div>
                    <div>
                      经历类型
                    </div>
                    <div>
                      <select class="form-select mb-3"name="experience_type" id="experience_type" required>
                        <option value="1" onClick={this.handleExperienceType}>教育</option>
                        <option value="2" onClick={this.handleExperienceType}>工作</option>
                        <option value="3" onClick={this.handleExperienceType}>培训</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div>经历名称</div>
                    <div>
                      <input class="form-control mb-3" type="text" name="experience_name" onChange={this.experienceNameChange} required/>
                    </div>
                  </div>
                  <div>
                    <div>经历描述</div>
                    <div>
                      <input class="form-control mb-3" type="text" name="description" onChange={this.experienceDescriptionChange} required/>
                    </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-6 col-12">
                        <div>开始日期</div>
                        <div>
                          <DatePicker className="form-control" selected={this.state.startDate_selectedDate} onChange={date => this.startDateChange(date)} />
                        </div>
                      </div>
                      <div class="col-lg-6 col-12">
                        <div>结束日期</div>
                        <div>
                          <DatePicker className="form-control" selected={this.state.endDate_selectedDate} onChange={date => this.endDateChange(date)} />
                        </div>
                      </div>
                  </div>
                  <div className="edit-profile-submitbtn">
                    <button id="submit" type="submit" class="btn btn-third" onClick={this.handleExperienceCreate}>添加经历<i class="hno hno-arrow-right"></i></button>
                  </div>
                    {
                      this.state.experience.map(exp => {
                        if(exp.experience_type !== 1){return null}
                        return (
                          <div>
                            <div>经历类型：教育</div>
                            <div>经历名称：{exp.experience_name}</div>
                            <div>经历描述：{exp.description}</div>
                            <div>经历时间：{exp.start_date} ~ {exp.end_date}</div>
                            <div>
                              <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={exp.experience_id} onClick={this.updateExperience}>更新<i class="hno hno-arrow-right"></i></button></div>
                              <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={exp.experience_id} onClick={this.deleteExperience}>删除<i class="hno hno-arrow-right"></i></button></div>
                            </div>
                          </div>
                        )
                      })
                    }
                    {
                      this.state.experience.map(exp => {
                        if(exp.experience_type !== 2){return null}
                        return (
                          <div>
                            <div>经历类型：工作</div>
                            <div>经历名称：{exp.experience_name}</div>
                            <div>经历描述：{exp.description}</div>
                            <div>经历时间：{exp.start_date} ~ {exp.end_date}</div>
                            <div>
                              <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={exp.experience_id} onClick={this.updateExperience}>更新<i class="hno hno-arrow-right"></i></button></div>
                              <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={exp.experience_id} onClick={this.deleteExperience}>删除<i class="hno hno-arrow-right"></i></button></div>
                            </div>
                          </div>
                        )
                      })
                    }
                    {
                      this.state.experience.map(exp => {
                        if(exp.experience_type !== 3){return null}
                        return (
                          <div>
                            <div>经历类型：培训</div>
                            <div>经历名称：{exp.experience_name}</div>
                            <div>经历描述：{exp.description}</div>
                            <div>经历时间：{exp.start_date} ~ {exp.end_date}</div>
                            <div>
                              <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={exp.experience_id} onClick={this.updateExperience}>更新<i class="hno hno-arrow-right"></i></button></div>
                              <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={exp.experience_id} onClick={this.deleteExperience}>删除<i class="hno hno-arrow-right"></i></button></div>
                            </div>
                          </div>
                        )
                      })
                    }
                    </div>
                  {/* 咨询费用 */}
                  <div>
                  <div class="mb-2">
                    <h4>编辑价格</h4>
                  </div>
                    <div>
                      <div>
                        咨询方式
                      </div>
                      <div>
                        <select class="form-select mb-3"name="contact_method" id="contact_method" required>
                          <option value="1" onClick={this.handleContactMethod}>语音</option>
                          <option value="2" onClick={this.handleContactMethod}>视频</option>
                          <option value="3" onClick={this.handleContactMethod}>线下</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <div>价格</div>
                      <div>
                        <input class="form-control mb-3" type="text" name="price_value_low" onChange={this.priceValueLowChange} required/>
                      </div>
                    </div>
                    <div>
                      <div>单位</div>
                      <div>
                        <select class="form-select"name="currency" id="currency" onChange={this.currencyChange} required>
                          <option value="USD">USD</option>
                          <option value="CNY">CNY</option>
                        </select>
                      </div>
                    </div>
                    <div className="edit-profile-submitbtn">
                      <button id="submit" type="submit" class="btn btn-third" onClick={this.handlePriceCreate}>添加价格<i class="hno hno-arrow-right"></i></button>
                    </div>
                  </div>
                    {
                      this.state.hourly_price.map((price => {
                        let contact_method = ''
                        if(price.contact_method === 0){contact_method = '未知'}
                        else if(price.contact_method === 1){contact_method = '语音'}
                        else if(price.contact_method === 2){contact_method = '视频'}
                        else if(price.contact_method === 3){contact_method = '线下'}
                        return (
                          <div>
                            <div>价格范围：{price.price_value_low} {price.currency}</div>
                            <div>咨询方式：{contact_method}</div>
                            <div>
                              <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={price.price_id} onClick={this.updatePrice}>更新<i class="hno hno-arrow-right"></i></button></div>
                              <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={price.price_id} onClick={this.deletePrice}>删除<i class="hno hno-arrow-right"></i></button></div>
                            </div>
                          </div>
                        )
                      }))
                    }
                </div>
                <div className='edit-address-info'>
                  {/* 联系方式 */}
                  <div>
                  <div><h4>编辑地址</h4></div>
                    <div>
                      <div>地址名称</div>
                      <div>
                        <input class="form-control mb-3" type="text" name="address_name" onChange={this.addressNameChange} required/>
                      </div>
                    </div>
                    <div>
                      <div>电话</div>
                      <div>
                        <input class="form-control mb-3" type="text" name="phone_number" onChange={this.phoneNumberChange}/>
                      </div>
                    </div>
                    <div>
                      <div>邮箱</div>
                      <div>
                        <input class="form-control mb-3" type="text" name="email_address" onChange={this.emailAddressChange}/>
                      </div>
                    </div>
                    <div>
                      <div>地址行1</div>
                    </div>
                    <div class="mb-3">
                      <GoogleMapAutoComplete onPlaceChange={(place) => this.handlePlaceChange(place)}></GoogleMapAutoComplete>
                    </div>
                    <div>
                      <div>地址行2</div>
                    </div>
                    <div>
                      <input class="form-control" type="text" name="street_2" onChange={this.streetTwoChange}/>
                    </div>
                    <div>
                      <button id="submit" type="submit" class="btn btn-third" onClick={this.handleAddressCreate}>添加地址<i class="hno hno-arrow-right"></i></button>
                    </div>
                  </div>
                  {this.state.address.map(elem => {
                        if(!elem.city && !elem.state && !elem.country){return null}
                        else{
                          return (<div style={{marginBottom: '40px'}}>
                                    <div style={{fontWeight:'bold'}}>{elem.address_name}</div>
                                    <div>{elem.street_1} {elem.street_2}</div>
                                    <div>{elem.city}, {elem.state}, {elem.country} {elem.zip_code}</div>
                                    <div>email: {elem.email_address}</div>
                                    <div>phone: {elem.phone_number}</div>
                                    <div style={{marginBottom:'10px',marginTop: '20px'}}>
                                      <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={elem.address_id} onClick={this.updateAddress}>更新<i class="hno hno-arrow-right"></i></button></div>
                                      <div className='edit-profile-icon'><button id="submit" type="submit" class="btn btn-default" value={elem.address_id} onClick={this.deleteAddress}>删除<i class="hno hno-arrow-right"></i></button></div>
                                    </div>
                                  {!!elem.latitude?
                                    <div>
                                      <GoogleMapFrame lat={elem.latitude} lng={elem.longitude} height={300} width="100%" zoom={12}></GoogleMapFrame>
                                    </div>:
                                      ""
                                  }
                                  </div>)
                        }
                  })}
                <div className='edit-profile-btn mb-4'>
                  <a>
                    <input class="form-control mb-3" id="search-btn" class="btn btn-primary" onClick={this.saveProfile} type="submit" value="保存信息"/>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </div>
       <div class="col-lg-2 col-12"></div>
      </div>
    )
  }
}
