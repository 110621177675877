import React, { Component } from 'react'
import TicketCard from './ticketCard';
import axios from 'axios'
import DatePicker from "react-datepicker";
import ReactLoading from 'react-loading';
import moment from 'moment';
import utils from './utils'

import '../css/appointment.css'

export default class AppointmentPage extends Component {

    state = {
        availableConsultants: [],
        availableTimeSlot: [],
        availableSessionLength: 0,
        user: { 
            anonymous: false, 
            user_id: '', 
            full_name: '',
            email: null,
            token: ''
        },
        appointment: {
            consultant_userid: "",
            date: new Date(),
            start_time: null,
            session_length: 1,
            timezone: moment.tz.guess(),
            notes: ''
        },
        ticket_submitting: false,
        ticket_data: null,
        paymentMethod: 'stripe',
        landingPageDisplay: true,
        landingPageAlertContent: '当前未登录，请输入未注册过的邮箱以开始预约或登录后预约'
    }

    anonymousLogin = () => {
        axios.post(utils.getUrl('permission/jwt/anon/'),
            { email: this.state.user.email })
            .then(response => {
                console.log(response.data)
                if (response.data.status === 0) {
                    this.setState({ 
                        user: {...this.state.user, token: response.data.data},
                        landingPageDisplay: false
                    })
                }
            }).catch(err => {
                console.log(err)
                this.setState({landingPageAlertContent: '邮箱已注册过Findself账号 登录再操作吧'})
            })
    }

    createTicket = async () => {
        // set loading animation
        this.setState({ticket_submitting: true})
        // submit ticket
        await axios.post(utils.getUrl(`appointment/ticket/create/${this.state.appointment.consultant_userid}/`), 
            { length: this.state.appointment.session_length, method: 1, currency: 'CNY', start_time: this.state.appointment.start_time, location: '', 
                meta: {notes: this.state.appointment.notes, timezone: this.state.appointment.timezone, email: this.state.user.email, full_name: this.state.user.full_name} },
            { headers: { 'Authorization': 'JWT ' + this.state.user.token } })
            .then(response => {
                console.log(response.data)
                this.setState({ticket_data: response.data})
                // delay 2s to dismiss the loading animation
                setTimeout(() => {
                    this.setState({ ticket_submitting: false})
                }, 2000);
            }).catch(err => {
                console.log(err)
            })
    }

    fetchConsultants = () => {
        // premium 0 -> normal, 1 -> premium
        axios.post(utils.getUrl( 'public/filter-by-tag/' ), 
            { premium_status: [1] })
            .then(response => {
                console.log(response.data.data)
                this.setState({availableConsultants: response.data.data})
            }).catch( err => { console.log(err) })
    }

    handleConsultantChange = (e) => {
        this.setState({ appointment: { ...this.state.appointment, consultant_userid: e.target.selectedOptions[0].dataset.userid} }, () => {
            this.fetchAvailabilityForConsultant()
        })
    }

    handleDateChange = () => {
        this.fetchAvailabilityForConsultant()
    }

    handleStartTimeChange = (e) => {
        let selectedStartTime = e.target.selectedOptions[0].dataset.starttime
        var startTime = selectedStartTime
        // 1 unit of session length is 30 minutes
        var sessionLength = 0
        this.state.availableTimeSlot.forEach(session => {
            if (session[0] === startTime) {
                startTime = session[1]
                sessionLength++
            }
        });
        this.setState({ availableSessionLength: sessionLength, appointment: { ...this.state.appointment, start_time: selectedStartTime } })
    }

    handleSessionLengthChange = (e) => {
        this.setState({ appointment: { ...this.state.appointment, session_length: parseInt(e.target.selectedOptions[0].value) } })
    }

    fetchAvailabilityForConsultant = () => {
        let startTime = new Date(this.state.appointment.date)
        startTime.setHours(0, 0, 0, 0)
        let endTime = new Date(this.state.appointment.date)
        endTime.setHours(24, 0, 0, 0)
        axios.post(utils.getUrl('appointment/schedule/'+this.state.appointment.consultant_userid+'/'),
            { start_time: startTime.toISOString(), end_time: endTime.toISOString(), offset: 0 },
            { headers: { 'Authorization': 'JWT ' + this.state.user.token } })
            .then(response => {
                console.log(response.data.intervals)
                let timeslots = response.data.intervals.filter((session) => {
                    // session[0] is start time, not allow appointment startime within 1 hour from now
                    var sessionDateTime = new Date(session[0])
                    var currentDateTime = new Date()
                    return session[2] === false && session[3] === false && (sessionDateTime - currentDateTime > 60000)
                })
                // reset start_time and session length option
                this.setState({ availableTimeSlot: [], availableSessionLength: 0 })
                this.setState({ availableTimeSlot: timeslots })
            }).catch(err => { console.log(err) })
    }

    getMaxSelectableDate = () => {
        // today -> 60 days away ()
        var future = new Date()
        future.setDate(future.getDate() + 60)
        return future
    }

    componentWillMount = () => {
        // set up consultant
        this.fetchConsultants()
        // set up current user
        let anonymousUser = !(localStorage.getItem('user_id') && localStorage.getItem('full_name'))
        this.setState({
            user: {
                anonymous: anonymousUser,
                user_id: anonymousUser? '' : localStorage.getItem('user_id'),
                full_name: anonymousUser? '' : localStorage.getItem('full_name'),
                token: anonymousUser ? '' : localStorage.getItem('loginToken')
            },
            landingPageDisplay: anonymousUser
        })
    }

    render() {
        let consultantOptions = this.state.availableConsultants.map((consultant) => {
            return (
                <option data-userid={consultant.user__id} date-profileid={consultant.profile_id}>{consultant.full_name}</option>
            )
        })
        let availabilityOptions = this.state.availableTimeSlot.map((session) => {
            let date = new Date(session[0])
            let timeSlotText = date.toLocaleDateString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit'
            })
            return (
                <option data-starttime={session[0]} data-endtime={session[1]}>{timeSlotText}</option>
            )
        })
        let sessionLengthOptions = []
        for (var i = 1; i <= this.state.availableSessionLength; i++) {
            sessionLengthOptions.push(<option value={i}> {i*0.5}小时 </option>)
        }
        return (
            <div style={{ paddingBottom: 100 }}>
                {/* 开篇 */}
                <div className="container-fluid main-content px-lg-10 py-1 d-flex flex-column align-items-center">
                    {this.state.landingPageDisplay?
                        <div style={{width:'50%'}}>
                            <div className="row my-4">
                                <div className="alert alert-primary text-center" role="alert">
                                    {this.state.landingPageAlertContent}
                                </div>
                                <form onSubmit={(e) => { this.anonymousLogin(); e.preventDefault(); }}>
                                    <input type="email" class="form-control" id="email" name="email" value={this.state.user.email}
                                        onChange={(e) => this.setState({ user: { ...this.state.user, email: e.target.value } })} placeholder="findself@gmail.com" required />
                                    <input class="form-control" type="submit" value="Check out as Guest" />
                                </form>
                            </div>
                            <hr style={{ color: '#43B0B1' }}/>
                            <div className="row my-4">
                                <input class="form-control" type="button" value="登录" onClick={() => window.location.href = "/#/login"} />
                                <input class="form-control" type="button" value="注册" onClick={() => window.location.href = "/#/signup/webuser"} />
                            </div>
                        </div>
                        :
                        <form onSubmit={(e) => { this.createTicket(); e.preventDefault(); }}>
                            <div className="row g-1 g-lg-4 pt-3">
                                <div className="col-12 col-lg-6 d-flex flex-column">
                                    <div className="pb-3">
                                        <label for="consultant" class="form-label">预约咨询师：</label>
                                        <select id="consultant" class="form-select" onChange={(e) => this.handleConsultantChange(e)} required>
                                            <option value="">选择咨询师</option>
                                            {consultantOptions}
                                        </select>
                                    </div>
                                    <div className="pb-3">
                                        <label for="username" class="form-label">用户姓名</label>
                                        <input type="" class="form-control" id="username" name="username" maxLength="20" 
                                            onChange={(e) => this.setState({ user: { ...this.state.user, full_name: e.target.value.trim() }})}
                                            placeholder="填写您的姓名" {...(!this.state.user.anonymous ? { value: this.state.user.full_name,  disabled: true } : {})} required />
                                    </div>
                                    <div className="pb-3">
                                        <label for="email" class="form-label">邮箱</label>
                                        <input type="email" class="form-control" id="email" name="email" value={this.state.user.email}
                                            onChange={(e) => this.setState({ user: { ...this.state.user, email: e.target.value } })} placeholder="findself@gmail.com" 
                                            {...(this.state.user.anonymous ? { disabled: true } : {})} required />
                                    </div>
                                    <div className="pb-3 row">
                                        <div className="col-lg-6">
                                            <label for="timezone" class="form-label">时区</label>
                                            <input type="text" class="form-control" id="timezone" name="timezone" placeholder="基于你的网络位置 暂不支持修改" value={this.state.appointment.timezone} disabled />
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="date" class="form-label">日期：</label>
                                            <div id="timezone" name="timezone" className="timezone">
                                                <DatePicker className="form-control" selected={this.state.appointment.date} minDate={new Date()} maxDate={this.getMaxSelectableDate()}
                                                    onChange={date => { this.setState({ appointment: { ...this.state.appointment, date: date } }, () => { this.handleDateChange() }) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3 row">
                                        <div className="col-lg-6">
                                            <label for="starttime" class="form-label">开始时间：</label>
                                            <select id="starttime" name="starttime" class="form-select" onChange={(e) => this.handleStartTimeChange(e)} required>
                                                <option value="">选择开始时间</option>
                                                {availabilityOptions}
                                            </select>
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="sessionlength" class="form-label">咨询时长：</label>
                                            <select id="sessionlength" name="sessionlength" class="form-select" onChange={(e) => this.handleSessionLengthChange(e)} required>
                                                <option value="">选择时长</option>
                                                {sessionLengthOptions}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <label for="notes" class="form-label">备注</label>
                                        <input type="text" class="form-control" id="notes" name="notes" maxLength="30" placeholder="可填写备注"
                                            onChange={(e) => this.setState({ appointment: { ...this.state.appointment, notes: e.target.value.trim() } })} />
                                    </div>
                                    <div className="pb-3">
                                        <label for="paymentMethod" class="form-label">支付方式</label>
                                        <select class="form-select" id="paymentMethod" name="paymentMethod" required>
                                            <option value="stripe" selected>Stripe ( 支持信用卡 / 支付宝 / Google Pay )</option>
                                            <option disabled>微信支付 ( 暂未开放 )</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 pt-2 d-flex flex-column align-items-center">
                                    <div class="card mb-2" style={{}}>
                                        <div class="row g-0">
                                            <div class="card-body">
                                                <h5 class="card-title">咨询师信息</h5>
                                                <div class="row g-0">
                                                    <div class="col-md-8">
                                                        <div class="card-body">
                                                            <p class="card-text">王妙韵, M.S</p>
                                                            <p class="card-text"><small class="text-muted">香港城市大学应用心理学硕士，华东理工大学管理学学士，150+小时咨询经验，目前为上海平安健康互联网心理科咨询师。</small></p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img src="https://findselfuseravatar.s3.us-west-1.amazonaws.com/post_img/counselorsunder30_wmy_profile.png" class="img-fluid rounded-start" alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.ticket_submitting ? <ReactLoading type='cylon' color='#5ecfd0' /> : ""}
                                    {this.state.user.anonymous && this.state.ticket_data ?
                                        <div className="alert alert-primary text-center" role="alert" style={{width: '100%'}}>
                                            请记录您的订单号 预约确认也会发送至邮箱
                                        </div>:''}
                                    {/*ticket card*/}
                                    {this.state.ticket_data ?
                                        <TicketCard data={this.state.ticket_data} token={this.state.user.token} />
                                        :
                                        <input class="form-control" type="submit" value="提交订单" />
                                    }
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        )
    }
}
