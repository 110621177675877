import axios from "axios";
const urlAddr = process.env.REACT_APP_FINDSELF_PRD==='1'? "https://api.findself.org/api/v1/": "https://uat-api.findself.org/api/v1/"
const env = process.env
const stripe_key_prd = "pk_live_51IQpgLL7zkzQauLdogY5DYk5HP0BeWVYfaskIReqAXwLvn5mLgSrxjzUB3R77nxamf42AX3gSD9vW5WkkMiYaPHu00Ul2BkMYi"
const stripe_key = "pk_test_51IQpgLL7zkzQauLdX7nHj4IPTexbTMvBWEkBn0818Wv6Frjx9di1n3SKnZ9IlHAm1nmbvX310mgs2TBpJaURXz0v00Q57JW2jl"

const getUrl = (url) => urlAddr + url
const getStripeKey = () =>stripe_key
const loadTagList = async(forceReload=false) => {
    if (window.tagList && !forceReload ) {
       return
    }
    await axios.get(getUrl('tags/list/'))
        .then(response => {
            // set payment list
            let tempPayment = []
            let tempArea = []
            let tempGroup = []
            let tempRole = []
            let tempMethodology = []
            let tempQA = []
            let tempLang = []
            let tempEvent = []
            let tempEventMethod = []
            response.data.results.map((tag) => {
                var cate = (("" + tag.tag_id).split(""))[0]
                if (cate === '7') {
                    tempLang.push(tag)
                }
                if (cate === '5') {
                    tempPayment.push(tag)
                }if (cate === '6') {
                    tempQA.push(tag)
                }else if (cate === '4') {
                    tempMethodology.push(tag)
                } else if (cate === '2') {
                    tempArea.push(tag)
                } else if (cate === '3') {
                    tempGroup.push(tag)
                } else if (cate === '1') {
                    tempRole.push(tag)
                } else if (cate === '8') {
                    tempEvent.push(tag)
                } else if (cate === '9') {
                    tempEventMethod.push(tag)
                }
            })
            window.tagList = {
                paymentList: tempPayment,
                areaList: tempArea.sort((a, b) => (a.value_cn).localeCompare(b.value_cn)),
                groupList: tempGroup.sort((a, b) => (a.value_cn).localeCompare(b.value_cn)),
                roleList: tempRole.sort((a, b) => (a.value_cn).localeCompare(b.value_cn)),
                methodologyList: tempMethodology.sort((a, b) => (a.value_cn).localeCompare(b.value_cn)),
                qAndAList: tempQA.sort((a, b) => (a.value_cn).localeCompare(b.value_cn)),
                langList:tempLang.sort((a, b) => (a.value_cn).localeCompare(b.value_cn)),
                eventList: tempEvent.sort((a, b) => (a.value_cn).localeCompare(b.value_cn)),
                eventMethodList: tempEventMethod.sort((a, b) => (a.value_cn).localeCompare(b.value_cn)),
                allTags: response.data.results,

            }
        }).catch(err => {
        // alert(err)
    })

}

const getAxiosConfig = ()=>{
    if (localStorage.getItem('loginToken')) {
        return {
            headers: {
                'Authorization': 'JWT ' + localStorage.getItem('loginToken')
            }
        }
    }
    else {
        return {}
    }
}

const toPST = (s)=>{
    const d = new Date(s)
    return d.toLocaleString("en-US", {timeZone: "us/pacific"}) + ' PST'
}

const logout = () => {
    localStorage.clear()
    // localStorage.removeItem('loginToken')
    // localStorage.removeItem('full_name')
    // localStorage.setItem('user_avatar', 'null')
    // localStorage.removeItem('user_id')
    // localStorage.removeItem('user_type')
    // localStorage.removeItem('profile_id')
    // localStorage.removeItem('user_email')
    // localStorage.removeItem('logInTime')
    window.location.href="/"
}
const translate = (arr,src)=>{
    const result = []
    for (var tag_id of arr) {
        let filtered = src.filter((ele) => ele.tag_id === tag_id)
        if (filtered) {
            result.push(filtered[0])
        }
    }
    return result
}
const loadBanner = async (forceReload=false)=>{
    if (window.allAds && !forceReload){
        return
    }

    await axios.get(getUrl('public/banners/list/')).then(
        (response)=>{
            window.allAds = response.data.data || []
        }
    ).catch((err)=>{
        // alert(err)
    })
}

const timezoneList = [
    {value_cn: '北京时间 GMT+08:00', id: 'Asia/Shanghai'},
    {value_cn: '东部时间 GMT-04:00', id: 'America/Detroit'},
    {value_cn: '太平洋时间 GMT-07:00', id: 'America/Los_Angeles'},
    {value_cn: '山地时间 GMT-06:00', id: 'America/Boise'},
    {value_cn: '中部时间 GMT-05:00', id: 'America/Chicago'},
    {value_cn: '澳洲时间 GMT+10:00', id: 'Australia/Canberra'},
    {value_cn: '伦敦时间 GMT+00:00', id: 'Europe/London'},
]


const questionPages = [
    {
        text: "在找我咨询显示", // check box label
        tag_id: "critical_question", //anything, need to be unique
        short: "找我"// badge name
    },
    {
        text: "在青年咨询师显示",
        tag_id: "premium_question",
        short: "青年"
    },
    {
        text: "在咨询师注册显示",
        tag_id: "register_question",
        short: "注册"
    }
]

const getFacebookCredential = (response) => {
    let facebook_id = response?.authResponse?.userID;
    if (!facebook_id) {
        alert("请提供正确的账号")
        return;
    }
    facebook_id = parseInt(facebook_id)
    const guri = `findself-facebook://${facebook_id ^ 0xB906ABCA}`;
    return guri;
}

const getGoogleCredential = (response) => {
    const google_id = response.getId();
    const guri = `findself-google://${google_id ^ 0xB906ABCA}`;
    return guri;
}

const parseLocalDateTimeString = (date)=>{
    date = date.replace('Z','')
    const date_time = date.split('T')
    let st = parseWeekDay(date_time[0]);
    let time = date_time[1].split(':')
    let h = parseInt(time[0]), m = parseInt(time[1])
    return addMinute(st, (h * 60 + m))
}
const getIntervalOptions = (date) => {
    return ['30']
}

const dateToHourString = (date)=>{
    const newDate = resolveOffset(date);
    return newDate.toISOString().substring(11, 16)
}

const hourStringToMs = (date) => {
    let st = date.split(':')
    let h = parseInt(st[0]),m= parseInt(st[1])
    return (h * 60 + m )
}
const parseWeekDay = (date)=>{
    let st = date.split('-');
    return new Date(parseInt(st[0]), parseInt(st[1])-1, parseInt(st[2]))
}
const createDate = (date)=>{
    if (date instanceof Date){
        return new Date(date.getTime()+ date.getTimezoneOffset() * 60000)
    } else {
        if (date.length==10){
            let newDate = new Date(date + "T00:00:00")
            return new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000)
        }else {
        let newDate = new Date(date);
           return  new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
        }
    }
}
const resolveOffset = (date)=>{
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
}
const addMinute = (date,minutes)=>{
    return new Date(date.getTime() + minutes * 60000);
}
const dateToDateString = (date) => {
    const newDate = resolveOffset(date);
    return newDate.toISOString().substring(0, 10)
}
const dateToDateTimeString = (date)=>{
    const newDate = resolveOffset(date);
    return newDate.toISOString().substring(0, 16)
}

const getLoginErrorTimes = () => {
    return parseInt(localStorage.getItem('loginErr') || 0)
}
const setLoginErrorTimes = (num) => {
    return localStorage.setItem('loginErr',num)
}
export default {
    loadTagList: loadTagList,
    translate: translate,
    logout: logout,
    loadBanner: loadBanner,
    questionPages: questionPages,
    timezoneList: timezoneList,
    getAxiosConfig: getAxiosConfig,
    getFacebookCredential: getFacebookCredential,
    getGoogleCredential: getGoogleCredential,
    getIntervalOptions: getIntervalOptions,
    dateToHourString: dateToHourString,
    dateToDateString: dateToDateString,
    parseWeekDay: parseWeekDay,
    getLoginErrorTimes: getLoginErrorTimes,
    setLoginErrorTimes: setLoginErrorTimes,
    dateToDateTimeString: dateToDateTimeString,
    hourStringToMs: hourStringToMs,
    addMinute: addMinute,
    createDate: createDate,
    parseLocalDateTimeString: parseLocalDateTimeString,
    resolveOffset,
    getUrl,
    toPST,
    env,
    getStripeKey
}
