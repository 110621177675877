import React, { Component } from 'react'
import {Container, Row, Col, Modal, Button} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import axios from 'axios'
import TagEdit from './tagEdit'
import ReactLoading from 'react-loading';
import AboutUsEdit from "./AboutUsEdit";
import avatar from '../assets/website_logo.png'
import utils from './utils'
import ImageUpload from './ImageUpload'

const parse = (html)=>{
  return <span dangerouslySetInnerHTML={{__html: html}}/>
}
const withDrawStatus = [
    '未处理','已确认','已取消'
]
const ticketStatus = [
 '已提交','','已完成','已取消','已付款']
export default class AdminPage extends Component {

  state = {
    comments: [],
    start: 0,
    end: 10,
    total: 0,
    currentCategory: 'tagManage',
    email: '',
    first_name: '',
    last_name: '',
    profile_id: '',
    user_id: '',
    invite_id: '',
    isRefundLoading: false,
    isWithDrawLoading: false,
    withDrawList: [],
    isTicketLoading: false,
    allTickets:[],
    refundList: [],
    ticketState: [0,1,4,2],
    consultant:'',
    coverUrl:'',
    // refund_status:null,
    user:'',
    showModal:false,
    activeWidthdraw:null,
  }

  componentDidMount = () => {
    this.handleSearch()
    this.getRefunds()
    this.getWithdraws()
    this.getAllTickets()
  }

  handleEmailChange = (event) => {
    this.setState({email: event.target.value})
  }
  handleFirstNameChange = (event) => {
    this.setState({first_name: event.target.value})
  }
  handleLastNameChange = (event) => {
    this.setState({last_name: event.target.value})
  }

  createInvitation = async() =>{
    await axios.post(utils.getUrl(`admin/invitation/create/`),
    {email:this.state.email, first_name: this.state.first_name, last_name: this.state.last_name}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then((response) => {
      const profile_id = response.data.data.profile_id
      alert('创建成功')
      this.setState({profile_id : profile_id}, this.getHomepage)
    }).catch(err => {
      // alert(err)
    })
  }
  handleModalClose = ()=>{
    this.setState({
      showModal:false,
      coverUrl:'',
      activeWidthdraw:null,
    })
  }

  getHomepage = async() =>{
    await axios.get(utils.getUrl(`public/${this.state.profile_id}/homepage/`),
    {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then((response) => {
      this.setState({user_id: response.data.user.id})
      window.confirm(`您的邀请码为${response.data.invitation.invitation_id}，请及时保存，现在为您跳转到该用户主页`)
      this.props.history.push(`/therapist/view/:${this.state.profile_id}`)
    }).catch(err => {
      // alert(err)
    })
  }

  addressComment = async(report_id) => {
    await axios.post(utils.getUrl(`public/blog/reports/${report_id}/resolve/`), {action: 'address'},
    {headers:{'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
    .then((response) => {
      alert('删除成功！')
      this.handleSearch()
    })
    .catch(err => {
      // alert(err)
    })
  }
  ignoreComment = async(report_id) => {
    await axios.post(utils.getUrl(`public/blog/reports/${report_id}/resolve/`), {action: 'ignore'},
    {headers:{'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
    .then((response) => {
      alert('忽略成功！')
      this.handleSearch()
    })
    .catch(err => {
      // alert(err)
    })
  }

  handlePagination = () => {
    let currentEnd = this.state.end
    this.setState({end: currentEnd+10}, () => {this.handleSearch()})
  }

  handleSearch = async() => {
    await axios.post(utils.getUrl('public/blog/reports/list/'), {start: this.state.start, end: this.state.end, addressed:[0]})
    .then((response) => {
      this.setState({ comments: response.data.data, total: response.data.count})
    })
    .catch(err => {
      // alert(err)
    })
  }

  handleCategory = (tag_id) =>{
    this.setState({currentCategory: tag_id})
  }

  getRefunds = async() => {
    await axios.post(utils.getUrl('appointment/ticket/query/'),
    {refund_status: [0], start: 0, end: 100}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then((response) => {
      let data = response.data.data
      
      this.setState({refundList: data.data})
    })
    .catch(err => {
      // alert(err)
    })
  }
  getAllTickets = async () => {
    await axios.post(utils.getUrl('appointment/ticket/query/'),
        {
          status: this.state.ticketState,
          start: 0,
          end: 100
        }, {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then((response) => {
          let data = response.data.data
          
          this.setState({allTickets: data.data})
        })
        .catch(err => {
          // alert(err)
        })
  }
  getWithdraws = async () => {
    await axios.post(utils.getUrl('payment/withdraw/query/'),
        {start:0,end:100}, {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then((response) => {
          let data = response.data.data
          
          this.setState({withDrawList: data.data})
        })
        .catch(err => {
          // alert(err)
        })
  }

  approveRefund = async(ticket_id) => {
    await axios.post(utils.getUrl(`appointment/ticket/refund/${ticket_id}/`),
    {processed: true}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then((response) => {
      
      alert('同意成功！')
    })
    .catch(err => {
      // alert(err)
    })
  }
  approveWithdraw = async (request_id) => {
    if (!this.state.coverUrl) {
      alert("请上传凭证")
      return
    }
    if (!request_id){
      alert("Error")
      return
    }
    await axios.post(utils.getUrl(`payment/withdraw/handle/${request_id}/`),
        {status: 1,image: this.state.coverUrl}, {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then((response) => {
          
          alert('同意成功！')
          this.updateWithDrawStatus(request_id,1, this.state.coverUrl)
          this.handleModalClose();
        })
        .catch(err => {
          // alert(err)
        })
  }
  updateWithDrawStatus = async (request_id,status,img)=>{
      this.setState((prevState)=>({
        withDrawList:prevState.withDrawList.map(ele=>(
            ele.request_id== request_id?{...ele,status:status, image: img || ele.image || ''}:ele
        ))
      }))
  }

  rejectWithdraw = async (request_id) => {
    await axios.post(utils.getUrl(`payment/withdraw/handle/${request_id}/`),
        {status: 2}, {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then((response) => {
          
          alert('拒绝成功！')
          this.updateWithDrawStatus(request_id, 2)
        })
        .catch(err => {
          // alert(err)
        })
  }

  rejectRefund = async(ticket_id) => {
    await axios.post(utils.getUrl(`appointment/ticket/refund/${ticket_id}/`),
    {processed: false}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then((response) => {
      
      alert('拒绝成功！')
    })
    .catch(err => {
      // alert(err)
    })
  }
  searchFilter = (elem)=>{
    const key = this.state.consultant
    if (!key){
      return true
    } else {
      if (elem.ticket_id == key) {
        return true
      }
      if (elem.consultant_data.id.toString() == key||elem.user_data.id.toString()==key){
        return true
      }
      const consultantName = elem.consultant_data.first_name + ' '+ elem.consultant_data.last_name
      if (consultantName.toLowerCase().includes(key)){
        return true
      }
      const userName = elem.user_data.nickname === '' ? elem.user_data.first_name + ' ' + elem.user_data.last_name : elem.user_data.nickname
      if (userName.toLowerCase().includes(key)) {
        return true
      }
      return false
    }
  }
  getControls(elem) {
    if (elem.refund_request?.status==0){
    return <React.Fragment>
      <input className="events-btn-2" onClick={() => this.approveRefund(elem.ticket_id)}
             style={{height: '36px', marginRight: '8px'}} type="button" value="同意退款"/>
      <input className="events-btn-2" onClick={() => this.rejectRefund(elem.ticket_id)}
             style={{height: '36px', marginRight: '8px'}} type="button" value="拒绝退款"/>
    </React.Fragment>}
    else if (!elem.refund_request) {
      return <input className="events-btn-2" onClick={() => this.approveRefund(elem.ticket_id)}
                    style={{height: '36px', marginRight: '8px'}} type="button" value="退款"/>
    }
  }

  renderCategory = () => {
    if(this.state.currentCategory === 'tagManage'){
      return <TagEdit></TagEdit>
    }else if(this.state.currentCategory === 'reportedComment'){
      return (<Container fluid>
        <Row style={{fontSize: '20px', textAlign: 'center', marginBottom: '20px'}}>举报评论管理</Row>
        {
          this.state.comments.map(comment =>{
            if(comment.addressed){return false}
            else{
              let user_type
              if(comment.target.author.user_type <= 2){user_type = 'Admin'}
              else if(comment.target.author.user_type === 3){user_type = 'Therapist'}
              else if(comment.target.author.user_type === 4){user_type = 'Webuser'}
              else{user_type = 'Other'}
              let username
              if(comment.target.author.nickname && comment.target.author.nickname !== ''){username = comment.target.author.nickname}
              else{username = comment.target.author.first_name+' '+comment.target.author.last_name}
              return (
                <Row style={{textAlign: 'center', marginBottom: '20px'}}>
                  <Row style={{fontWeight: '700'}}>评论{comment.report_id}</Row>
                  <Row>内容：{parse(comment.target.content)}</Row>
                  <Row><NavLink to={`/blog/:${comment.target.parent}/show/`}>原文链接</NavLink></Row>
                  <Row>举报理由：{comment.text}</Row>
                  <Row>作者：{username} [{user_type}]</Row>
                  <Row>编辑时间：{comment.target.edit_date}</Row>
                  <Row>
                    <button onClick={() =>this.addressComment(comment.report_id)} style={{marginRight: '20px'}}>删除评论</button>
                    <button onClick={() =>this.ignoreComment(comment.report_id)}>忽略举报</button>
                  </Row>
                </Row>
              )
            }
          })
        }
        {/* pagination */}
        <Row style={{marginTop: '20px'}}>
            <Col md={3}></Col>
            {<div>
              <Col md={2} style={{marginRight: '20px', color:'#7f888f'}}>
                <div style={{marginTop: '10px'}}>当前展示 0-{this.state.end > this.state.total ? this.state.total:this.state.end} 条结果/共{this.state.total}条</div>
              </Col>
              <Col md={2}>
                <a href='javascript:;' style={{paddingLeft: '30%'}}>
                  <div className='white-green-shadow-button' onClick={this.handlePagination} style={{fontSize:'14px'}}>显示更多</div>
                </a>
              </Col>
            </div>}
          </Row>
      </Container>)
    }else if(this.state.currentCategory === 'inviteCode'){
      return (<Container fluid>
                <Row style={{fontSize: '20px', textAlign: 'center', marginBottom: '20px'}}>创建邀请码</Row>
                <Row style={{marginTop: '40px', textAlign: 'center'}}>
                  <span style={{marginRight: '20px'}}>邮箱/email</span>
                  <input type="text" placeholder="email" className='tawkto-input' onChange={this.handleEmailChange} style={{width: '10%', marginBottom: '40px'}} />
                </Row>
                <Row style={{textAlign: 'center'}}>
                  <span style={{marginRight: '20px'}}>名字/first Name</span>
                  <input type="text" placeholder="first name" className='tawkto-input' onChange={this.handleFirstNameChange} style={{width: '10%', marginBottom: '40px'}} />
                </Row>
                <Row style={{textAlign: 'center'}}>
                  <span style={{marginRight: '20px'}}>姓氏/last Name</span>
                  <input type="text" placeholder="last name" className='tawkto-input' onChange={this.handleLastNameChange} style={{width: '10%', marginBottom: '40px'}} />
                </Row>
                <Row><a style={{textAlign: 'center', display: 'block'}}><button onClick={this.createInvitation} className='white-green-shadow-button'>创建</button></a></Row>
              </Container>)
    }
    else if(this.state.currentCategory === 'refund'){
      return (<Container>
                <Row style={{fontSize: '20px', textAlign: 'center', marginBottom: '20px'}}>退款管理</Row>
                {this.state.isRefundLoading?(<Row style={{paddingLeft: '45%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
                          this.state.refundList.map(elem=>{
                            let start_date = new Date(elem.start_time)
                            let end_date = new Date(start_date.getTime() + elem.length*1000*60*30)
                            let formatted_date = start_date.getFullYear()+'-' + (start_date.getMonth()+1) + '-'+start_date.getDate()
                            let time_duration = start_date.getHours()+':'+(start_date.getMinutes()===0?
                            '00':start_date.getMinutes()) + ' - ' + end_date.getHours()+':'+(end_date.getMinutes()===0?'00':start_date.getMinutes())
                            let submitTime = new Date(elem.refund_request.create_date)
                            submitTime = submitTime.getFullYear()+'-' + (submitTime.getMonth()+1) + '-'+submitTime.getDate() + ' ' + submitTime.getHours()+':'+(submitTime.getMinutes()===0?
                            '00':submitTime.getMinutes())
                            return <React.Fragment>
                            <Row style={{marginTop: '16px', height: '189px', borderBottom: '2px solid #E7E9EE'}}>
                                    <Col md={11} className='flex-block'>
                                      <Col md={3} style={{ marginRight: '24px' }}>
                                        <img src={elem.consultant_data.photo_url===''?avatar:elem.consultant_data.photo_url} alt='cover_img' style={{width: '135px', height: '135px', borderRadius: '135px'}}></img>
                                      </Col>
                                      <Col md={5}>
                                        <Row style={{fontSize: '24px', fontWeight: '400', marginBottom: '8px'}}>
                                          <Col md={6} style={{ paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                                            咨询师：{elem.consultant_data.first_name} {elem.consultant_data.last_name}
                                          </Col>
                                          <Col md={6} style={{ paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                                            申请人：{elem.user_data.nickname===''?elem.user_data.first_name+' '+elem.user_data.last_name:elem.user_data.nickname}
                                          </Col>
                                        </Row>
                                        <Row style={{marginBottom: 8}} className='flex-block'>
                                          <div style={{padding: '0', maxWidth: 100, marginRight: 20, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19.5" fill="#43B0B1" class="bi bi-geo-alt" viewBox="0 0 16 16" style={{position: 'relative', top: '3px', left: '0'}}>
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                  </svg>
                                            &nbsp;
                                            {elem.method===3?elem.meta?.city&&elem.meta?.state?
                                              (elem.meta?.city+', '+elem.meta?.state):'':
                                              elem.method===2?'视频':'语音'
                                            }
                                          </div>
                                          <div style={{padding: '0'}}>
                                            <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="19" height="19" fill="#43B0B1" style={{position: 'relative', top: '3px', left: '0'}}>
                                                    <path d="M220.90723,35.09277A11.85078,11.85078,0,0,0,209.165,32.02832L22.83691,84.582a12.00013,12.00013,0,0,0-1.8789,22.39453l85.61035,40.55176a4.01536,4.01536,0,0,1,1.90234,1.90234L149.02344,235.042a11.89209,11.89209,0,0,0,10.81152,6.86524q.51563,0,1.03711-.04395a11.90291,11.90291,0,0,0,10.5459-8.70019L223.97168,46.835A11.853,11.853,0,0,0,220.90723,35.09277Zm-4.63477,9.57032L163.71875,230.99121a4.00009,4.00009,0,0,1-7.46484.62695l-40.55371-85.6123-.01709-.03272L158.94629,102.71A3.99957,3.99957,0,0,0,153.29,97.05371l-43.26318,43.26294c-.01124-.00537-.022-.01245-.0337-.01782L24.38184,99.74609a4.00009,4.00009,0,0,1,.627-7.46484L211.33691,39.72754a4.15049,4.15049,0,0,1,1.13379-.16211A3.98288,3.98288,0,0,1,215.251,40.749,3.92519,3.92519,0,0,1,216.27246,44.66309Z"/>
                                                  </svg>
                                            &nbsp;{elem.consultant_data.visits}访问量
                                          </div>
                                        </Row>
                                        <Row style={{marginBottom: '12px'}}>订单号：{elem.ticket_id}</Row>
                                        <Row style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap', marginBottom: '12px'}}>
                                        退款理由：{elem.refund_request.reason}</Row>
                                        <Row>提交时间：{submitTime}</Row>
                                      </Col>
                                      <Col md={4} className='flex-block5'>
                                        <div style={{marginBottom: '8px', fontSize: '22px', textAlign: 'right'}}>{formatted_date}</div>
                                        <div style={{marginBottom: '26px', fontSize: '22px', textAlign: 'right'}}>{time_duration} {elem.meta.timezone || ''}</div>
                                        <div className='flex-block' style={{justifyContent: 'right'}}>
                                          <input className="events-btn-2" onClick={() =>this.approveRefund(elem.ticket_id)} style={{height: '36px', marginRight: '8px'}} type="button" value="同意退款" />
                                          <input className="events-btn-2" onClick={() =>this.rejectRefund(elem.ticket_id)} style={{height: '36px', marginRight: '8px'}} type="button" value="拒绝退款" />
                                        </div>
                                      </Col>
                                    </Col>
                                  </Row></React.Fragment>
                          })
                        }
                <div style={{height: '50px'}}></div>
              </Container>)
    }
    else if (this.state.currentCategory === 'allTickets') {
      return (<Container>
        <Row style={{fontSize: '20px', textAlign: 'center', marginBottom: '20px'}}>订单管理</Row>
        <Row style={{display:"flex"}}>
          {[0,4,2,3].map((code)=>{
            return <span style={{flexGrow: 1,textAlign:"center"}}>
              <input type="checkbox"  style={{position:"initial"}} checked={this.state.ticketState.includes(code)}
                          onChange={(e)=>{
                            let oldstate = this.state.ticketState
                            if(e.target.checked){
                              oldstate.push(code)
                              this.setState({ticketState: oldstate},this.getAllTickets)
                            }else {
                              this.setState({ticketState: oldstate.filter(ele=>ele!= code)}, this.getAllTickets)
                            }
                          }
                          }/>
                      <span>{ticketStatus[code]}</span>
                          </span>
                          }
          )

          }
          <input style={{flexGrow: 1}} type="text" placeholder={"search name or id"} value={this.state.consultant} onChange={(e)=>this.setState(
              {consultant:e.target.value}
          )}/>
        </Row>
        {this.state.isRefundLoading ? (
                <Row style={{paddingLeft: '45%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'}
                                                                width={'15%'}/></Row>) :
            this.state.allTickets.filter(this.searchFilter).map(elem => {
              let start_date = new Date(elem.start_time)
              let end_date = new Date(start_date.getTime() + elem.length * 1000 * 60 * 30)
              let formatted_date = start_date.getFullYear() + '-' + (start_date.getMonth() + 1) + '-' + start_date.getDate()
              let time_duration = start_date.getHours() + ':' + (start_date.getMinutes() === 0 ?
                  '00' : start_date.getMinutes()) + ' - ' + end_date.getHours() + ':' + (end_date.getMinutes() === 0 ? '00' : start_date.getMinutes())
              let submitTime = elem.refund_request? new Date(elem.refund_request.create_date) : ''
              submitTime = submitTime?submitTime.getFullYear() + '-' + (submitTime.getMonth() + 1) + '-' + submitTime.getDate() + ' ' + submitTime.getHours() + ':' + (submitTime.getMinutes() === 0 ?
                  '00' : submitTime.getMinutes()): submitTime
              return <React.Fragment>
                <Row style={{marginTop: '16px', height: '189px', borderBottom: '2px solid #E7E9EE'}}>
                  <Col md={11} className='flex-block'>
                    <Col md={3} style={{marginRight: '24px'}}>
                      <img src={elem.consultant_data.photo_url === '' ? avatar : elem.consultant_data.photo_url}
                           alt='cover_img' style={{width: '135px', height: '135px', borderRadius: '135px'}}></img>
                    </Col>
                    <Col md={5}>
                      <Row style={{fontSize: '24px', fontWeight: '400', marginBottom: '8px'}}>
                        <Col md={4} style={{paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                          咨询师：{elem.consultant_data.first_name} {elem.consultant_data.last_name}
                        </Col>
                        <Col md={4} style={{paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                          申请人：{elem.user_data.nickname === '' ? elem.user_data.first_name + ' ' + elem.user_data.last_name : elem.user_data.nickname}
                        </Col>
                        <Col md={4} style={{paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                          价格：{elem.currency.toLowerCase() == 'usd' ? '$' : '¥'} {elem.unit_price * elem.length}
                        </Col>
                      </Row>
                      <Row style={{marginBottom: 8}} className='flex-block'>
                        <div style={{
                          padding: '0',
                          maxWidth: 100,
                          marginRight: 20,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'wrap'
                        }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19.5" fill="#43B0B1"
                               class="bi bi-geo-alt" viewBox="0 0 16 16"
                               style={{position: 'relative', top: '3px', left: '0'}}>
                            <path
                                d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                          </svg>
                          &nbsp;
                          {elem.method === 3 ? elem.meta?.city && elem.meta?.state ?
                              (elem.meta?.city + ', ' + elem.meta?.state) : '' :
                              elem.method === 2 ? '视频' : '语音'
                          }
                        </div>
                        <div style={{padding: '0'}}>
                          <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="19" height="19"
                               fill="#43B0B1" style={{position: 'relative', top: '3px', left: '0'}}>
                            <path
                                d="M220.90723,35.09277A11.85078,11.85078,0,0,0,209.165,32.02832L22.83691,84.582a12.00013,12.00013,0,0,0-1.8789,22.39453l85.61035,40.55176a4.01536,4.01536,0,0,1,1.90234,1.90234L149.02344,235.042a11.89209,11.89209,0,0,0,10.81152,6.86524q.51563,0,1.03711-.04395a11.90291,11.90291,0,0,0,10.5459-8.70019L223.97168,46.835A11.853,11.853,0,0,0,220.90723,35.09277Zm-4.63477,9.57032L163.71875,230.99121a4.00009,4.00009,0,0,1-7.46484.62695l-40.55371-85.6123-.01709-.03272L158.94629,102.71A3.99957,3.99957,0,0,0,153.29,97.05371l-43.26318,43.26294c-.01124-.00537-.022-.01245-.0337-.01782L24.38184,99.74609a4.00009,4.00009,0,0,1,.627-7.46484L211.33691,39.72754a4.15049,4.15049,0,0,1,1.13379-.16211A3.98288,3.98288,0,0,1,215.251,40.749,3.92519,3.92519,0,0,1,216.27246,44.66309Z"/>
                          </svg>
                          &nbsp;{elem.consultant_data.visits}访问量
                        </div>
                      </Row>
                      <Row style={{marginBottom: '12px'}}>订单号：{elem.ticket_id} 状态 {ticketStatus[elem.status]}  </Row>
                      {elem.refund_request ?<React.Fragment><Row style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'wrap',
                        marginBottom: '12px'
                      }}>
                        退款理由：{elem.refund_request?.reason} 退款状态 {['已提交','已通过','已拒绝'][elem.refund_request.status]}</Row>
                      <Row>提交时间：{submitTime}</Row></React.Fragment>:""}
                    </Col>
                    <Col md={4} className='flex-block5'>
                      <div style={{marginBottom: '8px', fontSize: '22px', textAlign: 'right'}}>{formatted_date}</div>
                      <div style={{
                        marginBottom: '26px',
                        fontSize: '22px',
                        textAlign: 'right'
                      }}>{time_duration} {elem.meta?.timezone || 'UTC'}</div>
                      <div className='flex-block' style={{justifyContent: 'right'}}>
                        {this.getControls(elem)}
                      </div>
                    </Col>
                  </Col>
                </Row></React.Fragment>
            })
        }
        <div style={{height: '50px'}}></div>
      </Container>)
    }
    else if (this.state.currentCategory === 'withdraw') {
      return (<Container>
        <Row style={{fontSize: '20px', textAlign: 'center', marginBottom: '20px'}}>提现管理</Row>
        {this.state.isWithDrawLoading ? (
                <Row style={{paddingLeft: '45%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'}
                                                                width={'15%'}/></Row>) :
            this.state.withDrawList.map(elem => {
              return <React.Fragment>
                <Row style={{marginTop: '16px', height: '189px', borderBottom: '2px solid #E7E9EE'}}>
                  <Col md={11} className='flex-block'>
                    <Col md={3} style={{marginRight: '24px'}}>
                      <img src={elem.user_data.photo_url === '' ? avatar : elem.user_data.photo_url}
                           alt='cover_img' style={{width: '135px', height: '135px', borderRadius: '135px'}}></img>
                    </Col>
                    <Col md={5}>
                      <Row style={{fontSize: '24px', fontWeight: '400', marginBottom: '8px'}}>
                        <Col md={6} style={{paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                          咨询师：{elem.user_data.first_name} {elem.user_data.last_name}
                        </Col>
                        <Col md={6} style={{paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                          审核人：{elem.payment_operator_data?elem.payment_operator_data.first_name + ' ' + elem.payment_operator_data.last_name:"" }
                        </Col>
                      </Row>
                      <Row style={{marginBottom: 8}} className='flex-block'>

                        {/*<div style={{padding: '0'}}>*/}
                        {/*  <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="19" height="19"*/}
                        {/*       fill="#43B0B1" style={{position: 'relative', top: '3px', left: '0'}}>*/}
                        {/*    <path*/}
                        {/*        d="M220.90723,35.09277A11.85078,11.85078,0,0,0,209.165,32.02832L22.83691,84.582a12.00013,12.00013,0,0,0-1.8789,22.39453l85.61035,40.55176a4.01536,4.01536,0,0,1,1.90234,1.90234L149.02344,235.042a11.89209,11.89209,0,0,0,10.81152,6.86524q.51563,0,1.03711-.04395a11.90291,11.90291,0,0,0,10.5459-8.70019L223.97168,46.835A11.853,11.853,0,0,0,220.90723,35.09277Zm-4.63477,9.57032L163.71875,230.99121a4.00009,4.00009,0,0,1-7.46484.62695l-40.55371-85.6123-.01709-.03272L158.94629,102.71A3.99957,3.99957,0,0,0,153.29,97.05371l-43.26318,43.26294c-.01124-.00537-.022-.01245-.0337-.01782L24.38184,99.74609a4.00009,4.00009,0,0,1,.627-7.46484L211.33691,39.72754a4.15049,4.15049,0,0,1,1.13379-.16211A3.98288,3.98288,0,0,1,215.251,40.749,3.92519,3.92519,0,0,1,216.27246,44.66309Z"/>*/}
                        {/*  </svg>*/}
                        {/*  &nbsp;{elem.user.visits}访问量*/}
                        {/*</div>*/}
                      </Row>
                      <Row style={{marginBottom: '12px'}}>请求号：{elem.request_id} 用户ID：{elem.user_data.id} {elem.image?<a target="_blank" href={elem.image} rel="noreferrer">凭证</a>:""}</Row>

                      <Row style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'wrap',
                        marginBottom: '12px'
                      }}>
                        提现：{elem.amount/100 + elem.currency}</Row>
                      <Row>提交时间：{utils.toPST(elem.create_date)}</Row>
                      <Row>更新时间：{utils.toPST(elem.last_update)}</Row>
                    </Col>
                    <Col md={4} className='flex-block5'>
                      <div style={{marginBottom: '8px', fontSize: '22px', textAlign: 'right'}}>{withDrawStatus[elem.status]}</div>
                      {/*<div style={{*/}
                      {/*  marginBottom: '26px',*/}
                      {/*  fontSize: '22px',*/}
                      {/*  textAlign: 'right'*/}
                      {/*}}>{time_duration} {elem.meta.timezone || ''}</div>*/}
                      <div className='flex-block' style={{justifyContent: 'right'}}>
                        <input disabled={elem.status ==1} className="events-btn-2" onClick={() => this.setState({showModal:true,activeWidthdraw:elem.request_id})}
                               style={{height: '36px', marginRight: '8px'}} type="button" value="确认提现"/>
                        <input disabled={elem.status != 0} className="events-btn-2" onClick={() => this.rejectWithdraw(elem.request_id)}
                               style={{height: '36px', marginRight: '8px'}} type="button" value="拒绝提现"/>
                      </div>
                    </Col>
                  </Col>
                </Row></React.Fragment>
            })
        }
        <div style={{height: '50px'}}></div>
      </Container>)
    }
    else if(this.state.currentCategory === 'bookingNote'){
      return (<div style={{textAlign: 'center'}}><AboutUsEdit src_id="booking_note"/></div>)
    }else if(this.state.currentCategory === 'register'){
      return (<div style={{textAlign: 'center'}}><AboutUsEdit src_id="register"/></div>)
    }else{
      return (<div></div>)
    }
  }

  render() {
    return (
      <div style={{minHeight: '400px', width:"100%" , marginTop:"20px"}}>
          <Row className='my_title'>
            <Col md={2}></Col>
            <Col md={4}><h2>管理员主页</h2></Col>
          </Row>
        <Modal show={this.state.showModal} onHide={this.handleModalClose} animation={false}
               style={{backgroundColor: '#d4d4d4', zIndex: '999999'}}>
          <Modal.Header closeButton>
            <Modal.Title>上传凭证</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ImageUpload onChange={url=>this.setState({coverUrl:url})}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>this.approveWithdraw(this.state.activeWidthdraw)}>
              通过提现
            </Button>
            <Button variant="secondary" onClick={this.handleModalClose}>
              关闭
            </Button>
          </Modal.Footer>
        </Modal>
          <Row style={{textAlign: 'center', margin: '10px 0'}}>
            {/*<Col md={4}></Col>*/}
            {/* category */}
            <Col md={12} style={{textAlign: 'center',display: "flex",
              justifyContent: "center"}}><ul className="nav navbar-nav" style={{textAlign: 'center'}}>
              <li><a href='javascript:;' onClick={()=>this.handleCategory('tagManage')}>标签管理</a></li>
              <li><a href='javascript:;' onClick={()=>this.handleCategory('reportedComment')}>举报评论管理</a></li>
              <li><a href='javascript:;' onClick={()=>this.handleCategory('bookingNote')}>预约须知编辑</a></li>
              <li><a href='javascript:;' onClick={()=>this.handleCategory('inviteCode')}>创建邀请码</a></li>
              <li><a href='javascript:;' onClick={()=>this.handleCategory('register')}>注册页编辑</a></li>
              <li><a href='javascript:;' onClick={()=>this.handleCategory('refund')}>退款申请管理</a></li>
              <li><a href='javascript:;' onClick={() => this.handleCategory('allTickets')}>所有订单管理</a></li>
              <li><a href='javascript:;' onClick={() => this.handleCategory('withdraw')}>提现管理</a></li>
            </ul></Col>


          </Row>
          {
            this.renderCategory()
          }
      </div>
    )
  }
}
