import React, { Component } from 'react'
import { Row, Col, Container, Modal, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ReactLoading from 'react-loading';
import utils from './utils'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import map_img from '../imgs/blur_map.png'
import AboutUsEdit from "./AboutUsEdit";
import cover from '../imgs/home_pic4.png'
import { GoogleMapFrame, GoogleMapAutoComplete } from "./GoogleMap";
import RichEditBox from './RichEditBox';
import LogInContent from './login';
import Events from './events';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { faMapMarkerAlt, faPaperPlane, faSquare } from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga'
import indexPage from '../imgs/index_page.jpg'

import '../css/event.css';

export default class EventDetail extends Component {

    state = {
        tagList: [],
        subTagList: [],
        currentTagType: '',
        currentSubTags: [],
        event_id: '',
        event_name: '',
        calendars: [],
        city: '',
        state: '',
        country: 'US',
        zip_code: '',
        street: '',
        lat: '',
        lng: '',
        link: '',
        isOnline: false,
        liked: false,
        attends: 0,
        tags: [],
        display_time: '',
        slots: 0,
        calendar_id: '',
        isAttend: false,
        isLoading: false,
        isMoreLoading: false,
        moreEvents: [],
        likedList: [],
        summary: '',
        summary_edit: '',
        isCreator: false,
        loginModal: false,
        creator: '',
        creator_name: '',
        profile_status: 0,
        timezoneList: utils.timezoneList,
    }

    componentDidMount = async () => {
        this.props.cancelGap();
        this.props.setDisplayURL();
        await utils.loadTagList();
        this.setState({ tagList: window.tagList?.eventList || [], subTagList: window.tagList?.eventMethodList || [], event_id: this.props.match.params.event_id.slice(1) })
        this.getEventInfo()
        this.getAlreadyAttend()
        this.getAlreadyLiked()
        this.getMoreEvents()
        if (localStorage.getItem('user_id') && localStorage.getItem('user_id') === this.state.creator) {
            this.setState({ isCreator: true })
        }
    }
    componentWillUnmount = () => {
        this.props.addGap()
    }
    componentDidUpdate = async (prevProps) => {
        if (this.props.match.params.event_id.slice(1) !== prevProps.match.params.event_id.slice(1)) {
            await this.setState({ event_id: this.props.match.params.event_id.slice(1) })
            this.getEventInfo()
            this.getAlreadyAttend()
            this.getAlreadyLiked()
            this.getMoreEvents()
        }
    }

    openLogIn = () => {
        this.setState({ loginModal: true });
    }

    closeLogIn = () => {
        this.setState({ loginModal: false });
    }

    getEventInfo = async () => {
        this.setState({ isLoading: true })
        await axios.get(utils.getUrl(`event/${this.state.event_id}/update/`))
            .then(response => {

                this.setState({
                    event_name: response.data.name, calendars: response.data.calendars, profile_status: response.data.status,
                    tags: response.data.tags, host: response.data.host, summary: response.data.summary, creator: response.data.creator
                })
                if (response.data.calendars.length > 0) {
                    let elem = response.data.calendars[0]
                    let start_time = new Date(elem.start_time)
                    let start_date = (parseInt(start_time.getMonth()) + 1).toString() + '/' + start_time.getDate() + '/' + start_time.getFullYear()
                    let end_time = new Date(elem.end_time)
                    let end_date = (parseInt(end_time.getMonth()) + 1).toString() + '/' + end_time.getDate() + '/' + end_time.getFullYear()
                    start_time = start_time.getHours() + ':' + (start_time.getMinutes() === 0 ? '00' : start_time.getMinutes())
                    end_time = end_time.getHours() + ':' + (end_time.getMinutes() === 0 ? '00' : end_time.getMinutes())
                    let timezone = elem.timezone
                    this.state.timezoneList.map(tz => {
                        if (tz.id === timezone) {
                            timezone = tz.value_cn
                        }
                    })
                    let display_time = start_time + ', ' + start_date + ' ~ ' + end_time + ', ' + end_date + ' ' + timezone
                    if (elem.online) {
                        this.setState({ attends: elem.attends, slots: elem.slots, display_time: display_time, calendar_id: elem.calendar_id, link: elem.url, isOnline: true })
                    } else {
                        this.setState({
                            attends: elem.attends, city: elem.city, state: elem.region, country: elem.country, slots: elem.slots, isOnline: false,
                            display_time: display_time, lat: elem.latitude, lng: elem.longitude, zip_code: elem.zip_code, street: elem.street, calendar_id: elem.calendar_id
                        })
                    }
                }
            }).catch(err => {
                // alert(err)
            })
        this.getUserName()
        let tempTag = ''
        let tempList = []
        this.state.tagList.map(tag => {
            if (this.state.tags.includes(tag.tag_id)) {
                let cate = (('' + tag.tag_id).split(''))[0]
                if (cate === '8') {
                    tempTag = tag.value_cn
                }
            }
        })
        this.state.subTagList.map(tag => {
            if (this.state.tags.includes(tag.tag_id)) {
                let cate = (('' + tag.tag_id).split(''))[0]
                if (cate === '9') {
                    tempList.push(tag.value_cn)
                }
            }
        })
        this.setState({ isLoading: false, currentTagType: tempTag, currentSubTags: tempList })
    }

    getMoreEvents = async () => {
        this.setState({ isMoreLoading: true })
        await axios.post(utils.getUrl(`event/query/`), { pk: parseInt(this.state.event_id), order: 'relevance', start: 0, end: 4 })
            .then(response => {

                let temp_events = response.data.data.data
                // transform to 2d array
                const rows = temp_events.reduce(function (rows, key, index) {
                    return (index % 2 == 0 ? rows.push([key])
                        : rows[rows.length - 1].push(key)) && rows;
                }, [])
                this.setState({ moreEvents: rows })
            }).catch(err => {
                // alert(err)
            })
        this.setState({ isMoreLoading: false })
    }

    handleLike = async (event_id) => {
        if (!localStorage.getItem('loginToken')) {
            alert('请先登录')
            return
        }
        await axios.post(utils.getUrl(`event/${this.state.event_id}/like/`),
            { 'like': true }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                this.setState({ liked: true })
                alert('收藏成功')
            }).catch(err => {
                // alert(err)
            })
    }

    cancelLike = async (event_id) => {
        if (!localStorage.getItem('loginToken')) {
            alert('请先登录')
            return
        }
        if (!window.confirm('你确定要取消收藏吗？')) {
            return
        }
        await axios.post(utils.getUrl(`event/${this.state.event_id}/like/`),
            { 'like': false }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                this.setState({ liked: false })
                alert('取消收藏成功')
            }).catch(err => {
                // alert(err)
            })
    }

    handleMoreLike = async (event_id) => {
        if (!localStorage.getItem('loginToken')) {
            alert('请先登录')
            return
        }
        await axios.post(utils.getUrl(`event/${event_id}/like/`),
            { 'like': true }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                let tempList = this.state.likedList
                tempList.push(event_id)
                this.setState({ likedList: tempList })
                alert('收藏成功')
            }).catch(err => {
                // alert(err)
            })
    }

    cancelMoreLike = async (event_id) => {
        if (!localStorage.getItem('loginToken')) {
            alert('请先登录')
            return
        }
        if (!window.confirm('你确定要取消收藏吗？')) {
            return
        }
        await axios.post(utils.getUrl(`event/${event_id}/like/`),
            { 'like': false }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                let tempList = this.state.likedList
                tempList.splice(tempList.indexOf(event_id), 1)
                this.setState({ likedList: tempList })
                alert('取消收藏成功')
            }).catch(err => {
                // alert(err)
            })
    }

    getAlreadyLiked = async () => {
        if (!localStorage.getItem('loginToken')) { return }
        await axios.post(utils.getUrl(`event/query/`), { liked_by: parseInt(localStorage.getItem('user_id')) },
            { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                let dataList = response.data.data.data
                let tempList = []
                dataList.map(elem => { tempList.push(elem.event_id) })
                if (tempList.includes(this.state.event_id)) {
                    this.setState({ liked: true })
                } else { this.setState({ liked: false }) }
            }).catch(err => {
                // alert(err)
            })
    }

    getAlreadyAttend = async () => {
        if (!localStorage.getItem('loginToken')) { return }
        await axios.post(utils.getUrl(`event/query/`), { attend_by: parseInt(localStorage.getItem('user_id')) },
            { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                let dataList = response.data.data.data
                dataList.map(elem => {
                    if (elem.event_id === parseInt(this.state.event_id)) {
                        if (elem.attending.includes(this.state.calendar_id)) {
                            this.setState({ isAttend: true })
                        } else { this.setState({ isAttend: false }) }
                    }
                })
            }).catch(err => {
                // alert(err)
            })
    }

    handleCalendarSelect = (event) => {
        let calendar_id = parseInt(event.target.value)
        this.state.calendars.map(elem => {
            if (elem.calendar_id === calendar_id) {
                let start_time = new Date(elem.start_time)
                let start_date = (parseInt(start_time.getMonth()) + 1).toString() + '/' + start_time.getDate() + '/' + start_time.getFullYear()
                let end_time = new Date(elem.end_time)
                let end_date = (parseInt(end_time.getMonth()) + 1).toString() + '/' + end_time.getDate() + '/' + end_time.getFullYear()
                start_time = start_time.getHours() + ':' + (start_time.getMinutes() === 0 ? '00' : start_time.getMinutes())
                end_time = end_time.getHours() + ':' + (end_time.getMinutes() === 0 ? '00' : end_time.getMinutes())
                let timezone = elem.timezone
                this.state.timezoneList.map(tz => {
                    if (tz.id === timezone) {
                        timezone = tz.value_cn
                    }
                })
                let display_time = start_time + ', ' + start_date + ' ~ ' + end_time + ', ' + end_date + ' ' + timezone
                if (elem.online) {
                    this.setState({ attends: elem.attends, slots: elem.slots, display_time: display_time, calendar_id: elem.calendar_id, link: elem.url, isOnline: true })
                } else {
                    this.setState({
                        attends: elem.attends, city: elem.city, state: elem.region, country: elem.country, slots: elem.slots, isOnline: false,
                        display_time: display_time, lat: elem.latitude, lng: elem.longitude, zip_code: elem.zip_code, street: elem.street, calendar_id: elem.calendar_id
                    })
                }
            }
        })
    }

    handleAttend = async () => {
        if (this.state.attends >= this.state.slots) {
            alert('当前人数已满')
            return
        }
        await axios.post(utils.getUrl(`event/${this.state.event_id}/calendar/${this.state.calendar_id}/attend/`),
            { 'attend': true }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                this.setState({ isAttend: true })
                alert('报名成功')
            }).catch(err => {
                // alert(err)
            })
    }

    cancelAttend = async () => {
        if (!window.confirm('你确定要取消报名吗？')) {
            return
        }
        await axios.post(`event/${this.state.event_id}/calendar/${this.state.calendar_id}/attend/`,
            { 'attend': false }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                this.setState({ isAttend: false })
                alert('取消报名成功')
            }).catch(err => {
                // alert(err)
            })
    }

    forwardAttend = (event) => {
        let event_id = event.metadata.event_id
        const url = utils.getUrl(`/event/detail/:${event_id}`)
        this.props.history.push({ pathname: url })
        this.setState({})
    }

    loginAlert = () => {
        alert('您必须先登录！')
    }

    editSummary = async () => {
        if (this.state.summary_edit === '') {
            alert('当前内容为空！')
            return
        }
        let content = this.state.summary_edit
        await axios.post(utils.getUrl(`event/${this.state.event_id}/update/`), { summary: content },
            { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                this.setState({ summary: content })
                alert('编辑成功！')
            })
            .catch(err => {
                // alert(err)
            })
    }

    getUserName = async () => {
        await axios.get(utils.getUrl(`profile/${this.state.creator}/`))
            .then(response => {
                let data = response.data
                this.setState({ creator_name: data.nickname === '' ? data.first_name + ' ' + data.last_name : data.nickname })
            })
            .catch(err => {
                // alert(err)
            })
    }

    editStatus = (status) => {
        axios.post(utils.getUrl(`event/${this.state.event_id}/approve/`),
            { status: status }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                this.setState({ profile_status: status })
                alert('更改成功！')
            }).catch(err => {
                // alert(err)
            })
    }

    render() {
        return (
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink style={{ color: '#43B0B1', textDecoration: 'none', fontWeight: 400 }} to="/events/">找我团</NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">活动详情</li>
                    </ol>
                </nav>
                <div className="row" style={{ backgroundColor: 'white', height: 250, overflow: 'hidden' }}>
                    <img src={indexPage} alt="event background" style={{ width: '100%', minHeight: 250 }}></img>
                </div>
                <div className="row py-5">
                    <div className="col-md-1"></div>
                    <div className="col-6 col-md-5 px-5 event-desc">
                        <div className="row">
                            <ul>
                                <li>
                                    <div className="bullet">
                                        <FontAwesomeIcon icon={faSquare} color="#43B0B1" />
                                    </div>
                                    活动详情
                                    <div className="bullet-between">
                                        咨询师面对面解决你的烦恼, 咨询师面对面解决你的烦恼, 咨询师面对面解决你的烦恼, 咨询师面对面解决你的烦恼, 咨询师面对面解决你的烦恼, 咨询师面对面解决你的烦恼
                                    </div>
                                </li>
                                <li>
                                    <div className="bullet">
                                        <FontAwesomeIcon icon={faSquare} color="#43B0B1" />
                                    </div>
                                    报名方式
                                    <div className="bullet-between">
                                        登陆后查看报名方式
                                    </div>
                                </li>

                                <li>
                                    <div className="bullet">
                                        <FontAwesomeIcon icon={faSquare} color="#43B0B1" />
                                    </div>
                                    活动属性
                                    <div className="bullet-between">
                                        <div className="attribute-btn">属性1</div>
                                        <div className="attribute-btn">属性2</div>
                                        <div className="attribute-btn">属性3</div>
                                        <div className="attribute-btn">属性4</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="bullet">
                                        <FontAwesomeIcon icon={faSquare} color="#43B0B1" />
                                    </div>
                                    发起人
                                    <div className="bullet-between">
                                        <span> Nola Ro is Guyanese-born Mompreneur, Vegan Culinary artist, Podcaster, T-shirt/Merch designer, Restaurant manager and Mentor! </span>
                                        <span> When she's not busy waring her many business hats, she's spending quality time with friends and family cooking, coordinating game nights, or traveling.</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="row justify-content-start py-2 ps-3 ">
                            <div className="col-6 col-md-3">
                                <button className="square-btn" style={{ textAlign: 'left' }}>
                                    收藏<FontAwesomeIcon icon={faHeart} className="ms-3" />
                                </button>
                            </div>
                            <div className="col-6 col-md-3">
                                <button className="square-btn-solid">报名</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-5 px-5">
                        <div class="card" style={{ top: '-30%' }}>
                            <div class="card-body">
                                <h5 className="card-title px-2 pt-2">FindSelf 周末咨询师见面会</h5>
                                <div className="row px-2 py-3" style={{ fontWeight: 300, fontSize: 14 }}>
                                    <div className="col-4 ps-0">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="ms-3" color="#43B0B1" /> Boston
                                    </div>
                                    <div className="col-4 ps-0">
                                        <FontAwesomeIcon icon={faPaperPlane} className="ms-3" color="#43B0B1" /> 365人报名
                                    </div>
                                    <div className="col-4 ps-0">
                                        <FontAwesomeIcon icon={faHeart} className="ms-3" color="#43B0B1" /> 收藏
                                    </div>
                                </div>
                                <div className="row px-3 py-2" style={{ fontWeight: 200, fontSize: 14 }}>
                                    <div className="col-2">形式: </div>
                                    <div className="col-10 ps-0">线上活动</div>
                                </div>
                                <div className="row px-3 py-2" style={{ fontWeight: 200, fontSize: 14 }}>
                                    <div className="col-2">时间: </div>
                                    <div className="col-10 ps-0">2021年3月15日 下午3点</div>
                                </div>
                                <div className="row px-3 py-2" style={{ fontWeight: 200, fontSize: 14 }}>
                                    <div className="col-2">发起: </div>
                                    <div className="col-10 ps-0">用户啦啦啦</div>
                                </div>
                                <div className="row px-3 py-3">
                                    <a href="#" className="btn btn-primary btn-block">登陆后查看报名方式</a>
                                </div>
                                <div className="row px-3 py-2">
                                    <NavLink style={{ fontSize: 15, textDecoration: 'none', color: '#ffa500' }} to="/signup/webuser">还没有账号? 立即注册</NavLink>
                                </div>
                                <div className="row px-2 pt-3">
                                    <img src={map_img} alt='map_img' style={{ width: '100%', height: '200px' }}></img>
                                    <div style={{ fontSize: '22px', position: 'relative', top: '-120px', textAlign: 'center'}}>
                                        <a onClick={this.openLogIn} className='profile-link' style={{ fontSize: '22px', padding: 0, margin: 0, border: 0, color: '#43B0B1' }}>登录</a>后查看咨询师地址
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <Events/>
            </div>
        )
    }
}
