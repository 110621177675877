import React, {Component} from 'react'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import axios from 'axios'
import {Alert} from "react-bootstrap";
import ReactLoading from 'react-loading';
import utils from './utils'
import _ from './locale'


const getStatusText = (status) => {
    switch (status) {
        case 0:
            return <Alert variant="info" show={true}>This page is a draft</Alert>;
        case 1:
            return <Alert variant="warning" show={true}>This page is waiting for for approval</Alert>;
        case 2:
            return <Alert variant="warning" show={true}>This page was deleted or rejected, feel free to
                resubmit.</Alert>;

        case 10:
            return <Alert variant="danger" show={true}>This page has been published, your edit will be visible to
                public</Alert>;
    }
}
const editorOption = {
    plugins: [
        'align',
        'image',
        'font',
        'link',

    ],
    defaultStyle: "font-size: 18px;",
    buttonList: [
        ['undo', 'redo'],
        // ['font', 'fontSize', 'formatBlock'],
        ['fontSize'],
        // ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        // ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        // ['align', 'horizontalRule', 'list', 'lineHeight'],
        // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
        ['link', 'image','table'],
        // ['imageGallery'], // You must add the "imageGalleryUrl".
        ['fullScreen', 'showBlocks', 'codeView'],
        //['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        // ['save', 'template'],
        // '/', Line break
    ],
    resizingBar: false,
    width: "100%",
    minHeight: "300px",
    height: "auto",
    imageUploadSizeLimit: "5000000",
    imageMultipleFile: false,
    imageAccept: ".png,.jpg",


}

class QandABox extends Component {

    state = {
        src_id: null,
        status: 0,
        loaded: false,
        category:[0],
        tags:[],
        lang: "en_us",
        tagsList:[]
    }
    getCheckBox = (e) => {
        return <React.Fragment>
            <span> {e.text }</span>
            <input type="checkbox" style={{position: "initial"}} checked={this.state.tags?.includes(e.tag_id)}
            onChange={(event)=>{
                if (this.state.tags.includes(e.tag_id)){
                    this.setState({tags:(this.state.tags||[]).filter((tag)=>tag!==e.tag_id)})
                } else {
                    this.setState({tags:this.state.tags.concat([e.tag_id])})

                }
            }
            }
            /></React.Fragment>
    }

    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.title = React.createRef();
        this.language = React.createRef();
        this.category = React.createRef();
    }

    componentDidMount() {
        window.debug = this;
        window.richContent = this.editor.current;
        this.setup();
        this.loadTagList()
    }

    translate = (arr) => {
        return utils.translate(arr, this.state.tagsList).map(
            (ele) => ({id: ele.tag_id, name: ele.value_cn})
        )
    }


    setup() {
        const title = this.title.current;
        const editor = this.editor.current;
        const lang = this.language.current
        if (this.props.action == "create") {
            this.setState({loaded: true})
        } else if (this.props.action == "update") {
            axios.get(utils.getUrl(`cms/blog/${this.props.src_id}/update/`),
                {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(
                response => {
                    title.value = response.data.subject;
                    editor.editor?.setContents(response.data.content)
                    this.setState({
                        status: response.data.status,
                        tags: response.data.tags,
                        description: response.data.description || '',
                        loaded: true,
                        category: response.data.categories,
                        lang: response.data.lang || '',
                    })
                    lang.value = response.data.lang;
                    this.setCategory(response.data.categories)
                    // this.setCritical(response.data.tags)
                }
            ).catch(err => {
                // alert(err)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.action != this.props.action) {
            this.setup()
        }

    }
    setCategory = (cate)=>{
        if (cate){
            this.category.current.value = cate[0]
        }
    }
    getCritical = ()=> {
        return this.state.tags || []
    }
    setCritical = (tags)=>{

    }
    getContents = () => this.editor.current.editor.getContents();
    handleSave = () => {
        axios.post(utils.getUrl(`cms/blog/${this.props.src_id}/update/`),
            {
                "subject": this.title.current.value,
                "content": this.getContents(),
                "type": 2,
                "categories": this.getCategoryIds(),
                "tags": this.getCritical(),
                "lang": this.state.lang,
            },
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
            alert("成功");
            this.props.onSave();
        }).catch(err => {
            // alert(err)
        })

    }
    getCategoryIds = ()=>{
        return [parseInt(this.category.current.value || 0),]
    }
    handleCreate = () => {

        axios.post(utils.getUrl('cms/blog/create/'),
            {
                "subject": this.title.current.value,
                "content": this.getContents(),
                "type": 2,
                "tags": this.getCritical(),
                "categories": this.getCategoryIds(),
                "lang": this.state.lang,
            },
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
            alert("创建成功！");
            // 
            this.props.onCreate();
            // this.setState({action:"update",src_id:response.data.post_id})
        }).catch(err => {
            // alert(err)
        })

    }
    handleDelete = () => {
        axios.delete(utils.getUrl(`cms/blog/${this.props.src_id}/update/`),
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
            alert("删除成功！")
            this.props.onDelete();
        }).catch(err => {
            // alert(err)
        })
    }

    handleUpload = (files, info, uploadHandler) => {
        // 

        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append("ftype", files[0].name.split('.').reverse()[0]);
        axios.post(utils.getUrl('public/blog/imageUpload/'),
            formData,
            {
                headers: {
                    'Authorization': 'JWT ' + localStorage.getItem('loginToken'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(
            response => {
                uploadHandler(response.data);
            }
        ).catch(err => {
            // alert(err);
            uploadHandler({
                errorMessage: "err",
                result: []
            })

        })
        return undefined;

    }
    handleUploadError = (errorMessage) => {
        alert(errorMessage)
        return true
    }
    loadTagList = async () => {
        await utils.loadTagList();
        await this.setState({
            tagsList:window.tagList?.qAndAList
        })
        if (this.props.action == "create"){
            this.setState([window.tagList?.qAndAList[0]])
        } else {
            this.setCategory(this.state.category)
        }

    }

    handleSelect = (e) => {
        this.setState(
            {
                lang: e.target.value
            }
        )
    }
    handleSelectCategory = (e) => {
        // this.setState(
        //     {
        //         category: [e.target.value,]
        //     }
        // )
    }

    render() {
        const propAction = this.props.action
        // 
        return (
            <div className="question-box-container">

                <div style={{display: this.state.loaded ? "initial" : "none"}}>
                    <div>
                        {
                            getStatusText(this.state.status)
                        }
                    </div>
                    <input type="text" ref={this.title} placeholder="Enter Question Here" className="blog-title"/>
                    <div className="blog-set-language">
                            <span>
                                语言
                            </span>
                        <select onChange={this.handleSelect} ref={this.language}>
                            <option value="en_us">English</option>
                            <option value="cn">中文</option>
                        </select>

                        <span>
                                分类
                            </span>
                           <select onChange={this.handleSelectCategory} ref={this.category}>
                            {this.state.tagsList.map((ele)=> <option value={ele.tag_id}>{ele.value_cn}</option>)}
                        </select>
                        {utils.questionPages.map((e)=>this.getCheckBox(e))}
                    </div>


                    <div className="blog-area">
                        <SunEditor className="blog-area"
                                   ref={this.editor}
                                   setOptions={editorOption}
                                   onImageUploadBefore={this.handleUpload}
                                   handleUploadError={this.handleUploadError}

                        />
                    </div>
                    <div className="blog-control-row">
                        {propAction == "update" ?
                            <input type="submit" value={_("Save")} className="blog-submit-button"
                                   onClick={this.handleSave}/> : ""
                        }
                        {propAction == "create" ?
                            <input type="submit" value={_("Create" )}className="blog-submit-button"
                                   onClick={this.handleCreate}/> :
                            <input type="submit" value={_("Delete")} className="blog-submit-button"
                                   onClick={this.handleDelete}
                            />
                        }
                        {propAction == "create" ?"":
                        <input type="submit" value={_("Cancel" )}className="blog-submit-button"
                               onClick={this.props.onCancel}
                        />}
                    </div>
                </div>
                {this.state.loaded ?
                    "" : <div className="blog-loading-container"><ReactLoading type='spinningBubbles' color='#5ecfd0'
                                                                               height={'15%'} width={'15%'}/></div>
                }
            </div>
        );
    }
}

QandABox.defaultProps = {
    action: "create",
    src_id: null,
    comment_target: null,
    onSave:()=>{

    },
    onDelete:()=>{

    },
    onCreate:()=>{

    },
    onCancel:()=>{

    }
}
export default QandABox;
