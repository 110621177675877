import { Component } from "react";
import anonymousAvatar from '../../imgs/chatAvatar.png'
import adminAvatar from '../../imgs/website_logo.png'

export default class Message extends Component {

    state = {
        reversed: this.props.reversed,
        message: this.props.message,
        aiResponse: this.props.aiResponse,
        aiResponseId: this.props.aiResponseId,
        populateTextarea: this.props.populateTextarea,
        admin: this.props.admin
    }

    componentWillMount = () => {
    }

    render() {
        return (
            <div className={"d-flex flex-row my-3 " + (this.state.reversed ? "justify-content-end" : "justify-content-start")}>
                <img className={this.state.reversed ? "order-2" : "order-1"}
                    src={this.state.reversed === this.state.admin ? adminAvatar : anonymousAvatar} alt="chat avatar" style={{ height: 20, width: 20 }}></img>
                <div className={this.state.reversed ? "order-1" : "order-2"}
                    style={{ maxWidth: '80%' }}>
                    <div
                        style={{
                            marginLeft: 10, marginRight: 10, padding: '3px 12px', backgroundColor: this.state.reversed ? "lightgreen" : "mintcream", borderRadius: '6px', fontSize: 14,
                            lineHeight: '21px'
                        }}>
                        {this.state.message}
                    </div>
                    {this.state.admin&&!this.state.reversed&&this.state.aiResponse!==''?
                        <div
                            style={{
                                marginLeft: 10, marginRight: 10, marginTop: 3, padding: '3px 12px', backgroundColor: 'rgba(0,0,0,0)', borderRadius: '6px', fontSize: 11, fontWeight: 400,
                                color: '#8A8A8A', lineHeight: '12px', cursor: 'pointer'
                            }}
                            onClick={() => this.state.populateTextarea(this.state.aiResponse, this.state.aiResponseId)}>
                            {this.state.aiResponse}
                        </div>:''
                    }
                </div>
            </div>
        )
    }

}