const dict = [
    ["Approve","通过"],
    ["Submit", "提交"],
    ["Reject", "拒绝"],
    ["Edit", "编辑"],
    ["Reply", "回复"],
    ["Save", "保存"],
    ["Create", "创建"],
    ["Delete", "删除"],
    ["Cancel", "取消"],
    ["Permission Denied", "无权限"],
    ["Invalid User Username", "用户名错误"],
    ["You do not have permission modify this content", "无权限"],
    ["You do not have permission modify this user", "无权限"],
    ["You do not have permission modify this content", "无权限"],
    ["Runtime Error","系统错误"],
    ["Invalid email address: User does not exits","用户不存在"],
    ["Password must not be empty","密码不能为空"],
    ["Invalid Email Address","邮箱格式错误"],
    ["Amount not enough","您的余额不足"],
]

const getText =(text)=>{
    const filtered = dict.filter((ele)=>ele[0]== text);
    return  (filtered.length)? filtered[0][1]:text
}


export default getText;
