import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import {Container, Row, Col, Alert, Modal, Button} from 'react-bootstrap'
import avatar from '../assets/website_logo.png'


export default class PaymentSuccess extends Component {

  state={
    unit_price: 0,
    currency_symbol: '$US',
    length: 0,
    photo_url: '',
    start_time: '',
    method: '',
    first_name: '',
    last_name: '',
    forwardUrl: '',
    timezone: '',
  }

  componentDidMount = ()=>{
    if(!localStorage.getItem('ticketData')){
      window.location.href = '#/home'
      window.location.reload()
    }
    let data = JSON.parse(localStorage.getItem('ticketData'))
    let url = ''
    let user_id = localStorage.getItem('user_id')
    let user_type = parseInt(localStorage.getItem('user_type'))
    let profile_id = localStorage.getItem('profile_id')
    if(user_type===4){
      url = `/webuser/profile/:${user_id}`
    }else if(user_type===3){
      url = `/webuser/profile/:${user_id}`
    }else if(user_type<=2){
      url = `/admin`
    }
    this.setState({unit_price: data.unit_price, currency_symbol: data.currency==='USD'?'$US':'￥CNY', length: data.length, photo_url: data.photo_url, 
    start_time: data.start_time, method: data.method, first_name: data.first_name, last_name: data.last_name, forwardUrl: url, timezone: data.timezone})
  }

  forwardProfile = ()=>{
    // localStorage.removeItem('ticketData')
  }

  render() {
    let current_avatar = this.state.photo_url!==''?this.state.photo_url:avatar
    let fullname = this.state.first_name + ' ' + this.state.last_name
    let start_date = new Date(this.state.start_time)
    let end_date = new Date(start_date.getTime() + this.state.length*1000*60*30)
    let formatted_date = start_date.getFullYear()+'-' + (start_date.getMonth()+1) + '-'+start_date.getDate()
    let time_duration = start_date.getHours()+':'+(start_date.getMinutes()===0?'00':start_date.getMinutes()) + ' - ' + end_date.getHours()+':'+(end_date.getMinutes()===0?'00':start_date.getMinutes())
    let length_name = ''
    if(this.state.length===1){
      length_name = '快速咨询'
    }else if(this.state.length===2){
      length_name = '普通咨询'
    }else if(this.state.length===3){
      length_name = '初次评估'
    }else if(this.state.length===4){
      length_name = '长时咨询'
    }
    let appointment_method = ''
    if(this.state.method===1){
      appointment_method = '语音'
    }else if(this.state.method===2){
      appointment_method = '视频'
    }else if(this.state.method===3){
      appointment_method = '线下'
    }
    return (
      <div>
        <Container>
          <Row className='flex-block2'>
            <div className='payment-card' style={{width: '60%', padding: '25px 25px', textAlign: 'left', marginTop: '88px'}}>
              <div><svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M53.75 32.5L35.4166 50L26.25 41.25" stroke="#6FE4E5" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M40 70C56.5685 70 70 56.5685 70 40C70 23.4315 56.5685 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70Z" stroke="#6FE4E5" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg></div>
              <div style={{fontSize: '36px', fontWeight: '400', marginTop: '16px'}}>支付成功</div>
              <div style={{fontSize: '24px', marginTop: '16px'}}>Total: {this.state.unit_price*this.state.length} {this.state.currency_symbol}</div>
              <div style={{height: '200px', width: '100%', borderTop: '1px solid #E7E9EE', marginTop: '16px'}}>
                <div style={{marginTop: '16px'}}>您已成功预约: </div>
                {/* 咨询信息 */}
                <div className='payment-card flex-block' style={{height: '136px', marginTop: '16px', padding: '24px'}}>
                  <div><img src={current_avatar} alt='avatar' style={{width: '80px', height: '80px', borderRadius: '80px'}}></img></div>
                  <div className='flex-block4' style={{marginLeft: '24px', width: '38%'}}>
                    <div style={{fontWeight: '700', fontSize: '18px'}}>
                      {this.state.length*30}分钟 {length_name}
                    </div>
                    <div style={{marginTop: '8px'}}>{fullname}咨询师</div>
                    <div style={{marginTop: '8px', color: '#848F90'}}>{appointment_method}咨询</div>
                  </div>
                  <div className='flex-block4' style={{marginLeft: '24px', width: '35%', fontSize: '24px', lineHeight: '34.75px', textAlign: 'right'}}>
                    <div>{formatted_date}</div>
                    <div>{time_duration}</div>
                    <div>{this.state.timezone}</div>
                  </div>
                </div>                
              </div>
              <NavLink to={this.state.forwardUrl} style={{textDecoration: 'none'}}>
              <input type='button' onClick={()=>{localStorage.removeItem('ticketData')}} className='events-btn-2' style={{fontSize: '18px', fontWeight: '500', marginTop: '16px'}} value='查看我的预约' ></input>
              </NavLink>
            </div>
          </Row>
        </Container>
      </div>
    )
  }
}
