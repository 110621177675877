import axios from "axios";
import { Component } from "react";
import ReactGA from 'react-ga'
import utils from "./utils";

import '../css/crisisIntervent.css';

export default class CrisisIntervent extends Component {

    state = {
        resourceList: [],
        locationFilter: ''
    }

    componentWillMount = async () => {
        document.title = "FindSelf找我 - 危机转介";
        await axios.get(utils.getUrl('emergency/'))
            .then( response => {
                console.log(response.data.results)
                this.setState({
                    resourceList: response.data.results
                })
            }).catch( err => {
                console.log(err)
                alert('页面加载错误 请刷新重试')
            })
    }

    generateResouceCards = (resource) => {
        let contactInfo = resource['contact'].split('；')
        return (
            <div className="col-md-4 pt-0 d-flex align-items-stretch" style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                <div className="card grey-box" style={{ border: 0, borderRadius: 12, width: '100%' }}>
                    <div className="card-body">
                        <h5 className="card-title" style={{ fontSize: 16 }}><strong>{resource.title}</strong></h5>
                        <p className="card-text" style={{ fontSize: 14 }}>
                            {contactInfo.map((item, i) => {
                                if (i === 0) return item
                                else return <div>{item}</div>;
                            })}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    filterEvent = (event, locationPhrase) => {
        document.querySelectorAll('.filterBar > .badge').forEach(node => {
            if (node !== event.target) {
                node.classList.remove("bg-primary");
                node.classList.add("bg-secondary");
            } else {
                node.classList.remove("bg-secondary");
                node.classList.add("bg-primary");
            }
        })
        this.setState({
            locationFilter: locationPhrase
        })
    }

    render() {
        // GA - PageView - Start
        let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
        ReactGA.pageview(pagePathForGA);
        // GA - PageView - End
        return (
            <div className="container-fluid main-content px-lg-10 pt-0 pb-5">
                <div className="row g-1 g-lg-4">
                    <div className="col-md-8 pt-3 d-flex flex-column justify-content-around">
                        <div className="row">
                            <h1><strong>危机转介资源</strong></h1>
                        </div>
                        <div className="row filterBar">
                            <span class="badge rounded-pill bg-primary" onClick={(event)=>this.filterEvent(event, '')}>全部</span>
                            <span class="badge rounded-pill bg-secondary" onClick={(event) => this.filterEvent(event, '北京')}>北京</span>
                            <span class="badge rounded-pill bg-secondary" onClick={(event) => this.filterEvent(event, '上海')}>上海</span>
                            <span class="badge rounded-pill bg-secondary" onClick={(event) => this.filterEvent(event, '重庆')}>重庆</span>
                        </div>
                    </div>
                    <div className="col-md-4 pt-3">
                        <div className="card grey-box" style={{ border: 0, borderRadius: 8 }}>
                            <div className="card-body my-4 mx-4">
                                <p className="card-text card-text-large">
                                    全国公共卫生公益热线<br />
                                    <div className="pinned-text-highlight">12320</div> ( 非24小时热线 )
                                </p>
                                <p className="card-text card-text-large">
                                    蓝天联盟心理咨询<br />
                                    <div className="pinned-text-highlight">027-88569324</div>（ 8:00 - 23:00 ）
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row mt-5 mb-3">
                    <span class="badge rounded-pill bg-success">优质</span>
                </div>      
                <div className="row g-1 g-lg-4">
                    {/* display premium resources */}
                    {this.state.resourceList
                        .filter(resource => resource['importance'] === 2 && resource['region'].includes(this.state.locationFilter))
                        .map(resource =>this.generateResouceCards(resource))}
                </div>
                <div className="row mt-5 mb-3">
                    <span class="badge rounded-pill bg-info">验证通过</span>
                </div>
                <div className="row g-1 g-lg-4">
                    {/* display verified resources (exclude premium) */}
                    {this.state.resourceList
                        .filter(resource => resource['importance'] === 1 && resource['region'].includes(this.state.locationFilter))
                        .map(resource => this.generateResouceCards(resource))}
                </div>
                <div className="row mt-5 mb-3">
                    <span class="badge rounded-pill bg-dark">其他</span>
                </div>
                <div className="row g-1 g-lg-4">
                    {/* display other resources (exclude premium and verified) */}
                    {this.state.resourceList
                        .filter(resource => resource['importance'] === 0 && resource['region'].includes(this.state.locationFilter))
                        .map(resource => this.generateResouceCards(resource))}
                </div>
            </div>
        )
    }
}