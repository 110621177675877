import avatar from '../imgs/UserAvatarDefault.svg'
import logo from '../imgs/findself_logo.png'
import utils from './utils'

import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'

import '../css/top-nav-bar.css'

export default class TopNavBar extends Component {
  handleLogout = () => {
    utils.logout();
  }

  gotoHomePage = () => {
      const user_id= localStorage.getItem('user_id');
      let homepage_url;
      switch (localStorage.getItem('user_type')) {
          case '1':
            homepage_url = `#/admin`;
            break;
          case '3':
          case '4':
            homepage_url = `#/webuser/profile/:${user_id}`;
            break;
          default:
            homepage_url = '#/'
            break;
      }
      window.location.href = homepage_url;
  }

  componentDidMount = () => {
    document.querySelectorAll('.navbar-nav-link').forEach(item => {
      item.addEventListener('click', event => {
        if (window.innerWidth < 992) {
          document.querySelector(".navbar-toggler").click();
        }
      })
    })
  }

  render() {

    const logged_in = !!localStorage.getItem('user_id')
    let user_avatar = avatar
    if(localStorage.getItem('user_avatar') && localStorage.getItem('user_avatar')!== 'null'){
      user_avatar = localStorage.getItem('user_avatar')
    }

    return (
      <div>
        <nav className="container-fluid navbar navbar-expand-lg px-lg-10">
            <a className="navbar-brand" href="/#/home/">
              <img src={logo} alt="" width="40" className="logo d-inline-block align-text-top"/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <h2 className="navbar-toggler-icon brand-primary-color"><i className="bi bi-filter-circle"></i></h2>
            </button>
            <div className="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
              <ul className="nav navbar-nav flex-grow-1 justify-content-center">
                <li className="nav-item px-3"><NavLink to="/home" onClick={this.collapse} activeClassName="active" className="nav-link navbar-nav-link">首页</NavLink></li>
                <li className="nav-item px-3"><NavLink to="/counselorsunder30/" activeClassName='active' className="nav-link navbar-nav-link">青年咨询师</NavLink></li>
                <li className="nav-item px-3"><NavLink to="/talk/" activeClassName="active" className="nav-link navbar-nav-link">找我说</NavLink></li>
                <li className="nav-item px-3"><NavLink to="/crisisintervention" activeClassName="active" className="nav-link navbar-nav-link">危机转介资源</NavLink></li>
                {/*
                <li className="nav-item px-3"><NavLink to="/appointment" activeClassName="active" className="nav-link navbar-nav-link">预约/付费</NavLink></li>
                <li className="nav-item px-3"><NavLink to="/order" activeClassName="active" className="nav-link navbar-nav-link">预约管理/查询</NavLink></li>
                <li className="nav-item px-3"><NavLink to="/forum/" activeClassName="active" className="nav-link navbar-nav-link">找我论坛</NavLink></li>
                <li className="nav-item px-3"><NavLink to="/search/therapists" activeClassName="active" className="nav-link navbar-nav-link">找我咨询</NavLink></li>
                {logged_in?
                  <NavLink to="/talktome" activeClassName="active" className="nav-link navbar-nav-link">找我说志愿者</NavLink>
                :''}
                */}
              </ul>
              
              {logged_in
              ? <div className="dropdown">   
                  <button className="btn" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={avatar} alt="user-avatar" className="avatar d-inline-block align-text-top hide-mobile"/>
                  </button>
                  <div class="d-grid hide-desktop">
                        <button onClick={this.gotoHomePage} class="btn btn-light text-start my-1 navbar-nav-link" type="button">个人中心</button>
                        <button onClick={this.handleLogout} class="btn btn-light text-start my-1 navbar-nav-link" type="button">退出登录</button>
                  </div>
                  <ul className="dropdown-menu p-2">
                    <img src={avatar} alt="user-avatar" className="avatar my-1 hide-mobile"/>
                    {
                      localStorage.getItem('loginToken')
                        ? (localStorage.getItem('user_type') <= 2
                            ? <li class="my-1">欢迎, admin!</li>
                            : <li class="my-1">欢迎, {localStorage.getItem('first_name')}!</li>)
                        :(<li><NavLink to="/login">登录</NavLink></li>)
                    }
                    <div class="d-grid">
                        <button onClick={this.gotoHomePage} class="btn btn-light text-start my-1 navbar-nav-link" type="button">个人中心</button>
                        <button onClick={this.handleLogout} class="btn btn-light text-start my-1 navbar-nav-link" type="button">退出登录</button>
                    </div>
                  </ul>
                </div>
              : <ul className="nav navbar-nav justify-content-end">
                  <li className="px-lg-1 px-3"><NavLink to="/login" activeClassName="active" className="nav-link navbar-nav-link">登录</NavLink></li>
                </ul>
              }

              {/* code for registration
                <li className="px-lg-1 px-3"><NavLink to="/signup/webuser" activeClassName="active" className="nav-link navbar-nav-link">注册</NavLink></li>
                <li className="pt-1 hide-mobile"><p>|</p></li>
              */}
              
            </div>
        </nav>
      </div>
    )
  }
}
