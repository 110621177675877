import React, { Component } from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import utils from "./utils"
export default class BannerHorizontal extends Component {

  state = {
    banner_id: '',
    bannerNum: 0,
    bannerImgs: [],
    bannerList: [],
    hrefs: []
  }

  componentDidMount = () => {
    this.setState({banner_id: this.props.banner_id})
    this.getBannerList()
  }

  getBannerList = async() =>{
    await utils.loadBanner()

    window?.allAds?.map(elem => {
      if(elem.banner_id === this.state.banner_id){
        this.setState({bannerImgs: elem.media_urls, hrefs: elem.hrefs})
        this.setState({bannerNum: this.state.bannerImgs.length})
      }
    })
  }


  render() {
    return (
      <div>
        {localStorage.getItem('user_type')&&localStorage.getItem('user_type')<=2?
            (<button className="homepage-btn-1" style={{marginLeft: '10%'}} onClick={() =>this.props.forward(this.state.banner_id)}>管理广告</button>):(<div></div>)
            }
          <div class='ad_horizontal'>
            <CarouselProvider
            naturalSlideWidth={980}
            naturalSlideHeight={120}
            totalSlides={this.state.bannerNum}
            isPlaying={true}
            interval={4000}
            infinite={true}
            hasMasterSpinner={true}
            style={{ height:'120px'}}
            >
            <Slider>
              {
                this.state.bannerImgs.map(img => {
                  const index = this.state.bannerImgs.indexOf(img)
                  const link = this.state.hrefs[index]===''?null:this.state.hrefs[index]
                  return <Slide><a href={link} target='_blank'><Image src={img} style={{width: '980px', height:'120px', margin: '0 auto'}}/></a></Slide>
                }

                )
              }
            </Slider>
            <div style={{ textAlign:'center'}}><DotGroup className='dotgroup'/></div>
            </CarouselProvider>
          </div>
          {/* <div class='ad' style={{width: '100%'}}>
          {
            this.state.bannerImgs.map(img => {
                  const index = this.state.bannerImgs.indexOf(img)
                  const link = this.state.hrefs[index]===''?null:this.state.hrefs[index]
                  return (<div style={{marginBottom: '20px'}}><a href={link} target='_blank'><img src={img} style={{height:'200px', width: '100%'}}/></a></div>)
                })
          }
          </div> */}
      </div>
    )
  }
}
