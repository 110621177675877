import React, { Component } from 'react'
import axios from 'axios'
import {Container, Row, Col, Alert, Modal, Button} from 'react-bootstrap'
import {loadStripe} from '@stripe/stripe-js'
import us_city_list from '../assets/us_states_cities.json'
import avatar from '../assets/website_logo.png'
import utils from './utils'

export default class PaymentProgress extends Component {
  
  state = {
    ticket_data: {},
    ticket_id: '',
    stripe_id: '',
    street: '',
    street_2: '',
    zip_code: '',
    us_states_cities: us_city_list,
    cityList: [],
    stateList: [],
    selectedState: '',
    selectedCity: '',
  }

  handlePayment = async() => {
    if(this.state.street===''){
      alert('请填写一个地址！')
      return
    }else if(this.state.selectedState===''){
      alert('请选择一个州！')
      return
    }else if(this.state.selectedCity===''){
      alert('请选择一个城市！')
      return
    }else if(this.state.zip_code===''){
      alert('请填写一个邮编号码！')
      return
    }
    await this.createTicket().then(()=>{this.forwardPayment()})
  }

  createTicket = async() => {
    
    let billing_address = {street: this.state.street, street_2: this.state.street_2, city: this.state.selectedCity, state: this.state.selectedState, zip_code: this.state.zip_code}
    if(this.state.ticket_data.isOnsite){
      await axios.post(utils.getUrl(`appointment/ticket/create/${this.state.ticket_data.user_id}/`),
      {length: this.state.ticket_data.length, method: this.state.ticket_data.method, tags: this.state.ticket_data.tags, 
      meta:{description: this.state.ticket_data.description, billing_address: billing_address, timezone: this.state.ticket_data.timezone, city: this.state.ticket_data.city, state: this.state.ticket_data.state},
      currency: this.state.ticket_data.currency, start_time: this.state.ticket_data.start_time, location: this.state.ticket_data.location},
      {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
      .then(response=>{
        
        this.setState({ticket_id: response.data.ticket_id})
        alert('即将为您跳转支付界面...')
      }).catch(err=>{
        if(err.response.data.msg=='Invalid Time Range'){
          alert('该时间已被预约，请缩短时长或选择其他时间')
        }
      })
    }else{
      await axios.post(utils.getUrl(`appointment/ticket/create/${this.state.ticket_data.user_id}/`),
      {length: parseInt(this.state.ticket_data.length), method: this.state.ticket_data.method, tags: this.state.ticket_data.tags, 
      meta:{description: this.state.ticket_data.description, billing_address: billing_address, timezone: this.state.ticket_data.timezone},
      currency: this.state.ticket_data.currency, start_time: this.state.ticket_data.start_time, location: 'online'},
      {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
      .then(response=>{
        
        this.setState({ticket_id: response.data.ticket_id})
        alert('即将为您跳转支付界面...')
      }).catch(err=>{
        if(err.response.data.msg=='Invalid Time Range'){
          alert('该时间已被预约，请缩短时长或选择其他时间')
        }
      })
    }
  }

  forwardPayment = async() => {
    await axios.post(utils.getUrl(`appointment/ticket/pay/${this.state.ticket_id}/`),
    {pay: 'stripe'}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response=>{
      
      this.setState({stripe_id: response.data.id})
    })
    const stripe = await loadStripe(utils.getStripeKey())
    const result = await stripe.redirectToCheckout({
        sessionId: this.state.stripe_id,
    })
    if (result.error) {
        alert('支付错误')
    }
  }

  stateSelected = (event) => {
    const state = event.target.value
    if(state ===''){
      this.setState({cityList: [], selectedState: ''})
      return
    }
    let tempCity = []
    this.state.us_states_cities[state].map(city => {
      tempCity.push(city)
    })

    tempCity = tempCity.sort()

    this.setState({cityList: tempCity, selectedState: state})
  }

  citySelected = (event) => {
    const city = event.target.value
    this.setState({selectedCity: city})
  }

  componentDidMount = async() => {
    if(localStorage.getItem('ticketData')){
      let obj = JSON.parse(localStorage.getItem('ticketData'))
      this.setState({ticket_data: obj})
    }else{
      alert('无法访问数据')
    }

    // sort by alphabet
    const sortedList = Object.keys(this.state.us_states_cities).sort().reduce(
      (obj, key) => {
        obj[key] = this.state.us_states_cities[key];
        return obj;
      },
      {}
    )

    await this.setState({us_states_cities: sortedList})
    // load statesList
    let tempStates = []
    for(const elem in this.state.us_states_cities){
      tempStates.push(elem)
    }

    this.setState({stateList: tempStates})
    
  }

  render() {

    let currency_symbol = this.state.ticket_data.currency==='USD'?'$':'￥'
    let fullname = this.state.ticket_data.first_name + ' ' + this.state.ticket_data.last_name
    let current_avatar = this.state.ticket_data.photo_url&&!this.state.ticket_data.photo_url===''?this.state.ticket_data.photo_url:avatar
    let length_name = ''
    if(this.state.ticket_data.length===1){
      length_name = '快速咨询'
    }else if(this.state.ticket_data.length===2){
      length_name = '普通咨询'
    }else if(this.state.ticket_data.length===3){
      length_name = '初次评估'
    }else if(this.state.ticket_data.length===4){
      length_name = '长时咨询'
    }

    let appointment_method = ''
    if(this.state.ticket_data.method===1){
      appointment_method = '语音'
    }else if(this.state.ticket_data.method===2){
      appointment_method = '视频'
    }else if(this.state.ticket_data.method===3){
      appointment_method = '线下'
    }

    return (
      <div>
        <Container>
          {/* title */}
          <Row className='my_title'>
            <Col md={12}><h1>支付</h1></Col>
          </Row>
          <Row className='flex-block'>
              {/* left-side card */}
              <div className='payment-card' style={{width: '60%'}}>
                <div><span className='payment-subtitle'>Billing Address</span></div>
                {/* 地址 */}
                <div style={{marginTop: '16px'}}>
                  <input type='text' value={this.state.street} placeholder='地址/address' onChange={event => this.setState({street: event.target.value})}></input>
                </div>
                <div style={{marginTop: '16px'}}>
                  <input type='text' value={this.state.street_2} placeholder='地址2' onChange={event => this.setState({street_2: event.target.value})}></input>
                </div>
                {/* city/state/zipcode */}
                <Row className='custom-select-2' style={{marginTop: '16px'}}>
                  <Col md={4}>
                    <input type='text' value={this.state.selectedCity} placeholder='城市/City'
                    onChange={event => this.setState({selectedCity: event.target.value})} style={{paddingLeft: '8px'}}></input>
                    {/* <select id='city-select' value={this.state.selectedCity} onChange={this.citySelected} style={{paddingLeft: '8px'}}>
                      <option value=''>城市/City</option>
                      {this.state.cityList.map(city=>
                      (<option value={city}>{city}</option>))
                      }
                    </select> */}
                  </Col>
                  <Col md={4}>
                    <select id='state-select' value={this.state.selectedState} onChange={this.stateSelected} style={{paddingLeft: '8px'}}>
                      <option value=''>州/State</option>
                      {this.state.stateList.map(state=>
                      (<option value={state}>{state}</option>))
                      }
                    </select>
                  </Col>
                  <Col md={4}>
                    <input type='number' value={this.state.zip_code} placeholder='邮编/Zip Code' onChange={event => this.setState({zip_code: event.target.value})}></input>
                  </Col>
                </Row>
                <div style={{marginTop: '16px'}}>
                  <input type='button' onClick={this.handlePayment} className='events-btn-3' style={{fontSize: '18px', fontWeight: '500'}} 
                  value={'Pay '+(this.state.ticket_data.currency==='USD'?'$':'￥')+(this.state.ticket_data.unit_price)*parseInt(this.state.ticket_data.length)}></input>
                </div>
              </div>
              {/* right-side card */}
              <div className='payment-card' style={{height: '195px', width: '35%', marginLeft: '30px'}}>
                <div style={{color: '#071E22', fontSize: '30px', lineHeight: '43px'}}>Total: {currency_symbol+this.state.ticket_data.unit_price*parseInt(this.state.ticket_data.length)}</div>
                <div className='flex-block' style={{marginTop: '16px', borderTop: '1px solid #E7E9EE', borderBottom: '1px solid #E7E9EE', height: '104px', padding: '16px 0'}}>
                  <div><img src={current_avatar} alt='avatar' style={{width: '64px', height: '64px', borderRadius: '64px'}}></img></div>
                  <div className='flex-block4' style={{marginLeft: '24px', textAlign: 'left'}}>
                    <div style={{fontWeight: '700'}} className='flex-block'>
                      <span>{this.state.ticket_data.length*30}分钟 {length_name}</span>
                      <span style={{fontWeight: '500', marginLeft: '70px'}}>{currency_symbol+this.state.ticket_data.unit_price*parseInt(this.state.ticket_data.length)}</span>
                    </div>
                    <div style={{marginTop: '8px', fontSize: '12px'}}>{fullname}咨询师</div>
                    <div style={{marginTop: '8px', color: '#848F90', fontSize: '12px'}}>{appointment_method}咨询 </div>
                  </div>
                </div>
              </div>
          </Row>
          <div style={{height: '250px'}}></div>
        </Container>
      </div>
    )
  }
}
