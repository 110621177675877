import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import index_image from '../imgs/home_bg.png'
import pic1 from '../imgs/home_pic1.png'
import pic2 from '../imgs/home_pic2.png'
import ReactGA from 'react-ga'

import '../css/home.css'

class Home extends Component {

  forwardSearch = () => {
    this.props.history.push({pathname: `/search/therapists`})
  }

  forwardRegister = () => {
    this.props.history.push({pathname: `/signup/therapist`})
  }

  componentWillMount = async () => {
    document.title = "FindSelf找我 - 北美中文心理服务平台";
  }

  render() {
    // GA - PageView - Start
    let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
    ReactGA.pageview(pagePathForGA);
    // GA - PageView - End

    return (
      <div>
        <div className="container-fluid hero">
        <div className="container-fluid px-lg-10 py-5">
            <div className="row">
              <div className="col-lg-8 col-12 py-2">
                <h1>找我咨询，我们一直在这里</h1>
                <span>「FindSelf找我」 ——北美中文心理服务平台</span>
                <br></br>
                <span>一站式心理支持服务，与您共历心灵的成长</span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 py-2">
                {/* <button type="button" className="btn btn-primary" onClick={this.forwardSearch}>&nbsp;&nbsp;找我咨询&nbsp;&nbsp;</button>
                  &nbsp;&nbsp;
                <button type="button" className="btn btn-secondary mr-1" onClick={this.forwardRegister}>咨询师入驻</button> */}
              </div>
            </div>
            <img src={index_image} className="hero-mobile" alt="home"/>
        </div>
        </div>

        <div className="container-fluid px-lg-10 py-5">
          <div className="row mb-2">
            <h2>您可以在找我</h2>
          </div>
          <div className="row m-2 g-1 g-lg-4">
            <div className="row col-lg-6 col-12 py-4">
              <div className="col-3">
                <img src={pic1} className="card-img" alt="找我咨询"/>
              </div>
              <div className="col-8">
                <h3>找我咨询</h3>
                <p>中美心理咨询师服务平台，提供专业可靠、高性价比的心理咨询服务</p>
                <NavLink to="/counselorsunder30/" className="btn btn-third">查看青年咨询师 <i className="bi bi-arrow-right"></i></NavLink>
              </div>
            </div>
            <div className="row col-lg-6 col-12 py-4">
              <div className="col-3">
                <img src={pic2} className="card-img" alt="找我说"/>
              </div>
              <div className="col-8">
                <h3>找我说</h3>
                <p>北美地区最大全时段匿名免费即时线上文字支持平台，24/7 等你来找我说</p>
                <NavLink to="/talk/" className="btn btn-third">查看详情 <i className="bi bi-arrow-right"></i></NavLink>
              </div>
            </div>
          </div>  

          {/* <div className="row m-2 g-1 g-lg-4">
            <div className="row col-lg-6 col-12 py-4">
              <div className="col-3">
                <img src={pic4} className="card-img" alt="找我团"/>
              </div>
              <div className="col-8">
                <h3>找我团</h3>
                <p>自我探索与成长也可以妙趣横生，丰富多彩的心理团体活动就在您的身边</p>
                <NavLink to="/events/" className="btn btn-third">查看详情 <i className="bi bi-arrow-right"></i></NavLink>
              </div>
            </div>
            <div className="row col-lg-6 col-12 py-4">
              <div className="col-3">
                <img src={pic3} className="card-img" alt="找我论坛"/>
              </div>
              <div className="col-8">
                <h3>找我论坛</h3>
                <p>更多关于心理的小知识？北美心理行业最新动态？欢迎来这里寻找答案</p>
                <NavLink to="/forum/" className="btn btn-third">查看详情 <i className="bi bi-arrow-right"></i></NavLink>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    )
  }
}

export default withRouter(Home);