import React, {Component} from 'react'
import {Col, Row, Container,Pagination,PageItem,Alert,Badge, Modal, Button} from 'react-bootstrap'
import RichContent from './RichContent'
import axios from "axios";
import CommentBox from './CommentBox'
import {NavLink} from 'react-router-dom'
import avatar from '../assets/website_logo.png'
import ReactLoading from 'react-loading';
import utils from './utils'
import {ReactComponent as IconPost} from 'bootstrap-icons/icons/clock.svg'
import {ReactComponent as IconEdit} from 'bootstrap-icons/icons/arrow-clockwise.svg'
import {ReactComponent as IconComment} from 'bootstrap-icons/icons/chat-left-text.svg'
import {ReactComponent as IconView} from 'bootstrap-icons/icons/eye.svg'
import {ReactComponent as IconStar} from 'bootstrap-icons/icons/star.svg'
import {ReactComponent as IconStarFill} from 'bootstrap-icons/icons/star-fill.svg'
import _ from './locale'
import VVShare from "vvshare";
import ReCAPTCHA from "react-google-recaptcha";

const printAvatar = (url) => {
    if (url === null) {
        return avatar
    } else {
        return url
    }
}
// const window = (new JSDOM('')).window
// const DOMPurify = createDOMPurify(window)
const DOMPurify = {
    sanitize:(s)=>s
}
const pageSize= 10;

const getTitle = (s)=>{
    return s.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}


const getDisplayName = (user,render)=>{
    if (user.user_type == 3){
        if(render){
            return <NavLink to={`/userhomepage/${user.id}`}>
                {getTitle(user.first_name + ' ' + user.last_name+'(咨询师)')}
            </NavLink>
        }
        return getTitle(user.first_name + ' ' + user.last_name+'(咨询师)')
    }
    else {
        return getTitle((user.first_name + ' ' + user.last_name).trim() || user.nickname||'') || `User ${user.id}`
    }

}

const getStatusText = (status)=>{
    switch(status){
        case 0: return <Alert variant="info" show={true}>This page is a draft</Alert>;
        case 1: return <Alert variant="warning" show={true}>This page is waiting for for approval</Alert>;
        case 2:
            return <Alert variant="warning" show={true}>This page was deleted or rejected, feel free to
                resubmit.</Alert>;

        case 10: return "";
    }
}

class BlogCreate extends Component {
    render() {
        const history = this.props.history;
        return <RichContent action="create" history={history}/>
    }
}

class BlogEdit extends Component {
    render() {
        const history = this.props.history;
        const post_id = parseInt(this.props.match.params.post_id.slice(1));
        return <RichContent action="update" src_id={post_id} history={history}/>
    }
}

class BlogShow extends Component {

    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.commentBox = React.createRef();
        this.reportInput = React.createRef();
    }

    state={
        bodyLoaded:false,
        commentLoaded: false,
        title:"",
        content:"",
        author: {
            first_name: "",
            last_name: "",
            gender: 0,
            id: 3737795359,
            user_type:4,
        },
        post_date: "2021-01-08T15:36:32.447690Z",
        edit_date: "2021-01-08T15:36:32.447715Z",
        visits:0,
        likes:0,
        liked:false,
        comments:[

        ],
        tags:[],
        start:1,
        end: 1,
        count:0,
        totalPage:1,
        currPage:0,
        status:1,
        allTags:[],
        categories:[],
        showModal: false,
        modalText: '',
        reportShow: false,
        reportId: 0,
        can_reply:-1,
        prev:null,
        next:null
    }

    gotoAuthorHomepage = ()=>{
        this.props.history.push(`/userhomepage/${this.state.author.id}`)
    }

    setup = ()=>{
        const post_id = parseInt(this.props.match.params.post_id.slice(1));
        const config = localStorage.getItem('loginToken')?
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}:
            {};
        this.updateCommentStatus();
        axios.get(utils.getUrl(`public/blog/${post_id}/?show_neighbor=1`), config).then(   response => {
                this.setState(
                    {
                        title:response.data.subject,
                        content:response.data.content,
                        author:response.data.author,
                        edit_date:response.data.edit_date,
                        post_date: response.data.post_date,
                        visits: response.data.visits,
                        tags: response.data.tags,
                        categories:response.data.categories,
                        status: response.data.status,
                        bodyLoaded: true,
                        likes:response.data.likes,
                        liked:response.data.liked,
                        prev: response.data.prev,
                        next: response.data.next,
                    }
                )
            }
        ).catch(err => {
            // alert(err)
        })
        this.gotoPage(1);
    }
    componentWUpdate(prevProps, prevState, snapshot) {
        if( prevProps.match.params.post_id != this.props.match.params.post_id){
            this.setState({

                loaded:false,
            },()=>{
                this.setup();
                this.gotoPage(1,true);
                this.getTagList();
            })

        }
    }
    handleLikes = ()=>{
        const post_id = parseInt(this.props.match.params.post_id.slice(1));
        const user_id = localStorage.getItem('user_id')
        const config = {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}
        if (!user_id || !post_id){
            return
        }
        const liked = this.state.liked
        const url = utils.getUrl(`cms/favourites/${user_id}/`)
        this.setState({liked: !liked})
        if (!liked){
            axios.post(url,
                {action:"add",post_id: post_id}, config).then((response)=>{
                    alert("收藏成功")
                this.setState({liked: true,likes:this.state.likes +1 })
            }).catch((err)=>{
                alert("请求错误")
                this.setState({liked:liked})
            })
        } else {
            axios.post(url,
                {action: "drop", post_id: post_id}, config).then((response) => {
                alert("取消收藏成功")
                this.setState({liked: false, likes:Math.max( this.state.likes - 1,0)})
            }).catch((err) => {
                alert("请求错误")
                this.setState({liked: liked})
            })
        }
    }
    getTagList = async ()=>{
        await utils.loadTagList();
        this.setState({allTags: window.tagList?.allTags || []})

    }
    get_role = (arr)=>{
       for (var tag_id of arr) {
           let filtered = this.state.allTags.filter((ele)=>ele.tag_id=== tag_id)
           if (filtered){
                return filtered[0]?.value_cn
           }
       }
       return "Consultant"
    }
    componentDidMount() {
        this.setup();
        this.getTagList();
    }


    gotoPage = (page,force)=>{

        if (page == this.state.currPage && !force){
            return
        } else {
            this.setState({currPage: page});
            const post_id = parseInt(this.props.match.params.post_id.slice(1));
            axios.get(utils.getUrl(`public/blog/${post_id}/comments/list/?start=${pageSize*(page-1)}&end=${pageSize * page}`)).then(response => {
                    const currPage = Math.ceil(response.data.data.start / pageSize) + 1;
                    const totalPage = Math.ceil(response.data.data.count / pageSize) || 1;
                    this.setState(
                        {
                            start: response.data.data.start,
                            end: response.data.data.end,
                            count: response.data.data.count,
                            comments: response.data.data.data,
                            totalPage: totalPage,
                            currPage: currPage,
                            commentLoaded: true,
                        }
                    )
                }
            ).catch(err => {
                // alert(err)
            })
        }

    }

    deleteComment = (comment)=>{
        const config = localStorage.getItem('loginToken') ?
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}} :
            {};
        axios.delete(utils.getUrl(`cms/blog/${comment.post_id}/update/`),
            config).then((response)=>{
                alert('删除成功！')
                this.gotoPage(this.state.currPage,true)
        }).catch((err)=>{
            // alert(err)
        })
    }

    getPager = ()=>{
        const pagearray = [];
        let i;
        for (i = 1; i <= this.state.totalPage; i++) {
            pagearray.push(i);
        }
        const afterarray = [];
        for (i = this.state.totalPage-4; i <= this.state.totalPage; i++) {
            afterarray.push(i);
        }
        const beforearray = [];
        for (i = 1; i <= 5; i++) {
            beforearray.push(i);
        }
        if(this.state.totalPage==1){
            return <Pagination><Pagination.Item active>{1}</Pagination.Item></Pagination>
        } else if (this.state.totalPage <=5){
            return <Pagination>{pagearray.map((val) => <Pagination.Item
                    active={val == this.state.currPage}
                    onClick={() => {
                        this.gotoPage(val)
                    }}
            >{val}</Pagination.Item>)}</Pagination>
                 }  else if (this.state.currPage>= this.state.totalPage - 4) {
            return <Pagination>
                        <Pagination.First
                        onClick={() => {
                        this.gotoPage(1)
                        }}/>
                        <Pagination.Prev
                        onClick={() => {
                        this.gotoPage(this.state.currPage - 1)
                        }}/>
                        <Pagination.Item onClick={() => {
                        this.gotoPage(1)
                        }}>{1}</Pagination.Item>
                        <Pagination.Ellipsis/>
                {afterarray.map((val) => <Pagination.Item active={val == this.state.currPage} onClick={()=>{this.gotoPage(val)}}>{val}</Pagination.Item>)}
                        <Pagination.Next onClick={() => {
                        this.gotoPage(this.state.currPage+1)
                        }}/>
                        <Pagination.Last onClick={() => {
                        this.gotoPage(this.state.totalPage)
                        }}/>
                        </Pagination>

        } else if (this.state.currPage <= 5) {
            return <Pagination>
                <Pagination.First
                    onClick={() => {
                        this.gotoPage(1)
                    }}/>
                <Pagination.Prev
                    onClick={() => {
                        this.gotoPage(this.state.currPage - 1)
                    }}/>


                {beforearray.map((val) => <Pagination.Item active={val == this.state.currPage} onClick={() => {
                    this.gotoPage(val)
                }}>{val}</Pagination.Item>)}
                <Pagination.Ellipsis/>
                <Pagination.Item onClick={() => {
                    this.gotoPage(this.state.totalPage)
                }}>{this.state.totalPage}</Pagination.Item>


                <Pagination.Next onClick={() => {
                    this.gotoPage(this.state.currPage + 1)
                }}/>
                <Pagination.Last onClick={() => {
                    this.gotoPage(this.state.totalPage)
                }}/>
            </Pagination>
        } else{
            return <Pagination>
                <Pagination.First
                    onClick={() => {
                        this.gotoPage(1)
                    }}/>
                <Pagination.Prev
                    onClick={() => {
                        this.gotoPage(this.state.currPage - 1)
                    }}/>
                <Pagination.Item onClick={() => {
                    this.gotoPage(1)
                }}>{1}</Pagination.Item>
                <Pagination.Ellipsis/>

                <Pagination.Item onClick={() => {
                    this.gotoPage(this.state.currPage - 2)
                }}>{this.state.currPage - 2}</Pagination.Item>
                <Pagination.Item onClick={() => {
                    this.gotoPage(this.state.currPage - 1)
                }}>{this.state.currPage - 1}</Pagination.Item>
                <Pagination.Item active>{this.state.currPage}</Pagination.Item>
                <Pagination.Item onClick={() => {
                    this.gotoPage(this.state.currPage + 1)
                }}>{this.state.currPage + 1}</Pagination.Item>
                <Pagination.Item onClick={() => {
                    this.gotoPage(this.state.currPage + 2)
                }}>{this.state.currPage + 2}</Pagination.Item>

                <Pagination.Ellipsis/>
                <Pagination.Item onClick={() => {
                    this.gotoPage(this.state.totalPage)
                }}>{this.state.totalPage}</Pagination.Item>
                <Pagination.Next onClick={() => {
                    this.gotoPage(this.state.currPage + 1)
                }}/>
                <Pagination.Last onClick={() => {
                    this.gotoPage(this.state.totalPage)
                }}/>
            </Pagination>
        }
    }
    insertQuote = (user,msg,post_id)=>{

        const block = `<blockquote type="reply"><p class="reply-id">${post_id}</p>@${getDisplayName(user)}: ${msg}</blockquote>`
        window.$('.commentBoxContainer blockquote').remove()
        this.editor.current.appendContents(block);
        this.commentBox.current.scrollIntoView({behavior:"smooth",block:"end"})
    }
    handleAdmin = (status)=> {
        if ((localStorage.getItem('user_type') || 10) >= 3){
            alert('您没有权限这样做！')
            return
        }
        const config = {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}
        const post_id = parseInt(this.props.match.params.post_id.slice(1));

        axios.post(utils.getUrl(`cms/blog/${post_id}/approve/`),
            {status:status},config).then( (response)=>{
                alert('更改成功！')
            this.setup();}
        ).catch((err)=>{
            // alert(err)
        })

    }
    handleEdit = ()=>{
        const post_id = parseInt(this.props.match.params.post_id.slice(1));
        this.props.history.push(`/blog/:${post_id}/edit/`)
    }
    handleSubmit = () => {
        const post_id = parseInt(this.props.match.params.post_id.slice(1));
        const config = {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}
        axios.post(utils.getUrl(`cms/blog/${post_id}/update/`),
            {status: 1}, config).then((response) => {
                alert('拒绝成功！')
                this.setup();
            }
        ).catch((err) => {
            // alert(err)
        })
    }
    handleReport = ()=>{
        const value = this.reportInput.current?.value
        if (!value){
            alert("文字不能为空")
            return
        }
        axios.post(utils.getUrl("public/blog/reports/create/"),{
            "post_id":this.state.reportId,
            "text": value
        }).then(response => {
            this.setState({reportShow: false, reportId: 0})
            alert('提交成功！')
        }
        ).catch((err) => {
            // alert(err)
        })
    }
    hideReport = ()=>{
        this.setState({reportShow:false,reportId:0})
    }
    updateCommentStatus = async () => {
        const post_id = parseInt(this.props.match.params.post_id.slice(1));
        if (!localStorage.getItem('user_type')){
            this.setState({can_reply: -1})
        }
        axios.get(utils.getUrl(`public/blog/comment-access/?post=${post_id}`),utils.getAxiosConfig()).then(
            (response)=>{
                const reply_status = response.data.data.can_reply? 1:0
                window.debug_blog = this
                this.setState({can_reply: reply_status})
            }
        ).catch(
            (err)=>{
            }
        )
    }
    getCommentEmail = async()=>{
        let email = '';
        await axios.get(utils.getUrl('permission/status/'), utils.getAxiosConfig()).then(
            (response) => {
                email = response.data.email
            }
        ).catch((err) => {})


        if (!email) {
            alert("请登录")
            return;
        }
        await axios.post(utils.getUrl('permission/email-verification/generate-code/'),
            {
                email: email,
                action: "enable_comment"
            }).then(
            (response) => {
                alert("已发送至"+email)
                this.setState({can_reply:2})
            }
        )
    }
    handlSuccessfulVerification = async () => {
        const now = new Date()
        const timestamp = now.getTime()
        let signature = parseInt(timestamp / 1000 % 10000) ^ 0x345B32B3
        await axios.post(utils.getUrl('public/blog/comment-access/'),
            {
                signature: signature
            }, utils.getAxiosConfig()).then(
            (response) => {
                alert("验证成功")
               this.updateCommentStatus()
            }
        ).catch((err)=>{})

    }

    getCommentSection = ()=>{
        const post_id = parseInt(this.props.match.params.post_id.slice(1));
        switch (this.state.can_reply) {
            case 1: return <CommentBox ref={this.editor} parent={post_id} postSubmit={() => {
                this.gotoPage(1, true);
                this.updateCommentStatus()
            }}></CommentBox>;
            case 0:
                return <div className="comment-not-log-in"> 您在1小时内评论数量过多，请验证
                <ReCAPTCHA
                    sitekey={"6Ld3cGkaAAAAAOb87--wP7OL3OhIJ7AstH0ZWMhM"}
                    onChange={this.handlSuccessfulVerification}/>
                </div>
            case -1:
                return <div className="comment-not-log-in"><NavLink to="/login">登录后评论</NavLink></div>;

        }
    }
    render() {
        const history = this.props.history;
        const post_id = parseInt(this.props.match.params.post_id.slice(1));
        const access = (localStorage.getItem('user_type') || 10);
        const isAdmin = access < 3;
        const isLoggedIn = access < 5;
        const isAuthor = localStorage.getItem('user_id') && (localStorage.getItem('user_id') == this.state?.author?.id)
        return (
            <div>
                <Modal size="lg" show={this.state.reportShow} style={{opacity:"1", paddingTop:"30%"}} onHide={this.hideReport}>
                    <Modal.Header closeButton>
                        <Modal.Title>举报</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="report-body">
                        <div>
                            举报原因({"<"}100 Characters)
                        </div>
                        <textarea ref={this.reportInput} maxlength="100">

                        </textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleReport}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
              <Container >
                {
                    (this.state.bodyLoaded && this.state.commentLoaded)?"":
                        <div className="blog-loading-container"><ReactLoading className="blog-loading" type='spinningBubbles' color='#5ecfd0'
                                                                        height={'15%'} width={'15%'}/></div>
                }
            <Row sm={12} md={8} className="blog-page-container">
                <div style={{display: (this.state.bodyLoaded && this.state.commentLoaded) ? "initial" : "none"}}>
                    <div class="blog-admin-control">
                        {isAdmin && this.state.status ?
                            <React.Fragment>
                                {this.state.status != 10 ?
                                    <input type="submit" value={_("Approve")} className="blog-submit-button"
                                           onClick={() => {
                                               this.handleAdmin(10)
                                           }}/> : ""}
                                {this.state.status != 2 ?
                                    <input type="submit" value={_("Reject")} className="blog-submit-button"
                                           onClick={() => {
                                               this.handleAdmin(2)
                                           }}/> : ""}
                            </React.Fragment>

                            : ""

                        }

                        {isAuthor|| isAdmin ?
                            <input type="submit" value={_("Edit")} className="blog-submit-button"
                                   onClick={this.handleEdit}/> : ""


                        }
                        {isAuthor && [0,2].includes(this.state.status)?
                            <input type="submit" value={_("Submit")} className="blog-submit-button"
                                   onClick={this.handleSubmit}/> : ""
                        }
                    </div>
                    {getStatusText(this.state.status)}
                    {isLoggedIn?
                    <React.Fragment>
                    {this.state.liked?<span className="badge-liked" onClick={this.handleLikes}><span>取消收藏</span><span> <IconStarFill/>已收藏</span></span>:
                        <span className="badge-like" onClick={this.handleLikes}> <IconStarFill/> 收藏</span>}
                    </React.Fragment>
                        :""
                    }
                    <h1 className="blog-show-title">{this.state.title}</h1>

                    <div className="blog-show-tags">
                        {
                            utils.translate(this.state.categories, this.state.allTags).map(
                                (ele) => <Badge variant="primary">{ele?.value_cn}</Badge>
                            )
                        }
                        {
                            this.state.tags.map((elem)=>
                                <Badge variant="primary">{elem}</Badge>
                            )
                        }

                        <div>

                        </div>
                    </div>

                    <div className="blog-show-summary">

                        <span><IconPost/>{this.state.post_date?.substring(0, 10)} {this.state.post_date?.substring(11, 19)}
                        </span>

                        <span><IconEdit/>{this.state.edit_date?.substring(0, 10)} {this.state.edit_date?.substring(11, 19)}
                        </span>

                        <span><IconView/>{this.state.visits} 访问
                        </span>

                        <span><IconComment/>{this.state.count} 评论
                        </span>
                        <span><IconStar/>{this.state.likes} 收藏
                        </span>
                    </div>
                    <div className="blog-show-author-row">
                        <div className="blog-show-author-by">
                            BY
                        </div>
                    <div className="blog-show-author-card" onClick={this.gotoAuthorHomepage}>
                    <span className="blog-show-author-col-1">
                    <img src={printAvatar(this.state.author?.photo_url)} alt='user_avatar' className="blog-author-avatar"></img>
                    </span>
                    <span className="blog-show-author-col-2">
                    <div className="blog-show-author">{this.state.author?.profile_info?.full_name}</div>
                        <div
                            className="blog-show-author-type"> {this.get_role(this.state.author?.profile_info?.role || [])} </div>

                     </span>
                </div>
                    </div>
                           <div className="blog-show-body">
                <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.content)}}/>
                </div>
                    <div className="blog-share">
                        <span>分享到:</span>
                    <VVShare
                        title={`%23FindSelf %23找我 %23找我博客 ${this.state.title} `}
                        url={window.location.href}
                        description={`%23FindSelf %23找我 %23找我博客 ${this.state.title} ${this.state.description}`}
                        width={30}
                        height={30}
                        network={["facebook", "linkedin", "twitter", "weibo"]}
                    />
                    </div>
                </div>
                <div style={{display: this.state.commentLoaded ? "initial" : "none"}}>
                    <div className="blog-list-jump">
                        {this.state.prev?
                            <div>上一篇:<NavLink
                                to={`/blog/:${this.state.prev?.post_id}/show/`}>{this.state.prev?.subject}</NavLink>
                            </div>:""
                        }<div></div>
                        {this.state.next ? <div>下一篇:<NavLink
                            to={`/blog/:${this.state.next?.post_id}/show/`}>{this.state.next?.subject}</NavLink></div>:""

                        }
                    </div>
                <div className="blog-show-comment-before">
                    {this.state.count}条评论
                </div>
                {/*<hr className="blog-show-break"/>*/}
                <div>
                    {this.state.comments.map((feedback)=>{
                        return(
                            <div className="blog-comment-show">
                                <div className="blog-comment-header">
                                <span className="blog-comment-author"> <img
                                    src={printAvatar(this.state.author?.photo_url)} alt='user_avatar'
                                    className="blog-author-avatar"></img>{getDisplayName(feedback.author,true)}</span>
                                    <span
                                        className="blog-comment-date">{feedback.post_date?.substring(0,10)} {feedback.post_date?.substring(11, 19)}:</span>
                                    <span className="blog-comment-action"> {this.state.can_reply==1?<a onClick={() => {
                                        this.insertQuote(feedback.author,feedback.content,feedback.post_id)
                                    }}>回复</a>:""}
                                    {localStorage.getItem('user_id')== feedback.author.id ||isAdmin?<a onClick={() => {
                                        this.deleteComment(feedback)
                                    }}>删除</a>:""}
                                        <a onClick={() => {
                                            this.setState({reportShow:true,reportId: feedback.post_id})
                                        }}>举报</a>
                                    </span>
                                </div>

                                    {/*<div>{feedback.subject}</div>*/}
                                <div className="blog-comment-body">
                                    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(feedback.content)}}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <hr className="blog-show-break"/>
                {
                    this.getPager()
                }
                <div ref={this.commentBox} style={{paddingButtom:"30px"}} className="commentBoxContainer">
                    {this.getCommentSection()}
                </div>
                </div>
            </Row>
            </Container>
            </div>
        )
    }
}

export default {
    BlogCreate:BlogCreate,
    BlogEdit: BlogEdit,
    BlogShow:BlogShow,
};
