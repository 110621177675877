import React, {Component} from 'react'
import avatar from '../assets/website_logo.png'
import googleLogo from '../assets/images/google.svg'
import ReactDOM from 'react-dom';
import {GoogleMapAutoComplete} from "./GoogleMap";

class OAuthGOOGLE extends Component{
    /*props:s
    * onSignIn(profile) a callback function to handle userinfo after signin
    * */
    constructor(props) {
        super(props);
        this.button = React.createRef();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (window?.gapi) {
            const node = this.button.current;
            const handler = this.props.onSignIn
            window.gapi.load('auth2', () => {
                const auth2 = window.gapi.auth2.init({
                    client_id: '61314360718-f03htd3gbnuv93stelj1sn4pl6eaf93p.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin'
                });
                auth2.attachClickHandler(node, {}, (googleUser) => {
                    const profile = googleUser.getBasicProfile();
                    return handler(profile);
                }, (error) => {
                    alert(error.error);
                });
            });
        }
    }

    componentDidMount() {
        // if (!window) {
        //     throw new Error('DOM is unavailable')
        // }
        // const exist = document.getElementById('googleOAuthId');
        if (window?.gapi){
            const handler = this.props.onSignIn
            const node = this.button.current;
            window.gapi.load('auth2', () => {
                const auth2 = window.gapi.auth2.init({
                    client_id: '61314360718-f03htd3gbnuv93stelj1sn4pl6eaf93p.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin'
                });
                auth2.attachClickHandler(node, {}, (googleUser) => {
                    const profile = googleUser.getBasicProfile();
                    return handler(profile);
                }, (error) => {
                    alert(error.error);
                });
            });
            return;
        }
        const google = document.createElement('script');
        const node = this.button.current;
        google.id = 'googleOAuthId';
        google.src ='https://apis.google.com/js/platform.js';
        const handler = this.props.onSignIn
        google.onload = google.onreadystatechange = function () {
            if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {

                window.gapi.load('auth2', () => {
                    const auth2 = window.gapi.auth2.init({
                        client_id: '61314360718-f03htd3gbnuv93stelj1sn4pl6eaf93p.apps.googleusercontent.com',
                        cookiepolicy: 'single_host_origin'
                    });
                    auth2.attachClickHandler(node, {}, (googleUser)=>{
                        const profile = googleUser.getBasicProfile();
                        return handler(profile);
                    }, (error) => {
                        alert(error.error);
                    });
                });

            }

        };
        google.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(google);
    }
    render() {
        return (
            <span ref={this.button} className="oauth-login-button google-login-button">
                <img src={googleLogo} style={{height: '40px', width: '40px'}}/>
                {/* <span>用Google邮箱登陆</span> */}
            </span>
        )
    }
}

OAuthGOOGLE.defaultProps = {
    onSignIn: (profile) => {
        // 
    }
};
export default OAuthGOOGLE
