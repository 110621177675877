import { Link } from 'react-router-dom'

export const TTMInfoCard = (props) => {

    return (
        <div className="row g-1 g-lg-4">
            {props.showTTM === false ? '':
                <div className="col-md-4 pt-3 d-flex align-items-stretch">
                    <div className="card grey-box" style={{ border: 0, width: '100%' }}>
                        <div className="card-body">
                            <h5 className="card-title"><strong>「找我说」</strong></h5>
                            <p className="card-text">全时段、即时、匿名的免费线上文字一对一心理支持服务。</p>
                            <button className="btn btn-secondary" onClick={() => props.displayIntro()}>开始找我说</button>
                        </div>
                    </div>
                </div>
            }
            <div className="col-md-4 pt-3 d-flex align-items-stretch">
                <div className="card grey-box" style={{ border: 0, width: '100%' }}>
                    <div className="card-body">
                        <h5 className="card-title"><strong>「找我邮局」</strong></h5>
                        <p className="card-text">让一切慢下来，来一次深层次的交流，「找我邮局」开始营业了。</p>
                        <Link className="btn btn-secondary" to="/postoffice" target="_blank" rel="noopener noreferrer">了解更多</Link>
                    </div>
                </div>
            </div>
            <div className="col-md-4 pt-3 d-flex align-items-stretch">
                <div className="card  grey-box" style={{ border: 0, width: '100%' }}>
                    <div className="card-body" >
                        <h5 className="card-title"><strong>「危机转介资源」</strong></h5>
                        <p className="card-text">如果您处于危机情景中，请使用「危机转介资源」寻求帮助。</p>
                        <Link className="btn btn-secondary" to="/crisisintervention" target="_blank" rel="noopener noreferrer">寻求帮助</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}