import React, { Component } from 'react'
import { Row, Col, Container, Card, Button } from 'react-bootstrap'
import ReactLoading from 'react-loading';
import BannerHorizontal from './bannerHorizontal'
import { Link } from 'react-router-dom'
import axios from 'axios'
import cover from '../imgs/home_pic4.png'
import utils from './utils'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga'
import { Modal } from 'react-bootstrap'

const pageSize = 10;
export default class Events extends Component {

    state = {
        bannerList: [],
        cateRefArray: [],
        cateLength: 2,
        eventsList: [
            { 
                id: '3737843055',
                title: '「FindSelf」意义感探索支持小组',
                shortDes: '意义感探索小组将带着大家通过艺术创作来开启探索自我的旅程。具体的艺术创作方式包括但不限于绘画、编曲、编舞、书写（诗、故事、剧本）。我们总有些难以言表的情感、感受、体验、思绪。但是，当这些故事被画出来',
                location: '线上',
                schedule: '每周六 22:00 - 23:30 (美东)',
                registerStatus: '报名中',
                registerForm: 'https://findself.va.mikecrm.com/Y7qbsum'
            },
            {
                id: '3737843055',
                title: '「找我解压」解压支持小组',
                shortDes: '解压小组力求创造一个温和包容的支持性空间，让大家倾诉并讨论自己生活中遇到的压力事件，同时探索到压力事件背后的心理症结。 同时我们将运用正念、艺术创作等方式，以大家一起练习一些释放压力的小技巧，寻找最适合',
                location: '线上',
                schedule: '每周日 09:00 - 10:30 (美东)',
                registerStatus: '已结束',
                registerForm: 'https://findself.va.mikecrm.com/T0RRPa1'
            },
            {
                id: '3737843055',
                title: '「找我正念」正念开放小组',
                shortDes: '正念（Mindfulness）是指不带评判地、有意识地去感受当下我们自己的想法、情绪、身体感受和身边的环境。它是一种当下的觉知，用一种不评判、不自欺的态度活在此时此刻。 研究表明，正念及其相关的技巧是可以后天习得的，他们可以帮助我减轻压力和焦虑、调节情绪、提高注意力、培养同情心',
                location: '线上',
                schedule: '每周日、周五 21:30(北京)',
                registerStatus: '已结束',
                registerForm: ''
            },
            {
                id: '3737843055',
                title: '「FindSelf」亲密关系支持小组',
                shortDes: '该团体完全免费，团体将持续6周，本团体为封闭团体，会在进行必要的准备工作与信息收集后进行团体成员事前沟通和筛选。',
                location: '线上',
                schedule: '每周六 9:00 - 10:00 (美东)',
                registerStatus: '已结束',
                registerForm: 'https://findself.va.mikecrm.com/dpUGFte'
            }
        ],
        tagList: [],
        current_tag: 0,
        isLoading: false,
        isSearched: false,
        start: 0,
        end: 10,
        scrollPosition: 0,
        currentScrollPosition: 0,
        likedList: [],
        status: 2,
        showModal: false,
        formTitle: '',
        formUrl: ''
    }

    componentDidMount = async () => {
        //document.body.addEventListener('scroll', this.handlePagination)
        //document.body.addEventListener('scroll', this.scrollFunction)
        // window.onscroll = function() {this.scrollFunction()};
        //this.props.cancelGap();
        //this.props.setDisplayURL();
        //this.getBannerList()
        //await utils.loadTagList();
        //this.setState({ tagList: window.tagList?.eventList || [] })
        //this.getEvents()
    }
    componentWillUnmount = () => {
        document.body.removeEventListener('scroll', this.handlePagination)
        document.body.removeEventListener('scroll', this.scrollFunction)
        //this.props.addGap()
    }

    getEvents = async () => {
        this.setState({ isLoading: true })
        this.getAlreadyLiked()
        let temp_events = []
        // 根据tag获取events
        if (this.state.current_tag !== 0) {
            let current_tag = []
            current_tag.push(parseInt(this.state.current_tag))
            if (localStorage.getItem('user_type') && localStorage.getItem('user_type') <= 2) {
                await axios.post(utils.getUrl(`event/admin/query/`),
                    { tags: current_tag, start: this.state.start, end: this.state.end, order: '-time', status: [this.state.status] },
                    { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } }).then(response => {
                        temp_events = response.data.data.data
                        this.setState({ totalResults: response.data.data.count })
                    }).catch(err => {
                        alert(err)
                    })
            } else {
                await axios.post(utils.getUrl(`event/query/`),
                    { tags: current_tag, start: this.state.start, end: this.state.end, order: '-time' }).then(response => {
                        temp_events = response.data.data.data
                        this.setState({ totalResults: response.data.data.count })
                    }).catch(err => {
                        // alert(err)
                    })
            }
        } else {
            if (localStorage.getItem('user_type') && localStorage.getItem('user_type') <= 2) {
                await axios.post(utils.getUrl(`event/admin/query/`),
                    { start: this.state.start, end: this.state.end, order: '-time', status: [this.state.status] },
                    { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } }).then(response => {
                        temp_events = response.data.data.data
                        this.setState({ totalResults: response.data.data.count })
                    }).catch(err => {
                        alert(err)
                    })
            } else {
                await axios.post(utils.getUrl(`event/query/`),
                    { start: this.state.start, end: this.state.end, order: '-time' }).then(response => {
                        temp_events = response.data.data.data
                        this.setState({ totalResults: response.data.data.count })
                    }).catch(err => {
                        // alert(err)
                    })
            }

        }

        // transform to 2d array
        const rows = temp_events.reduce(function (rows, key, index) {
            return (index % 2 == 0 ? rows.push([key])
                : rows[rows.length - 1].push(key)) && rows;
        }, [])
        await this.setState({ eventsList: this.state.eventsList.concat(rows), isLoading: false, isSearched: true })
        console.log(this.state.eventsList)
        // scroll to previous position
        // const offset = document.querySelector('#events_body').getBoundingClientRect()
        // document.querySelector("body").scrollTo(0, scrollPosition?scrollPosition:this.state.scrollPosition)
        // this.setState({scrollPosition: offset.height})
    }

    scrollFunction = async () => {
        var mybutton = document.getElementById("myBtn");
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    handlePagination = async () => {

        if (this.state.isLoading) { return }
        // 滑到底部加载更多
        var top = document.querySelector("body").scrollTop
        var height = window.innerHeight
        var total = $(document).height()
        if (total - height - top > 100) {
            return
        }
        if (!this.state.isSearched) { return }
        if (this.state.end >= this.state.totalResults) {
            return false;
        }
        let currentStart = this.state.start
        let currentEnd = this.state.end
        await this.setState({ start: currentStart + pageSize, end: currentEnd + pageSize }, () => { this.getEvents() })
    }

    backtoTop = () => {
        document.querySelector("body").scrollTo(0, 0)
    }

    // 跳转广告管理界面
    forwardAdManage = (banner_id) => {
        this.props.history.push({ pathname: `/admanage/:${banner_id}` })
    }

    getBannerList = async () => {
        await axios.get(utils.getUrl('public/banners/list'))
            .then(response => {
                this.setState({ bannerList: response.data.data })
            }).catch(err => {
                // alert(err)
            })
    }

    handleCategorySelect = async (event, tag_id) => {
        document.querySelectorAll('#cateList > .filter-btn').forEach(node => {
            if (node !== event.target) {
                node.classList.remove("filter-btn-selected")
            } else {
                node.classList.add("filter-btn-selected")
            }
        })
        await this.setState({ current_tag: tag_id, start: 0, end: 10, scrollPosition: 0, eventsList: [] })
        this.getEvents()
    }

    handleAttend = (obj) => {
        let event_id = obj.metadata.event_id
        const url = `/event/detail/:${event_id}`
        this.props.history.push({ pathname: url })
    }

    handleLike = async (event_id) => {
        if (!localStorage.getItem('loginToken')) {
            alert('请先登录')
            return
        }
        await axios.post(utils.getUrl(`event/${event_id}/like/`),
            { 'like': true }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                let tempList = this.state.likedList
                tempList.push(event_id)
                this.setState({ likedList: tempList })
                alert('收藏成功')
            }).catch(err => {
                // alert(err)
            })

    }

    cancelLike = async (event_id) => {
        if (!localStorage.getItem('loginToken')) {
            alert('请先登录')
            return
        }
        if (!window.confirm('你确定要取消收藏吗？')) {
            return
        }
        await axios.post(utils.getUrl(`event/${event_id}/like/`),
            { 'like': false }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                let tempList = this.state.likedList
                tempList.splice(tempList.indexOf(event_id), 1)
                this.setState({ likedList: tempList })
                alert('取消收藏成功')
            }).catch(err => {
                // alert(err)
            })

    }

    getAlreadyLiked = async () => {
        if (!localStorage.getItem('loginToken')) {
            return
        }
        await axios.post(utils.getUrl(`event/query/`), { liked_by: parseInt(localStorage.getItem('user_id')) }, { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
            .then(response => {
                let dataList = response.data.data.data
                let tempList = []
                dataList.map(elem => { tempList.push(elem.event_id) })
                this.setState({ likedList: tempList })
            }).catch(err => {
                // alert(err)
            })
    }

    forwardEventCreate = () => {
        if (!localStorage.getItem('loginToken')) {
            alert('请先登录！')
            return
        }
        this.props.history.push({ pathname: `/event/create/` })
    }

    handleAdminOrder = async (event) => {
        await this.setState({ status: parseInt(event.target.value), start: 0, end: pageSize, eventsList: [] })
        this.getEvents()
    }

    componentWillMount = async () => {
        document.title = "FindSelf找我 - 找我团";
    }

    openEventDetailPage = (event) => {
        /*
            let url = '/#/event/detail/' + event
            window.open(url);
        */
       alert('暂无详情页 请点击报名查看')
    }

    openEventRegisterPage = (formUrl) => {
        if (this.state.formUrl !== formUrl) {
            this.setState({
                formUrl: formUrl
            })
        }
        if (formUrl === '') {
            alert('报名已结束')
            return
        }
        this.setState({
            showModal: true
        })
    }

    generateEventModule = (event) => {
        return (
            < div className = "row py-3" >
                <div className="col-md-3 d-flex justify-content-center">
                    <img src={cover} alt='cover_img' style={{ width: 180, height: 180, marginRight: 12 }}></img>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-12" style={{fontWeight:500, fontSize: 20, cursor: 'pointer'}}
                            onClick={() => this.openEventDetailPage(event.id)} >
                            {event.title}
                        </div>
                    </div>
                    <div className="row py-1" style={{ fontWeight: 300, fontSize: 14 }}>
                        <div className="col-6 col-md-5 ps-0">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="ms-3" color="#43B0B1" /> {event.location}
                        </div>
                        <div className="col-6 col-md-5 ps-0">
                            <FontAwesomeIcon icon={faPaperPlane} className="ms-3" color="#43B0B1" /> {event.registerStatus}
                        </div>
                    </div>
                    <div className="row pb-1" style={{ fontWeight:200, fontSize:14 }}>
                        <div className="col-2 col-md-12 col-lg-2 ps-md-3">简介: </div>
                        <div className="col-10 col-md-12 col-lg-10 ps-0 ps-md-3 ps-lg-0">
                            {event.shortDes}...
                            <div style={{ fontSize: 14, color: '#43B0B1', display: 'inline-block', cursor: 'pointer' }}
                                onClick={() => this.openEventDetailPage(event.id)}>查看详情</div>
                        </div>
                    </div>
                    <div className="row pb-1" style={{ fontWeight: 200, fontSize: 14 }}>
                        <div className="col-2 col-md-12 col-lg-2 ps-md-3">时间: </div>
                        <div className="col-10 col-md-12 col-lg-10 ps-0 ps-md-3 ps-lg-0">{event.schedule}</div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row justify-content-around py-2">
                        <div className="col-6 col-md-3">
                            {/*<button className="square-btn" style={{textAlign:'left'}}>
                                详情<FontAwesomeIcon icon={faHeart} className="ms-3"/>
                            </button>*/}
                            <button className="square-btn" onClick={()=>this.openEventDetailPage(event.id)}>详情</button>
                        </div>
                        <div className="col-6 col-md-3">
                            <button className="square-btn-solid" 
                                onClick={() => this.openEventRegisterPage(event.registerForm)}>报名</button>
                        </div> 
                    </div>
                </div>
            </div >
        )
    }

    render() {
        // GA - PageView - Start
        if (this.props.history) {
            let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
            ReactGA.pageview(pagePathForGA);
        }
        // GA - PageView - End
        return (
            <div className="container">
                {/* page title */}
                <h1 className="py-3" style={{ fontWeight: '400' }}>找我团</h1>
                {/* page head filter */}
                <div className="py-3" hidden>
                    <div className='filter-btn filter-btn-selected' onClick={(event) => this.handleCategorySelect(event, 0)} style={{ marginRight: '16px' }}>全部</div>
                    {this.state.tagList.map(tag => {
                        return (<div className='filter-btn' onClick={(event) => this.handleCategorySelect(event, tag.tag_id)} style={{ marginRight: '16px' }}>{tag.value_cn}</div>)
                    })}
                </div>
                {/* page main body */}
                <div className="row pt-3 pb-5">
                    {/* page body left */}
                    <div className="col-md-12">
                        {/* generate event modules based on event list */}
                        {this.state.eventsList.map(event => this.generateEventModule(event))}
                    </div>
                    {/* page body right */}
                    <div className="col-md-0" hidden>
                        <div className="row px-5 pb-3" style={{ textAlign: 'center', fontSize: 22, fontWeight: 300 }}>
                            <Card style={{ backgroundColor: '#FAFAFA', borderRadius: '3%' }}>
                                <Card.Body>
                                    <Card.Title>
                                        发起一项活动？
                                    </Card.Title>
                                    <Card.Text style={{ }}>
                                        除了参与活动，你也可以发起关于心理的活动，和志同道合的小伙伴一起交流
                                    </Card.Text>
                                    <input type='button' onClick={this.forwardEventCreate} className='square-btn' value='发起找我团'
                                        style={{ width:'100%', fontSize: 18, fontWeight: 200, backgroundColor: 'transparent' }}></input>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
                {/* Mike Form Modal */}
                <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                    <Modal.Body>
                        <iframe src={this.state.formUrl} title='报名表单' style={{ width: '100%', height: 600 }} ></iframe>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
