import React, {Component} from  'react'
import axios from "axios";
import utils from "./utils";

class ImageUpload extends Component {
    state = {
        cover_url: ""
    }
    uploadCover = (e) => {
        const files = e.target.files;
        if (!(files?.length)) {
            alert("请选择一个文件")
            return
        }
        if (files[0].size > 524288) {
            alert("文件必须小于5MB")
            return;
        }
        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append("ftype", files[0].name.split('.').reverse()[0]);
        axios.post(utils.getUrl('public/blog/imageUpload/'),
            formData,
            {
                headers: {
                    'Authorization': 'JWT ' + localStorage.getItem('loginToken'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(
            response => {
                const url = response.data.result[0].url;
                this.setState({cover_url: url})
                this.props?.onChange(url)
            }
        ).catch(err => {
            // alert(err);

        })
    }
    render() {
        return (
            <div>
                <div className="blog-cover-admin-container">
                    {this.state.cover_url ?
                        <img src={this.state.cover_url} className="blog-cover-img"/>
                        : <div className="blog-no-cover">NO IMAGE</div>
                    }
                </div>
                <div className="blog-cover-upload">
                    <button className="styleClass" onClick={() => {
                        document.getElementById('uploadCover').click()
                    }}>
                        Upload Image
                    </button>

                    <input type="file" accept=".png,.jpg" id="uploadCover" onChange={this.uploadCover}
                           style={{display: "None"}}/>
                    {this.state.cover_url ? <button className="blog-cover-delete" onClick={
                        () => this.setState({cover_url: ""})
                    }>Delete</button> : ""
                    }
                </div>
            </div>
        );
    }
}

export default ImageUpload;
