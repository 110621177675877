import React, {Component} from 'react'
import {ViewState} from '@devexpress/dx-react-scheduler';
import axios from 'axios';
import {
    Scheduler,
    WeekView,
    DateNavigator,
    TodayButton,
    Toolbar,
    Appointments,
    AppointmentTooltip

} from '@devexpress/dx-react-scheduler-material-ui';
import AppointmentLayout from './AppointmentLayout'
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import utils from './utils'
import classNames from 'clsx';
import { DateTime } from 'luxon';

const renderColor = (obj)=>{
   const {available, reserved} = obj
    if (!available) return 'grey'
    if (available && reserved) return 'blue'
    return 'green'
}
const Appointment = ({
                         children, style, ...restProps
                     }) => (
    <Appointments.Appointment
        {...restProps}
        style={{
            ...style,
            backgroundColor: renderColor(restProps.data),
            borderRadius: '8px',
        }}
    >
        {children}
    </Appointments.Appointment>
);

const style = ({palette}) => ({
    icon: {
        color: palette.action.active,
    },
    textCenter: {
        textAlign: 'center',
    },

    header: {
        height: '260px',
        backgroundSize: 'cover',
    },
    commandButton: {
        backgroundColor: 'rgba(255,255,255,0.65)',
    },
});

const IntervalArray = utils.getIntervalOptions()
const currentDate = '2018-11-01';
const schedulerData = [
    {startDate: '2018-11-01T09:45', endDate: '2018-11-01T11:00', title: 'Session', available:true,reserved:true},
    {startDate: '2018-11-01T12:00', endDate: '2018-11-01T13:30', title: 'Session', available: true, reserved: false},
    {startDate: '2018-11-01T13:50', endDate: '2018-11-01T14:30', title: 'Session', available: false},
];

const Header = withStyles(style, {name: 'Header'})(({
                                                        children, appointmentData, classes, ...restProps
                                                    }) => (
    <AppointmentTooltip.Header
        {...restProps}
        className="tool-header"
        appointmentData={appointmentData}
    >

    </AppointmentTooltip.Header>
));

const closeToolTip = (e)=>{
    window.$('.tool-header').find('button').click()
}

const CommandButton = withStyles(style, {name: 'CommandButton'})(({
                                                                      classes, ...restProps
                                                                  }) => (
    <AppointmentTooltip.CommandButton {...restProps} className={classes.commandButton}/>
));



class ScheduleConfig extends Component {
    state = {
        data : [],
        currentDate: new Date(),
        offset:0,
        excludeTimes:[],
        timezoneList: utils.timezoneList,
    }

    getUTCTimeString = (date)=>{
        date = utils.createDate(date)
        date = utils.addMinute(date,-this.state.offset * 60)
        return date.toISOString();
    }

    updateExcusion = async()=>{
        const payload = {
            start_times:this.state.excludeTimes
        }
        let url = utils.getUrl(`appointment/exclusion/${this.props.userId}/`)
        if(this.props.locationId && this.props.locationId!==''){
            url+='?location_id='+ this.props.locationId;
        }
        await axios.post(url,payload,utils.getAxiosConfig()).then(
            (response)=>{
                this.setState({excludeTimes:response.data.start_times})
                alert("Success")
            }
        ).catch(error=>{})
    }

    Content = withStyles(style, {name: 'Content'})(({
                                                        children, appointmentData, classes, ...restProps
                                                    }) => (
        <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
            <Grid container alignItems="center">
                {!appointmentData.booked?(appointmentData.available ? <button onClick={
                        (e) => {
                            this.setState((prvState)=>{
                                const lst = prvState.excludeTimes
                                lst.push(this.getUTCTimeString(appointmentData.startDate))
                                return {excludeTimes:lst}
                            },this.updateExcusion)
                            // appointmentData.reserved = true
                            closeToolTip(e)
                            appointmentData.available = false
                        }
                    }>设置此时段不可预约</button> :
                    <button onClick={
                        (e) => {
                            // appointmentData.reserved = false
                            const utc = this.getUTCTimeString(appointmentData.startDate)
                            
                            this.setState((prvState) => {
                                let lst = prvState.excludeTimes
                                lst = lst.filter(ele => utc != ele)
                                
                                return {
                                    excludeTimes:lst.filter(ele => utc != ele)
                                }

                            }, this.updateExcusion)
                            appointmentData.available = true
                            closeToolTip(e)
                        }
                    }>恢复为可预约
                    </button>):<div>
                    用户: {appointmentData.meta?appointmentData.meta.user_data.nick_name===''?appointmentData.meta.user_data.first_name + ' '+ appointmentData.meta.user_data.last_name: appointmentData.meta.user_data.nick_name: ''} <br/>
                    咨询方式: {appointmentData.meta?.method}<br/>
                    咨询原因: {appointmentData.meta?.tags[0]}<br/>
                    价格: {appointmentData.meta?.currency} {appointmentData.meta?.unit_price}<br/>
                </div>}
            </Grid>
        </AppointmentTooltip.Content>
    ));

    currentDateChange = (newDate)=>{
        this.setState({currentDate:newDate}, this.fetchData)

    }
    componentDidUpdate(prevProps) {
        if (this.props.locationId !== prevProps.locationId) {
            this.fetchData()
            this.fetchExclusion()
        }
    }
    updateTZ = ()=>{
        this.setState((prevState)=>{
            return {
                data:prevState.data.map(
                    (ele)=>({
                        ...ele,
                        startDate: utils.addMinute(ele.rawStartDate, this.state.offset * 60),
                        endDate: utils.addMinute(ele.rawEndDate, this.state.offset * 60),
                    })
                )
            }
        })
    }
    getMonday = ()=>{
        const t0 = utils.createDate(this.state.currentDate)
        return utils.addMinute(t0,-t0.getUTCDay()*24*60)
    }
    fetchExclusion = async() =>{
        let url = utils.getUrl(`appointment/exclusion/${this.props.userId}/`)
        if (this.props.locationId && this.props.locationId!=='') {
            url += '?location_id=' + this.props.locationId;
        }
        await axios.get(url,utils.getAxiosConfig()).then(
            (response)=>{
                this.setState({excludeTimes:response.data.start_times})
            }
        ).catch(err=>{})
    }
    fetchData = async()=>{
        let t0 = this.getMonday()
        t0 = utils.addMinute(t0,-7*24*60)
        const t1 = utils.addMinute(t0,14*24*60)
        let url = utils.getUrl(`appointment/schedule/${this.props.userId}/`)
        if (this.props.locationId && this.props.locationId!=='') {
            url += '?location_id=' + this.props.locationId;
        }
        await axios.post(url,
            {start_time:t0,end_time:t1,offset:0},utils.getAxiosConfig()).then(
            (response)=>{
                

                let lst = response.data.tickets.map((ele)=>{
                    const startDate = utils.parseLocalDateTimeString(ele.start_time.replace('Z',''));
                    const endDate = utils.addMinute(startDate,ele.length*30)
                    return {
                        rawStartDate: startDate,
                        rawEndDate: endDate,
                        startDate: utils.addMinute(startDate,this.state.offset * 60),
                        endDate: utils.addMinute(endDate, this.state.offset * 60),
                        title: "Reservation",
                        id: ele.ticket_id,
                        available: false,
                        meta:ele,
                        booked: true,
                    }
                })
                lst = lst.concat(response.data.intervals.map(
                    (ele, idx) => {
                        const startDate= utils.parseLocalDateTimeString(ele[0]);
                        const endDate = utils.parseLocalDateTimeString(ele[1]);
                        return {
                            rawStartDate: startDate,
                            rawEndDate: endDate,
                            startDate: utils.addMinute(startDate, this.state.offset * 60),
                            endDate: utils.addMinute(endDate, this.state.offset * 60),
                            title: "Session",
                            id: -idx,
                            available: !ele[2],
                            booked: ele[3],
                        }
                    }
                ).filter(ele => !ele.booked))
                this.setState(
                    {data:lst,

                    }
                )
            }
        ).catch(err=>{})
    }

    setTimeZone = (event)=>{
        if(event.target.value ==='0'){
            this.setState({offset: 0}, this.updateTZ)
            return
        }
        let timezone = event.target.value
        var local = DateTime.local()
        var rezoned = local.setZone(timezone)
        var offset = rezoned.offset/60
        this.setState({offset: offset}, this.updateTZ)
    }

    componentDidMount = ()=> {
        this.fetchData()
        this.fetchExclusion()
        let timezone = 'America/Detroit'
        var local = DateTime.local()
        var rezoned = local.setZone(timezone)
        var offset = rezoned.offset/60
        this.setState({offset: offset}, this.updateTZ)
        window.debug_d = this
    }



    render() {
        return <div className="schedule-appointment">
            <div style={{margin: '20px 0'}}>
                <div>显示时区</div>
                <select id='timezone' onChange={this.setTimeZone}>
                {this.state.timezoneList.map(elem=>
                  {if(elem.id==='America/Detroit'){return <option value={elem.id} selected>{elem.value_cn}</option>}
                      else{return <option value={elem.id}>{elem.value_cn}</option>}})
                }  
                </select>
                {/* <input type="number" value={this.state.offset} onChange={
                e => this.setState({offset: parseInt(e.target.value)},this.updateTZ)
            }/> */}
            </div>

            <Scheduler
                data={this.state.data}
                height={660}
            >
                <ViewState
                    currentDate={this.state.currentDate}
                    onCurrentDateChange={this.currentDateChange}
                />

                <Toolbar/>
                <DateNavigator/>
                <TodayButton/>

                <WeekView
                    startDayHour={0}
                    endDayHour={24}
                    // timeTableCell={TimeTableCell}
                    // dayScaleCell={DayScaleCell}
                />
                <Appointments
                    appointmentComponent={Appointment}
                />
                <AppointmentTooltip
                    headerComponent={Header}
                    contentComponent={this.Content}
                    commandButtonComponent={CommandButton}
                    showCloseButton
                    />
            </Scheduler>
        </div>
    }
}

ScheduleConfig.defaultProps = {
    showControl:false,
}
export default ScheduleConfig;

