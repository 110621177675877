import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import facebookLogo from '../assets/images/facebook.svg'


class OAuthFaceBook extends Component{
    constructor(props) {
        super(props);
        this.button = React.createRef();
    }
    state = {

    }
    reload = ()=>{
        this.setState({})
    }

    componentDidMount() {

        if (window?.FB ) {

        }
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '926893938146833',
                cookie: true,
                xfbml: true,
                version: 'v9.0'
            });

            window.FB.AppEvents.logPageView();

        };


        const facebook = document.createElement('script');
        facebook.id = 'facebook-jssdk'
        facebook.src = "https://connect.facebook.net/en_US/sdk.js";
        // const handler = this.props.onSignIn
        document.getElementsByTagName('head')[0].appendChild(facebook);

    }

    onLogin = (response)=>{
        // 
    }

    handleFaceBookLogin = ()=>{
        if (!window.FB){
            alert("Failed to Connect to Facebook, please retry later.")
            return
        }
        window.FB.login((response)=>{
            // window.FB.logout()

            this.props.onSignIn(response)
            window.FB.logout()
        },{
            auth_type:"reauthorize",
            enable_profile_selector:true})
    }

    render() {
        return <span ref={this.button} className="oauth-login-button oauth-facebook" onClick={this.handleFaceBookLogin}>
            <img src={facebookLogo} style={{width: '40px', height: '40px'}}/>
            {/* <span>用Facebook登陆</span> */}
        </span>
    }
}

OAuthFaceBook.defaultProps = {
    onSignIn: (response) => {
        // 
    }
}
export default OAuthFaceBook;
