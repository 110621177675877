/* 咨询师看自己主页视角下的页面 */
import React, { Component } from 'react'
import axios from 'axios'
import {Container, Row, Col, Alert, Modal, Button, Pagination, Tab, Nav, Card} from 'react-bootstrap'
import {GoogleMapFrame, GoogleMapAutoComplete} from "./GoogleMap";
import avatar from '../assets/website_logo.png'
import ProfileEdit from './userProfilePageEdit';
import ReactLoading from 'react-loading';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import AboutUsEdit from "./AboutUsEdit";
import utils from './utils'
import {loadStripe} from '@stripe/stripe-js'
import { withRouter, NavLink } from 'react-router-dom';
import CreateAppointment from "./createAppointment"
const keys = ['additional_info','advanced_credential','len_exp_hr',
'title','self_intro','first_name','last_name','payment_method',
    'tags','address','hourly_price','license','experience'
]
const pageSize= 5;
const parse = (html)=>{
  return <span dangerouslySetInnerHTML={{__html: html}}/>
}
class TherapistProfileEdit extends Component {

  state = {
    profile_id: null,
    user_id: null,
    additional_info: '',
    advanced_credential: '',
    len_exp_hr: 0,
    title: null,
    self_intro: '',
    gender: 0,
    first_name: '',
    last_name: '',
    email: '',
    email_sent: '',
    photo_url: null,
    payment_method: [],
    tags: [],
    address: [],
    hourly_price: [],
    license: [],
    experience: [],
    allTags: [],
    areaList: [],
    profile_status: 0,
    blogList: [],
    showModal: false,
    modalText: '',
    isPremium: 0,
    isLoading: false,
    isAppointmentLoading: false,
    totalClientsSubmitted: 0,
    totalClientsPaid: 0,
    totalClientsCompleted: 0,
    totalClientsCancelled: 0,
    current_ticket_id: '',
    isClientAppointmentLoading: false,
    blogDisplayNum: 3,
  }

  getCompletion = ()=>{
    return keys.filter((ele)=>{
      if(this.state[ele]?.length===0){
        return false
      }
      return !!(this.state[ele] || this.state[ele]===0)}).length / keys.length
  }

  editProfile = () => {
    // this.setState({displayEditPage: true})
    this.props.history.push(`/therapist/profile/:${this.state.user_id}/:${this.state.profile_id}`)
  }

  submitProfile = async() => {
    if(this.state.profile_status === 2){
      alert('提交成功！')
      return false
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/submit/`),
    {profile_status: 1}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('提交成功！')
    })
    .catch(err => {
      // alert(err)
    })
  }
  // load blog list
  showBlogs = async() => {
    this.setState({isBlogLoading: true})
    const tempData = {
      author: this.state.user_id,
      search_mode: 'all',
      type: [0],
      order: 'edit_date',
      start: 0, end: 100
    }
    await axios.post(utils.getUrl(`cms/blog/query/`),
    tempData)
    .then(response => {
      let tempBlog = response.data.data
      // const rows = tempBlog.reduce(function (rows, key, index) {
      //   return (index % 2 == 0 ? rows.push([key])
      //     : rows[rows.length-1].push(key)) && rows;
      // }, []);
      this.setState({blogList: tempBlog, blogCount: Math.floor(response.data.count/2)+1, isBlogLoading: false})
    })
    .catch(err => {
      // alert(err)
    })
  }
  // forward a specific blog page
  forwardBlog = (post_id) => {
    const url = utils.getUrl(`/blog/:${post_id}/show/`)
    this.props.history.push(url)
  }
  // open a therapist' s profile page
  openProfilePage = (profile_id, user_id) => {
    const url = `/therapist/view/:${profile_id}`
    this.props.history.push({pathname: url, state: {user_id: user_id}})
  }
  // forward blog create page
  forwardBlogCreate = () =>{
    this.props.history.push('/blog/create/')
  }

  componentDidMount = async() => {
    
    this.setState({isLoading: true})
    await utils.loadTagList()
    let areaList = window.tagList.areaList
    areaList.map(elem=>{
      if(elem.value_cn === "其他\r"){
        let index = areaList.indexOf(elem)
        areaList.push(areaList.splice(index, 1)[0])
      }
    })
    this.setState({allTags: window.tagList.allTags, areaList})
    // set profile id
    this.setState({ profile_id: parseInt(localStorage.getItem('profile_id')), user_id: parseInt(localStorage.getItem('user_id')) })
    // get info
    await axios.get(utils.getUrl(`public/${this.state.profile_id}/homepage/`), { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
      .then((response) => {
        const data = response.data
        this.setState({
          additional_info: data.additional_info, advanced_credential: data.advanced_credential, len_exp_hr: data.len_exp_hr,
          title: data.title, self_intro: data.self_intro, to_visitor: data.to_visitor, gender: data.user.gender, first_name: data.user.first_name, last_name: data.user.last_name,
          email: data.user.email, verified_phone: data.user.verified_phone, photo_url: data.user.photo_url,
          payment_method: data.payment_method, tags: data.identification.tags, address: data.address, hourly_price: data.hourly_price,
          license: data.license, experience: data.experience, profile_status: data.profile_status, isPremium: data.premium_status, isLoading: false
        })
        if (data.invitation) {
          let formatted_date = new Date(data.invitation.email_sent)
          formatted_date = formatted_date.getFullYear() + '-' + (formatted_date.getMonth() + 1) + '-' + formatted_date.getDate() + ' ' + formatted_date.getHours() + ':' + formatted_date.getMinutes()
          this.setState({ first_name: data.invitation.first_name, last_name: data.invitation.last_name, email: data.invitation.email, email_sent: formatted_date })
        }
      }).catch(err => {
        // alert(err)
      })
    this.showBlogs()
  }

  setActiveKey = (newKey) => {
    this.setState({activeKey: newKey})
  }

  getFormattedDate = (datestring) => {
    let date = new Date(datestring)
    return date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()
  }

  initClientAppointmentNumber = async()=>{
    // submitted
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: [0]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalClientsSubmitted: data.count})
    }).catch(err => {
      // alert(err)
    })
    // completed
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: [2]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalClientsCompleted: data.count})
    }).catch(err => {
      // alert(err)
    })
    // paid
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: [4]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalClientsPaid: data.count})
    }).catch(err => {
      // alert(err)
    })
    //cancelled
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: [3]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalClientsCancelled: data.count})
    }).catch(err => {
      // alert(err)
    })
  }

  loadClientAppointments = async(status, event)=>{
    let current_status = [];
    if(status && event){
      current_status.push(parseInt(status))
      let tempList = document.querySelectorAll('.client-nav-btns input')
      tempList.forEach(node=>{
          node.classList.remove('events-btn-3')
          node.classList.add('events-btn-2')
      })
      event.target.classList.remove('events-btn-2')
      event.target.classList.add('events-btn-3')
    }else{
      current_status.push(4) //default paid
    }
    this.setState({isClientAppointmentLoading: true})

    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: current_status}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      
      this.setState({clientList_display: data.data, isClientAppointmentLoading: false})
    }).catch(err => {
      alert(err)
    })
    
  }

  changeAppointmentStatus = async(status, ticket_id) => {
    await axios.post(utils.getUrl(`appointment/ticket/update/${ticket_id}/`),
    {status: parseInt(status)}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response =>{
      
    }).catch(err=>{})
  }

  setRefund = (ticket_id)=>{
    var element = document.getElementById('refund'+ticket_id)
    if(element.style.display === 'block'){
      element.style.display = 'none'
    }else{
      element.style.display = 'block'
    }
    this.setState({current_ticket_id: ticket_id})
  }

  submitRefund = async()=>{
    
    await axios.post(utils.getUrl(`appointment/ticket/refund/${this.state.current_ticket_id}/`), 
    {reason: this.state.refund_reason}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response=>{
      
      alert('已收到您的退款申请')
      this.setState({displayRefund: false})
    }).catch(err => {alert(err)})
  }

  handlePayment = async(ticket_id)=>{
    alert('正在为您跳转登录界面...')
    await axios.post(utils.getUrl(`appointment/ticket/pay/${ticket_id}/`),
    {pay: 'stripe'}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response=>{
      
      this.setState({stripe_id: response.data.id})
    })
    const stripe = await loadStripe('pk_test_51IQpgLL7zkzQauLdX7nHj4IPTexbTMvBWEkBn0818Wv6Frjx9di1n3SKnZ9IlHAm1nmbvX310mgs2TBpJaURXz0v00Q57JW2jl')
    const result = await stripe.redirectToCheckout({
        sessionId: this.state.stripe_id,
    })
    if (result.error) {
        alert('支付错误')
    }
  }

  render() {
    let user_avatar = avatar
    if(this.state.photo_url){user_avatar = this.state.photo_url}
    let title
    if(this.state.title === 1){title = 'Dr. '}
    else if(this.state.title === 2){title = 'Prof. '}
    else{title = ''}
    let gender
    if(this.state.gender === 1){gender = '男'}
    else if(this.state.gender === 2){gender = '女'}
    else if(this.state.gender === 3){gender = '其他'}
    else{gender = '未知'}
    let paymentList = []
    this.state.payment_method.map(payment => {
      if(payment === 500001){paymentList.push('Visa')}
      else if(payment === 500002){paymentList.push('Apple Pay')}
    })
    let tempArea = []
    let tempGroup = []
    let tempRole = []
    let tempLang = []
    this.state.tags.map(tag => {
      let tag_value = ''
      // search for string value
      this.state.allTags.map(elem=>{
        if(tag === elem.tag_id){
          tag_value = elem.value_cn
        }
      })
      // 分类tags
      var cate = ((""+tag).split(""))[0]
      if(cate === '2'){
        tempArea.push(tag_value)
      }else if(cate === '3'){
        tempGroup.push(tag_value)
      }else if(cate === '1'){
        tempRole.push(tag_value)
      }else if(cate === '7'){
        tempLang.push(tag_value)
      }
    })
    let tempCity = []
    if(this.state.isLoading){
      return (<div style={{paddingLeft: '45%'}}>
        <ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/>
        <div style={{height: '400px'}}></div>
      </div>)
    }else {
      return (
        <div>
                      <div>
                        {/* alert */}
                        <div>
                          {
                            (() => {
                              switch (this.state.profile_status) {
                                case 0:
                                return (<Alert variant='warning'>
                                    This profile has been saved but not yet submitted
                                  </Alert>)
                                case 1: return (<Alert variant='warning'>
                                    This profile has been submitted but not yet approved
                                  </Alert>)
                                case 2:  return (<Alert variant='success'>
                                    This profile has been approved!
                                  </Alert>)
                                case 3:  return (<Alert variant='danger'>
                                    This profile has been denied and needs to be edited before submit again!
                                  </Alert>)
                                case 4:  return (<Alert variant='danger'>
                                    This profile has been frozen, please contact the administrator for more information
                                  </Alert>)
                                case 5: return (<Alert variant='warning'>
                                    This is a invited therapist profile that needs further information, invite email has been sent to <span style={{fontWeight: 'bold'}}>{this.state.email}</span> at <span style={{fontWeight: 'bold'}}>{this.state.email_sent}</span>
                                  </Alert>)
                                default: return (<div></div>);
                              }
                            })()
                          }
                        </div>
                        {/* buttons */}
                        <div class="btn-group mb-4">
                            <a>
                              <input id="search-btn" class="btn btn-primary me-2" onClick={this.editProfile} type="submit" value="编辑"/>
                            </a>
                            <a>
                              <input id="search-btn" class="btn btn-secondary" onClick={this.submitProfile} type="submit" value="提交"/>
                            </a>
                        </div>
                      </div>
                      <div>
                        <div>
                        {
                            this.state.isPremium===1?(<Alert variant='primary' style={{backgroundColor: '#cce5ff'}}>
                                    认证青年咨询师<svg t="1610943117593" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="813" width="16" height="16"><path d="M922.026667 561.493333a136.533333 136.533333 0 0 1 0-98.986666l7.253333-19.626667a139.52 139.52 0 0 0-72.96-177.066667L837.12 256A141.226667 141.226667 0 0 1 768 186.88l-8.533333-19.2a139.52 139.52 0 0 0-177.066667-72.96l-19.626667 7.253333a136.533333 136.533333 0 0 1-98.986666 0l-19.626667-7.253333a139.52 139.52 0 0 0-178.346667 72.96L256 186.88A141.226667 141.226667 0 0 1 186.88 256l-19.2 8.533333a139.52 139.52 0 0 0-72.96 177.066667l7.253333 19.626667a136.533333 136.533333 0 0 1 0 98.986666l-7.253333 19.626667a139.52 139.52 0 0 0 72.96 177.066667l19.2 8.533333A141.226667 141.226667 0 0 1 256 837.12l8.533333 19.2a139.52 139.52 0 0 0 177.066667 72.96l19.626667-7.253333a136.533333 136.533333 0 0 1 98.986666 0l19.626667 7.253333a139.52 139.52 0 0 0 177.066667-72.96l8.533333-19.2A141.226667 141.226667 0 0 1 837.12 768l19.2-8.533333a139.52 139.52 0 0 0 72.96-177.066667z m-224.426667-146.346666l-239.786667 239.786666a20.48 20.48 0 0 1-29.866666 0L326.4 554.666667a21.333333 21.333333 0 0 1 0-30.293334l22.613333-22.613333a20.48 20.48 0 0 1 29.866667 0l64 63.573333 202.24-202.24a21.333333 21.333333 0 0 1 29.866667 0l22.613333 22.613334a21.333333 21.333333 0 0 1 0 29.44z" p-id="814"></path></svg>
                                  </Alert>):(<div></div>)
                          }
                        </div>
                        <div>
                          {/* left content */}
                          <div md={12} className="fixed-flow-wrapper">
                          <div>
                            <div class="mb-2">
                                <span class="fs-3 box-shadow">基本信息</span>
                            </div>
                            <div className="profile-completion">已填写{Math.ceil(this.getCompletion()*100)}% </div>
                            <div style={{marginBottom: '20px'}} className='flex-block'>
                              {/* 头像 */}
                              <div style={{ paddingRight: '10px', paddingTop: '10px'}}>
                                <div>
                                <img className="profile-avatar" src={user_avatar} alt="user_avatar" style={{
                                  width: '80px',
                                  height: 'auto',
                                  border: '1px solid #F4B9A6',
                                  borderRadius: '200px'
                                }}></img>
                                </div>
                              </div>
                              {/* 基本信息 */}
                              <div>
                                <div>
                                    <h6>{title}{this.state.first_name} {this.state.last_name}, {this.state.advanced_credential}</h6>
                                </div>
                                <div>性别: {gender}</div>
                                <div>咨询经验: {this.state.len_exp_hr} 小时</div>
                                <div>简介: {this.state.self_intro}</div>
                                <div>付款方式: {paymentList.map(payment => {
                                  return (paymentList.indexOf(payment)===paymentList.length-1?
                                  payment:payment+'/')
                                })}</div>
                                <div>语言: {tempLang.map(lang => {
                                  return (tempLang.indexOf(lang)===tempLang.length-1?
                                  lang:lang+'/')
                                })}</div>
                              </div>
                            </div>
                            <div>
                              <div>
                                {/* 给来访者的话 */}
                                <div class="mb-4">
                                  <div><h6>给来访者的话</h6></div>
                                  <div>{this.state.to_visitor}</div>
                                </div>
                                {/* 咨询师身份 */}
                                <div class="mb-4">
                                  <div><h6>咨询师身份</h6></div>
                                  <div> {tempRole.map(role => (role + ' '))}</div>
                                </div>
                                {/* 擅长领域 */}
                                <div class="mb-4">
                                  <div><h6>擅长领域</h6></div>
                                  <div> {tempArea.map(area => (area + ' '))}</div>
                                </div>
                                {/* 面向群体 */}
                                <div class="mb-4">
                                  <div><h6>面向群体</h6></div>
                                  <div> {tempGroup.map(group => (group + ' '))}</div>
                                </div>
                                {/* 额外信息 */}
                                <div class="mb-4">
                                  <div><h6>额外信息</h6></div>
                                  <div> {this.state.additional_info}</div>
                                </div>
                              </div>
                            </div>
                          </div></div>
                          {/* right content */}
                          <div>
                            <div class="mb-2">
                                <span class="fs-3 box-shadow">详细信息</span>
                            </div>
                            <div className="mainpage_info">
                              <div>
                                {/* 相关执照 */}
                                <div class="mb-4">
                                  <div><h6>相关执照</h6></div>
                                
                                {
                                  this.state.license.map(elem => {
                                  let isPassed
                                  if(elem.has_passed){isPassed = '是'}
                                  else(isPassed = '获取中')
                                  let tag_value = ''
                                  this.state.allTags.map(tag=>{
                                    if(elem.mental_health_role === tag.tag_id){
                                      tag_value = tag.value_cn
                                    }
                                  })
                                  return (<div>
                                            <div> 证书编号：{elem.license_number}</div>
                                            <div> 咨询师身份：{tag_value}</div>
                                            <div> 是否已经获取：{isPassed}</div>
                                            <div> 证书所在州：{elem.license_state}</div>
                                            <div> 证书过期日：{elem.license_expiration}</div>
                                            <div> 督导师姓名：{elem.supervisor_name}</div>
                                            <div> 督导师执照编号：{elem.supervisor_license}</div>
                                          </div>)
                                })}
                                </div>
                                {/* 教育经历 */}
                                <div class="mb-4">
                                    <div><h6>教育经历</h6></div>
                                  {
                                    this.state.experience.map(exp => {
                                      if(exp.experience_type !== 1){return null}
                                      return (
                                        <div>
                                          <div>经历名称：{exp.experience_name}</div>
                                          <div>经历描述：{exp.description}</div>
                                          <div>经历时间：{exp.start_date} ~ {exp.end_date}</div>
                                        </div>
                                      )
                                    })
                                  }
                                  </div>
                                  {/* 工作经历 */}
                                  <div class="mb-4">
                                    <div><h6>工作经历</h6></div>
                                  {
                                    this.state.experience.map(exp => {
                                      if(exp.experience_type !== 2){return null}
                                      return (
                                        <div>
                                          <div>经历名称：{exp.experience_name}</div>
                                          <div>经历描述：{exp.description}</div>
                                          <div>经历时间：{exp.start_date} ~ {exp.end_date}</div>
                                        </div>
                                      )
                                    })
                                  }
                                  </div>
                                  {/* 培训经历 */}
                                  <div class="mb-4">
                                    <div><h6>培训经历</h6></div>
                                  {
                                    this.state.experience.map(exp => {
                                      if(exp.experience_type !== 3){return null}
                                      return (
                                        <div>
                                          <div>经历名称：{exp.experience_name}</div>
                                          <div>经历描述：{exp.description}</div>
                                          <div>经历时间：{exp.start_date} ~ {exp.end_date}</div>
                                        </div>
                                      )
                                    })
                                  }
                                  </div>
                                  {/* 咨询费用 */}
                                  <div class="mb-4">
                                    <div><h6>咨询费用</h6></div>
                                  {
                                    this.state.hourly_price.map((price=>{
                                      let contact_method = ''
                                      if(price.contact_method === 0){contact_method = '未知'}
                                      else if(price.contact_method === 1){contact_method = '语音'}
                                      else if(price.contact_method === 2){contact_method = '视频'}
                                      else if(price.contact_method === 3){contact_method = '线下'}
                                      return (
                                        <div>
                                          <div>价格范围：{price.price_value_low} ~ {price.price_value_high} {price.currency}</div>
                                          <div>咨询方式：{contact_method}</div>
                                        </div>
                                      )
                                    }))
                                  }
                                  </div>
                                  {/* 预约须知 */}
                                    <div class="mb-4">
                                    <div><h6>预约须知</h6></div>
                                    <div>
                                      <AboutUsEdit src_id="booking_note"/>
                                    </div>
                                    </div>
                              </div>
                                {/* 联系方式 */}
                                <div class="mb-4">
                                  <div><h6>联系方式</h6></div>
                                {this.state.address.map(elem => {
                                      if(!elem.city && !elem.state && !elem.country){return null}
                                      else{
                                        return (<div>
                                                  <div style={{fontWeight:'bold'}}>{elem.address_name}</div>
                                                  <div>{elem.street_1} {elem.street_2}</div>
                                                  <div>{elem.city}, {elem.state}, {elem.country} {elem.zip_code}</div>
                                                  <div>email: {elem.email_address}</div>
                                                  <div>phone: {elem.phone_number}</div>
                                                {!!elem.latitude?
                                                  <div>
                                                    <GoogleMapFrame lat={elem.latitude} lng={elem.longitude} height={300} width="100%" zoom={12}></GoogleMapFrame>
                                                  </div>:
                                                    ""
                                                }
                                                </div>)
                                      }
                                })}
                              </div>
                            </div>
                            {/* 我的文章 */}
                            <div className="profile-subtitle">
                              <div class="mb-2">
                                <span class="fs-3 box-shadow">我的文章</span>
                              </div>
                              <div>
                                      <a>
                                        <input id="search-btn" class="btn btn-secondary" onClick={this.forwardBlogCreate} type="submit" style={{backgroundColor: 'rgb(94, 207, 208)', height: '40px', lineHeight: '18px'}} value="创建文章"/>
                                      </a>
                              </div>
                            </div>
                            <div className='profile-body-block' style={{borderLeft: 'none'}}>
                              <div className='flex-block4'>
                              {this.state.blogList.length === 0?(<div></div>):
                                this.state.blogList.map((blog, index)=>{
                                  if(index >= this.state.blogDisplayNum){return}
                                  return <div style={{width: 550, height: 122.5, marginBottom: 16}} key={'blog'+index} className='flex-block'>
                                    <div style={{marginRight: 24}}><img src={blog.cover_url?blog.cover_url:avatar} alt='blog_cover' style={{width: 120, height: 120}}></img></div>
                                    <div className='flex-block4'>
                                      <div style={{fontWeight: '400', fontSize: 16, lineHeight: '23px' }}>{blog.subject}</div>
                                      <div className='blog-des'>{blog.description}</div>
                                      <div><NavLink to={`/blog/:${blog.post_id}/show/`} className='navlink' style={{color: '#43B0B1', textDecoration: 'none'}}>阅读更多</NavLink></div>
                                    </div>
                                  </div>
                                })
                              }
                              </div>
                            </div>
                            {/* <CarouselProvider
                              naturalSlideWidth={1050}
                              naturalSlideHeight={150}
                              totalSlides={this.state.blogCount}
                              infinite={true}
                              hasMasterSpinner={true}
                              style={{height: '180px'}}
                              className='blog-carousel'
                              >
                              <div md={1} className='blog-carousel-btn' style={{marginTop: '5%'}}><ButtonBack>{'<'}</ButtonBack></div>
                              <div md={10} className='blog-slide-row'>
                              <Slider>
                              {
                                this.state.blogList.map(blog => {
                                  if(!blog[1]){
                                  let blog_date1 = new Date(blog[0].post_date)
                                  blog_date1 = blog_date1.getFullYear()+'-' + (blog_date1.getMonth()+1) + '-'+blog_date1.getDate()+' '+blog_date1.getHours()+':'+blog_date1.getMinutes()
                                  return(
                                    <Slide>
                                    <div style={{marginBottom: '10px'}}>
                                    <div md={6} className='profile_blog' onClick={()=>this.forwardBlog(blog[0].post_id)}>
                                      <div md={4}><Image src={blog[0].cover_url?blog[0].cover_url:avatar} alt='blog_cover' className='blog_cover' hasMasterSpinner={true}></Image></div>
                                      <div md={8}>
                                        <div className='blog-des' style={{fontWeight: 'bold'}}>{blog[0].subject}</div>
                                        <div className='blog-des'>{blog[0].description}</div>
                                        <div className='blog-des' style={{color: '#707070'}}>{blog_date1}</div>
                                        <div style={{color: '#7070705d'}}>访问量：{blog[0].visits}</div>
                                      </div>
                                    </div>
                                  </div>
                                  </Slide>)
                                  }else{
                                    let blog_date1 = new Date(blog[0].post_date)
                                    let blog_date2 = new Date(blog[1].post_date)
                                    blog_date1 = blog_date1.getFullYear()+'-' + (blog_date1.getMonth()+1) + '-'+blog_date1.getDate()+' '+blog_date1.getHours()+':'+blog_date1.getMinutes()
                                    blog_date2 = blog_date2.getFullYear()+'-' + (blog_date2.getMonth()+1) + '-'+blog_date2.getDate()+' '+blog_date2.getHours()+':'+blog_date2.getMinutes()
                                    return(
                                    <Slide>
                                    <div style={{marginBottom: '10px'}}>
                                    <div md={6} className='profile_blog' onClick={()=>this.forwardBlog(blog[0].post_id)}>
                                      <div md={4}><Image src={blog[0].cover_url?blog[0].cover_url:avatar} alt='blog_cover' className='blog_cover' hasMasterSpinner={true}></Image></div>
                                      <div md={8}>
                                        <div className='blog-des' style={{fontWeight: 'bold'}}>{blog[0].subject}</div>
                                        <div className='blog-des'>{blog[0].description}</div>
                                        <div className='blog-des' style={{color: '#707070'}}>{blog_date1}</div>
                                        <div style={{color: '#7070705d'}}>访问量：{blog[0].visits}</div>
                                      </div>
                                    </div>
                                    <div md={6} className='profile_blog' onClick={()=>this.forwardBlog(blog[1].post_id)}>
                                      <div md={4}><Image src={blog[1].cover_url?blog[1].cover_url:avatar} alt='blog_cover' className='blog_cover' hasMasterSpinner={true}></Image></div>
                                      <div md={8}>
                                        <div className='blog-des' style={{fontWeight: 'bold'}}>{blog[1].subject}</div>
                                        <div className='blog-des'>{blog[1].description}</div>
                                        <div className='blog-des' style={{color: '#707070'}}>{blog_date2}</div>
                                        <div style={{color: '#7070705d'}}>访问量：{blog[1].visits}</div>
                                      </div>
                                    </div>
                                  </div>
                                  </Slide>)
                                  }
                                })
                              }
                              </Slider>
                              </div>
                              <div md={1} className='blog-carousel-btn' style={{marginTop: '5%'}}><ButtonNext>{'>'}</ButtonNext></div>
                              <div className='blog-carousel-btn-2'>
                                <div md={2}></div>
                                <ButtonBack>{'<'}</ButtonBack><ButtonNext>{'>'}</ButtonNext>
                              </div>
                              </CarouselProvider> */}
                            </div>
                        </div>
                      </div>
                    </div>
      )
    }
      
  }

}

export default withRouter(TherapistProfileEdit)