import React, {Component} from 'react'
import axios from 'axios'
import {Container, Row, Col} from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import utils from './utils'
const categoryIdx = {
    roleList:1,
    areaList:2,
    groupList:3,
    methodologyList:4,
    paymentList:5,
    qAndAList:6,
    langList:7,
    eventList:8,
    eventMethodList:9,
}

const dataList = [
    {
        name:"roleList",
        desc:"咨询师身份/ROLE"
    },
    {
        name: "areaList",
        desc: "擅长领域/SPECIALITY"
    },
    {
        name: "groupList",
        desc: "面向群体/CLIENT"
    },
    {
        name: "methodologyList",
        desc: "治疗流派/SCHOOL"
    },
    {
        name: "paymentList",
        desc: "付款方式/PAYMENT"
    },
    {
        name: "qAndAList",
        desc: "Q&A 分类",
        showOrder:true,
    },
    {
        name: "langList",
        desc: "语言/language",
    },
    {
        name:"eventList",
        desc: "找我团",
    },
    {
        name:"eventMethodList",
        desc: "活动方式",
    }
]
export default class TagEdit extends Component{
    state = {
        allTags : [],
        roleList: [],
        areaList: [],
        groupList: [],
        qAndAList:[],
        methodologyList: [],
        langList:[],
        eventList:[],
        paymentList: [],
        eventMethodList:[]
    }
    updateTags = async()=>{
        await utils.loadTagList(true);
        if(window.tagList){
            this.setState(window.tagList)
        }
    }
    componentDidMount() {
        this.updateTags();
    }

    getRequestList = (lstName,showOrder)=>{
        const idx= categoryIdx[lstName]
        let requests = []
        this.state[lstName].filter((e) => !(e.deleted && !e.tag_id)).map((ele)=>{
            if (ele.deleted){
                if(ele.tag_id){
                    requests.push({
                        action: "delete",
                        value:"",
                        value_cn:"",
                        category: idx,
                        tag_id:ele.tag_id
                    })
                }
            }
            else if (ele.action == "add"){
                requests.push({
                    action:"create",
                    value:ele.new_value.trim(),
                    value_cn: ele.new_value_cn.trim(),
                    priority: ele.new_priority || ele.priority || 0,
                    category:idx
                })
            } else if ((ele.new_value && ele.new_value.trim() != ele.value.trim()) ||
                (ele.new_value_cn && ele.new_value_cn.trim() != ele.value_cn.trim()) ||
                (ele.new_priority && ele.new_priority != ele.priority)
            ){
                requests.push({
                    action: "update",
                    value: ele.new_value?.trim() || ele.value,
                    value_cn: ele.new_value_cn?.trim() || ele.value_cn,
                    priority: ele.new_priority || ele.priority ||0,
                    category: idx,
                    tag_id: ele.tag_id
                })
            }
        })
        return requests
    }

    handleSave = async (lstName,showOrder=false) =>{
        const payload = {data:this.getRequestList(lstName,showOrder)}
        if (!payload.data){
            alert('没有变化')
            return
        }
        await axios.post(utils.getUrl("tags/update/"), payload, {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(
            (response)=>{
            alert("成功！")
            }
        ).catch((err)=>{
            // alert(err)
        })
        await axios.get(utils.getUrl(`tags/list/?tag_type=${categoryIdx[lstName]}`)).then(
            (response)=>{
                this.setState({[lstName]:response.data.results})
            },()=>{}
        ).catch((err) => {
            // alert(err)
        })
    }



    getRows = (lstName,showOrder=false)=>{
        return <Table striped bordered hover size="sm">
            <thead>
            <td>Id</td>
            <td>English</td>
            <td>Chinese</td>
            <td>Priority</td>
            {
                showOrder?<td>Priority</td>:""
            }
            </thead>
            {
                this.state[lstName].filter((e)=>(!(e.deleted && !e.tag_id))).sort((a,b)=>{
                    return (a.tag_id || 9000000 )- (b.tag_id || 9000000)}).map((ele) => {
                    return <tr className={ele.deleted ? "deleted":""}>
                        <td>{ele.action!="add"?ele.tag_id:""}</td>
                        <td>
                            <input type="text" className={(ele.new_value && ele.new_value.trim()!=ele.value.trim())?"edited":""} value={ele.new_value === undefined?ele.value: ele.new_value} onChange={(e)=>{
                                //
                                this.setState(
                                (prevState)=>({
                                    [lstName]:prevState[lstName].map(
                                        (tag)=> tag.tag_id== ele.tag_id?{...tag, new_value:e.target.value}:tag
                                    )
                                })
                            )}}/></td>
                        <td><input type="text" className={((ele.new_value_cn && ele.new_value_cn.trim() !== ele.value_cn.trim())) ? "edited" : ""} value={ele.new_value_cn === undefined?ele.value_cn: ele.new_value_cn} onChange={(e) => {
                            //
                            this.setState(
                                (prevState) => ({
                                    [lstName]: prevState[lstName].map(
                                        (tag) => tag.tag_id == ele.tag_id ? {...tag, new_value_cn: e.target.value} : tag
                                    )
                                })
                            )
                        }}/></td>
                        {
                            showOrder ? <td><input type="number"
                                                   className={((ele.new_priority && ele.new_priority !== ele.priority)) ? "edited" : ""}
                                                   value={ele.new_priority === undefined ? ele.priority : ele.new_priority}
                                                   onChange={(e) => {
                                                       //
                                                       this.setState(
                                                           (prevState) => ({
                                                               [lstName]: prevState[lstName].map(
                                                                   (tag) => tag.tag_id == ele.tag_id ? {
                                                                       ...tag,
                                                                       new_priority: e.target.value
                                                                   } : tag
                                                               )
                                                           })
                                                       )
                                                   }}/></td> : ""
                        }
                        <td>
                            <button onClick={(e)=>{
                                ele.deleted = !ele.deleted;

                this.setState({[lstName]: this.state[lstName]})
            }}>{!ele.deleted?"delete":"revert"}</button>
                        </td>
                    </tr>
                })
            }
            <tr>
                <td></td>
                <td><input type="text" className="value"/></td>
                <td><input type="text" className="value_cn"/></td>
                {showOrder? <td><input type="number" className="priority" /></td>:""}
                <td>
                    <button onClick={(e) => {
                        const obj = {
                            tag_id : 10000+parseInt(Math.random()*225500),
                            action: "add",
                            new_value: window.$(e.target).closest('tr').find('.value').val(),
                            new_value_cn: window.$(e.target).closest('tr').find('.value_cn').val(),
                            value:'',
                            value_cn:'',
                            priority:showOrder? window.$(e.target).closest('tr').find('.priority').val() ||0 :0

                        }
                        if (!(obj.new_value && obj.new_value_cn)){
                            alert("Cannot have empty strings!")
                            return
                        }
                        window.$(e.target).closest('tr').find('.value').val("")
                        window.$(e.target).closest('tr').find('.value_cn').val("")
                        // 
                        this.state[lstName].push(obj)
                        this.setState({[lstName]: this.state[lstName]})
                    }}>add
                    </button></td>
            </tr>
        </Table>
    }


    render(){
       return (
           <div> <Container fluid>
               <h3 style={{fontWeight: 'bold', fontSize: '22px', textAlign: 'center'}}>标签管理</h3>
           </Container>
        <Container className="tag-edit-container">
           <div  className="tag-edit-table-container">
           {
               dataList.map((ele)=>
                   <Row md={12} sm={12} className="tag-edit-item">
                   <h4 >
                       {ele.desc}
                   </h4>
                   {
                       this.getRows(ele.name,ele.showOrder)
                   }
                       <div class="button-row">
                   <button onClick={(e) => this.handleSave(ele.name,ele.showOrder)}>Submit</button>
                       </div>
                   </Row>
               )
           }
           </div>
       </Container></div>
          )
    }
}
