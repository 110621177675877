/* 其他用户看咨询师主页视角下的页面 */
import React, { Component } from 'react'
import axios from 'axios'
import { Container, Row, Col, Alert, Modal, Tooltip} from 'react-bootstrap'
import {GoogleMapFrame} from "./GoogleMap";
import avatar from '../assets/website_logo.png'
import quote_left from '../imgs/quote_left.svg'
import quote_right from '../imgs/quote_right.svg'
import {NavLink} from 'react-router-dom'
import ReactLoading from 'react-loading';
import 'pure-react-carousel/dist/react-carousel.es.css';
import AboutUsEdit from "./AboutUsEdit";
import map_img from '../imgs/blur_map.png'
import utils from './utils'
import moment from 'moment'
import {loadStripe} from '@stripe/stripe-js'
import LogInContent from './login';
import $ from 'jquery'

import '../css/therapist-profile-view.css'

export default class TherapistProfileView extends Component {

  state = {
    profile_id: '',
    user_id: '',
    additional_info: '',
    advanced_credential: '',
    len_exp_hr: 0,
    title: '',
    self_intro: '',
    to_visitor: '',
    gender: 0,
    email: '',
    email_sent: '',
    first_name: '',
    last_name: '',
    photo_url: '',
    payment_method: [],
    tags: [],
    address: [],
    hourly_price: [],
    license: [],
    experience: [],
    allTags: [],
    areaList: [],
    paymentList: [],
    profile_status: 0,
    blogList: [],
    showModal: false,
    modalText: '',
    isPremium: 0,
    isLoading: false,
    isBlogLoading: false,
    isCalendarLoading: false,
    methodSelected: false,
    invitation:{},
    blogCount: 0,
    blogDisplayNum: 3,
    liked:false,
    visits: 0,
    appointment_reason: '',
    appointment_length: '2',
    appointment_description: '',
    appointment_method: '',
    appointment_address: '',
    appointment_currency: '',
    appointment_payment_method: '1',
    current_lat: '',
    current_lng: '',
    current_address_id: '',
    current_timeslot: '',
    currentWeek: 0,
    timezoneList: utils.timezoneList,
    appointment_timezone: 'America/Detroit',
    display_timezone: '东部时间',
    appointment_timeList_full: [],
    appointment_timeList: [],
    appointment_length_list: [{value_cn: '快速咨询(30分钟)', value: '1'}, {value_cn: '普通咨询(60分钟)', value: '2'}, {value_cn: '初次评估(90分钟)', value: '3'}, {value_cn: '长时咨询(120分钟)', value: '4'}],
    display_length_list: [{value_cn: '快速咨询(30分钟)', value: '1'}, {value_cn: '普通咨询(60分钟)', value: '2'}, {value_cn: '初次评估(90分钟)', value: '3'}, {value_cn: '长时咨询(120分钟)', value: '4'}],
    currencyList: [{value_cn: '美元', value: 'USD'}, {value_cn: '人民币', value: 'CNY'}],
    calendar_weekList: [],
    timeslot_list: [],
    isOnsite: false,
    ticket_id: '',
    unit_price: 0,
    current_email: '',
    current_phone: '',
    loginModal: false,
    paymentRatio: 0.5,
    reservationIframe: false,
  }
  componentDidMount = async() => {
    document.body.addEventListener('scroll', this.handleBlogScroll);
    this.setState({isLoading: true})
    // get tag list
    await utils.loadTagList()
    let areaList = window.tagList.areaList
    areaList.map(elem=>{
      if(elem.value_cn === "其他\r"){
        let index = areaList.indexOf(elem)
        areaList.push(areaList.splice(index, 1)[0])
      }
    })
    this.setState({allTags: window.tagList.allTags, areaList, paymentList: window.tagList.paymentList})
    // set profile id
    await this.setState({profile_id: parseInt(this.props.match.params.profile_id.slice(1))})
    // get info
    if(!localStorage.getItem('loginToken')){
      await axios.get(utils.getUrl(`public/${this.state.profile_id}/homepage/`))
      .then((response) => {
      const data = response.data
      
      this.setState({additional_info: data.additional_info, advanced_credential: data.advanced_credential, len_exp_hr: data.len_exp_hr,
      title: data.title, self_intro: data.self_intro, to_visitor: data.to_visitor, gender: data.user.gender, first_name: data.user.first_name, last_name: data.user.last_name,
      email: data.user.email, verified_phone: data.user.verified_phone, photo_url: data.user.photo_url,
      payment_method: data.payment_method, tags: data.identification.tags, address: data.address, hourly_price: data.hourly_price,
      license: data.license, experience: data.experience, profile_status: data.profile_status, user_id: data.user.id, isPremium: data.premium_status, isLoading: false,
      liked:data.liked, visits: data.visits})
    }).catch(err => {
      // alert(err)
    })
    }else{ 
      await axios.get(utils.getUrl(`public/${this.state.profile_id}/homepage/`), {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
      .then((response) => {
      const data = response.data
      
      this.setState({additional_info: data.additional_info, advanced_credential: data.advanced_credential, len_exp_hr: data.len_exp_hr,
      title: data.title, self_intro: data.self_intro, to_visitor: data.to_visitor, gender: data.user.gender, first_name: data.user.first_name, last_name: data.user.last_name,
      email: data.user.email, verified_phone: data.user.verified_phone, photo_url: data.user.photo_url,
      payment_method: data.payment_method, tags: data.identification.tags, address: data.address, hourly_price: data.hourly_price,
      license: data.license, experience: data.experience, profile_status: data.profile_status, user_id: data.user.id, isPremium: data.premium_status, invitation:data.invitation,isLoading: false,
      liked: data.liked, visits: data.visits})
      if(data.invitation){
        let formatted_date = new Date(data.invitation.email_sent)
        formatted_date = formatted_date.getFullYear()+'-' + (formatted_date.getMonth()+1) + '-'+formatted_date.getDate()+' '+formatted_date.getHours()+':'+formatted_date.getMinutes()
        this.setState({first_name: data.invitation.first_name, last_name: data.invitation.last_name, email: data.invitation.email, email_sent: formatted_date})
      }
    }).catch(err => {
      // alert(err)
    })
    }
    // get payment ratio
    await axios.get(utils.getUrl(`payment/ratio/${this.state.user_id}/`),{headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response=>{
      
      this.setState({paymentRatio: response.data.ratio})
    }).catch(err => {
      // alert(err)
    })

    this.showBlogs()
    this.initAppointmentCalendar()
  }

  openLogIn = () => {
    this.setState({ loginModal: true });
  }

  closeLogIn = () => {
    this.setState({ loginModal: false });
  }

  approveProfile = () => {
    axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/approve/`),
    {profile_status: 2}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      this.setState({profile_status: 2})
      alert('通过成功！')
    }).catch(err => {
      // alert(err)
    })
  }
  deleteInvitation = ()=>{
    axios.delete(utils.getUrl(`admin/invitation/${this.state?.invitation?.invitation_id}/update/`),
         {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then(response => {

          alert('取消成功！',()=> this.props.history.push('/search/therapists'))

        }).catch(err => {
      // alert(err)
    })
  }
  sendInvitation = ()=>{
    axios.post(utils.getUrl(`admin/invitation/${this.state?.invitation?.invitation_id}/send-email/`),
        {},{headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then(response => {
          alert('发送成功！',)

        }).catch(err => {
      // alert(err)
    })
  }
  denyProfile = () => {
    axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/approve/`),
    {profile_status: 3}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      this.setState({profile_status: 3})
      alert('拒绝成功！')
    }).catch(err => {
      // alert(err)
    })
  }
  frozeProfile = () => {
    axios.post(utils.getUrl(`profile/${this.state.user_id}/froze/`),
    {frozen: true}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      this.setState({profile_status: 4})
      alert('冻结成功！')
    }).catch(err => {
      // alert(err)
    })
  }
  unfrozeProfile = () => {
    axios.post(utils.getUrl(`profile/${this.state.user_id}/froze/`),
    {frozen: false}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      this.setState({profile_status: 1})
      alert('取消冻结成功！')
    }).catch(err => {
      // alert(err)
    })
  }
  approvePremium = () => {
    axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/approve-premium/`),
    {premium_status: 1}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      this.setState({isPremium: 1})
      alert('通过成功！')
    }).catch(err => {
      // alert(err)
    })
  }
  denyPremium = () => {
    axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/approve-premium/`),
    {premium_status: 0}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      this.setState({isPremium: 0})
      alert('取消成功！')
    }).catch(err => {
      // alert(err)
    })
  }
  setPaymentRatio= async() => {
    if(this.state.paymentRatio > 1 || this.state.paymentRatio < 0.1){
      alert('分成必须是一个大于0.1小于1的数！')
    }
    await axios.post(utils.getUrl(`payment/ratio/${this.state.user_id}/`),
    {ratio: parseFloat(this.state.paymentRatio)}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response=>{
      
      alert('设置成功！')
    }).catch(err => {
      // alert(err)
    })
  }
  // load blog list
  showBlogs = async() => {
    this.setState({isBlogLoading: true})
    const tempData = {
      author: this.state.user_id,
      search_mode: 'all',
      type: [0],
      order: 'edit_date',
      start: 0, end: 100
    }
    await axios.post(utils.getUrl(`cms/blog/query/`),
    tempData)
    .then(response => {
      let tempBlog = response.data.data
      
      // const rows = tempBlog.reduce(function (rows, key, index) {
      //   return (index % 2 == 0 ? rows.push([key])
      //     : rows[rows.length-1].push(key)) && rows;
      // }, []);

      this.setState({blogList: tempBlog, blogCount: response.data.count, isBlogLoading: false})
    })
    .catch(err => {
      // alert(err)
    })
  }
  // forward to a specific blog page
  forwardBlog = (post_id) => {
    const url = utils.getUrl(`/blog/:${post_id}/show/`)
    this.props.history.push(url)
  }
  // 跳转广告管理界面
  forwardAdManage = (banner_id) =>{
    this.props.history.push({pathname: `/admanage/:${banner_id}`})
  }    

  editProfile = () => {
    this.props.history.push(`/therapist/profile/:${this.state.user_id}/:${this.state.profile_id}`)
  }

  loginAlert = () => {
    alert('您必须先登录！')
  }
  // 处理收藏
  handleLikes = () => {
    const profile_id = parseInt(this.state.profile_id);
    const user_id = localStorage.getItem('user_id')
    const config = {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}
    if (!user_id || !profile_id) {
      this.props.history.push('/login');
      return
    }
    const liked = this.state.liked
    const url = utils.getUrl(`profile/${user_id}/favourites/`)
    this.setState({liked: !liked})
    if (!liked) {
      axios.post(url,
          {action: "add", profile_id: profile_id}, config).then((response) => {
        alert("收藏成功")
        this.setState({liked: true})
      }).catch((err) => {
        alert("请求错误")
        this.setState({liked: liked})
      })
    } else {
      axios.post(url,
          {action: "drop", profile_id: profile_id}, config).then((response) => {
        alert("取消收藏成功")
        this.setState({liked: false,})
      }).catch((err) => {
        alert("请求错误")
        this.setState({liked: liked})
      })
    }
  }
  // 咨询方式选择
  handleAppointmentMethodChange = async(event) =>{
    let type = event.target.value
    let currencyList = []
    if(type==='3'){
      this.state.hourly_price.map(price =>{
        if(price.contact_method===3){
          let value_cn = price.currency==='USD'?'美元':'人民币'
          if(!currencyList.some(item => item.value_cn === value_cn)){
            currencyList.push({value: price.currency, value_cn})
          }
        }
      })
      await this.setState({isOnsite: true, methodSelected: false})
      this.handleAddressSelect(this.state.address.length>0?this.state.address[0].address_id:'')
    }else if(type==='2'){
      this.state.hourly_price.map(price =>{
          if(price.contact_method===2){
            let value_cn = price.currency==='USD'?'美元':'人民币'
            if(!currencyList.some(item => item.value_cn === value_cn)){
              currencyList.push({value: price.currency, value_cn})
            }
          }
        })
      this.setState({isOnsite: false, methodSelected: true, isCalendarLoading: true, current_lat:'', current_lng: ''}, ()=>this.getAppointmentSchedule(false))
    }else if(type==='1'){
      this.state.hourly_price.map(price =>{
          if(price.contact_method===1){
            let value_cn = price.currency==='USD'?'美元':'人民币'
            if(!currencyList.some(item => item.value_cn === value_cn)){
              currencyList.push({value: price.currency, value_cn})
            }
          }
      })
      this.setState({isOnsite: false, methodSelected: true, isCalendarLoading: true, current_lat:'', current_lng: ''}, ()=>this.getAppointmentSchedule(false))
    }else{
      currencyList = [{value_cn: '美元', value: 'USD'}, {value_cn: '人民币', value: 'CNY'}]
      this.setState({isOnsite: false, methodSelected: false})
    }
    if(currencyList.length===0){
      currencyList.push({value: '', value_cn: '该咨询师暂时不支持此咨询方式'})
    }
    if(currencyList.length===1){
      this.setState({appointment_currency: currencyList[0].value})
    }
    this.setState({appointment_method: type, currencyList, display_length_list: this.state.appointment_length_list})
  }
  // 预约地址选择
  handleAddressSelect = (address_id) =>{
      if(address_id===''){
        this.setState({methodSelected: false, display_length_list: this.state.appointment_length_list, current_lat:'', current_lng: ''})
      }else{
        let id = parseInt(address_id)
        this.state.address.map(elem=>{
          if(elem.address_id===id){
            this.setState({current_lat:elem.latitude, current_lng: elem.longitude, current_address_id: id, current_city: elem.city, current_state: elem.state, current_email:elem.email_address, 
              current_phone:elem.phone_number, methodSelected: true, isCalendarLoading: true, display_length_list: this.state.appointment_length_list},
              ()=>this.getAppointmentSchedule(true))
          }
        })
      }
  }
  // 切换calendar week
  handleWeekSwitch = async(index)=>{
    let currentWeek = this.state.currentWeek+index
    if(currentWeek < 0){currentWeek = 0}
    else if(currentWeek > 4){currentWeek = 4}
    await this.setState({currentWeek})
    await this.initAppointmentCalendar()
    this.updateAppointmentCalendar()
  }

  // 获取可选预约时间
  getAppointmentSchedule = async(isOnsite) => {
    let start_time = new Date()
    let end_time =  new Date(start_time.getTime() + 27*24*60*60*1000) //4 weeks
    if(isOnsite){
      await axios.post(utils.getUrl(`appointment/schedule/${this.state.user_id}/?location_id=${this.state.current_address_id}`),
      {start_time: start_time.toISOString(), end_time: end_time.toISOString(), offset: 0}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
      .then(response=>{
        let data = response.data.intervals.map(elem=>{
          return elem.slice()
        })
        let schedule = data.map(elem=>{
          return elem.slice()
        })
        // filter timeslots by length
        for(let i=0;i<schedule.length;i++) {
          if(!schedule[i].includes(true)){
            let test_date = new Date(schedule[i][1]).getTime()
            let length = 1
            let j
            for(j=i+1;j<schedule.length;j++){
              if(!schedule[j].includes(true) && new Date(schedule[j][0]).getTime() === test_date){
                test_date = new Date(schedule[j][1]).getTime()
                length++
              }else{break}
            }
            if(length < parseInt(this.state.appointment_length)){
              for(let z=i;z<j;z++){
                schedule[z][2] = true
              }
            }
          }
        }
        
        this.setState({appointment_timeList_full: data, appointment_timeList: schedule}, ()=>this.updateAppointmentCalendar())
      }).catch(err=>{
        // alert(err)
      })
    }else{
      await axios.post(utils.getUrl(`appointment/schedule/${this.state.user_id}/`),
      {start_time: start_time.toISOString(), end_time: end_time.toISOString(), offset: 0}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
      .then(response=>{
        
        let data = response.data.intervals.map(elem=>{
          return elem.slice()
        })
        let schedule = data.map(elem=>{
          return elem.slice()
        })
        // filter timeslots by length
        for(let i=0;i<schedule.length;i++) {
          if(!schedule[i].includes(true)){
            let test_date = new Date(schedule[i][1]).getTime()
            let length = 1
            let j
            for(j=i+1;j<schedule.length;j++){
              if(!schedule[j].includes(true) && new Date(schedule[j][0]).getTime() === test_date){
                test_date = new Date(schedule[j][1]).getTime()
                length++
              }else{break}
            }
            if(length < parseInt(this.state.appointment_length)){
              for(let z=i;z<j;z++){
                schedule[z][2] = true
              }
            }
          }
        }
        
        this.setState({appointment_timeList_full: data, appointment_timeList: schedule}, ()=>this.updateAppointmentCalendar())
      }).catch(err=>{
        // alert(err)
      })
    }
  }

  initAppointmentCalendar= () => {
    let timeList = []
    let week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let current_day = moment().day()
    for(let i=0;i<7;++i){
      timeList.push({day: week[(moment().day()+i)%7], date_num: moment().day(current_day+i+7*this.state.currentWeek).date(),
      date: (month[moment().day(current_day+i+7*this.state.currentWeek).month()])+' '+moment().day(current_day+i+7*this.state.currentWeek).date()})
    }
    this.setState({calendar_weekList: timeList})
  }

  updateAppointmentCalendar = () => {
    let timeslot_list = []
    for(let i=0;i<7;++i){timeslot_list.push([])}
    this.state.appointment_timeList.map(elem=>{
      if(!elem.includes(true)){
        let start_time = this.state.appointment_timezone===''?new Date(elem[0]):this.resolveTimezone(elem[0])
        if( start_time < new Date(new Date().getTime() + (this.state.currentWeek+1)*7*24*60*60*1000)
        && start_time > new Date(new Date().getTime() + (this.state.currentWeek)*7*24*60*60*1000)){
          let display_time = start_time.getHours()+':'+(start_time.getMinutes()===0?'00':start_time.getMinutes())
          // let current_day = new Date(new Date().getTime() + (this.state.currentWeek)*7*24*60*60*1000)
          // let diff = (start_time-current_day)/1000/60/60/24
          // diff = Math.ceil(diff>0?diff:0)
          let index = 0;
          this.state.calendar_weekList.map(elem=>{
            if(elem.date_num === start_time.getDate()){
              index = this.state.calendar_weekList.indexOf(elem)
            }
          })
          timeslot_list[index].push({display_time: display_time, full_time: elem[0]})
        }
      }
    })
    this.setState({timeslot_list, isCalendarLoading: false})
  }
  // 选择预约时间
  timeslotSelect = (event, time) => {
    // let length = 1
    // let test_date = (new Date(new Date(time).getTime() + 60*1000*30*length)).getTime()
    // let time_list = this.state.appointment_timeList
    // for(let i=0;i<time_list.length;++i){
    //   if(!time_list[i].includes(true) && new Date(time_list[i][0]).getTime() === test_date){
    //     test_date = (new Date(new Date(time).getTime() + 60*1000*30*(++length))).getTime()
    //   }
    // }
    let element = event.target
    let tempList = Array.from(document.querySelectorAll('.timeslot-selected'))
    if(tempList.includes(element)){
      element.classList.remove('timeslot-selected')
      this.setState({current_timeslot: ''})
    }else{
      tempList.forEach(elem =>{
        elem.classList.remove('timeslot-selected')
      })
      element.classList.add('timeslot-selected')
    }
    // length = length>4?4:length
    // let length_list = this.state.appointment_length_list.slice(0, length)
    this.setState({current_timeslot: time})
  }
  // 进行预约
  handleAppointment = async() => {
    if(this.state.appointment_method===''){
      alert('请选择一个咨询方式！')
      return
    }else if(this.state.appointment_reason===''){
      alert('请选择一个咨询原因！')
      return
    }else if(this.state.appointment_currency===''){
      alert('请选择一个付款单位！')
      return
    }else if(this.state.appointment_length===''){
      alert('请选择一个咨询种类！')
      return
    }else if(this.state.current_timeslot===''){
      alert('请选择一个咨询时间！')
      return
    }else if(this.state.appointment_timezone===''){
      alert('请选择一个咨询时区！')
      return
    }

    localStorage.removeItem('ticketData')
    let tag_list = []
    tag_list.push(parseInt(this.state.appointment_reason))
    let unit_price = 0
    this.state.hourly_price.map(price =>{
      if(price.contact_method === parseInt(this.state.appointment_method) && price.currency === this.state.appointment_currency){
        unit_price = (price.price_value_low*100)/2/100
        
      }
    })
    localStorage.setItem('ticketData', JSON.stringify({length: parseInt(this.state.appointment_length), method: parseInt(this.state.appointment_method), 
      tags: tag_list, description: this.state.appointment_description, first_name: this.state.first_name, last_name: this.state.last_name, photo_url: this.state.photo_url, unit_price: unit_price,
      currency: this.state.appointment_currency, start_time: this.state.current_timeslot, location: this.state.current_address_id, isOnsite: this.state.isOnsite, user_id: this.state.user_id, 
      timezone: this.state.display_timezone, city: this.state.current_city, state: this.state.current_state}))
      this.props.history.push({pathname: `/payment/progress/`})

  }

  createTicket = async() => {
    let tag_list = []
    tag_list.push(parseInt(this.state.appointment_reason))
    if(this.state.isOnsite){
      await axios.post(utils.getUrl(`appointment/ticket/create/${this.state.user_id}/`),
      {length: parseInt(this.state.appointment_length), method: parseInt(this.state.appointment_method), tags: tag_list, 
      meta:{description: this.state.appointment_description, timezone: this.state.display_timezone, city: this.state.current_city, state: this.state.current_state},
      currency: this.state.appointment_currency, start_time: this.state.current_timeslot, location: this.state.current_address_id},
      {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
      .then(response=>{
        
        this.setState({ticket_id: response.data.ticket_id, unit_price: response.data.unit_price})
        alert('即将为您跳转支付界面...')
      }).catch(err=>{
        if(err.response.data.msg==='Invalid Time Range'){
          alert('该时间已被预约，请缩短时长或选择其他时间')
        }
      })
    }else{
      await axios.post(utils.getUrl(`appointment/ticket/create/${this.state.user_id}/`),
      {length: parseInt(this.state.appointment_length), method: parseInt(this.state.appointment_method), tags: tag_list, 
      meta:{description: this.state.appointment_description, timezone: this.state.display_timezone},
      currency: this.state.appointment_currency, start_time: this.state.current_timeslot, location: 'online'},
      {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
      .then(response=>{
        
        this.setState({ticket_id: response.data.ticket_id})
        alert('即将为您跳转支付界面...')
      }).catch(err=>{
        if(err.response.data.msg==='Invalid Time Range'){
          alert('该时间已被预约，请缩短时长或选择其他时间')
        }
      })
    }
  }

  handlePayment = async() => {
    await axios.post(utils.getUrl(`appointment/ticket/pay/${this.state.ticket_id}/`),
    {pay: 'stripe'}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response=>{
      
      this.setState({stripe_id: response.data.id})
    })
    const stripe = await loadStripe(utils.getStripeKey())
    const result = await stripe.redirectToCheckout({
        sessionId: this.state.stripe_id,
    })
    if (result.error) {
        alert('支付错误')
    }
  }
  // 咨询种类改变
  handleLengthChange = async(event) => {
    let schedule = this.state.appointment_timeList_full.map(elem=>{
      return elem.slice()
    })
    await this.setState({appointment_length: event.target.value})
    // filter timeslots by length
    for(let i=0;i<schedule.length;i++) {
      if(!schedule[i].includes(true)){
        let test_date = new Date(schedule[i][1]).getTime()
        let length = 1
        let j
        for(j=i+1;j<schedule.length;j++){
          if(!schedule[j].includes(true) && new Date(schedule[j][0]).getTime() === test_date){
            test_date = new Date(schedule[j][1]).getTime()
            length++
          }else{break}
        }
        if(length < parseInt(this.state.appointment_length)){
          for(let z=i;z<j;z++){
            schedule[z][2] = true
          }
        }
      }
    }
    
    await this.setState({appointment_timeList: schedule})
    this.updateAppointmentCalendar()
  }
  // 时区改变
  setTimeZone = (event) =>{
    let value_cn = ''
    this.state.timezoneList.map(elem=>{
      if(elem.id === event.target.value){
        value_cn = elem.value_cn
      }
    })
    this.setState({appointment_timezone: event.target.value, display_timezone: value_cn}, ()=>{
      this.updateAppointmentCalendar()
    })
  }
  // convert time string to a certain timezone date
  resolveTimezone = (time) =>{
    return this.convertTZ(utils.resolveOffset(utils.parseLocalDateTimeString(time)).toUTCString(), this.state.appointment_timezone)
  }

  convertTZ = (date, tzString)=> {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}))
  }

  handleBlogScroll = ()=>{
    var top = document.querySelector("body").scrollTop
    var height = window.innerHeight
    var total = $(document).height()
    if(total - height - top > 10){
      return
    }
    let blogDisplayNum = this.state.blogDisplayNum+3>this.state.blogCount?this.state.blogCount:this.state.blogDisplayNum+3
    this.setState({blogDisplayNum})
  }

  renderTooltip = (props) => {
    
    return (<Tooltip id="button-tooltip" style={{zIndex: 9999999}} {...props}>
      Simple tooltip
    </Tooltip>)
  }

  adminSection = () => {
    return (
      <div>
      <Row>
        {/* admin btns */}
        {(localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?
        <div>
        <Row style={{padding: '10px 40px', backgroundColor: '#b5c0d5', color: '#fff'}}>
        {
          (localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?(<Col md={2} className='adminBtn'>
            <a>
              <input className="profile-btn-1" onClick={this.approveProfile} type="submit" style={{height: '40px', lineHeight: '18px'}} value="通过"/>
            </a>
          </Col>):(<div></div>)
        }
        {
          (localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?(<Col md={2} className='adminBtn'>
            <a>
              <input className="profile-btn-1" onClick={this.denyProfile} type="submit" style={{height: '40px', lineHeight: '18px', width: '50%'}} value="拒绝"/>
            </a>
          </Col>):(<div></div>)
        }
        {(localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?
          <Col md={2} className='adminBtn'><a>
            <input className="profile-btn-1" onClick={this.editProfile} type="submit" style={{height: '40px', lineHeight: '18px', width: '50%'}} value="编辑"/>
          </a></Col>:(<div></div>)}
        {
          (localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?(<Col md={2} className='adminBtn'>
            <a>
              <input className="profile-btn-1" onClick={this.frozeProfile} type="submit" style={{height: '40px', lineHeight: '18px', width: '50%'}} value="冻结"/>
            </a>
          </Col>):(<div></div>)
        }
        {
          (localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?(<Col md={2} className='adminBtn'>
            <a>
              <input className="profile-btn-1" onClick={this.unfrozeProfile} type="submit" style={{height: '40px', lineHeight: '18px', width: '50%'}} value="取消冻结"/>
            </a>
          </Col>):(<div></div>)
        }
        </Row>
        <Row style={{padding: '10px 40px', backgroundColor: '#b5c0d5', color: '#fff'}}>
        {
          (this.state.profile_status === 5 && localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type')) <= 2)? (
              <Col md={2} className='adminBtn'>
                <a>
                  <input className="profile-btn-1" onClick={this.deleteInvitation} type="submit" style={{height: '40px', lineHeight: '18px'}} value="删除邀请"/>
                </a>
                </Col>) : (<Col md={2}></Col>)
        }
        {
          (localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?(<Col md={3} className='adminBtn'>
            <a>
              <input className="profile-btn-1" onClick={this.approvePremium} type="submit" style={{height: '40px', lineHeight: '18px'}} value="通过青年咨询师"/>
            </a>
          </Col>):(<div></div>)
        }
        {
          (localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?(<Col md={2}></Col>):(<div></div>)
        }
        {
          (localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?(<Col md={3} className='adminBtn' style={{textAlign: 'left'}}>
            <a>
              <input className="profile-btn-1" onClick={this.denyPremium} type="submit" style={{height: '40px', lineHeight: '18px'}} value="取消青年咨询师"/>
            </a>
          </Col>):(<div></div>)
        }
        {(this.state.profile_status === 5 && localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type')) <= 2)?
          (<Col md={2} className='adminBtn' style={{textAlign: 'left'}}>
                <a>
                  <input className="profile-btn-1" onClick={this.sendInvitation} type="submit" style={{height: '40px', lineHeight: '18px'}} value="发送邮件"/>
                </a>
              </Col>):(<Col md={2}></Col>)
        }
        </Row>
        {(localStorage.getItem('user_type') && parseInt(localStorage.getItem('user_type'))<=2)?
          <Row style={{textAlign: 'center', padding: '10px 40px', backgroundColor: '#b5c0d5'}}>
            <input type="number" placeholder="咨询师分成" value={this.state.paymentRatio} onChange={(event)=>this.setState({paymentRatio: event.target.value})}></input>
            <input className="profile-btn-1" type='button' style={{height: '40px', lineHeight: '18px'}} value="设置分成" onClick={this.setPaymentRatio}></input>
        </Row>:''}
        </div>:<div></div>}
      </Row>

      {/* profile status */}
      <Row style={{textAlign: 'center'}}>
      { localStorage.getItem('user_type')&& parseInt(localStorage.getItem('user_type')) <= 2?
        (() => {
          switch (this.state.profile_status) {
            case 0:
            return (<Alert variant='warning'>
                This profile has been saved but not yet submitted
              </Alert>)
            case 1: return (<Alert variant='warning'>
                This profile has been submitted but not yet approved
              </Alert>)
            case 2:  return (<Alert variant='success'>
                This profile has been approved!
              </Alert>)
            case 3:  return (<Alert variant='danger'>
                This profile has been denied and needs to be edited before submit again!
              </Alert>)
            case 4:  return (<Alert variant='danger'>
                This profile has been frozen, please contact the administrator for more information
              </Alert>)
            case 5: return (<Alert variant='warning'>
                This is a invited therapist profile that needs further information, invite email has been sent to <span style={{fontWeight: 'bold'}}>{this.state.email}</span> at <span style={{fontWeight: 'bold'}}>{this.state.email_sent}</span>
              </Alert>)
            default: return (<div></div>);
          }
        })():(<div></div>)
      }
    </Row>
    </div>
    )
  }

  render() {
    let user_avatar = avatar
    if(this.state.photo_url){user_avatar = this.state.photo_url}
    let title
    if(this.state.title === 1){title = 'Dr. '}
    else if(this.state.title === 2){title = 'Prof. '}
    else{title = ''}
    let gender
    if(this.state.gender === 1){gender = '男'}
    else if(this.state.gender === 2){gender = '女'}
    else if(this.state.gender === 3){gender = '其他'}
    else{gender = '未知'}
    let paymentList = []
    this.state.payment_method.map(payment => {
      if(payment === 500001){paymentList.push('Visa')}
      else if(payment === 500002){paymentList.push('Apple Pay')}
      else{paymentList.push('Other')}
    })
    let tempArea = []
    let tempGroup = []
    let tempRole = []
    let tempLang = []
    this.state.tags.map(tag => {
      let tag_value = ''
      // search for string value
      this.state.allTags.map(elem=>{
        if(tag === elem.tag_id){
          tag_value = elem.value_cn
        }
      })
      // 分类tags
      var cate = ((""+tag).split(""))[0]
      if(cate === '2'){
        tempArea.push(tag_value)
      }else if(cate === '3'){
        tempGroup.push(tag_value)
      }else if(cate === '1'){
        tempRole.push(tag_value)
      }else if(cate === '7'){
        tempLang.push(tag_value)
      }
    })
    let profile_status
    if(this.state.profile_status === 0){profile_status = 'Saved'}
    else if(this.state.profile_status === 1){profile_status = 'Submitted'}
    else if(this.state.profile_status === 2){profile_status = 'Approved'}
    else if(this.state.profile_status === 3){profile_status = 'Denied'}
    else if(this.state.profile_status === 4){profile_status = 'Frozen'}
    let current_address = ''
    if(this.state.address.length>0 && this.state.address[0].city && this.state.address[0].state && this.state.address[0].country){
      current_address = this.state.address[0].city+', '+this.state.address[0].state
    }
    let contact_method_list = []
    let min_price
    if(this.state.hourly_price[0]){min_price = this.state.hourly_price[0].price_value_low}
    else{min_price = '未知'}
    this.state.hourly_price.map(price => {
      let contact_method = ''
      if(price.price_value_low < min_price){
        min_price = price.price_value_low
      }
      if(price.contact_method === 1){contact_method = '语音'}
      else if(price.contact_method === 2){contact_method = '视频'}
      else if(price.contact_method === 3){contact_method = '线下'}
      if(!contact_method_list.some((elem)=>elem.value_cn===contact_method) && contact_method !== ''){
        contact_method_list.push({value_cn: contact_method, id: price.contact_method})
      }
    })
    if(this.state.isLoading){
      return (<Row style={{paddingLeft: '45%'}}>
        <ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/>
        <div style={{height: '400px'}}></div>
      </Row>)
    }else{
    return (
      <div>
        <Modal animation={false} show={this.state.loginModal} onHide={this.closeLogIn}>
          <Modal.Body>
            <LogInContent cancelGap={this.props.cancelGap} addGap={this.props.addGap} isModalLogIn={true} curUrl={window.location.href} setDisplayURL={this.props.setDisplayURL} />
          </Modal.Body>
        </Modal>
        {this.adminSection()}

        <div class="container-fluid px-lg-10 py-5">
            <span><NavLink to="/search/therapists" class="breadcrumb-link">找我咨询</NavLink></span> /
            <span class="fs-1">咨询师详情</span>
        </div>
        <div class="container-fluid px-lg-10 banner-box py-4 mb-5">
              <div class="row g-1 g-lg-4 align-items-top">
                <div class="col-lg-7 col-12">
                  <div class="banner-text">
                      <p><img src={quote_left} alt="quote"/></p>
                      {this.state.to_visitor===''
                      ?<h4>你带着你的故事和勇气来到这里，想要去开启一段寻找自己的旅程。我想，做出这个决定并不容易；你的勇气也来之不易，它很珍贵。倘若旅程开启，我愿意陪伴你，行走在这段未知的路途中，去触碰自己，亲近自己，探索真实的样子。</h4>
                      :<h4>{this.state.to_visitor}</h4>
                      }
                      <p class="text-end"><img src={quote_right} alt="quote"/></p>
                  </div>
                </div>
                <div class="col-lg-5 col-12">
                  <div class="reservation-box p-3">
                    <div class="row col-12 py-2">
                      <div class="col-3 align-items-center list-avatar-image">
                        <img src={user_avatar} alt='user_avatar' class="list-avatar-img"/>
                      </div>
                      <div class="col-9">
                        <div class="row pt-2">
                          <h2>{title}{this.state.first_name} {this.state.last_name}, {this.state.advanced_credential}</h2>
                        </div>
                        <div>
                          <span class="p-2"><i class="bi bi-geo-alt brand-primary-color"></i>{current_address}</span>
                          <span class="p-2"><i class="bi bi-cursor brand-primary-color"></i>{this.state.visits}</span>
                          <span class="p-2">
                            <i class="bi bi-heart brand-primary-color" onClick={()=>this.handleLikes()}></i>
                            {localStorage.getItem('loginToken') && this.state.liked?"已收藏":"收藏"}
                          </span>
                        </div>
                      </div>
                      <div class="row col-12 py-2">
                        <div class="col-5">
                          <h6>
                            {contact_method_list.length===0?'未知':
                              contact_method_list.length===1?contact_method_list[0].value_cn:
                                contact_method_list.map(contact=>{
                                  if(contact_method_list.indexOf(contact)===contact_method_list.length-1){
                                    return contact.value_cn
                                  }else{return contact.value_cn + ' | '}
                            })}
                          </h6>
                          <small>咨询方式</small>
                        </div>
                        <div class="col-4">
                          <h6>${min_price}/hr 起</h6>
                          <small>费用</small>
                        </div>
                        <div class="col-3">
                          <h6>{this.state.len_exp_hr} hr+</h6>
                          <small>咨询经验</small>
                        </div>
                      </div>
                    </div>

                    {/* 资讯原因 */}
                    {/* {localStorage.getItem('loginToken')&&this.state.isPremium?
                    <div className='mb-2'>
                      <select id='appointment-reason' className='form-select' onChange={(event)=>this.setState({appointment_reason: event.target.value})}>
                        <option selected disabled value=''>咨询原因</option>
                        {this.state.areaList.map(tag=>
                        (<option value={tag.tag_id}>{tag.value_cn}</option>))
                        }
                      </select>
                    </div>:''} */}
                    {/* 原因详情 */}
                    {/* {localStorage.getItem('loginToken')&&this.state.isPremium?
                    <div className='mb-2'>
                      <input type='text' class="form-control" placeholder="原因详情 (选填)" value={this.state.appointment_description} onChange={(event)=>{this.setState({appointment_description: event.target.value})}}></input>
                    </div>:''} */}
                    {/* 咨询种类 */}
                    {/* {localStorage.getItem('loginToken')&&this.state.isPremium?
                    <div className='mb-2'>
                      <select id='appointment-length' className='form-select' value={this.state.appointment_length} onChange={this.handleLengthChange}>
                        <option selected disabled value=''>咨询种类</option>
                        {this.state.methodSelected?
                          this.state.display_length_list.map(length=>{
                          if(length.value === '2'){return <option value={length.value} selected='selected'>{length.value_cn}</option>}
                          else{return <option value={length.value}>{length.value_cn}</option>}
                        }):this.state.appointment_length_list.map(length=>{
                          if(length.value === '2'){return <option value={length.value} selected='selected'>{length.value_cn}</option>}
                          else{return <option value={length.value}>{length.value_cn}</option>}
                        })}
                      </select>
                    </div>:''} */}
                    {/* 咨询方式 */}
                    {/* {localStorage.getItem('loginToken')?
                    <div className='mb-2'>
                      <select id='appointment-method' className='form-select' onChange={this.handleAppointmentMethodChange}>
                        <option selected disabled value=''>咨询方式</option>
                        {contact_method_list.map(contact=>{
                                    return <option value={contact.id}>{contact.value_cn}</option>}
                        )}
                      </select>
                    </div>:''} */}
                    {/* 地址选择 */}
                    {/* {localStorage.getItem('loginToken')&&this.state.isOnsite?
                    <div className='mb-2'>
                      <select id='appointment-address' className='form-select' onChange={(event) =>this.handleAddressSelect(event.target.value)}>
                        <option selected disabled value=''>地址选择</option>
                        {this.state.address.map(elem=>{
                          if(!elem.city && !elem.state && !elem.country){return ''}
                          let tempAddress = elem.street_1+' '+elem.street_2+' '+elem.city+', '+elem.state
                          if(elem.latitude===this.state.current_lat&&elem.longitude===this.state.current_lng){
                            return <option value={elem.address_id} selected>{tempAddress}</option>
                          }else{
                            return <option value={elem.address_id}>{tempAddress}</option>
                          }
                        })
                        }
                      </select>
                    </div>:''} */}
                    {/* 时区选择 */}
                    {/* {localStorage.getItem('loginToken')&&this.state.isPremium?
                    <div className='mb-2'>
                      <select id='timezone' className='form-select' onChange={this.setTimeZone}>
                        <option selected disabled value=''>时区选择</option>
                        {this.state.timezoneList.map(elem=>
                          {if(elem.id==='America/Detroit'){return (<option value={elem.id} value_cn={elem.value_cn} selected>{elem.value_cn}</option>)}
                            else{return (<option value={elem.id} value_cn={elem.value_cn}>{elem.value_cn}</option>)}}
                          )}
                      </select>
                    </div>:''} */}
                    {/* 付款单位 */}
                    {/* {localStorage.getItem('loginToken')&&this.state.isPremium?
                    <div className='mb-2'>
                      <select id='currency' className='form-select' onChange={(event)=>this.setState({appointment_currency: event.target.value})}>
                        <option selected disabled value=''>付款单位</option>
                        {this.state.currencyList.map(currency=>{
                          return <option value={currency.value}>{currency.value_cn}</option>
                        })}
                      </select>
                    </div>:''} */}
                    {/* 付款方式 */}
                    {/* {localStorage.getItem('loginToken')?<div className='profile-appointment-input custom-select-2'>
                      <select id='currency' onChange={(event)=>this.setState({appointment_payment_method: event.target.value})}>
                        <option value=''></option>
                        {this.state.paymentList.map(payment=>{
                          return <option value={payment.tag_id}>{payment.value_cn}</option>
                        })}
                      </select>
                      <span>付款方式</span>
                    </div>:''} */}
                    {/* 预约calendar */}
                    {this.state.methodSelected&&this.state.isPremium?<div className='create-event-input-2' style={{minHeight: '180px', padding: '8px', height: '100%'}}>
                      <div className='flex-block' style={{padding: '0 5px', justifyContent: 'space-evenly', marginBottom: '8px'}}>
                        <svg style={{position: 'relative', top: '10px', left: '-3px', cursor: 'pointer'}} onClick={() =>this.handleWeekSwitch(-1)} width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.5 1.625L0.75 5L4.5 8.375" stroke="#6FE4E5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {/* 日期 */}
                        {this.state.calendar_weekList.map(elem=>{
                          return <div className='flex-block3' style={{fontSize: '12px', fontWeight: '500', width: '55px'}}>
                            <div>{elem.day}</div>
                            <div>{elem.date}</div>
                          </div>
                        })}
                        <svg style={{position: 'relative', top: '10px', right: '-3px', cursor: 'pointer'}} onClick={() =>this.handleWeekSwitch(1)}  width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.875 1.625L4.625 5L0.875 8.375" stroke="#6FE4E5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                      {this.state.isCalendarLoading?<Row style={{paddingLeft: '45%'}}>
                          <ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/>
                        </Row>:
                        <div className='flex-block' style={{padding: '0 5px', justifyContent: 'space-evenly'}}>
                        {/* 时间 */}
                        {this.state.timeslot_list.map(elem=>{
                          return <div className='flex-block3' style={{width: '55px'}}>
                            {elem.map(time=>{
                              return <div className='timeslot' onClick={(event) =>this.timeslotSelect(event, time.full_time)}>{time.display_time}</div>
                            })}
                          </div>
                        })}
                      </div>}
                    </div>:''}

                    {/* 预约button */}
                    <div class="d-grid">
                      {localStorage.getItem('loginToken')?
                      this.state.isPremium?
//                      <input className="btn btn-primary" onClick={this.handleAppointment} type="button" value="预约咨询"/>
                        <input className="btn btn-primary" onClick={()=>this.setState({reservationIframe: true})} type="button" value="预约咨询"/>    
                      :''
                      :<input className="btn btn-primary" onClick={this.openLogIn} type="button" value="登录后预约咨询"/>}
                    </div>

                    {/* 注册 */}
                    {localStorage.getItem('loginToken')?'':
                    <div class="row">
                      <NavLink to='/signup/webuser' className="btn btn-third text-start ms-2">还没有账号？立即注册</NavLink>
                    </div>}
                    {/* 联系方式 */}
                    {localStorage.getItem('loginToken')&&this.state.methodSelected?
                    <div class="row">
                        <div>邮箱 <span style={{color: '#43B0B1'}}>{this.state.current_email}</span></div>
                        <div>电话 <span style={{color: '#43B0B1'}}>{this.state.current_phone}</span></div>
                    </div>:''}
                    {/* 地图 */}
                    {localStorage.getItem('loginToken')?this.state.current_lat!==''&&this.state.current_lng!==''?
                    <div class="row" style={{marginTop: '24px'}}>
                      <GoogleMapFrame lat={this.state.current_lat} lng={this.state.current_lng} height={300} width="100%" zoom={12}></GoogleMapFrame>
                    </div>
                    :''
                    :<div class="row" style={{marginTop: '24px'}}>
                        <img src={map_img} alt='map_img' style={{width: '100%', height: '200px'}}></img>
                        <div><a onClick={this.openLogIn} class="link">登录</a>后查看咨询师联系方式</div>
                    </div>
                    }
                    {/* 预约须知 */}
                    {localStorage.getItem('loginToken')?
                    <div class="row" style={{marginTop: '24px'}}>
                        <span>预约须知：</span>
                        <AboutUsEdit src_id="booking_note"/>
                    </div>:''}
                  </div>
              </div>
            </div>
        </div>


        <div class="container-fluid px-lg-10 py-2">
          <div class="row">
            <div class="col-lg-7 col-12 therapist_profile_list">
              <ul>
                <li>
                  <h4>咨询师简介</h4>
                  <p>{this.state.self_intro}</p>
                </li>
                <li>
                  <h4>咨询师资质</h4>
                  <p>{tempRole.map(role => role + ' ')}</p>
                </li>
                <li>
                  <h4>擅长领域</h4>
                  <p>{tempArea.map(area => area + ' ')}</p>
                </li>
                <li>
                  <h4>支持语言</h4>
                  <p>{tempLang.map(lang => lang + ' ')}</p>
                </li>
                <li>
                  <h4>面向群体</h4>
                  <p>{tempGroup.map(group => group + ' ')}</p>
                </li>
                <li>
                  <h4>工作经历</h4>
                  <p>{
                      this.state.experience.map(exp => {
                        let exp_type = '工作'
                        if(exp.experience_type === 1){exp_type = '教育'}
                        else if(exp.experience_type === 3){exp_type = '培训'}
                        return (
                          <div style={{marginBottom: '20px', paddingLeft: '18.5px'}}>
                            <Row>经历名称：{exp.experience_name}</Row>
                            <Row>经历类型：{exp_type}</Row>
                            <Row>经历描述：{exp.description}</Row>
                            <Row>经历时间：{exp.start_date} ~ {exp.end_date}</Row>
                          </div>
                        )
                      })
                    }
                  </p>
                </li>
                <li>
                  <h4>相关执照</h4>
                  <p>
                    {
                      this.state.license.map(elem => {
                      let isPassed
                      if(elem.has_passed){isPassed = '是'}
                      else(isPassed = '获取中')
                      let tag_value = ''
                      this.state.allTags.map(tag=>{
                        if(elem.mental_health_role === tag.tag_id){
                          tag_value = tag.value_cn
                        }
                      })
                      return (<div style={{marginBottom: '20px', paddingLeft: '18.5px'}}>
                                <Row> 证书编号：{elem.license_number}</Row>
                                <Row> 咨询师身份：{tag_value}</Row>
                                <Row> 是否已经获取：{isPassed}</Row>
                                <Row> 证书所在州：{elem.license_state}</Row>
                                <Row> 证书过期日：{elem.license_expiration}</Row>
                                <Row> 督导师姓名：{elem.supervisor_name}</Row>
                                <Row> 督导师执照编号：{elem.supervisor_license}</Row>
                              </div>)
                    })}
                  </p>
                </li>
                <li>
                  <h4>咨询费用</h4>
                  <p>{
                      this.state.hourly_price.map((price=>{
                        let contact_method = ''
                        if(price.contact_method === 0){contact_method = '未知'}
                        else if(price.contact_method === 1){contact_method = '语音'}
                        else if(price.contact_method === 2){contact_method = '视频'}
                        else if(price.contact_method === 3){contact_method = '线下'}
                        return (
                          <div style={{marginBottom: '20px', paddingLeft: '18.5px'}}>
                            <Row>价格：{price.price_value_low} {price.currency}</Row>
                            <Row>咨询方式：{contact_method}</Row>
                          </div>
                        )
                      }))
                    }
                  </p>
                </li>
                <li>
                  <h4>其他信息</h4>
                  <p>{this.state.additional_info}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <Row className='profile-body-block' style={{borderLeft: 'none'}}>
              <Row style={{color: '#43B0B1', marginBottom: '16px'}}><h4>我的文章</h4></Row>
              <Row className='flex-block4'>
              {this.state.blogList.length === 0?(<div></div>):
                this.state.blogList.map((blog, index)=>{
                  if(index >= this.state.blogDisplayNum){return}
                  return <div style={{width: 550, height: 122.5, marginBottom: 16}} key={'blog'+index} className='flex-block'>
                    <div style={{marginRight: 24}}><img src={blog.cover_url?blog.cover_url:avatar} alt='blog_cover' style={{width: 120, height: 120}}></img></div>
                    <div className='flex-block4'>
                      <div style={{fontWeight: '400', fontSize: 16, lineHeight: '23px' }}>{blog.subject}</div>
                      <div className='blog-des'>{blog.description}</div>
                      <div><NavLink to={`/blog/:${blog.post_id}/show/`} className='navlink' style={{color: '#43B0B1', textDecoration: 'none'}}>阅读更多</NavLink></div>
                    </div>
                  </div>
                })
              }
              </Row>
            </Row>
          </Col>
        </Row> */}
        {/*
        import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
        <CarouselProvider
                naturalSlideWidth={800}
                naturalSlideHeight={150}
                totalSlides={this.state.blogCount}
                infinite={true}
                style={{height: '180px'}}
                className='blog-carousel'
                >
                <Col md={1} className='blog-carousel-btn' style={{marginTop: '5%'}}><ButtonBack>{'<'}</ButtonBack></Col>
                <Col md={10} className='blog-slide-row'>
                <Slider>
                {this.state.blogList.map(blog => {
                    if(!blog[1]){
                    let blog_date1 = new Date(blog[0].post_date)
                    blog_date1 = blog_date1.getFullYear()+'-' + (blog_date1.getMonth()+1) + '-'+blog_date1.getDate()+' '+blog_date1.getHours()+':'+blog_date1.getMinutes()
                    return(
                      <Slide>
                      <Row style={{marginBottom: '10px'}}>
                      <Col md={6} className='profile_blog' onClick={()=>this.forwardBlog(blog[0].post_id)}>
                        <Col md={4} style={{marginRight: '10px'}}><Image src={blog[0].cover_url?blog[0].cover_url:avatar} alt='blog_cover' className='blog_cover' hasMasterSpinner={true}></Image></Col>
                        <Col md={8} style={{paddingTop: '20px'}}>
                          <Row className='blog-des' style={{fontWeight: 'bold'}}>{blog[0].subject}</Row>
                          <Row className='blog-des'>{blog[0].description}</Row>
                          <Row className='blog-des' style={{color: '#707070'}}>{blog_date1}</Row>
                          <Row style={{color: '#7070705d'}}>访问量：{blog[0].visits}</Row>
                        </Col>
                      </Col>
                    </Row>
                    </Slide>)
                    }else{
                      let blog_date1 = new Date(blog[0].post_date)
                      let blog_date2 = new Date(blog[1].post_date)
                      blog_date1 = blog_date1.getFullYear()+'-' + (blog_date1.getMonth()+1) + '-'+blog_date1.getDate()+' '+blog_date1.getHours()+':'+blog_date1.getMinutes()
                      blog_date2 = blog_date2.getFullYear()+'-' + (blog_date2.getMonth()+1) + '-'+blog_date2.getDate()+' '+blog_date2.getHours()+':'+blog_date2.getMinutes()
                      return(
                      <Slide>
                      <Row style={{marginBottom: '10px'}}>
                      <Col md={6} className='profile_blog' onClick={()=>this.forwardBlog(blog[0].post_id)}>
                        <Col md={4}><Image src={blog[0].cover_url?blog[0].cover_url:avatar} alt='blog_cover' className='blog_cover' hasMasterSpinner={true}></Image></Col>
                        <Col md={8} style={{paddingTop: '20px', paddingLeft: '15px'}}>
                          <Row className='blog-des' style={{fontWeight: 'bold'}}>{blog[0].subject}</Row>
                          <Row className='blog-des'>{blog[0].description}</Row>
                          <Row className='blog-des' style={{color: '#707070'}}>{blog_date1}</Row>
                          <Row style={{color: '#7070705d'}}>访问量：{blog[0].visits}</Row>
                        </Col>
                      </Col>
                      <Col md={6} className='profile_blog' onClick={()=>this.forwardBlog(blog[1].post_id)}>
                        <Col md={4}><Image src={blog[1].cover_url?blog[1].cover_url:avatar} alt='blog_cover' className='blog_cover' hasMasterSpinner={true}></Image></Col>
                        <Col md={8} style={{paddingTop: '20px', paddingLeft: '15px'}}>
                          <Row className='blog-des' style={{fontWeight: 'bold'}}>{blog[1].subject}</Row>
                          <Row className='blog-des'>{blog[1].description}</Row>
                          <Row className='blog-des' style={{color: '#707070'}}>{blog_date2}</Row>
                          <Row style={{color: '#7070705d'}}>访问量：{blog[1].visits}</Row>
                        </Col>
                      </Col>
                    </Row>
                    </Slide>)
                    }
                  })
                }
                </Slider>
                </Col>
                <Col md={1} className='blog-carousel-btn' style={{marginTop: '5%'}}><ButtonNext>{'>'}</ButtonNext></Col>
                <Row className='blog-carousel-btn-2'>
                  <Col md={1}></Col>
                  <ButtonBack>{'<'}</ButtonBack><ButtonNext>{'>'}</ButtonNext>
                </Row>
                </CarouselProvider> */}
            
      <Modal show={this.state.reservationIframe} onHide={()=>this.setState({reservationIframe: false})}>
        <Modal.Body>
          <div><iframe src="https://findself.va.mikecrm.com/9zg4dQA" title="预约咨询师" className="footer_modal"></iframe></div>
        </Modal.Body>
      </Modal>

      </div>
    )
  }
}
}
