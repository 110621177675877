import React, {Component} from 'react'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import ReactTagInput from "@pathofdev/react-tag-input";
import axios from 'axios'
import "@pathofdev/react-tag-input/build/index.css";
import {Alert} from "react-bootstrap";
import avatar from "../assets/website_logo.png";
import ReactLoading from 'react-loading';
import utils from './utils'
import ReactTags from 'react-tag-autocomplete'
import xss, {FilterXSS} from 'xss'

const getStatusText = (status) => {
    switch (status) {
        case 0:
            return <Alert variant="info" show={true}>This page is a draft</Alert>;
        case 1:
            return <Alert variant="warning" show={true}>This page is waiting for for approval</Alert>;
        case 2:
            return <Alert variant="warning" show={true}>This page was deleted or rejected, feel free to resubmit.</Alert>;

        case 10:
            return <Alert variant="danger" show={true}>This page has been published, your edit will be visible to public</Alert>;
    }
}
const xssInstance = new FilterXSS({
    css: false,
    onTagAttr: (tag, name, value, isWhiteAttr) => {
        if (name == "style") {
            return value
        }
    }
    // Parameters are the same with onTag
    // If a string is returned, the tag would be replaced with the string
    // If return nothing, the default measure would be taken (specifies using
    // escape, as described below)
})

const editorOption = {
    plugins:[
        'align',
        'image',
        'font',
        'link',

    ],
    defaultStyle:"font-size: 18px;",
    buttonList: [
        ['undo', 'redo'],
        // ['font', 'fontSize', 'formatBlock'],
        ['fontSize','fontColor'],
        // ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        // ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        // ['align', 'horizontalRule', 'list', 'lineHeight'],
        // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
        ['link', 'image', ],
        // ['imageGallery'], // You must add the "imageGalleryUrl".
        ['fullScreen', 'showBlocks'],
        //['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        // ['save', 'template'],
        // '/', Line break
    ],
    resizingBar:false,
    width:"100%",
    minHeight:"300px",
    height:"auto",
    imageUploadSizeLimit: "5000000",
    imageMultipleFile:false,
    imageAccept:".png,.jpg",


}

class RichContent extends Component{

    state = {
        src_id:null,
        status:0,
        loaded:false,
        tags : [],
        cover_url:"",
        categories:[],
        tagsList:[],
        description:"",
        suggestions:[],
        lang:"en_us"
    }
    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.title = React.createRef();
        this.description = React.createRef();
        this.language= React.createRef();
    }

    componentDidMount() {
        window.debug = this;
        window.richContent = this.editor.current;
        this.setup();
        this.loadTagList()
    }
    translate = (arr)=>{
        return utils.translate(arr, this.state.tagsList).map(
            (ele)=>({id:ele.tag_id,name:ele.value_cn})
        )
    }

    onTagDelete(i) {
        const categories = this.state.categories.slice(0)
        categories.splice(i, 1)
        this.setState({categories: categories})
    }

    onTagAddition(tag) {
        if (this.state.categories.length >= 6){
            alert('最多只能有6个标签！')
            return
        }
        const categories = [].concat(this.state.categories, tag.id)
        this.setState({categories: categories})

    }
    setup(){
        const title = this.title.current;
        const editor = this.editor.current;
        const lang = this.language.current
        if(this.props.action == "create"){
            this.setState({loaded:true})
        } else if (this.props.action == "update"){
            axios.get(utils.getUrl(`cms/blog/${this.props.src_id}/update/`),
                {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(
                    response => {
                        title.value = response.data.subject ;
                        editor.editor?.setContents(response.data.content)
                        this.setState({status:response.data.status,
                            tags:response.data.tags,
                            description:response.data.description || '',
                            loaded: true,
                            categories:response.data.categories,
                            cover_url:response.data.cover_url || '',
                            lang: response.data.lang || '',
                        })
                        lang.value = response.data.lang;
                    }

            ).catch(err => {
                // alert(err)
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.action != this.props.action){
          this.setup()
        }

    }
    getContents = () => xssInstance.process(this.editor.current.editor.getContents()||"");
    getCategoryIds = ()=>{
        return this.state.categories;
    }
    handleSave = ()=>{
        axios.post(utils.getUrl(`cms/blog/${this.props.src_id}/update/`),
            {"subject": this.title.current.value,
                "content": this.getContents(),
                "tags":this.state.tags,
                "description":this.state.description,
                "cover_url": this.state.cover_url,
                "categories": this.getCategoryIds(),
                "lang":this.state.lang,
                // "status": 0,
            },
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
                alert('保存成功！')
            if (this.state.status == 10) {
                this.props.history.replace({
                    pathname: `/blog/:${response.data.post_id}/show/`,
                })
            }
        }).catch(err => {
            // alert(err)
        })

    }

    handleCreate = () => {

        axios.post(utils.getUrl('cms/blog/create/'),
            {
                "subject": this.title.current.value,
                "content": this.getContents(),
                "description": this.state.description,
                "status": 0,
                "tags": this.state.tags,
                "categories": this.getCategoryIds(),
                "cover_url": this.state.cover_url,
                "lang": this.state.lang,
            },
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
                alert('创建成功！')
            // 
            this.props.history.replace({
                pathname: `/blog/:${response.data.post_id}/edit/`,
            })
            // this.setState({action:"update",src_id:response.data.post_id})
        }).catch(err => {
            // alert(err)
        })

    }
    handleCreateAndPublish = () => {
        axios.post(utils.getUrl('cms/blog/create/'),
            {
                "subject": this.title.current.value,
                "content": this.getContents(),
                "tags": this.state.tags,
                "description": this.state.description,
                "cover_url": this.state.cover_url,
                "categories": this.getCategoryIds(),
                "status": 1,
                "lang": this.state.lang,
            },
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
                alert('发布成功！')
            // 
            this.props.history.replace({
                pathname: `/blog/:${response.data.post_id}/show/`,
            })
            // this.setState({action:"update",src_id:response.data.post_id})
        }).catch(err => {
            // alert(err)
        })

    }
    handleDelete = () => {
        axios.delete(utils.getUrl(`cms/blog/${this.props.src_id}/update/`),
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
                alert('删除成功！')
            this.props.history.replace({
                pathname: '/blog/create/',})
            }).catch(err => {
                // alert(err)
        })
    }
    handlePublish = () => {
        axios.post(utils.getUrl(`cms/blog/${this.props.src_id}/update/`),
            {
                "subject": this.title.current.value,
                "content": this.getContents(),
                "tags": this.state.tags,
                "description": this.state.description,
                "cover_url": this.state.cover_url,
                "categories": this.getCategoryIds(),
                "status": 1,
                "lang": this.state.lang,
            },
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
                alert('发布成功！')
            this.props.history.replace({
                pathname: `/blog/:${response.data.post_id}/show/`,
            })
        }).catch(err => {
            // alert(err)
        })
    }
    handleUpload = (files, info, uploadHandler)=>{
        // 

        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append("ftype", files[0].name.split('.').reverse()[0]);
        axios.post(utils.getUrl('public/blog/imageUpload/'),
            formData,
            {
                headers: {
                    'Authorization': 'JWT ' + localStorage.getItem('loginToken'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(
                response => {
                    uploadHandler(response.data);
                }
        ).catch(err =>{
            // alert(err);
            uploadHandler({
                errorMessage:"err",
                result:[]
            })

        })
        return undefined;

    }
    handleUploadError = (errorMessage)=>{
        alert(errorMessage)
        return true
    }
    tagValidator = (tag)=>{
        const len = tag?.length || 0
        const valid = len > 0 && len < 20;
        if (!valid){
            alert('标签最多20个字符')
        }
        return valid
    }
    handleNewTags = (newTags)=>{
        this.setState({tags:newTags});
    }
    setDescription = (desc)=>{
        this.setState({description: desc})
    }
    loadTagList = async ()=>{
        await utils.loadTagList();
        const tagList = (window.tagList?.groupList || []).concat(window.tagList?.areaList || []);
        this.setState({tagsList:
            tagList,
            suggestions: tagList.map((ele) => {
            return {
                id: ele.tag_id,
                name: ele.value_cn,
            }
        }).filter((ele)=>![200001,300001].includes(ele.id))
        })

    }
    getSuggestions = ()=>{
        return this.state.suggestions.filter((ele)=>!this.state.categories.includes(ele.id))
    }

    uploadCover = (e)=>{
        const files = e.target.files;
        if (!(files?.length)){
            alert("请选择一个文件")
            return
        }
        if (files[0].size > 524288){
            alert("文件必须小于5MB")
            return;
        }
        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append("ftype", files[0].name.split('.').reverse()[0]);
        axios.post(utils.getUrl('public/blog/imageUpload/'),
            formData,
            {
                headers: {
                    'Authorization': 'JWT ' + localStorage.getItem('loginToken'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(
            response => {
                const url = response.data.result[0].url;
                this.setState({cover_url: url})
            }
        ).catch(err => {
            // alert(err);

        })
    }
    handlePreview= ()=>{
        this.props.history.replace({
            pathname: `/blog/:${this.props.src_id}/show/`,
        })
    }
    handleSelect = (e)=>{
        this.setState(
            {
                lang:e.target.value
            }
        )
    }
    render(){
        const propAction = this.props.action
        // 
        return (
            <div className="blog-container">
                    <div style={{display:this.state.loaded?"initial":"none"}}>
                        <div>
                            {
                                getStatusText(this.state.status)
                            }
                        </div>
                        <input type="text" ref={this.title} placeholder="Place Title Here" className="blog-title"/>
                        <div className="blog-set-language">
                            <span>
                                语言
                            </span>
                            <select onChange={this.handleSelect} ref={this.language}>
                                <option value="en_us">English</option>
                                <option value="cn">中文</option>
                            </select>
                        </div>
                        <div className="blog-cover-upload">
                            <button className="styleClass" onClick={()=>{
                                document.getElementById('uploadCover').click()}}>
                                Upload Cover</button>
                        <input type="file" accept=".png,.jpg" id="uploadCover" onChange={this.uploadCover} style={{display:"None"}}/>
                            {this.state.cover_url?<button className="blog-cover-delete" onClick={
                                ()=>this.setState({cover_url:""})
                            }>Delete Cover</button>:""
                            }
                        </div>

                            <div className="blog-cover-container">
                                {this.state.cover_url ?
                                <img src={this.state.cover_url} className="blog-cover-img"/>
                                    : <div className="blog-no-cover">NO COVER</div>
                                }
                            </div>


                        <div className="blog-description-container">
                        <textarea maxlength={99} rows={3}
                                  value={this.state.description}
                                  onChange={(e)=>this.setDescription(e.target.value || "")}
                                  ref={this.description} placeholder="Place Description Here" className="blog-description">

                        </textarea>
                        <div className="blog-description-textcount">Characters: {this.state.description.length}/99</div>
                        </div>
                        <div className="blog-category-input">
                            <ReactTags
                                tags={this.translate(this.state.categories)}
                                suggestions={this.getSuggestions()}
                                minQueryLength={0}
                                maxSuggestionsLength={100}
                                onDelete={this.onTagDelete.bind(this)}
                                onAddition={this.onTagAddition.bind(this)}
                            />
                        </div>
                        <div className="blog-tag-input">
                            <ReactTagInput tags={this.state.tags} onChange={this.handleNewTags}
                                           validator={this.tagValidator} maxTags={6} placeholder={"Type and press enter to add keywords"}/>
                        </div>
            <div className="blog-area">
                <SunEditor className="blog-area"
                    ref={this.editor}
                           setOptions={editorOption}
                           onImageUploadBefore={this.handleUpload}
                           handleUploadError={this.handleUploadError}

                />
            </div>
                <div className="blog-control-row">
                    {propAction=="update"?
                        <input type="submit" value="保存" className="blog-submit-button"
                               onClick={this.handleSave}/>:""
                    }
                    {propAction == "create" ?
                        <input type="submit" value="创建" className="blog-submit-button"
                               onClick={this.handleCreate}/>:
                        <input type="submit" value="删除" className="blog-submit-button"
                               onClick={this.handleDelete}
                        />
                    }

                    {this.state.status==0?(propAction == "create" ?
                        <input type="submit" value="发布" className="blog-submit-button"
                               onClick={this.handleCreateAndPublish}
                        />:
                        <input type="submit" value="发布" className="blog-submit-button"
                               onClick={this.handlePublish}
                        />):""
                    }
                    {propAction == "create" ?"":
                        <input type="submit" value="返回" className="blog-submit-button"
                               onClick={this.handlePreview}
                        />
                    }
                </div>
                </div>
                {this.state.loaded ?
                    "": <div className="blog-loading-container"><ReactLoading type='spinningBubbles' color='#5ecfd0'
                                                                        height={'15%'} width={'15%'}/></div>
                }
            </div>
        );
    }
}

RichContent.defaultProps = {
    action:"create",
    src_id:null,
    comment_target:null
}
export default RichContent;
