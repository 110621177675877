import React, { Component } from 'react'
import axios from 'axios'
import ScheduleConfig from './ScheduleConfig';
import ScheduleAppointment from './ScheduleAppointment';
import {Container, Row, Col, Alert, Modal, Button} from 'react-bootstrap'
import utils from './utils'

export default class CreateAppointment extends Component {

  state={
    user_id: '',
    profile_id: '',
    address: [],
    current_location_id: '',
    first_name: '',
    last_name: '',
    hourly_price: [],
    hasOnline: false,
    hasOnSite: false,
  }

  componentDidMount = async()=>{
    const user_id = parseInt(localStorage.getItem('user_id'))
    const profile_id = parseInt(localStorage.getItem('profile_id'))
    // get info
    await axios.get(utils.getUrl(`public/${profile_id}/homepage/`),
    {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
      .then((response) => {
      const data = response.data
      data.hourly_price.map(elem=>{
        if(elem.contact_method===1 || elem.contact_method===2){
          this.setState({hasOnline: true})
        }else if(elem.contact_method===3){
          this.setState({hasOnsite: true})
        }
      })
      console.log(this.state.hasOnline&&this.state.hasOnsite)
      this.setState({address: data.address, user_id: user_id, profile_id, first_name: data.user.first_name, 
        last_name: data.user.last_name})
    }).catch(err => {
      // alert(err)
    })
  }

  handleLocationChange = (event) => {
    if(event.target.value==='' && !this.state.hasOnline){
      alert('请先为该咨询方式设置一个价格！')
      return
    }else if(event.target.value!=='' && !this.state.hasOnsite){
      alert('请先为该咨询方式设置一个价格！')
      return
    }
    this.setState({current_location_id: event.target.value===''?'':parseInt(event.target.value)})
  }

  render() {
    return (
      <div>
        <Container style={{width: '100%'}}>
          <div style={{marginBottom: '40px'}}>
            <h3>咨询师：{this.state.first_name} {this.state.last_name}</h3>
            <span>
                <span style={{marginRight: '20px'}}>选择地址</span>
                <select id='location' onChange={(event)=>this.handleLocationChange(event)}>
                {this.state.hasOnline?<option value=''>线上</option>:''}
                {this.state.hasOnsite?this.state.address.map(elem=>
                  (<option value={elem.address_id}>{elem.street_1} {elem.street_2} {elem.city} {elem.state}</option>)):''
                }
                {!this.state.hasOnline&&!this.state.hasOnsite?<option disabled>暂无可选项，请先为咨询方式添加价格</option>:''}
                </select>
            </span>
          </div>
          <div style={{marginBottom: '16px'}}><h3>当前预约</h3></div>
          <div style={{marginBottom: '80px'}}><ScheduleAppointment key={'Appointment'+this.state.user_id+' '+ this.state.current_location_id} userId={this.state.user_id} locationId={this.state.current_location_id}/></div>
          <div style={{marginBottom: '16px', color: '#848F90'}}>
            TIPS: 在日历中双击空位以创建预约, 编辑完成后点击保存按钮储存结果
          </div>
          <div style={{marginBottom: '80px'}}><ScheduleConfig key={'Config'+this.state.user_id+' '+ this.state.current_location_id} userId={this.state.user_id} locationId={this.state.current_location_id}/></div>
        </Container>
      </div>
    )
  }
}
