import React, { Component } from 'react'
import barcode from '../imgs/wechat_public_barcode (2).jpg'
import {NavLink, withRouter} from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import '../css/footer.css'

class Footer extends Component {
  state = {
    url: '/',
    volunteerIframe: false,
    therapistIframe: false,
    homepageIframe: false,
    crisisInterventionResourceSharingIframe: false,
    width: 0,
  }

  componentDidMount = () => {
    this.setState({url: this.props.location.pathname});

    console.log(this.props.location.pathname);
  }

  componentDidUpdate = (prevProps) => {
    console.log(this.props.location.pathname);
    if (this.props.location.pathname !== prevProps.location.pathname){
      this.setState({url: this.props.location.pathname});
    }
  }

  subFooterText = () => {
    switch (this.state.url) {
      case "/counselorsunder30/":
        return (
          <div className="container-fluid grey-box px-lg-10 py-4">
            <div className="row justify-content-center align-items-center">
              {/* <div className="col-lg-6 col-12">
                  <p className="text-lg-end text-center pt-3">"您也是一名青年咨询师吗？诚邀您加入「FindSelf找我」"</p>
              </div> */}
              <div className="col-lg-3 col-12 text-center">
                <button className='btn-secondary p-3' onClick={()=>this.setState({therapistIframe: true})}>预约青年咨询师</button>
              </div>
            </div>
          </div>
        )
      case "/talk/":
        return (
          <div className="container-fluid grey-box px-lg-10 py-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-12">
                <p className="text-lg-end text-center pt-3">您也想成为一名「找我说」小伙伴，帮助他人吗？</p>
              </div>
              <div className="col-lg-3 col-12 text-lg-start text-center">
                <button className='btn-secondary p-3' onClick={()=>this.setState({volunteerIframe: true})}>小伙伴申请通道</button>
              </div>
            </div>
          </div>
        )
      case "/talking":
      case "/talktimetable":
      case "/events/":
      case "/crisisintervention":
      case "/talktome":
      case "/talktome/admin":
        return (
          <div className="container-fluid grey-box px-lg-10 py-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-12">
                <p className="text-lg-end text-center pt-3">您也有关于危机干预的资源吗？让我们把这份资源做一份整合</p>
              </div>
              <div className="col-lg-3 col-12 text-lg-start text-center">
                <button className='btn-secondary p-3' onClick={() => this.setState({ crisisInterventionResourceSharingIframe: true })}>分享资源</button>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div className="container-fluid grey-box px-lg-10 py-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-12">
                <p className="text-lg-end text-center pt-3">您也是一名心理健康工作者？诚邀您加入「FindSelf找我」</p>
              </div>
              <div className="col-lg-3 col-12 text-lg-start text-center">
                <button className='btn-secondary p-3' onClick={()=>this.setState({homepageIframe: true})}>即刻申请入驻</button>
                {/* <NavLink className="btn btn-secondary p-3" to="/signup/therapist">即刻申请入驻</NavLink> */}
              </div>
            </div>
          </div>
        )
    }
  }

  render() {
    return (
      <div>
        {this.subFooterText()}
        <nav className="container-fluid navbar navbar-expand-lg px-lg-10 footer">
            <div className="navbar-collapse mr-auto">
              <ul className="nav navbar-nav flex-grow-1 justify-content-center justify-content-lg-start">
                {/* <li className="nav-item text-center text-lg-start pe-4 pb-1"><NavLink className="footer-text" to="/search/therapists">找我咨询</NavLink></li> */}
                <li className="nav-item text-center text-lg-start pe-lg-4 pb-1"><NavLink className="footer-text" to="/counselorsunder30/">青年咨询师</NavLink></li>
                <li className="nav-item text-center text-lg-start pe-lg-4 pb-1"><NavLink className="footer-text" to="/talk/">找我说</NavLink></li>
                <li className="nav-item text-center text-lg-start pe-lg-4 pb-1"><NavLink className="footer-text" to="/events">找我团</NavLink></li>
                {/* <li className="nav-item text-center text-lg-start pe-lg-4 pb-1"><NavLink className="footer-text" to="/forum">找我论坛</NavLink></li> */}
                {/* <li className="nav-item text-center text-lg-start pe-lg-4 pb-1"><NavLink className="footer-text" to="/questions/">常见问题</NavLink></li> */}
                <li className="nav-item text-center text-lg-start pe-lg-4 pb-1"><NavLink className="footer-text" to="/aboutus/cn">关于找我</NavLink></li>
              </ul>
              <ul className="nav navbar-nav justify-content-center justify-content-lg-right">
                <li className="nav-item pe-lg-2 pt-lg-4 pe-lg-4 pt-4"><p className="footer-static-text text-center text-lg-start">找我公众号</p></li>
                <li className="nav-item text-center text-lg-start pt-lg-4 pe-lg-4 pe-lg-0 pt-0 justify-content-sm-center">
                    <img src={barcode} alt="" width="80" className="d-inline-block align-text-top text-center text-lg-start"/>
                </li>
              </ul>
          </div>
        </nav>
        <div className="container-fluid px-lg-10 footer py-1 text-lg-start text-center pe-4">
          <small>©FindSelf 2021. All rights reserved.</small>
        </div>

        {/* modals for applying for volunteers and therapists */}
        <Modal show={this.state.homepageIframe} onHide={()=>this.setState({homepageIframe: false})}>
          <Modal.Body>
            <div><iframe src="https://findself.va.mikecrm.com/okK4bpl" title="青年咨询师申请" className="footer_modal"></iframe></div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.volunteerIframe} onHide={()=>this.setState({volunteerIframe: false})}>
          <Modal.Body>
            <div><iframe src="https://findself.va.mikecrm.com/okK4bpl" title="小伙伴申请" className="footer_modal"></iframe></div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.therapistIframe} onHide={()=>this.setState({therapistIframe: false})}>
          <Modal.Body>
            <div><iframe src="https://findself.va.mikecrm.com/9zg4dQA" title="预约青年咨询师" className="footer_modal"></iframe></div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.crisisInterventionResourceSharingIframe} onHide={() => this.setState({ crisisInterventionResourceSharingIframe: false })}>
          <Modal.Body>
            <div><iframe src="https://findself.va.mikecrm.com/FMvoh4U" title="危机转介资源整合" className="footer_modal"></iframe></div>
          </Modal.Body>
        </Modal>

      </div>
    )
  }
}

export default withRouter(Footer)
