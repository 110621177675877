import React, {Component} from 'react'
import {NavLink, Redirect} from 'react-router-dom'
import axios from "axios";
import utils from './utils'


export default class userHomepage extends Component{
    state = {
        tgt : null
    }
    componentDidMount = ()=> {
        axios.get(utils.getUrl(`profile/${this.props.match.params.user_id.slice(0)}/profile-id/`)).then(
            (response)=>{

                this.setState({tgt: `/therapist/view/:${response.data.data}`})
            }).catch(
            (err)=>{
                // alert(err)
                this.setState({tgt: "/"})
            }
        )

    }

    render() {
        if (!this.state.tgt) {
            return <div></div>
        } else {
            return <Redirect to={this.state.tgt}></Redirect>
        }
    }
}
