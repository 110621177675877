import React, {Component} from 'react'
import {ViewState, EditingState, IntegratedEditing} from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    WeekView,
    Appointments,
    AppointmentTooltip,
    AppointmentForm,
    ConfirmationDialog,
    DragDropProvider,
    DateNavigator,
    TodayButton,
    Toolbar

} from '@devexpress/dx-react-scheduler-material-ui';
import {Row,Col} from 'react-bootstrap';
import AppointmentLayout from './AppointmentLayout'
import utils from './utils'
import axios from "axios";
import { DateTime } from 'luxon';

const IntervalArray = utils.getIntervalOptions()
const currentDate = '2018-11-01';
const schedulerData = [
    {startDate: '2018-11-01T09:45', endDate: '2018-11-01T11:00', title: 'Meeting'},
    {startDate: '2018-11-01T12:00', endDate: '2018-11-01T13:30', title: 'Go to a gym'},
];


const DoW = [
    utils.parseWeekDay('2018-10-29'),
    utils.parseWeekDay('2018-10-30'),
    utils.parseWeekDay('2018-10-31'),
    utils.parseWeekDay('2018-11-01'),
    utils.parseWeekDay('2018-11-02'),
    utils.parseWeekDay('2018-11-03'),
    utils.parseWeekDay('2018-10-28'),
]
window.dow= DoW;
window.utils = utils;
const BasicLayout = ({onFieldChange, appointmentData, ...restProps}) => {
    const onIntervalChange = (nextValue) => {
        onFieldChange({interval: nextValue,title:`Every ${nextValue} Min`});
    };

    return (
        <AppointmentLayout
            appointmentData={appointmentData}
            onFieldChange={onFieldChange}
            {...restProps}
        >
            {/*<AppointmentForm.Label*/}
            {/*    text="Time Interval"*/}
            {/*    type="title"*/}
            {/*/>*/}
            <select style={{display:"none"}} onChange={
                (e)=>{
                    onIntervalChange(e.target.value)
                }
            } value={appointmentData.interval}>
                {IntervalArray.map((ele)=><option value={ele}>{ele}</option>)}
            </select>

        </AppointmentLayout>
    );
};

class ScheduleConfig extends Component {
    state = {
        data : [],
        currentDate: new Date(),
        offset: 0,
        schedules: [],
        overwrites: [],
        owner: null,
        start_time: null,
        end_time: null,
        timezoneList: utils.timezoneList,
        timezone_id: '',
    }
    parseOneSchedule = (schedule,idx) => {
        try {
            const argv = schedule.split(',');
            const dow = parseInt(argv[0]);
            const times = argv[1].split('-');
            let interval = parseInt(argv[0]);
            interval = 30;
            let t0 = utils.hourStringToMs(times[0]);
            let t1 = utils.hourStringToMs(times[1]);
            let date = DoW[dow]

            return {
                startDate:(utils.addMinute(date,t0)),
                endDate:(utils.addMinute(date, t1)),
                title:`Every ${interval} Minutes`,
                interval: interval,
                id:idx+1,

            }
        } catch (e) {
            return null
        }

    }
    parseOneOverwrite = (schedule,idx) => {
        try {
            const argv = schedule.split(',');
            const date = utils.parseWeekDay (argv[0]);
            const times = argv[1].split('-');
            let interval = parseInt(argv[0]);
            interval = 30;
            let t0 = utils.hourStringToMs (times[0]);
            let t1 = utils.hourStringToMs(times[1]);

            return {
                startDate: utils.addMinute(date, t0),
                endDate: utils.addMinute(date, t1),
                interval: interval,
                title: `Evert ${interval} Minutes`,
                id:idx+1
            }
        } catch (e) {
            return null
        }

    }
    parseSchedules = (schedules) => {
        return schedules.map(
            this.parseOneSchedule
        ).filter(ele=>ele)
    }
    parseOverwrites= (overwrites) => {
        return overwrites.map(
            this.parseOneOverwrite
        ).filter(ele => ele)
    }
    getApiUrl = ()=>{
        let url = utils.getUrl(`appointment/availability/${this.props.userId}/`)
        if (this.props.locationId && this.props.locationId!=='') {
            url += '?location_id=' + this.props.locationId;
        }
        return url;
    }
    updateData = (response)=>{
        console.log(response)
        this.setState(
            {
                offset: response.data.data.offset,
                schedules: this.parseSchedules(response.data.data.schedules),
                overwrites: this.parseOverwrites(response.data.data.overwrites),
                owner: response.data.owner,
                start_time: response.data.start_time,
                end_time: response.data.end_time,
                timezone_id: response.data.data.timezone_id
            }
        )
    }
    getDataRemote = async () => {
        await axios.get(this.getApiUrl(),utils.getAxiosConfig()).then(
            this.updateData
        ).catch(err=>{})
    }
    commitChangesRoutine = ({added, changed, deleted}) => {

        this.setState((state) => {
            let data = state.schedules;
            if (added) {
                const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
                if (!added.interval){
                    added = {...added, interval: IntervalArray[0], title: `Every ${IntervalArray[0]} Min`}
                }
                data = [...data, {id: startingAddedId, ...added}];
            }
            if (changed) {
                data = data.map(appointment => (
                    changed[appointment.id] ? {...appointment, ...changed[appointment.id]} : appointment));
            }
            if (deleted !== undefined) {
                data = data.filter(appointment => appointment.id !== deleted);
            }
            return {schedules:data};
        });
    }
    commitChangesOverwrites = ({added, changed, deleted}) => {

        this.setState((state) => {
            let data = state.overwrites;
            if (added) {
                const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
                if (!added.interval) {
                    added = {...added, interval: IntervalArray[0], title: `Every ${IntervalArray[0]} Min`}
                }
                data = [...data, {id: startingAddedId, ...added}];
            }
            if (changed) {
                data = data.map(appointment => (
                    changed[appointment.id] ? {...appointment, ...changed[appointment.id]} : appointment));
            }
            if (deleted !== undefined) {
                data = data.filter(appointment => appointment.id !== deleted);
            }
            return {overwrites: data};
        });
    }
    currentDateChange = (newDate)=>{
        this.setState({currentDate:newDate})
    }
    componentDidUpdate = (prevProps, prevState, snapshot)=> {
        if (prevProps.data != this.props.data){
            this.setState({data:this.props.data})
        }
    }
    componentDidMount = async()=> {
        this.setState({data: this.props.data || []})
        this.getDataRemote()
        window.debug_c = this
    }
    componentDidUpdate(prevProps) {
        if (this.props.locationId !== prevProps.locationId) {
            this.setState({data: this.props.data || []})
            this.getDataRemote()
        }
    }

    encodeRoutine = ()=>{
        let data = this.state.schedules
        //data = data.filter(event => utils.dateToDateString(new Date(event.startDate)) == utils.dateToDateString(new Date(event.endDate)))
        return data.map(
            (event)=>{
                const startDate = event.startDate
                const endDate = event.endDate
                const prefix = (startDate.getDay() - 1 +7)% 7;
                if (utils.dateToDateString(startDate) !== utils.dateToDateString(endDate)){
                   // alert("Cannot have schedule spanning more than one day!")
                    return null
                }
                return `${prefix},${utils.dateToHourString(startDate)}-${utils.dateToHourString(endDate)},${event.interval}`
            }
        ).filter((ele)=>!!ele)
    }
    encodeOverwrites = () => {
        let data = this.state.overwrites
        return data.map(
            (event) => {
                const startDate = event.startDate
                const endDate = event.endDate
                const prefix = utils.dateToDateString(endDate)
                if (utils.dateToDateString(startDate) !== utils.dateToDateString(endDate)) {
                   // alert("Cannot have schedule spanning more than one day!")
                    return null
                }
                return `${prefix},${utils.dateToHourString(startDate)}-${utils.dateToHourString(endDate)},${event.interval}`
            }
        ).filter((ele) => !!ele)
    }
    saveData = async()=>{
        console.log(this.state.offset)
        const payload = {
            data:{
                offset: this.state.offset,
                schedules: this.encodeRoutine(),
                overwrites: this.encodeOverwrites(),
                timezone_id: this.state.timezone_id
            },
            start_time: this.state.start_time,
            end_time: this.state.end_time,
        }
        await axios.post(this.getApiUrl(),payload,utils.getAxiosConfig()).then(
            (response)=>{
                alert("保存成功！")
                this.updateData(response)
            }
        ).catch(err =>{})
    }
    setTimeZone = async(event)=>{
        let timezone_id = event.target.value
        if(timezone_id ==='0'){
            this.setState({offset: 0, timezone_id})
            return
        }
        var local = DateTime.local()
        var rezoned = local.setZone(timezone_id)
        let offset = rezoned.offset/60
        this.setState({offset, timezone_id})
    }
    getWeeklyScheduler = ()=> <Scheduler
        data={this.state.schedules}
        height={660}
        className="scheduler-weekly"
    >
        <ViewState
            currentDate={currentDate}
        />

        <EditingState
            onCommitChanges={this.commitChangesRoutine}

        />
        <IntegratedEditing/>

        <WeekView
            startDayHour={0}
            endDayHour={24}
            cellDuration={60}


            // timeTableCell={TimeTableCell}
            // dayScaleCell={DayScaleCell}
        />
        <Appointments/>
        <AppointmentTooltip
            showOpenButton
            showDeleteButton
        />
        {/*<ConfirmationDialog/>*/}
        <AppointmentForm
            basicLayoutComponent={BasicLayout}
        />
        <DragDropProvider/>
    </Scheduler>

    getOverwriteScheduler = ()=> <Scheduler
        data={this.state.overwrites}
        height={660}
    >
        <ViewState
            currentDate={this.state.currentDate}
            onCurrentDateChange={this.currentDateChange}
        />
            <Toolbar/>
            <DateNavigator/>
            <TodayButton/>

        <EditingState
            onCommitChanges={this.commitChangesOverwrites}

        />
        <IntegratedEditing/>

        <WeekView
            startDayHour={0}
            endDayHour={24}
            cellDuration={60}


            // timeTableCell={TimeTableCell}
            // dayScaleCell={DayScaleCell}
        />
        <Appointments/>
        <AppointmentTooltip
            showOpenButton
            showDeleteButton
        />
        {/*<ConfirmationDialog/>*/}
        <AppointmentForm
            basicLayoutComponent={BasicLayout}
        />
        <DragDropProvider/>
    </Scheduler>
    
    render() {
        return <div className="schedule-config">
            <div style={{margin: '20px 0'}}>
                <div>选择时区</div>
                <select id='timezone' onChange={(event)=>this.setTimeZone(event)}>
                <option value="0">UTC</option>
                {this.state.timezoneList.map(elem=>
                  {
                      if(this.state.timezone_id === elem.id){return <option value={elem.id} selected>{elem.value_cn}</option>}
                      else{return <option value={elem.id}>{elem.value_cn}</option>}
                  })
                }
                </select>
            </div>
            {/* <input type="number" value={this.state.offset} onChange={
                e=>this.setState({offset:e.target.value})
            }/> */}
            <div style={{marginBottom: '16px'}}><h3>编辑日常预约(每周重复)</h3></div>
            <div className="schedule-weekly">

                <div>
                    {this.getWeeklyScheduler()}
                </div>
            </div>
                    <div style={{marginTop: '16px'}}><input type='button' className="events-btn-3" onClick={this.saveData} style={{width: '144px', height: '32px'}} value='保存'></input></div>
            <div style={{marginBottom: '16px', marginTop: '32px'}}><h3>编辑临时预约(一次性)</h3></div>
                    {this.getOverwriteScheduler()}


            <div style={{marginTop: '16px'}}><input type='button' className="events-btn-3" onClick={this.saveData} style={{width: '144px', height: '32px'}} value='保存'></input></div>
        </div>
    }
}

ScheduleConfig.defaultProps = {
    showControl:false,
}
export default ScheduleConfig;

