import { Component } from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import utils from './utils'

import '../css/appointment.css'

export default class TicketCard extends Component {

    state = {
        ticket: this.props.data,
        paymentMethod: 'stripe',
        stripe_id: null,
        token: this.props.token || localStorage.getItem('loginToken')
    }

    componentWillMount = () => {
    }

    startPayment = async () => {
        await axios.post(utils.getUrl(`appointment/ticket/pay/${this.state.ticket.ticket_id}/`),
            { pay: this.state.paymentMethod }, { headers: { 'Authorization': 'JWT ' + this.state.token } })
            .then(response => {
                this.setState({ stripe_id: response.data.id })
            })
        const stripe = await loadStripe(utils.getStripeKey())
        const result = await stripe.redirectToCheckout({
            sessionId: this.state.stripe_id,
        })
        if (result.error) {
            alert('支付错误')
        }
    }

    render() {
        let ticket = this.state.ticket
        let consultant = ticket.consultant_data
        let meta = ticket.meta
        let user = ticket.user_data
        let ticketStatusString = ""
        let actionButton = ""
        let user_name = (user.first_name + user.last_name) || meta['full_name']
        switch (ticket.status) {
            case 0:
                ticketStatusString = "🆕 新订单"
                actionButton = <button type="button" onClick={()=>this.startPayment()} class="btn btn-success">支付订单</button>
                break;
            case 1:
                ticketStatusString = "?"
                break;
            case 2:
                ticketStatusString = "咨询完成 😄"
                actionButton = <button type="button" class="btn btn-light">进行评价</button>
                break;
            case 3:
                ticketStatusString = "已取消 ❌"
                actionButton = <Link to="/appointment" className="btn btn-light">再次预约</Link>
                break;
            case 4:
                ticketStatusString = "已支付 ✅"
                actionButton = <button type="button" class="btn btn-light">申请退款</button>
                break;
            default:
                break;
        }
        return (
            <div class="card" style={{ }}>
                <div class="row g-0">
                    <div class="card-body">
                        <h5 class="card-title">订单号：{ticket.ticket_id}</h5>
                        <div className="row">
                            <div className="col-lg-4">
                                <p class="card-text">咨询师： {consultant.first_name + consultant.last_name} </p>
                                <p class="card-text">订单状态： {ticketStatusString} </p>
                                <p class="card-text">提交时间： {new Date(ticket.created_time).toLocaleString()} </p>
                                <p class="card-text">订单更新： {new Date(ticket.updated_time).toLocaleString()} </p>
                            </div>
                            <div className="col-lg-4">
                                <p class="card-text">用户： {user_name} </p>
                                <p class="card-text">咨询开始时间 {new Date(ticket.start_time).toLocaleString()} </p>
                                <p class="card-text">咨询时长： {(ticket.length * 0.5) + '小时'} </p>
                                <p class="card-text">咨询方式： 在线语音/视频 </p>
                            </div>
                            <div className="col-lg-4">
                                <p class="card-text">支付金额： {(ticket.length * ticket.unit_price) + ticket.currency} </p>
                                <p class="card-text">
                                    <small class="text-muted">
                                        {actionButton}
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}