import React, { Component } from 'react'
import { Row, Col, Container, Jumbotron, Card, Modal } from 'react-bootstrap'
import AboutUsEdit from "./AboutUsEdit";
import {NavLink} from 'react-router-dom'
import aboutusImage from '../imgs/about-us-background.svg';
import pic1 from '../imgs/home_pic1.png';
import pic2 from '../imgs/home_pic2.png';
import pic3 from '../imgs/home_pic3.png';
import pic4 from '../imgs/home_pic4.png';
import analytics from '../imgs/Analytics.png'
import map from '../imgs/Map.png'
import message from '../imgs/Message.png'
import partner from '../imgs/Partner.png'

export default class Aboutus extends Component {
  state = {
    volunteerIframe: false,
    businessIframe: false,
  }

  componentDidMount = () => {
    this.props.setDisplayURL();
  }

  componentWillMount = async () => {
    document.title = "FindSelf找我 - 关于我们";
  }

  render() {
    return (
      <div>
        <div className="container-fluid px-lg-10 py-5">
          {/* <AboutUsEdit src_id="about_cn"/>   */}
            <div className="row">
              <div className="col-12 py-2">
                <h1>关于我们</h1>
                <p>「FindSelf找我」，一个源自宾大，属于你我的心理服务平台。</p>
                <p>2020是魔幻现实的一年。在美国摸爬滚打了多年的我们，亲身体会了美国社会的苦和难，深刻了解了中国留学生在这个多元文化中的笑与泪，以及在美华人遭遇的瓶颈与无奈。怀着关怀心理健康的美好愿望，「FindSelf找我」在这个特殊的年份诞生了。我们希望能够一直陪伴、支持你的「找我」心灵之旅。</p>
                <p>我们的初创团队毕业于宾夕法尼亚大学心理咨询专业。毕业后的我们形成了一股年轻一代在美心理咨询师的专业力量——「Counselors under 30™」，活跃在费城、纽约、波士顿、洛杉矶、北京、上海、广州等各大城市的临床中心、咨询机构中。</p>
                <p>作为心理咨询师，我们坚持「做实事，不浮躁」的踏实作风，不盲目扩张，不夸大宣传，力求打造与运营一个专业、真实的心理服务平台。</p>
                <p>在一路前行的过程中，我们逐渐拢聚起了更多方的力量，点点星光汇聚成一束属于在美留学生群体的光。「FindSelf找我」在未来会有更丰富的功能、服务和活动上线，在漫长岁月里继续给你暖心的陪伴与支持。</p>
              </div>
              <img src={aboutusImage} className="" alt="home"/>
            </div>
        </div>

        <div className="container-fluid px-lg-10 py-5">
          <div className="row mb-2">
            <h2>我们的服务</h2>
          </div>
          <div className="row m-2 g-1 g-lg-4">
            <div className="row col-lg-6 col-12 py-4">
              <div className="col-3">
                <img src={pic1} className="card-img" alt="找我咨询"/>
              </div>
              <div className="col-8">
                <h3>青年咨询师 - Counselors under 30™</h3>
                <p>海内外优质年轻一代心理咨询师，提供专业可靠的高性价比心理咨询服务。 能更好地站在你的角度倾听诉说、感知情绪、理解想法、分析问题、以一 个较为年轻的视角身份伴你走出困境</p>
                <NavLink to="/counselorsunder30/" className="btn btn-third">查看详情 <i className="bi bi-arrow-right"></i></NavLink>
              </div>
            </div>
            <div className="row col-lg-6 col-12 py-4">
              <div className="col-3">
                <img src={pic2} className="card-img" alt="找我说"/>
              </div>
              <div className="col-8">
                <h3>找我说</h3>
                <p>北美地区最大全时段匿名免费即时线上文字支持平台，24/7 等你来找我说</p>
                <NavLink to="/talk/" className="btn btn-third">查看详情 <i className="bi bi-arrow-right"></i></NavLink>
              </div>
            </div>
          </div>  

          {/* <div className="row m-2 g-1 g-lg-4">
            <div className="row col-lg-6 col-12 py-4">
              <div className="col-3">
                <img src={pic4} className="card-img" alt="找我团"/>
              </div>
              <div className="col-8">
                <h3>找我团</h3>
                <p>自我探索与成长也可以妙趣横生，丰富多彩的心理团体活动就在您的身边</p>
                <NavLink to="/events/" className="btn btn-third">查看详情 <i className="bi bi-arrow-right"></i></NavLink>
              </div>
            </div>
            <div className="row col-lg-6 col-12 py-4">
              <div className="col-3">
                <img src={pic3} className="card-img" alt="找我论坛"/>
              </div>
              <div className="col-8">
                <h3>找我论坛</h3>
                <p>更多关于心理的小知识？北美心理行业最新动态？欢迎来这里寻找答案</p>
                <NavLink to="/forum/" className="btn btn-third">查看详情 <i className="bi bi-arrow-right"></i></NavLink>
              </div>
            </div>
          </div> */}

        </div>
        <div className="container-fluid py-lg-2 py-4 px-lg-10">
            <div className="card-dark pt-4 pb-5">
                <div className="row pb-2 px-1">
                    <h2 className="text-center text-light">北美最大的中文文字心理支持平台。</h2>
                </div>
                <div className="row g-1 g-lg-2">
                    <div className="col-lg-3 col-6 text-center pb-4 pb-lg-1">
                        <p className="text-center text-light">小伙伴</p>
                        <img src={partner} className="text-center icon" alt="..."></img>
                        <h1 className="text-center text-light pt-2">100+<span  class="fs-5"> 位</span></h1>
                    </div>
                    <div className="col-lg-3 col-6 text-center">
                        <p className="text-center text-light">每月来访</p>
                        <img src={analytics} className="text-center icon" alt="..."></img>
                        <h1 className="text-center text-light pt-2">900+<span  class="fs-5"> 人次</span></h1>
                    </div>
                    <div className="col-lg-3 col-6 text-center">
                        <p className="text-center text-light">累计帮助</p>
                        <img src={map} className="text-center icon" alt="..."></img>
                        <h1 className="text-center text-light pt-2">12, 000+<span  class="fs-5"> 人次</span></h1>
                    </div>
                    <div className="col-lg-3 col-6 text-center">
                        <p className="text-center text-light">每月消息</p>
                        <img src={message} className="text-center icon" alt="..."></img>
                        <h1 className="text-center text-light pt-2">22, 000+<span  class="fs-5"> 条</span></h1>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
        {/* <AboutUsEdit src_id="extra_2"/> */}
        {/* <AboutUsEdit src_id="tawkto"/> */}
        <div class="container-fluid px-lg-10 py-5">
          <div class="row g-1 g-lg-4">
            <div class="col-12">
              <h2>合作院校</h2>
              <p>「找我说」也正积极完善与海内外高校的合作。我们期待「找我说」能够成为更多高校心理咨询中心的官方推介资源，为广大师生提供更具有针对性、时效性的心理援助。</p>
            </div>
          </div>
          <div class="row align-items-center g-1 g-lg-4">
            <div class="col-3 col">
              <img src="https://branding.web-resources.upenn.edu/sites/default/files/UniversityofPennsylvania_FullLogo_RGB_0.png" class="brand" alt="..."></img>
            </div>
            <div class="col-3">
              <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/e0ab803bcb0198995d574d28cc8d185d.png" class="brand" alt="..."></img>
            </div>
            <div class="col-3">
              <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/d0fdf78c01d36d9f330450738639ea30.png" class="brand" alt="..."></img>
            </div>
            <div class="col-3">
              <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/2f1d46ff075925b25c742a55309c987a.png" class="brand" alt="..."></img>
            </div>
            <div class="col-3">
              <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/1003dcc73580ea525f230f2d13002a07.png" class="brand" alt="..."></img>
            </div>
            <div class="col-3">
              <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/b518454e0cda32a09be14b455c666def.png" class="brand" alt="..."></img>
            </div>
            <div class="col-3">
              <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/afc9deabbc5b01cb8f10298ce7b7b454.png" class="brand" alt="..."></img>
            </div>
          </div>
        </div>
        {/* <Row>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button onClick={()=>this.setState({businessIframe: true})} className='homepage-btn-3' style={{ width: 200, height: 60, borderRadius: '4px', marginTop: 24 }}>商务合作</button>
            <button onClick={()=>this.props.history.push(`/signup/therapist`)} className='homepage-btn-3' style={{ width: 200, height: 60, borderRadius: '4px', marginTop: 24 }}>咨询师入住</button>
            <button onClick={()=>alert('敬请期待')} className='homepage-btn-3' style={{ width: 200, height: 60, borderRadius: '4px', marginTop: 24 }}>实习生招募</button>
            <button onClick={()=>this.setState({volunteerIframe: true})} className='homepage-btn-3' style={{ width: 200, height: 60, borderRadius: '4px', marginTop: 24 }}>志愿者招募</button>
          </div>
        </Row>

        <Modal show={this.state.volunteerIframe} onHide={()=>this.setState({volunteerIframe: false})} animation={false} dialogClassName="tawkto-modal">
          <Modal.Body>
            <div>
              <iframe src="https://findself.va.mikecrm.com/okK4bpl" style={{ height: 800, display: 'block', minWidth: '100%', width: 100, border: 'none', overflow: 'auto' }}></iframe>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.businessIframe} onHide={()=>this.setState({businessIframe: false})} animation={false} dialogClassName="tawkto-modal">
          <Modal.Body>
            <div>
              <iframe src="https://findself.va.mikecrm.com/CVmAMZJ" style={{ height: 800, display: 'block', minWidth: '100%', width: 100, border: 'none', overflow: 'auto' }}></iframe>
            </div>
          </Modal.Body>
        </Modal> */}
      </div>
    )
  }
}
