import React, { Component } from 'react'
import AboutUsEdit from "./AboutUsEdit";
import {Row, Col, Container} from 'react-bootstrap'

export default class Aboutus_en extends Component {
  render() {
    return (
      <div>
        {/* title */}
        <Row className='my_title'>
          <Col md={2}></Col>
          <Col md={4}><h2>A letter from our team:</h2></Col>
        </Row>  
        {/* content */}
          <AboutUsEdit src_id="about_en"/>
      </div>
    )
  }
}
