import React, {Component} from 'react'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import axios from 'axios'
import AlertModal from './alertModal'
import xss from 'xss'
import _ from './locale'
import utils from './utils'

const editorOption = {
    plugins: [
        'font',
        'link'
    ],
    defaultStyle: "font-size: 18px;",
    buttonList: [
        ['undo', 'redo'],
        // ['font', 'fontSize', 'formatBlock'],
        // ['fontSize', 'formatBlock'],
        // ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        // ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        // ['align', 'horizontalRule', 'list', 'lineHeight'],
        // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
        ['link',],
        // ['imageGallery'], // You must add the "imageGalleryUrl".
        // ['save', 'template'],
        // '/', Line break
    ],
    resizingBar: false,
    width: "100%",
    height: "150px",
    // maxCharCount: "500",
    showModal: false,
    modalText: ''
}

const countWords = (str)=> {
    // var matches = str.match(/[\u00ff-\uffff]|\S+/g);
    // return matches ? matches.length : 0;
    return str.length || 0
}
class CommentBox extends Component {

    state= {
        showModal: false,
        modalText: '',
        wordCount:0,
        content:""
    }

    constructor(props) {
        super(props);
        this.editor = React.createRef();
    }

    getContents = () => (this.editor.current.editor.getContents());

    handleCreate = ()=>{

        const parent = this.props.parent;
        if (!localStorage.getItem('loginToken')){
            alert('您必须先登录！')
            return;
        }
        if (!parent){
            alert('不是有效的id！')
            return;
        }
        const content = this.getContents()
        const charCount = this.getWordCount(content)
        if (charCount < 10 ){
            alert('请输入至少10个字')
            return;
        }
        const response_to = parseInt(window.$(content).find('.reply-id').first().text() || 0) || undefined;
        axios.post(utils.getUrl('cms/blog/create/'),
            {
                "subject": `REPLAY-TO-${parent}`,
                "content": xss(content),
                "status": 10,
                "type":1,
                'parent': parent,
                "response_to": response_to,
            },
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
            alert('创建成功！')
            this.editor.current.editor.setContents("");
            this.props.postSubmit();
            // this.setState({action:"update",src_id:response.data.post_id})
        }).catch(err => {
            // alert(err)
        })
    }

    appendContents = (msg) => {
        this.editor.current.editor.appendContents(msg);
    }

    setContents = (msg)=>{
        this.editor.current.editor.setContents(xss(msg||""));
    }
    getWordCount = (content)=>{
       const ctx =  window?.$(`<div>${content || ''}</div>`)
        ctx.find('blockquote').remove()
        return countWords(ctx.text()) || 0
    }

    handleChange = (content)=>{
        const wordCount = this.getWordCount(content)
        if (wordCount > 9999){
            this.setContents(this.state.content || "")
        } else {
            this.setState({wordCount: wordCount, content: content})
            // this.setContents
        }

    }

    handleKeyPress = (e)=>{
        const content = xss(this.getContents())
        this.handleChange(content)
    }
    render() {
        return (
            <div onKeyPress={this.handleKeyPress}>

                <SunEditor className="blog-area"
                           ref={this.editor}
                           setOptions={editorOption}
                           onChange={this.handleChange}
                           // onChange = {this.handleChange}
                />
                <div>{this.state.wordCount}/9999</div>
                <div className="blog-control-row">
                    <input type="submit" value={_("Reply" )}className="blog-submit-button"

                           onClick={this.handleCreate}/>
                </div>
            </div>
        )
    }
}

CommentBox.defaultProps = {
    parent:null,
    postSubmit:()=>{}
}
export default CommentBox;
