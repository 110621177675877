import React, { Component } from 'react';
import { Container, Row, Col, Image, Form, Modal } from 'react-bootstrap';
import axios from 'axios'
import utils from './utils';
import AboutUsEdit from "./AboutUsEdit";

export default class Verification extends Component {

  state = {
    verification_code: '',
    signature: '',
    registerData: {},
    address: {},
    userId: null,
    invitation: null,
    displayTerms: false,
  }

  openTerms = () => {
    if (this.state.verification_code === '') {
      alert('请先输入验证码!');
      return;
    }

    this.setState({ displayTerms: true });
  }

  handleSubmit = async (event) => {
    this.setState({ displayTerms: false });
    event.preventDefault();
    // verify code
    await axios.post(utils.getUrl('permission/email-verification/verify-code/'),
      { email: this.props.location.state.email, code: this.state.verification_code, action: 'register' })
      .then(response => {
        if (response.data.match) {
          alert('验证成功！')
          this.setState({ signature: response.data.signature })
        } else {
          alert('验证码错误！')
          return false;
        }
      }).catch(err => { })

    // 进行注册
    let isRegistered = false
    var formData = new FormData();
    const registerData = { 'meta': this.state.registerData, 'signature': this.state.signature, 'invitation': this.state.invitation, 'credential': localStorage.getItem('guri') }
    const data = JSON.stringify(registerData)
    formData.append('data', data)
    await axios.post(utils.getUrl('permission/register/'),
      formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => {
        this.setState({ userId: response.data.data.id, userType: response.data.data.user_type })
        isRegistered = true
      }).catch(err => { })

    if (isRegistered) {
      await axios.post(utils.getUrl('permission/jwt/auth/'),
        { username: this.state.registerData.username, password: this.state.registerData.password })
        .then(response => {
          localStorage.setItem('loginToken', response.data.token)
          this.handleSuccessLogin()
          if (this.state.userType === 4) {
            alert('注册成功！')
            this.props.history.push({ pathname: `/webuser/profile/:${this.state.userId}` })
          } else if (this.state.userType === 3) {
            if (this.state.invitation !== null) {
              alert('注册成功！')
              const profile_id = localStorage.getItem('profile_id');
              this.props.history.push({ pathname: `/therapist/edit/:${this.state.userId}/:${profile_id}` })
            } else {
              this.props.history.push({ pathname: `/therapist/profile/register`, state: { id: this.state.userId } })
            }
          }
        }).catch(err => {
          // alert(err)
        })
    }
  }

  handleSuccessLogin = async () => {
    // get user id
    let timeStamp = Math.floor(Date.now() / 1000);
    localStorage.setItem("logInTime", timeStamp)
    await axios.get(utils.getUrl('permission/status/'),
      { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
      .then(response => {
        this.setState({ userId: response.data.user_id })
        localStorage.setItem('user_id', response.data.user_id)
      }).catch(err => {
        // alert(err)
      })

    // get user info
    await axios.get(utils.getUrl(`profile/${this.state.userId}/`),
      { headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
      .then(response => {
        window.debug_response = response
        if (response.data.frozen) {
          utils.logout();
          alert("账号已被冻结，请联系管理员")
          return
        }
        localStorage.setItem('user_avatar', response.data.photo_url)
        localStorage.setItem('username', response.data.username)
        if (response.data.user_type === 4) {
          localStorage.setItem('full_name', response.data.nickname)
          localStorage.setItem('first_name', response.data.nickname)
        } else {
          localStorage.setItem('full_name', response.data.first_name + ' ' + response.data.last_name)
          localStorage.setItem('first_name', response.data.first_name)
        }
        localStorage.setItem('user_type', response.data.user_type)
        localStorage.setItem('user_email', response.data.email)

        if (response.data.profiles.length > 0) {
          const profile_id = response.data.profiles[0].profile_id
          if (response.data.user_type === 3) {
            localStorage.setItem('profile_id', profile_id)
          }

        }
      }).catch(err => {
        // alert(err)
      })
  }

  returnHomePage = () => {
    this.props.history.push({ pathname: '/home' });
  }

  // send verification email
  sendVerification = async () => {
    await axios.post(utils.getUrl('permission/email-verification/generate-code/'), { email: this.props.location.state.email, action: 'register' })
      .then(response => {
        if (response.data.status === 0) {
          alert('已重新发送邮件')
        }
      }).catch(err => {
        // alert(err)
      })
  }

  handleCodeChange = (event) => {
    this.setState({ verification_code: event.target.value })
  }

  componentDidMount = () => {
    this.setState({ registerData: this.props.location.state.data, invitation: this.props.location.state.invitation })
  }

  render() {
    const email = this.props.location.state.email
    return (
      // <div>
      //   <div id="form-section" class="container-fluid signup" style={{backgroundColor: '#FFF1EE', position: 'relative'}}>
      //       <div class="row">
      //           <div class="form-holder" style={{marginRight: '0px', paddingBottom: '80px'}}>
      //               <div class="signin-signup-form">
      //                   <div class="form-items">
      //                       <div class="form-title" style={{fontWeight: 'bold', fontSize: '21px'}}>验证邮箱</div>
      //                       <div class="form-title" style={{fontSize: '16px', color: '#828588', marginBottom: '20px'}}>验证码已经发送到：</div>
      //                       <div class="form-title" style={{fontSize: '20px'}}>{email}</div>
      //                       <form id="signinform">
      //                           <div class="form-text">
      //                               <div style={{color: '#abb4bc', margin: '10px', fontWeight: '600'}}>验证码</div>
      //                               <input type="text" name="verification_code" placeholder="验证码" onChange={this.handleCodeChange} required/>
      //                           </div>
      //                           <div class="form-button">
      //                               <button id="submit" type="submit" class="btn btn-default" onClick={this.handleSubmit}>提交<i class="hno hno-arrow-right"></i></button>
      //                           </div>
      //                           <div class="menu-holder">
      //                               <ul class="main-links" style={{color: '#000000', position: 'relative', top: '0', left: '0', textAlign: 'left'}}>
      //                                 <li><a class="normal-link" href="javascript:void(0)" onClick={this.sendVerification}>没收到验证码?</a></li>
      //                               </ul>
      //                           </div>
      //                       </form>
      //                   </div>
      //               </div>
      //           </div>
      //       </div>
      //       </div>
      // </div>
      <Container>
        <Modal show={this.state.displayTerms} onHide={() => this.setState({ displayTerms: false })} animation={false} dialogClassName="verification-modal">
          <Modal.Body>
            <AboutUsEdit src_id="register" />
          </Modal.Body>

          <Modal.Footer>
            <p className=''>我已阅读「FindSelf找我」的 <a href='/#/agreements'>《知情同意书》</a> <a href='/#/userTerms'>《用户服务协议》</a>和<a href='/#/privacy'>《隐私政策》</a>。</p>
            <button className='btn btn-primary' onClick={this.handleSubmit}>我同意</button>
            <button className='btn btn-secondary' onClick={this.returnHomePage}>拒绝</button>
          </Modal.Footer>
        </Modal>

        <Container className='create-event-container' style={{ margin: 'auto', width: 600, display: 'block' }}>
          <Row>
            <div >
              <span className='create-event-subtitle'>验证邮箱</span>
            </div>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <div>
              验证码已经发送到：{email}
            </div>
          </Row>

          <Row>
            <div className='create-event-input'>
              <input style={{ paddingLeft: '1%' }} type="text" name="verification_code" placeholder="验证码" onChange={this.handleCodeChange} required />
            </div>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <div>
              <a className='align-middle' href="javascript:void(0)" onClick={this.sendVerification} style={{ color: '#43B0B1' }}>没收到验证码?</a>
            </div>
          </Row>

          <Row>
            <a><button onClick={this.openTerms} className='homepage-btn-1' style={{ borderRadius: '4px', marginTop: 24 }}>提交</button></a>
          </Row>
        </Container>
        <div style={{ height: '200px' }}></div>
      </Container>
    )
  }
}
