import { Component } from "react";
import axios from 'axios'
import utils from './utils'
import MapPin from '../imgs/MapPin@2x.png'
import Medal from '../imgs/Medal@2x.png'
import '../css/TherapistInformation.css'


export default class TherapistInformation extends Component {

    constructor(props) {
        super(props);
        this.updateTherapist = this.updateTherapist.bind(this);
        this.handlePhotoChange = this.handlePhotoChange.bind(this);
        this.handleSpecialitiesChange = this.handleSpecialitiesChange.bind(this);
        this.handleWorkPopulationsChange = this.handleWorkPopulationsChange.bind(this);
    }
    
    state = {
        name: '',
        photo: null,
        city: '',
        title: '',
        intro: '',
        is_intern: true,
        experience: '',
        education_background1: '',
        education_background2: '',
        training1Start:'',
        training1End: '',
        training1Name: '',
        training2Start: '',
        training2End: '',
        training2Name: '',
        training3Start: '',
        training3End: null,
        training3Name: '',
        work_populations: ["人际关系", "心身健康"],
        specialities: ['精神分析和心里动力疗法'],
        duration: '',
        cost: '',
        costMulti: '',
        process: '',
        newUser: true,
        photoUrl: '',
        displayMode: false,
    }

    componentWillMount = () => {
        document.title = "FindSelf找我 - 青年咨询师";
        var paths = window.location.hash.split('/')
        console.log(paths)
        //直接访问
        if (paths.length === 2) {
        } 
        //点击修改访问
        else if (paths.length !== 2) {
            var therapistId = paths[2];
            axios.get(utils.getUrl(`consultant-page/${therapistId}/`))
                .then(response => {
                    console.log(response.data)
                    this.setState({
                        id: response.data.id,
                        name: response.data.name,
                        city: response.data.city,
                        title: response.data.title,
                        intro: response.data.intro,
                        is_intern: response.data.is_intern,
                        experience: response.data.experience,
                        education_background1: response.data.education_background1,
                        education_background2: response.data.education_background2,
                        training1Start: response.data.training1Start,
                        training1End: response.data.training1End,
                        training1Name: response.data.training1Name,
                        training2Start: response.data.training2Start,
                        training2End: response.data.training2End,
                        training2Name: response.data.training2Name,
                        training3Start: response.data.training3Start,
                        training3End: response.data.training3End,
                        training3Name: response.data.training3Name,
                        work_populations: response.data.work_populations,
                        specialities: response.data.specialities,
                        duration: response.data.duration,
                        cost: response.data.cost,
                        costMulti: response.data.costMulti,
                        process: response.data.process,
                        newUser: false,
                        photoUrl: response.data.photo,
                        displayMode: paths[3] === 'view' ? true : false,
                    });
                })
                .catch(err => {
                    window.location.href = '/#/home';
                })
        } else {
            window.location.href = '/#/home';
        }
        
    }

    updateTherapist() {
        if (this.state.name === '') {
            alert('请填写咨询师信息')
            return
        }
        if (this.state.newUser){
            const formData = new FormData();
            formData.append('name', this.state.name);
            formData.append('city', this.state.city);
            formData.append('title', this.state.title);
            formData.append('intro', this.state.intro);
            formData.append('is_intern', this.state.is_intern);
            formData.append('experience', this.state.experience);
            formData.append('education_background1', this.state.education_background1);
            formData.append('education_background2', this.state.education_background2);
            formData.append('training1Start', this.state.training1Start);
            formData.append('training1End', this.state.training1End);
            formData.append('training1Name', this.state.training1Name);

            formData.append('training2Start', this.state.training2Start);
            formData.append('training2End', this.state.training2End);
            formData.append('training2Name', this.state.training2Name);

            formData.append('training3Start', this.state.training3Start);
            formData.append('training3End', this.state.training3End);
            formData.append('training3Name', this.state.training3Name);
            for (let i = 0; i < this.state.work_populations.length; i++) {
                formData.append(`work_populations[${i}]`, this.state.work_populations[i]);
            }
            for (let i = 0; i < this.state.specialities.length; i++) {
                formData.append(`specialities[${i}]`, this.state.specialities[i]);
            }
            formData.append('duration', this.state.duration);
            formData.append('cost', this.state.cost);
            formData.append('costMulti', this.state.costMulti);
            formData.append('process', this.state.process);
            if (this.state.photo){
                formData.append('photo', this.state.photo);
            }
            console.log(formData);
            axios.post(utils.getUrl('consultant-page/'),
                formData,
                {
                    headers: {
                        /*'Authorization': "Token " + localStorage.getItem('loginToken'),*/
                        'Content-Type': 'multipart/form-data'
                    }
                })
            .then(response => {
                console.log(response)
                this.setState({ 
                    id: response.data.id
                })
                alert('上传成功')
            })
            .catch(err => { 
                console.log(err)
                alert("生成发生错误，请稍再在试")
            })
        }else{
            const formData = new FormData();
            formData.append('name', this.state.name);
            formData.append('city', this.state.city);
            formData.append('title', this.state.title);
            formData.append('intro', this.state.intro);
            formData.append('is_intern', this.state.is_intern);
            formData.append('experience', this.state.experience);
            formData.append('education_background1', this.state.education_background1);
            formData.append('education_background2', this.state.education_background2);
            formData.append('training1Start', this.state.training1Start);
            formData.append('training1End', this.state.training1End);
            formData.append('training1Name', this.state.training1Name);

            formData.append('training2Start', this.state.training2Start);
            formData.append('training2End', this.state.training2End);
            formData.append('training2Name', this.state.training2Name);

            formData.append('training3Start', this.state.training3Start);
            formData.append('training3End', this.state.training3End);
            formData.append('training3Name', this.state.training3Name);
            for (let i = 0; i < this.state.work_populations.length; i++) {
                formData.append(`work_populations[${i}]`, this.state.work_populations[i]);
            }
            for (let i = 0; i < this.state.specialities.length; i++) {
                formData.append(`specialities[${i}]`, this.state.specialities[i]);
            }
            formData.append('duration', this.state.duration);
            formData.append('cost', this.state.cost);
            formData.append('costMulti', this.state.costMulti);
            formData.append('process', this.state.process);
            if (this.state.photo){
                formData.append('photo', this.state.photo);
            }
            let temp = axios.put(utils.getUrl(`consultant-page/${this.state.id}/`),
            formData,
                {
                    headers: {
                        /*'Authorization': "Token " + localStorage.getItem('loginToken'),*/
                        'Content-Type': 'multipart/form-data'
                    }
                })
            .then(response => {
                console.log(response)
                alert('修改成功')
            })
            .catch(err => { 
                console.log(err)
                alert("生成发生错误，请稍再在试")
            })
        }
    } 

    handlePhotoChange(event) {
        if(event.target.files[0]){
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                photo: file,
                photoUrl: reader.result,
                });
            };
            reader.readAsDataURL(file);
        } 
    } 
    
    handleSpecialitiesChange(event) {
        const { options } = event.target;
        const values = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }
        this.setState({ specialities: values });
    }

    handleWorkPopulationsChange(event) {
        const { options } = event.target;
        const values = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }
        this.setState({ work_populations: values });
    }

    render() {
        return (
            <div className="container">
                <div className="row mb-3">
                    {this.state.displayMode ? 
                    <div className="row">
                        <div className="d-flex align-items-center mb-2">
                            <div className="fs-5 fw-bold me-4">{this.state.name}</div>
                            <div className="fs-5">{this.state.title}</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                                <span class="badge rounded-pill bg-primary">咨询师</span>
                            </div>
                            <div className="d-flex" style={{ fontSize: 16, fontWeight: 300 }}>
                                <div className="me-3">
                                    <img src={MapPin} className='location-rate-icons' alt="MapPin" />
                                    {this.state.city}
                                </div>
                                <div>
                                    <img src={Medal} className='location-rate-icons' alt="Medal" />
                                    咨询经验: {this.state.experience}+小时
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row mb-4">
                                    <h5> 个人介绍 </h5>
                                    <br />
                                    <div>
                                        {this.state.intro}
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <h5> 咨询方式 </h5>
                                    <br />
                                    <div>
                                        {this.state.process}
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <h5> 教育经历 </h5>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-4 pt-0 d-flex align-items-stretch" style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                                            <div className="card grey-box" style={{ border: 0, borderRadius: 12, width: '100%' }}>
                                                <div className="card-body">
                                                    <h5 className="card-title" style={{ fontSize: 16 }}><strong>1</strong></h5>
                                                    <p className="card-text" style={{ fontSize: 14 }}>
                                                        {this.state.education_background1}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pt-0 d-flex align-items-stretch" style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                                            <div className="card grey-box" style={{ border: 0, borderRadius: 12, width: '100%' }}>
                                                <div className="card-body">
                                                    <h5 className="card-title" style={{ fontSize: 16 }}><strong>2</strong></h5>
                                                    <p className="card-text" style={{ fontSize: 14 }}>
                                                            {this.state.education_background2}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <h5> 培训经历 </h5>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-4 pt-0 d-flex align-items-stretch" style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                                            <div className="card grey-box" style={{ border: 0, borderRadius: 12, width: '100%' }}>
                                                <div className="card-body">
                                                    <h5 className="card-title" style={{ fontSize: 16 }}><strong>{this.state.training1Start} - {this.state.training1End}</strong></h5>
                                                    <p className="card-text" style={{ fontSize: 14 }}>
                                                        {this.state.training1Name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pt-0 d-flex align-items-stretch" style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                                            <div className="card grey-box" style={{ border: 0, borderRadius: 12, width: '100%' }}>
                                                <div className="card-body">
                                                    <h5 className="card-title" style={{ fontSize: 16 }}><strong>{this.state.training2Start} 到 {this.state.training2End}</strong></h5>
                                                    <p className="card-text" style={{ fontSize: 14 }}>
                                                        {this.state.training2Name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pt-0 d-flex align-items-stretch" style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                                            <div className="card grey-box" style={{ border: 0, borderRadius: 12, width: '100%' }}>
                                                <div className="card-body">
                                                    <h5 className="card-title" style={{ fontSize: 16 }}><strong>{this.state.training3Start} 到 {this.state.training3End}</strong></h5>
                                                    <p className="card-text" style={{ fontSize: 14 }}>
                                                        {this.state.training3Name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <h5> 工作人群 </h5>
                                    <br />
                                    <div>
                                        {this.state.work_populations.map(element => <div className="pill">{element}</div>)}
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <h5> 擅长方向 </h5>
                                    <br />
                                    <div>
                                        {this.state.specialities.map(element => <div className="pill">{element}</div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="d-flex flex-column align-items-center">
                                    <img src={this.state.photoUrl} alt="Counselor Profile" className="profile-image" />
                                    <button id="submit" type="submit" class="btn btn-primary px-5">联系猫猫预约注册</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    <div className="col-lg-12">                     
                        <from className="row g-3">
                            <div className="col-12 fs-6 fw-bold" style={{ color:'#2e6173', textDecoration: 'underline', textDecorationColor: '#B8E1E1', textDecorationThickness: 2, textUnderlineOffset: 8 }}>
                                个人信息
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="text" className="form-control" id="name" placeholder="findself" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                <label for="name">姓名</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="text" className="form-control" id="title" placeholder="findself" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} />
                                <label for="title">展示称谓</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="text" className="form-control" id="city" placeholder="findself" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                <label for="city">城市</label>
                            </div>
                            <div className="col-md-12 form-floating mb-1">
                                <input type="text" className="form-control" id="intro" placeholder="findself" value={this.state.intro} onChange={(e) => this.setState({ intro: e.target.value })}  />
                                <label for="intro">个人介绍</label>
                            </div>
                            <div className="col-12 fs-6 fw-bold" style={{ color:'#2e6173', textDecoration: 'underline', textDecorationColor: '#B8E1E1', textDecorationThickness: 2, textUnderlineOffset: 8 }}>
                                咨询师经验
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <select className="form-select" aria-label="Default select example" id="is_intern" value={this.state.is_intern} onChange={(e) => this.setState({ is_intern: e.target.value })} >
                                    <option value={false}>咨询师</option>
                                    <option value={true}>实习咨询师</option>
                                </select>
                                <label for="is_intern">咨询师类型</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <input type="number" className="form-control" id="experience" placeholder="findself" value={this.state.experience} onChange={(e) => this.setState({ experience: e.target.value })}  />
                                <label for="experience">咨询经验（小时）</label>
                            </div>
                            <div className="col-md-12 form-floating mb-1">
                                <input type="text" className="form-control" id="education_background1" placeholder="findself" value={this.state.education_background1} onChange={(e) => this.setState({education_background1: e.target.value})} />
                                <label for="education_background1">教育背景1</label>
                            </div>
                            <div className="col-md-12 form-floating mb-1">
                                <input type="text" className="form-control" id="education_background1" placeholder="findself" value={this.state.education_background2} onChange={(e) => this.setState({education_background2: e.target.value})}/>
                                <label for="education_background2">教育背景2</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="date" className="form-control" id="training1StartDate" placeholder="findself" value={this.state.training1Start} onChange={(e) => this.setState({training1Start: e.target.value})}/>
                                <label for="training1StartDate">培训经历1 开始时间</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="date" className="form-control" id="training1EndDate" placeholder="findself" value={this.state.training1End} onChange={(e) => this.setState({training1End: e.target.value})}/>
                                <label for="training1EndDate">培训经历1 结束时间</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="text" className="form-control" id="training1Name" placeholder="findself" value={this.state.training1Name} onChange={(e) => this.setState({training1Name: e.target.value})}/>
                                <label for="training1Name">培训经历1 名称</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="date" className="form-control" id="training2StartDate" placeholder="findself" value={this.state.training2Start} onChange={(e) => this.setState({training2Start: e.target.value})}/>
                                <label for="training2StartDate">培训经历2 开始时间</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="date" className="form-control" id="training2EndDate" placeholder="findself" value={this.state.training2End} onChange={(e) => this.setState({training2End: e.target.value})}/>
                                <label for="training2EndDate">培训经历2 结束时间</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="text" className="form-control" id="training2Name" placeholder="findself" value={this.state.training2Name} onChange={(e) => this.setState({training2Name: e.target.value})}/>
                                <label for="training2Name">培训经历2 名称</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="date" className="form-control" id="training3StartDate" placeholder="findself" value={this.state.training3Start} onChange={(e) => this.setState({training3Start: e.target.value})}/>
                                <label for="training3StartDate">培训经历1 开始时间</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="date" className="form-control" id="training3EndDate" placeholder="findself" value={this.state.training3End} onChange={(e) => this.setState({training3End: e.target.value})}/>
                                <label for="training3EndDate">培训经历1 结束时间</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="text" className="form-control" id="training1Name" placeholder="findself" value={this.state.training3Name} onChange={(e) => this.setState({training3Name: e.target.value})}/>
                                <label for="training3Name">培训经历1 名称</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                工作人群
                                <select multiple className="form-select" aria-label="Default select example" style={{height:200}} id="work_populations" value={this.state.work_populations} onChange={this.handleWorkPopulationsChange} >
                                    <option value="婚姻家庭">婚姻家庭</option>
                                    <option value="情绪管理">情绪管理</option>
                                    <option value="人际关系">人际关系</option>
                                    <option value="个人成长">个人成长</option>
                                    <option value="职场">职场</option>
                                    <option value="性心理">性心理</option>
                                    <option value="恋爱情感">恋爱情感</option>
                                    <option value="心身健康">心身健康</option>
                                </select>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                擅长方向
                                <select multiple className="form-select " aria-label="Default select example" style={{height:200}} id="specialities" value={this.state.specialities} onChange={this.handleSpecialitiesChange} >
                                    <option value="精神分析和心理动力疗法">精神分析和心里动力疗法</option>
                                    <option value="认知行为疗法（CBT）">认知行为疗法（CBT）</option>
                                    <option value="人本主义疗法">人本主义疗法</option>
                                    <option value="接纳与承诺疗法（ACT）">接纳与承诺疗法（ACT）</option>
                                    <option value="叙事疗法">叙事疗法</option>
                                    <option value="情绪取向疗法（EFT）">情绪取向疗法（EFT）</option>
                                    <option value="辨证行为疗法（DBT）">辨证行为疗法（DBT）</option>
                                    <option value="艺术疗法">艺术疗法</option>
                                    <option value="焦点解决短程心理疗法">焦点解决短程心理疗法</option>
                                </select>
                            </div>
                            <div className="col-12 fs-6 fw-bold" style={{ color:'#2e6173', textDecoration: 'underline', textDecorationColor: '#B8E1E1', textDecorationThickness: 2, textUnderlineOffset: 8 }}>
                                咨询详情
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="number" className="form-control" id="duration" placeholder="findself" value={this.state.duration} onChange={(e) => this.setState({ duration: e.target.value })}  />
                                <label for="duration">咨询时长（小时）</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="number" className="form-control" id="cost" placeholder="findself" value={this.state.cost} onChange={(e) => this.setState({ cost: e.target.value })}  />
                                <label for="cost">单次费用</label>
                            </div>
                            <div className="col-md-4 form-floating mb-1">
                                <input type="text" className="form-control" id="costMulti" placeholder="findself" value={this.state.costMulti} onChange={(e) => this.setState({ costMulti: e.target.value })}  />
                                <label for="costMulti">多次费用</label>
                            </div>
                            <div className="col-md-12 form-floating mb-1">
                                <input type="text" className="form-control" id="process" placeholder="findself" value={this.state.process} onChange={(e) => this.setState({ process: e.target.value })}  />
                                <label for="process">咨询过程与方式</label>
                            </div>
                            <div className="col-12 fs-6 fw-bold" style={{ color:'#2e6173', textDecoration: 'underline', textDecorationColor: '#B8E1E1', textDecorationThickness: 2, textUnderlineOffset: 8 }}>
                                照片
                            </div>
                            <div className="col-md-12 form-floating mb-1">
                                <div>个人图片，点击按钮重新上传</div>
                                <img src={this.state.photoUrl} alt="请上传照片"></img>
                                <input type="file" className="form-control btn starter-btn mb-3" accept="image/*" id="photo" placeholder="findself" onChange={this.handlePhotoChange} />
                            </div>
                            <div>
                                <button type="submit" className="btn starter-btn mb-3" onClick={this.updateTherapist} style={{ width: '100%' }} >提交</button>
                            </div>                            
                        </from>
                    </div>}
                </div>
            </div>
        )
    }
}
