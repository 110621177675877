import React, {Component} from 'react'
import QandABox from './QandABox'
import utils from './utils'
import axios from 'axios'
import ReactLoading from "react-loading";
import {Row, Col, Container, Badge} from 'react-bootstrap'
import { NavHashLink } from 'react-router-hash-link'
import AboutUsEdit from "./AboutUsEdit";
import ScrollToTop from '../scrollToTop'
import _ from './locale'

const tagSort = (a, b) => {
    return -((a.priority || 0) - (b.priority || 0))
}
class Questions extends Component{
    state = {
        posts:[],
        tagList:[],
        allPost:[],
        loaded:false,
    }
    setup = async()=>{
        await utils.loadTagList();
        this.setState({
            tagList:window.tagList.qAndAList
        })
        this.reloadPost()
    }
    reloadPost = async ()=>{
        await this.setState({loaded:false})
        axios.get(utils.getUrl(`cms/questions/list/?lang=${this.props.lang}`)).then(
            (response) => {
                const data = response.data.data.data;
                this.setState({allPost: data,loaded:true})
            }
        ).catch((err) => {
            // alert(err)
        })
    }

    componentDidMount() {
        this.setup();
        this.props.setDisplayURL();
        window.question = this;
    }

    render() {
        const isAdmin =( localStorage.getItem('user_type') || 10)<3
        return (
            <div class="container-fluid px-lg-10 py-5">
                <div class="row">
                    <h2>常见问题</h2>
                </div>
                {/* <div class="row" style={{textAlign: 'center'}}><AboutUsEdit src_id="questions_content"/></div> */}
                <div class="row">
                    <div id="list-example" class="list-group col-lg-3 col-12 hide-mobile">
                        {this.state.tagList.sort(tagSort).map(
                            elem=>(
                                <NavHashLink smooth to={`#tag${elem.tag_id}`} className="list-group-item list-group-item-action">
                                    {elem.value_cn}/{elem.value}
                                </NavHashLink>
                            ))
                        }
                    </div>
                    
                    <div class="col-lg-9 col-12">
                        {this.state.tagList.sort(tagSort).map((ele)=>
                            <div class="upperline py-3" id={'tag'+ele.tag_id}>
                                {this.state.allPost.filter((post)=> post.categories.includes(ele.tag_id)).map(
                                    (post)=>(this.state.activeEdit===post.post_id?
                                    // edit question content
                                    <QandABox key={post.post_id} action={"update"} src_id={post.post_id} id={post.post_id}
                                                onCancel={()=>{this.setState({activeEdit:-1})}}
                                                onCreate={()=>{
                                                    this.setState({activeEdit: -1})
                                                    this.reloadPost()
                                                }}
                                                onDelete={()=>{
                                                    this.setState({activeEdit: -1})
                                                    this.reloadPost()
                                                }}
                                                onSave = {
                                                    ()=>{
                                                        this.setState({activeEdit:-1})
                                                        this.reloadPost()
                                                    }
                                                }
                                    />
                                    :
                                    <div id={post.post_id}>
                                        <h4>{post.subject}</h4>
                                        <div><span class="px-2"><i class="bi bi-tag"></i>{ele.value_cn} / {ele.value}</span></div>
                                        <span dangerouslySetInnerHTML={{__html: post.content}}/>
                                        {isAdmin ?
                                            <React.Fragment>
                                                {utils.questionPages.map((ele)=>{
                                                    return post?.tags?.includes(ele.tag_id)?<Badge variant="primary">{ele.short}</Badge>:""
                                                })}
                                            <input type="submit" value={_("Edit")} className="blog-submit-button"
                                                    onClick={() => {
                                                        this.setState({activeEdit: post.post_id})
                                                    }}/> </React.Fragment>: ""}
                                    </div>)
                                ) }
                            </div>)}  
                        </div>
                    </div>

                    {isAdmin?
                    <QandABox key="create" action={"create"}
                        onCreate={() => {
                            this.setState({activeEdit: -1})
                            this.reloadPost()
                        }}/>
                    :""}
                </div>)
    }
}   

Questions.defaultProps = {
    lang:"cn"
}

export default Questions
