import { Component } from "react";
import axios from 'axios'
import utils from './utils'
import '../css/appointment.css'
import TicketCard from "./ticketCard";

export default class TicketManager extends Component {

    state = {
        user_id: parseInt(localStorage.getItem('user_id')),
        user_type: parseInt(localStorage.getItem('user_type')) || 5,
        token: localStorage.getItem('loginToken'),
        ticketCount: null,
        tickets: [],
        filter: [0,4],
        alertContent: '当前未登录 输入邮箱和订单号查询指定订单 登录后可查询所有订单',
        email: '',
        ticket_id: '',
        anonymousUser: false
    }

    componentWillMount = async () => {
        if (this.state.token) {
            this.loadTickets()
        } else {
            this.setState({ anonymousUser: true })
        }    
    }

    loadTickets = async () => {
        var payload = {}
        switch (this.state.user_type) {
            case 3:
                payload = {
                    consultant: this.state.user_id,
                    start: 0,
                    end: 100
                }
                break;
            case 4:
            case 5:
                payload = {
                    user: this.state.user_id,
                    start: 0,
                    end: 100
                }
                break;
            default:
                break;
        }
        await axios.post(utils.getUrl(`appointment/ticket/query/`), payload, { headers: { 'Authorization': 'JWT ' + this.state.token }})
            .then(response => {
                let data = response.data.data
                this.setState({ 
                    ticketCount: data.count, 
                    tickets: data.data,
                    filteredTickets: data.data
                })
                console.log(data)
            }).catch(err => {
                console.log(err)
            })
    }

    anonymousLoginSearch = () => {
        axios.post(utils.getUrl('permission/jwt/anon/'),
            { email: this.state.email })
            .then(response => {
                console.log(response.data)
                if (response.data.status === 0) {
                    var token = response.data.data
                    this.setState({
                        token: token,
                        filter: [0, 1, 2, 3, 4]
                    })
                    // get user id
                    axios.get(utils.getUrl('permission/status/'),
                        { headers: { 'Authorization': 'JWT ' + token } })
                        .then(response => {
                            this.setState({ user_id: response.data.user_id }, ()=> this.loadTickets())
                        }).catch(err => {
                            console.log(err)
                        })
                }
            }).catch(err => {
                console.log(err)
                this.setState({ alertContent: '邮箱已注册过Findself账号 登录后再操作吧' })
            })
    }

    filterEvent = (event, filter) => {
        document.querySelectorAll('.filterBar > .badge').forEach(node => {
            if (node !== event.target) {
                node.classList.remove("bg-primary");
                node.classList.add("bg-secondary");
            } else {
                node.classList.remove("bg-secondary");
                node.classList.add("bg-primary");
            }
        })
        this.setState({
            filter: filter
        })
    }

    render() {
        let ticketCards = this.state.tickets
            .filter((ticket) => {
                return this.state.filter.includes(ticket.status)
            })
            .filter((ticket) => {
                if (this.state.anonymousUser) return ticket.ticket_id === parseInt(this.state.ticket_id)
                return true
            })
            .map((ticket) => {
                return (
                    <TicketCard key={ticket.ticket_id} data={ticket} />
                )
            })

        return (
            <div style={{ paddingBottom: 100 }}>
                <div className="container-fluid main-content px-lg-10 py-1">
                    {this.state.anonymousUser?
                        <div className="pb-3 row">
                            <div className="alert alert-primary text-center" role="alert">
                                {this.state.alertContent}
                            </div>
                            <form onSubmit={(e) => { this.anonymousLoginSearch(); e.preventDefault(); }}>
                                <div className="col-lg-4">
                                    <label for="timezone" class="form-label">邮箱：</label>
                                    <input type="email" class="form-control" value={this.state.email}
                                        onChange={(e) => this.setState({ email: e.target.value.trim() })} placeholder="" required/>
                                </div>
                                <div className="col-lg-4">
                                    <label for="date" class="form-label">订单号：</label>
                                    <input type="number" class="form-control" value={this.state.ticket_id}
                                        onChange={(e) => this.setState({ ticket_id: e.target.value.trim() })} placeholder="" required/>
                                </div>
                                <div className="col-lg-4">
                                    <input class="form-control" type="submit" value="查询订单" />
                                </div>
                            </form>
                        </div>
                        :
                        <div className="row filterBar">
                            <span class="badge rounded-pill bg-primary" onClick={(event) => this.filterEvent(event, [0, 4])}>新订单</span>
                            <span class="badge rounded-pill bg-secondary" onClick={(event) => this.filterEvent(event, [0])}>未支付</span>
                            <span class="badge rounded-pill bg-secondary" onClick={(event) => this.filterEvent(event, [2])}>已完成</span>
                            <span class="badge rounded-pill bg-secondary" onClick={(event) => this.filterEvent(event, [0, 1, 2, 3, 4])}>全部</span>
                        </div>
                    }
                    <div className="row g-1 g-lg-4 pt-3">
                        {ticketCards}
                    </div>
                </div>
            </div>
        )
    }

}