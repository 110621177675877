import React from 'react'
import {Container, Row, Col, Alert, Modal, Button} from 'react-bootstrap'

export default function PaymentFail() {
  return (
    <div>
      <Container>
      <Row className='flex-block2'>
        <div className='payment-card flex-block' style={{width: '60%', padding: '25px 25px', marginTop: '88px', justifyContent: 'center'}}>
          <div><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="none" class="bi bi-x-circle" viewBox="-2 0 20 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" stroke="#dd0612"/>
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" stroke="#dd0612"/>
          </svg></div>
          <div style={{fontSize: '36px', fontWeight: '400', marginTop: '16px', marginLeft: '16px'}}>支付失败</div>
        </div>
      </Row>
      </Container>
    </div>
  )
}
