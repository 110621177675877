import React, { Component } from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import axios from 'axios'
import {NavLink} from 'react-router-dom'
import ImageUploader from 'react-images-upload'
import cover from '../imgs/home_bg.png'
import utils from './utils'
import {GoogleMapFrame, GoogleMapAutoComplete} from "./GoogleMap"
import DateTimePicker from 'react-datetime-picker'
import RichEditBox from './RichEditBox';

export default class EventEdit extends Component {

  state = {
    eventTypeList: [],
    subTagList: [],
    cover_url: '',
    isUploadCover: false,
    canUploadCover: false,
    isOnline: false,
    event_id: '',
    event_name: '',
    description: '',
    event_slots: 0,
    current_eventType: '',
    event_link: '',
    selectedTags: [],
    selectedSubTags: [],
    tagInput: '',
    displaySubTag: false,
    suggestions: [],
    lat: 0, 
    lng: 0, 
    street: '',
    country: 'US',
    state: '',
    city: '',
    zip_code: '',
    timezoneList: utils.timezoneList,
    currentTimezone: [],
    previous_time_num: 0,
    startDateTime: [],
    endDateTime: [],
    startTimestamp: [],
    endTimestamp: [],
    summary: '',
    summary_edit: '',
    calendars: [{}],
  }

  componentDidMount = async() => {
    this.props.cancelGap();
    this.props.setDisplayURL();
    await utils.loadTagList();
    await this.setState({eventTypeList: window.tagList?.eventList || [], subTagList: window.tagList?.eventMethodList || [], event_id: this.props.match.params.event_id.slice(1)})
    this.getEventInfo()
  }
  componentWillUnmount = () => {
    this.props.addGap()
  }

  getEventInfo = async() => {
    let temp_data
    await axios.get(utils.getUrl(`event/${this.state.event_id}/update/`), {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
    .then(response=>{
      temp_data = response.data.calendars.length>0 ? response.data.calendars[0]:''
      
      this.setState({event_name: response.data.name, description: response.data.description, cover_url: response.data.cover, summary: response.data.summary, 
      event_slots: response.data.calendars.length>0?response.data.calendars[0].slots:0, selectedTags: response.data.tags, calendars: response.data.calendars, })
    }).catch(err => {
      alert(err)
    })
    let tempSubtags = []
    this.state.selectedTags.map(tag =>{
      if((tag+'').charAt(0)==='8'){
        this.setState({current_eventType: tag})
        if(tag === 800000){
          this.setState({isOnline: true, event_link: temp_data!==''?temp_data.url:''})
        }else{
          this.setState({isOnline: false})
          if(temp_data!==''){
            this.setState({lat: temp_data.latitude, lng: temp_data.longitude, street: temp_data.street, 
              country: temp_data.country, state: temp_data.region, city:temp_data.city, zip_code:temp_data.zip_code})
          }
        }
      }else{
        this.state.subTagList.some(subtag=>{
          if(subtag.tag_id === tag){this.handleSubTagSelect(subtag)}
        })
      }
    })
    if(this.state.calendars.length > 0){
      let timezoneList = this.state.currentTimezone
      let startDateTime = this.state.startDateTime
      let endDateTime = this.state.endDateTime
      let startTimestamp = this.state.startTimestamp
      let endTimestamp = this.state.endTimestamp

      this.state.calendars.map(time=>{
        timezoneList.push(time.timezone)
        startDateTime.push(new Date(time.start_time))
        endDateTime.push(new Date(time.end_time))
        startTimestamp.push(Date.parse(new Date(time.start_time)))
        endTimestamp.push(Date.parse(new Date(time.end_time)))
      })
      this.setState({currentTimezone: timezoneList, startDateTime, endDateTime, previous_time_num: this.state.calendars.length})
    }
  }

  handleCover = () => {
    this.setState({isUploadCover: true})
  }


  onDrop = async(pictureFile, pictureDataURL) => {
    await this.setState({cover_url: pictureDataURL[0], canUploadCover: true})
  }

  setCover = () =>{
    this.setState({isUploadCover: false, canUploadCover: false})
  }

  cancelCover = () =>{
    if(!window.confirm('确定要清空图片吗？')){
      return
    }
    this.setState({cover_url: '',isUploadCover: false, canUploadCover: false})
  }

  handlePlaceChange = (place) => {
    const format_address = place.formatted_address.split(',')
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    const street_1 = format_address[0]
    // let street_2 = ''
    // if(format_address.length > 4){street_2 = format_address[1]}
    const country = place.address_components[place.address_components.length-2].short_name
    const state = place.address_components[place.address_components.length-3].short_name
    const city = place.address_components[place.address_components.length-5]?place.address_components[place.address_components.length-5].short_name:''
    let zip_code = place.address_components[place.address_components.length-1].short_name
    if(zip_code.length === 4){
      zip_code = place.address_components[place.address_components.length-2].short_name + '-' + zip_code
    }
    this.setState({lat, lng, street: street_1, country, state, city, zip_code})
  }
  // 选择活动形式
  handleTagSelect = (event) => {
    let tag_id = parseInt(event.target.value)
    if(tag_id === 800000){
      this.setState({isOnline: true})
    }else{
      if(this.state.isOnline){
        this.setState({isOnline: false})
      }
    }
    let tempList = this.state.selectedTags
    this.setState({current_eventType: tag_id})
    if(!tempList.includes(tag_id)){
      tempList.map(tag=>{
        if((tag+'').charAt(0) === '8'){
          tempList.splice(tempList.indexOf(tag), 1)
        }
      })
      tempList.push(tag_id)
    }
    this.setState({selectedTags: tempList})
  }
  // 搜索subtag
  handleTagInput = async(event) => {
    await this.setState({tagInput: event.target.value})
    this.getSuggestions()
    if(this.state.tagInput.length>0){
      this.setState({displaySubTag: true})
    }else{
      this.setState({displaySubTag: false})
    }
  }
  // 选择subtag
  handleSubTagSelect = (tag) => {
    let tempList = this.state.selectedSubTags
    let tagInput = ''
    if(!tempList.includes(tag)){
      tempList.push(tag)
    }
    tempList.map(elem=>{
      tagInput += '#'+elem.value_cn
    })
    let selectedTags = this.state.selectedTags
    if(!selectedTags.includes(tag.tag_id)){
      selectedTags.push(tag.tag_id)
    }
    this.setState({tagInput, displaySubTag: false, selectedSubTags: tempList, selectedTags})
  }
  // 获取subtag搜索建议
  getSuggestions = () => {
    const inputValue = this.state.tagInput.trim().toLowerCase()
    const inputLength = inputValue.length;
    let suggestions = inputLength === 0 ? [] : this.state.subTagList.filter(elem =>
      elem.value_cn.toLowerCase().slice(0, inputLength) === inputValue
    )
    this.setState({suggestions})
  }
  // 转换时区
  convertTZ = (date, tzString)=> {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}))
  }
  // 输入开始时间
  handleStartDateTime = (value, index) => {
    if(!this.state.currentTimezone[index]){
      alert('请先选择一个时区！')
      return
    }
    let timestamp = Date.parse(value)
    let startDateTime = this.state.startDateTime
    let startTimestamp = this.state.startTimestamp
    startDateTime[index] = value
    startTimestamp[index] = timestamp
    this.setState({startDateTime, startTimestamp})
  }
  // 输入结束时间
  handleEndDateTime = (value, index) => {
    if(!this.state.currentTimezone[index]){
      alert('请先选择一个时区！')
      return
    }
    let timestamp = Date.parse(value)
    let endDateTime = this.state.endDateTime
    let endTimestamp = this.state.endTimestamp
    endDateTime[index] = value
    endTimestamp[index] = timestamp
    this.setState({endDateTime, endTimestamp})
  }
  
  handleTimezoneChange = (event, index) => {
    let timezone_id = event.target.value
    let timezoneList = this.state.currentTimezone
    timezoneList[index] = timezone_id
    this.setState({currentTimezone: timezoneList})
  }
  // 编辑活动介绍
  editSummary = async() => {
    if(this.state.summary_edit===''){
      alert('当前内容为空！')
      return
    }
    let content = this.state.summary_edit
    this.setState({summary: content})
    alert('保存成功！')
  }

  // 增加活动时间槽
  addTimeSlot = () => {
    let calendars = this.state.calendars
    calendars.push({})
    this.setState({calendars})
  }
  // 移除活动时间槽
  removeTimeSlot = (index) => {
    let calendars = this.state.calendars
    calendars.pop()
    let startDateTime = this.state.startDateTime
    let endDateTime = this.state.endDateTime
    let startTimestamp = this.state.startTimestamp
    let endTimestamp = this.state.endTimestamp
    startDateTime.splice(index, 1)
    endDateTime.splice(index, 1)
    startTimestamp.splice(index, 1)
    endTimestamp.splice(index, 1)
    this.setState({calendars, startDateTime, endDateTime, startTimestamp, endTimestamp})
  }

  deleteCalendarTime = async(calendar_id, index) => {
    if(!window.confirm('此时间为已创建的时间，您确定要删除吗？')){
      return
    }
    let calendars = this.state.calendars
    calendars.splice(index, 1)
    let startDateTime = this.state.startDateTime
    let endDateTime = this.state.endDateTime
    let startTimestamp = this.state.startTimestamp
    let endTimestamp = this.state.endTimestamp
    startDateTime.splice(index, 1)
    endDateTime.splice(index, 1)
    startTimestamp.splice(index, 1)
    endTimestamp.splice(index, 1)
    this.setState({calendars, startDateTime, endDateTime, startTimestamp, endTimestamp, previous_time_num: this.state.previous_time_num-1})
    await axios.delete(utils.getUrl(`event/${this.state.event_id}/calendar/${calendar_id}/update/`), 
    {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
    .then(response=>{
      
    }).catch(err => {
      alert(err)
    })
  }

  addCalendar = async()=>{
    if(this.state.startDateTime.length===0){
      alert('请填写至少一个开始时间！')
      return
    }else if(this.state.endDateTime.length===0){
      alert('请填写至少一个结束时间！')
      return
    }

    let tempCalendar = {}
    for(let i=0; i<this.state.startTimestamp.length; ++i){
      if(this.state.startTimestamp[i]>=this.state.endTimestamp[i]){
        alert('结束时间必须大于开始时间！')
        return
      }
      if(this.state.isOnline){
        tempCalendar = {
          'online': true,
          'url': this.state.event_link,
          'start_time': this.state.startTimestamp[i],
          'end_time': this.state.endTimestamp[i],
          'timezone': this.state.currentTimezone[i],
          'slots': this.state.event_slots,
        }
      }else{
        tempCalendar = {
          'street': this.state.street,
          'city': this.state.city,
          'region': this.state.state,
          'country': this.state.country,
          'zip_code': this.state.zip_code,
          'longitude': this.state.longitude,
          'latitude': this.state.latitude,
          'online': false,
          'start_time': this.state.startTimestamp[i],
          'end_time': this.state.endTimestamp[i],
          'timezone': this.state.currentTimezone[i],
          'slots': this.state.event_slots,
        }
      }
      if(i<this.state.previous_time_num){
        await axios.post(utils.getUrl(`event/${this.state.event_id}/calendar/${this.state.calendars[i].calendar_id}/update/`), 
        tempCalendar, {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then(response => {
          
        }).catch(err =>{
          // alert(err)
        })
      }else{
        await axios.post(utils.getUrl(`event/${this.state.event_id}/calendar/create/`), 
        tempCalendar, {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then(response => {
          
        }).catch(err =>{
          // alert(err)
        })
      }
    }
  }

  updateEvent = async()=>{
    if(!this.state.current_eventType || this.state.current_eventType===''){
      alert('请选择一个活动形式！')
      return
    }else if(this.state.event_name===''){
      alert('请输入一个活动名称！')
      return
    }else if(this.state.event_slots===0){
      alert('请指定一个人数上限！')
      return
    }else if(!this.state.isOnline && this.state.street===''){
      alert('请输入一个完整地址！')
      return
    }
    // update event
    await axios.post(utils.getUrl(`event/${this.state.event_id}/update/`), 
    {cover:this.state.cover_url, summary:this.state.summary, description:this.state.description, name: this.state.event_name, tags: this.state.selectedTags},
    {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
    .then(response =>{
      
    }).catch(err=>{
      // alert(err)
    })
  }

  // 创建活动
  submitEvent = async() =>{
    await this.updateEvent()
    await this.addCalendar()
    // status set to submitted
    await axios.post(utils.getUrl(`event/${this.state.event_id}/update/`),{'status': 1},
    {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
    .then(response=>{
      alert('创建成功！')
      this.props.history.push({pathname: `/event/detail/:${this.state.event_id}`})
    }).catch(err => {
      // alert(err)
    })  
    
  }
  // 保存草稿
  submitDraft = async() => {
    if(this.state.event_id===''){
      await this.updateEvent()
      await this.addCalendar()
    }
    if(this.state.event_id!==''){
    // status set to saved
    await axios.post(utils.getUrl(`event/${this.state.event_id}/update/`),{'status': 0},
      {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
      .then(response=>{
        alert('保存成功！')
      }).catch(err => {
        // alert(err)
      })
    }
  }
  // 当datetimepicker打开时添加class
  handlePickerClass = (id)=>{
    var elem = document.getElementsByClassName(id)
    elem[0].classList.add('top-z-index-2')
  }

  render() {
    return (
      <div>
        <Container>
          {/* title */}
          <Row>
            <h1>编辑团</h1>
          </Row>
          {/* body */}
          <Row>
            <div className="create-event-container">
              {/* cover */}
              <div style={{ background: '#E7E9EE', height: '220px', width: '100%', position: 'relative'}}>
                <img src={this.state.cover_url} alt=' ' style={{width: '100%', height: '220px'}}></img>
                <input type="button" className="profile-btn-2" style={{width: '120px', height: '32px', position: 'absolute', top: '94px', left: '45%'}} onClick={this.handleCover} value='上传活动主图'></input>
              </div>
              {/* upload cover */}
              {this.state.isUploadCover?
                <ImageUploader
                        onChange={this.onDrop}
                        buttonText='选择图片'
                        imgExtension={['.jpg', '.png', '.gif']}
                        maxFileSize={524288}
                        label='最大尺寸: 500kb, 接受格式: jpg|png|gif'
                        withPreview={false}
                        singleImage={true}
                    />:''}
              {this.state.canUploadCover?
              <div style={{textAlign: 'center', width: '100%'}}>
              <input type="button" className="profile-btn-1" style={{width: '120px', height: '32px'}} onClick={this.setCover} value='确认上传'></input>
              <input type="button" className="profile-btn-2" style={{width: '120px', height: '32px'}} onClick={this.cancelCover} value='取消'></input>
              </div>
              :''}
              {!this.state.canUploadCover&&this.state.cover_url!==''?
              <input type="button" className="profile-btn-1" style={{width: '120px', height: '32px'}} onClick={this.cancelCover} value='清空图片'></input>:''}
              {/* 基本信息 */}
              <div className='create-event-subtitle'>
                基本信息
              </div>
              {/* 活动名称 */}
              <div className='create-event-input'>
                <input type='text' value={this.state.event_name} onChange={(event)=>{this.setState({event_name: event.target.value})}}></input>
                <span>活动名称</span>
              </div>
              {/* 活动简介 */}
              <div className='create-event-input'>
                <input type='text' value={this.state.description} onChange={(event)=>{this.setState({description: event.target.value})}}></input>
                <span>活动简介</span>
              </div>
              {/* 活动形式 */}
              <div className='create-event-input custom-select-2'>
                <select id='event-type' onChange={this.handleTagSelect}>
                  <option value=''></option>
                  {this.state.eventTypeList.map(tag=>
                  {if(tag.tag_id === this.state.current_eventType){return <option value={tag.tag_id} selected>{tag.value_cn}</option>}
                  else{return <option value={tag.tag_id}>{tag.value_cn}</option>}})
                  }
                </select>
                <span>活动形式</span>
              </div>
              {/* 活动链接 */}
              {this.state.isOnline?
                <div className='create-event-input'>
                <input type='text' value={this.state.event_link} onChange={(event)=>{this.setState({event_link: event.target.value})}}></input>
                <span>活动链接</span>
              </div>:''}
              {/* 活动地点 */}
              {!this.state.isOnline?
                <div className='create-event-input'>
                <GoogleMapAutoComplete onPlaceChange={(place) => this.handlePlaceChange(place)}></GoogleMapAutoComplete>
                <span>活动地点</span>
              </div>:''}
              {/* 人数上限 */}
              <div className='create-event-input'>
                <input type='number' value={this.state.event_slots} onChange={(event)=>{this.setState({event_slots: event.target.value})}}></input>
                <span>人数上限</span>
              </div>
              {/* 活动属性 */}
              <div className='create-event-input'>
                <input type='text' value={this.state.tagInput} onChange={this.handleTagInput} style={{color: '#43B0B1'}}></input>    
                <span>活动属性</span>
                <div class="dropdown-content city-select" style={{display: this.state.displaySubTag?'block':'none'}}>
                  {(this.state.suggestions.length===0?this.state.subTagList:this.state.suggestions).map(tag => <a href='javascript:;' onClick={() => this.handleSubTagSelect(tag)}>{tag.value_cn}</a>)}
                </div>
              </div>
            
              {/* 时间信息 */}
              <div className='create-event-subtitle'>
                时间信息
              </div>
              {/* 时间范围 */}
              {this.state.calendars.map((elem, index)=>{
                return <React.Fragment>
                <div className='create-event-input custom-select-2'>
                  <select id='timezone' onChange={(event) => {this.handleTimezoneChange(event, index)}}>
                    <option value=''></option>
                    {this.state.timezoneList.map(elem=>
                      {if(elem.id === this.state.currentTimezone[index]){
                        return <option value={elem.id} selected>{elem.value_cn}</option>
                      }else{return <option value={elem.id}>{elem.value_cn}</option>}
                      })
                    }
                  </select>
                  <span>时区选择</span>
                </div>
                <div className='flex-block create-event-input-2' key={index}>
                <span style={{marginRight: '24px'}}>开始时间{index+1}</span>
                <DateTimePicker
                    className={'picker-start'+index}
                    format='y-MM-dd h:mm a'
                    onChange={(value) => this.handleStartDateTime(value, index)}
                    value={this.state.startDateTime[index]}
                    onCalendarOpen={()=>this.handlePickerClass('picker-start'+index)}
                    dayPlaceholder='dd'
                    hourPlaceholder='hh'
                    minutePlaceholder='mm'
                    monthPlaceholder='MM'
                    yearPlaceholder='yyyy'
                  />
                <span style={{marginRight: '25px', marginLeft: '25px'}}>结束时间{index+1}</span>
                <DateTimePicker
                    className={'picker-end'+index}
                    format='y-MM-dd h:mm a'
                    onChange={(value) => this.handleEndDateTime(value, index)}
                    value={this.state.endDateTime[index]}
                    onCalendarOpen={()=>this.handlePickerClass('picker-end'+index)}
                    dayPlaceholder='dd'
                    hourPlaceholder='hh'
                    minutePlaceholder='mm'
                    monthPlaceholder='MM'
                    yearPlaceholder='yyyy'
                  />
                  <input type="button" className="profile-btn-1" style={{width: '120px', height: '32px', marginLeft: '50px', position: 'relative', bottom: '2px'}} 
                  onClick={() =>{!elem.calendar_id?this.removeTimeSlot(index):this.deleteCalendarTime(elem.calendar_id, index)}} value='移除'></input>
              </div></React.Fragment>
              })}
              {/* 添加时间 */}
              <div style={{marginTop: '16px', position: 'relative'}} onClick={this.addTimeSlot}>
                <button className="profile-btn-2" style={{width: '128px', height: '32px', textAlign: 'right'}}>
                  增加活动时间
                </button>
                <svg width="24" height="24" style={{position: 'absolute', top: '5px', left: '0'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#43B0B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.25 12H15.75" stroke="#43B0B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 8.25V15.75" stroke="#43B0B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              {/* 活动介绍 */}
              <div className='create-event-subtitle'>
                活动介绍
              </div>
              {/* 活动介绍内容显示 */}
              <div style={{maxHeight: '100px', marginTop: '16px', width: '100%', lineHeight: '24px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} dangerouslySetInnerHTML={{__html: this.state.summary}}>
              </div>
              <button className="profile-btn-1" onClick={this.editSummary} style={{height: '32px', width: '128px', marginTop: '17px', marginBottom: '10px', lineHeight: '24px'}}>保存</button>
              <RichEditBox value={this.state.summary} onChange={(c)=>{this.setState({summary_edit:c})}}/>
              {/* 保存草稿/提交 */}
              <div className='flex-block' style={{marginTop: '30px'}}>
                <input className="profile-btn-2" onClick={this.submitDraft} style={{height: '32px', width: '64px', marginRight: '8px', fontSize: '12px'}} value='保存草稿' type="button"/>
                <input className="profile-btn-1" onClick={this.submitEvent} style={{height: '32px', width: '120px', padding: '8px 48px', fontSize: '12px'}} value='提交' type='button'/>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    )
  }
}
