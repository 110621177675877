import React, { Component } from 'react'
import { SwatchesPicker } from 'react-color';
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'
import utils from './utils'
import axios from 'axios'
import ReactLoading from 'react-loading';
import {Row} from 'react-bootstrap'
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { IoMdTime } from 'react-icons/io';
import { IoColorPaletteOutline } from 'react-icons/io5';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { AiOutlineSchedule } from 'react-icons/ai';
import Overlay from 'react-bootstrap/Overlay';

const popover = {
  zIndex: '2',
}
const cover = {
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
}

class TalkTimeTable extends Component {
  state = {
    schedule_timezone: utils.timezoneList[0].id,
    display_timezone: utils.timezoneList[0].value_cn.split(" ")[0],
    volunteer_schedule: null,
    responder_schedule: null,
    loading_start_date: true,
    loading_schedule: true,
    show_color_picker: false,
    show_offcanvas_profile: false,
    current_step: 1,
    nickname: null,
    dayOfWeek: null,
    color: '',
  }

  constructor(props) {
    super(props);
    this.week_offset = 0
    this.isMobile = window.innerWidth < 992;
    this.hours = [...Array(24).keys()];
    this.modified_schedule = {
      volunteer_schedule: {},
      responder_schedule: {}
    }
  }

  componentDidMount() {
    this.updateStartDate()
    this.updateStartDateInterval = setInterval(() => {this.updateStartDate()}, 1000)
    this.updateScheduleInterval = setInterval(() => {this.getSchedule()}, 300000)
    this.getSchedule()
  }

  componentWillUnmount = () => {
    document.title = "FindSelf找我 - TimeTable";
    clearInterval(this.updateStartDateInterval)
    clearInterval(this.updateScheduleInterval)
  }

  ProfileSettings = () => {
    return (
      <ListGroup variant="flush" className='text-start'>
        <ListGroup.Item as="li">
          <div className='ms-2 me-auto mb-3'>
            <div className='fw-bold' style={{color: '#43B0B1'}}>
              <IoMdTime className='me-2' />
              选择所在时区（不会丢弃所做的更改）
            </div>
            <select id="timezone" className="form-select my-3" defaultValue={this.state.schedule_timezone} onChange={this.setTimeZone}>
              {
                utils.timezoneList.map(elem => <option value={elem.id} value_cn={elem.value_cn}>{elem.value_cn}</option>)
              }
            </select>
            当前时间：{this.state.start_date.format('YYYY年MM月DD日 HH:mm:ss')}
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <div className='ms-2 me-auto mb-3'>
            <div className='fw-bold' style={{color: '#43B0B1'}}>
              <MdDriveFileRenameOutline className='me-2' />
              输入小伙伴昵称
            </div>
            <Form>
              <Form.Group controlId="nickname" className='my-3'>
                <Form.Control type="text" value={this.state.nickname} placeholder='小伙伴昵称' onChange={event => {
                  if (event.target.value == '') {
                    this.setState({current_step: 1})
                  } else if (this.state.current_step < 2) {
                    if (this.state.color != '') {
                      this.setState({current_step: 3});
                    } else {
                      this.setState({current_step: 2});
                    }
                  }
                  this.setState({nickname: event.target.value})
                }} />
              </Form.Group>
            </Form>
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <div className='ms-2 me-auto mb-3'>
            <div className='fw-bold' style={{color: this.state.current_step >= 2 ? '#43B0B1' : '#b8b8b8'}}>
              <IoColorPaletteOutline className='me-2' />
              选择名卡颜色
            </div>
            <button className='badge rounded-pill my-3 fw-bold shadow-none' style={{
              background: this.state.current_step >= 2 ? '#dedede' : '#b8b8b8',
              color: this.state.current_step >= 2 ? this.state.color : '#ffffff',
            }} onClick={() => this.setState({show_color_picker: true})} disabled={this.state.current_step < 2}>
              {this.state.nickname ? this.state.nickname : '小伙伴'}
            </button>
            {
              this.state.show_color_picker ?
                <div className="mt-2 position-absolute" style={popover}>
                  <div className="position-fixed" style={cover} onClick={() => {
                    if (this.state.current_step < 3) {
                      this.setState({current_step: 3});
                    }
                    this.setState({show_color_picker: false});
                  }}/>
                  <SwatchesPicker color={this.state.color} onChange={color => this.setState({color: color.hex})} />
                </div> :
                null
            }
          </div>
        </ListGroup.Item>
        {
          this.isMobile ?
            <button className='btn' onClick={()=>this.setState({show_offcanvas_profile: false})} style={{background: this.state.current_step >= 3 ? '#43B0B1' : '#b8b8b8'}} disabled={this.state.current_step < 3}>
              进入排班表
            </button> :
            <ListGroup.Item as="li">
              <div className='ms-2 me-auto mb-3'>
                <div className='fw-bold' style={{color: this.state.current_step >= 3 ? '#43B0B1' : '#b8b8b8'}}>
                  <AiOutlineSchedule className='me-2' />
                  在右侧排班表中点击空白格
                </div>
              </div>
            </ListGroup.Item>
        }
      </ListGroup>
    );
  }

  getQueryDate = () => {
    let today = moment()
    return today.subtract(today.day() + 1, 'day').format('YYYY-MM-DD')
  }

  updateStartDate = () => {
    this.setState({start_date: moment().tz(this.state.schedule_timezone).add(this.week_offset, 'week')}, () => {
      if (this.state.loading_start_date) {
        this.updateDays()
        this.setState({loading_start_date: false})
      }
    })
  }

  updateDays = () => {
    let beginning_of_week = moment(this.state.start_date).tz(this.state.schedule_timezone).day(0)
    const days = []
    for (let i = 0; i < 7; i++) {
      days.push(moment(beginning_of_week).add(i, 'day'));
    }
    this.setState({
      days: days,
      dayOfWeek: days[parseInt(this.state.start_date.format('d'))]
    })
  }

  getSchedule = () => {
    this.setState({loading_schedule: true})
    axios.get(utils.getUrl(`talktimetable/schedule`), {params: {from: this.getQueryDate(), offset: 4}})
      .then(response => {
        this.setState({
          volunteer_schedule: response.data.data.volunteer_schedule,
          responder_schedule: response.data.data.responder_schedule,
          loading_schedule: false,
        })
      }).catch(err => {
        alert(err)
      })
  }

  changeWeek = (delta) => {
    this.week_offset += delta
    this.setState({loading_start_date: true}, () => {
      this.updateStartDate()
    })
  }

  postSchedule = () => {
    axios.post(utils.getUrl(`talktimetable/schedule`), this.modified_schedule, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}}).then(response => {
      alert('保存成功！')
      this.getSchedule()
    }).catch(err => {
      alert(err)
    })
  }

  setTimeZone = (event) =>{
    let value_cn = ''
    utils.timezoneList.map(elem=>{
      if(elem.id === event.target.value){
        value_cn = elem.value_cn
      }
    })
    this.setState({
      schedule_timezone: event.target.value,
      display_timezone: value_cn.split(" ")[0],
      loading_start_date: true,
    }, () => this.updateStartDate)
  }

  setDayOfWeek = (event) => {
    this.setState({dayOfWeek: this.state.days[parseInt(event.target.value)]})
  }

  handleVolunteerTdClick = (volunteer_utc_key, td_editable) => {
    console.log(volunteer_utc_key)
    if (this.state.current_step < 3) {
      alert('请先设置个人信息');
      return
    }
    let volunteer_schedule = {...this.state.volunteer_schedule};
    let originalName = (volunteer_schedule[volunteer_utc_key] || {name: ''}).name;
    if (!td_editable || originalName != '' && originalName != this.state.nickname) {
      alert('不能修改这一格');
      return
    }
    if (originalName == this.state.nickname) {
      volunteer_schedule[volunteer_utc_key] = this.modified_schedule.volunteer_schedule[volunteer_utc_key] = {
        name: '',
        color: '',
      };
    } else {
      volunteer_schedule[volunteer_utc_key] = this.modified_schedule.volunteer_schedule[volunteer_utc_key] = {
        name: this.state.nickname,
        color: this.state.color,
      };
    }
    this.setState({
      volunteer_schedule: volunteer_schedule,
      loading_schedule: true,
    }, () => this.setState({loading_schedule: false}));
  }

  handleResponderTdClick = (responder_utc_key) => {
    if (this.state.current_step < 3) {
      alert('请先设置个人信息');
      return
    }
    let responder_schedule = {...this.state.responder_schedule};
    let originalName = (responder_schedule[responder_utc_key] || {name: ''}).name;
    if (originalName != '' && originalName != this.state.nickname) {
      alert('不能修改这一格');
      return
    }
    if (originalName == this.state.nickname) {
      responder_schedule[responder_utc_key] = this.modified_schedule.responder_schedule[responder_utc_key] = {
        name: '',
        color: '',
      };
    } else {
      responder_schedule[responder_utc_key] = this.modified_schedule.responder_schedule[responder_utc_key] = {
        name: this.state.nickname,
        color: this.state.color,
      };
    }
    this.setState({
      responder_schedule: responder_schedule,
      loading_schedule: true,
    }, () => this.setState({loading_schedule: false}));
  }

  render() {
    if (this.state.loading_start_date || this.state.loading_schedule) {
      return (
        <Row style={{paddingLeft: '45%'}}>
          <ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/>
          <div style={{height: '400px'}}></div>
        </Row>
      )
    } else {
      return (
        <div className="text-center">
          <div className="row">
            {
              this.isMobile ?
                <nav class="navbar navbar-light bg-light sticky-top text-center">
                  <div class="container-fluid">
                    <button type="button" onClick={() => this.setState({show_offcanvas_profile: !this.state.show_offcanvas_profile})}>
                      <span class="navbar-toggler-icon"></span>
                    </button>
                    <a class="navbar-brand">找我说小伙伴排班表</a>
                    <div></div>
                    <div class={"offcanvas offcanvas-start" + (this.state.show_offcanvas_profile ? " show" : "")} tabIndex="-1" id="offcanvasProfile" aria-labelledby="offcanvasProfileLabel">
                      <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="offcanvasProfileLabel">输入小伙伴信息</h5>
                        <button type="button" className="btn-close" onClick={() => this.setState({show_offcanvas_profile: false})}></button>
                      </div>
                      <div class="offcanvas-body">
                        <this.ProfileSettings />
                      </div>
                    </div>
                  </div>
                </nav> :
                <div className="col-2 text-start">
                  <this.ProfileSettings />
                </div>
            }
            <div className='col' >
              {
                this.isMobile ?
                  <div className='text-start ms-4 mt-3' style={{color: '#43B0B1'}}>点击空白格添加或移除名字</div> :
                  <div className="row d-flex">
                    <div className="col justify-content-center align-self-center">
                      <input type="button" value="上一周" className="btn btn-outline-dark mx-1" onClick={() => this.changeWeek(-1)} disabled={this.week_offset <= 0} />
                    </div>
                    <div className="col-8">
                      <h1 className="card-header text-center font-weight-bold text-uppercase py-4">
                        「找我说」{this.state.start_date.format('YYYY')}第{this.state.start_date.format('w')}周排班表
                      </h1>
                    </div>
                    <div className="col justify-content-center align-self-center">
                      <input type="button" value="下一周" className="btn btn-outline-dark mx-1" onClick={() => this.changeWeek(1)} disabled={this.week_offset >= 4} />
                    </div>
                  </div>
              }
              <div className="card-body">
                <div id="table" className="table-responsive">
                  {
                    this.isMobile ?
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th>{this.state.display_timezone}</th>
                            <th>
                              <select id="day-select" className="form-select" defaultValue={this.state.start_date.format('d')} onChange={this.setDayOfWeek}>
                                {
                                  this.state.days.map(day=><option value={day.format('d')}>{day.format('ddd MM-DD')}</option>)
                                }
                              </select>
                              切换时间不会丢弃您的更改
                            </th>
                            <th>负责人</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.hours.map(hour=>{
                              let tz_time = moment(this.state.dayOfWeek).tz(this.state.schedule_timezone).hour(hour)
                              let responder_key = tz_time.format('YYYY-ww-HH')
                              let responder_utc_key = tz_time.utc().format('YYYY-ww-HH')
                              let target_responder_schedule = this.state.responder_schedule[responder_utc_key] || {name: ''}
                              let volunteer_key = tz_time.format('YYYY-MM-DD-HH')
                              let volunteer_utc_key = tz_time.utc().format('YYYY-MM-DD-HH')
                              let target_volunteer_schedule = this.state.volunteer_schedule[volunteer_utc_key] || {name: '', color: 'rgba(0, 0, 0, 0)'}
                              return (
                                <tr>
                                  <td>{hour}:00</td>
                                  <td id={volunteer_key} data-utckey={volunteer_utc_key} onClick={() => this.handleVolunteerTdClick(volunteer_utc_key, moment() < tz_time)}>
                                    {
                                      target_volunteer_schedule.name ? <span className='badge rounded-pill fw-bold' style={{background: '#dedede', color: target_volunteer_schedule.color}}>{target_volunteer_schedule.name}</span> : null
                                    }
                                  </td>
                                  <td id={responder_key} data-utckey={responder_utc_key} onClick={() => this.handleResponderTdClick(responder_utc_key)}>
                                    {
                                      target_responder_schedule.name ? <span className='badge rounded-pill fw-bold' style={{background: '#dedede', color: target_responder_schedule.color}}>{target_responder_schedule.name}</span> : null
                                    }
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    :
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th>{this.state.display_timezone}</th>
                            {
                              this.state.days.map(day=><th>{moment(day).tz(this.state.schedule_timezone).format('ddd MM-DD')}</th>)
                            }
                            <th>负责人</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.hours.map(hour=>{
                              let tz_time = moment(this.state.start_date).tz(this.state.schedule_timezone).hour(hour)
                              let responder_key = tz_time.format('YYYY-ww-HH')
                              let responder_utc_key = tz_time.utc().format('YYYY-ww-HH')
                              let target_responder_schedule = this.state.responder_schedule[responder_utc_key] || {name: ''}
                              return (
                                <tr>
                                  <td>{hour}:00</td>
                                  {
                                    this.state.days.map(day=>{
                                      let tz_time = moment(day).tz(this.state.schedule_timezone).hour(hour)
                                      let volunteer_key = tz_time.format('YYYY-MM-DD-HH')
                                      let volunteer_utc_key = tz_time.utc().format('YYYY-MM-DD-HH')
                                      let target_volunteer_schedule = this.state.volunteer_schedule[volunteer_utc_key] || {name: '', color: 'rgba(0, 0, 0, 0)'}
                                      return (
                                        <td id={volunteer_key} data-utckey={volunteer_utc_key} onClick={() => this.handleVolunteerTdClick(volunteer_utc_key, moment() < tz_time)}>
                                          {
                                            target_volunteer_schedule.name ? <span className='badge rounded-pill fw-bold' style={{background: '#dedede', color: target_volunteer_schedule.color}}>{target_volunteer_schedule.name}</span> : null
                                          }
                                        </td>
                                      )
                                    })
                                  }
                                  <td id={responder_key} data-utckey={responder_utc_key} onClick={() => this.handleResponderTdClick(responder_utc_key)}>
                                    {
                                      target_responder_schedule.name ? <span className='badge rounded-pill fw-bold' style={{background: '#dedede', color: target_responder_schedule.color}}>{target_responder_schedule.name}</span> : null
                                    }
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                  }
                </div>
                <div>
                  <input type="button" value="上一周" className="btn btn-outline-dark mx-1" onClick={() => this.changeWeek(-1)} disabled={this.week_offset <= 0} />
                  <input type="button" value="提交" className="btn btn-rounded my-0 btn-primary btn-lg mx-1" onClick={this.postSchedule} />
                  <input type="button" value="下一周" className="btn btn-outline-dark mx-1" onClick={() => this.changeWeek(1)} disabled={this.week_offset >= 4} />
                </div>
                翻页不会丢弃您的更改
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(TalkTimeTable);
