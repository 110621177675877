import React, {Component} from 'react'
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51IQpgLL7zkzQauLdX7nHj4IPTexbTMvBWEkBn0818Wv6Frjx9di1n3SKnZ9IlHAm1nmbvX310mgs2TBpJaURXz0v00Q57JW2jl');


export default class PaymentTest extends Component{
    state={
        value:""
    }
    handleStripe = async()=>{
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
            sessionId: this.state.value,
        });
        if (result.error) {
            alert('err')
        }
    }

    render(){
        return <div>
            <input value={this.state.value} onChange={(e)=>this.setState({value:e.target.value})}
                   />
                   <button onClick={this.handleStripe}>TEST</button>
        </div>
    }

}
