import React, {Component} from 'react'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import ReactTagInput from "@pathofdev/react-tag-input";
import axios from 'axios'
import "@pathofdev/react-tag-input/build/index.css";
import {Alert} from "react-bootstrap";
import avatar from "../assets/website_logo.png";
import ReactLoading from 'react-loading';
import utils from './utils'
import ReactTags from 'react-tag-autocomplete'
import AlertModal from './alertModal'
import {FilterXSS} from 'xss'
import _ from './locale'

const getStatusText = (status) => {
    switch (status) {
        case 0:
            return <Alert variant="info" show={true}>This page is a draft</Alert>;
        case 1:
            return <Alert variant="warning" show={true}>This page is waiting for for approval</Alert>;
        case 2:
            return <Alert variant="warning" show={true}>This page was deleted or rejected, feel free to resubmit.</Alert>;
        case 10:
            return <Alert variant="danger" show={true}>This page has been published, your edit will be visible to public</Alert>;
        default:
            return "";
    }
}
const xssInstance = new FilterXSS({
    css:false,
     onTagAttr:(tag, name, value, isWhiteAttr)=> {
        if (name=="style") {
            return value
        }
     }
    // Parameters are the same with onTag
    // If a string is returned, the tag would be replaced with the string
    // If return nothing, the default measure would be taken (specifies using
    // escape, as described below)
}


)
const editorOption = {
    plugins:[
        'align',
        'image',
        'font',
        'link',
        'video',
    ],
    defaultStyle:"font-size: 18px;",
    buttonList: [
        ['undo', 'redo'],
        // ['font', 'fontSize', 'formatBlock'],
        ['fontSize','fontColor'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        // ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
        ['table','link', 'image', 'video'],
        // ['imageGallery'], // You must add the "imageGalleryUrl".
        ['fullScreen', 'showBlocks', 'codeView'],
        //['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        // ['save', 'template'],
        // '/', Line break
    ],
    resizingBar:false,
    width:"100%",
    minHeight:"300px",
    height:"auto",
    imageUploadSizeLimit: "50000000",
    imageMultipleFile:false,
    imageAccept:".png,.jpg",
}

class AboutUsEdit extends Component{

    state = {
        src_id:null,
        status:0,
        loaded:false,
        tags : [],
        cover_url:"",
        categories:[],
        tagsList:[],
        description:"",
        suggestions:[],
        lang:"en_us",
        editMode:0,
        content:""
    }
    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.title = React.createRef();
    }

    componentDidMount() {
        window.debug = this;
        window.richContent = this.editor.current;
        this.setup();
        this.loadTagList()
    }
    translate = (arr)=>{
        return utils.translate(arr, this.state.tagsList).map(
            (ele)=>({id:ele.tag_id,name:ele.value_cn})
        )
    }

    setup(){
        const editor = this.editor.current;
        const title = this.title.current;
        axios.get(this.getUrl()
            ).then(
            response => {
                title.value = response.data.subject;
                editor.editor?.setContents(response.data.content)
                this.setState({
                    status: response.data.status,
                    tags: response.data.tags,
                    description: response.data.description || '',
                    loaded: true,
                    content:response.data.content,
                    categories: response.data.categories,
                    cover_url: response.data.cover_url || '',
                    lang: response.data.lang || '',
                })
            }
        ).catch(err => {
            // alert(err)
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    getContents = () =>(this.editor.current.editor.getContents()||"");
    getCategoryIds = ()=>{
        return this.state.categories;
    }
    getUrl = ()=>utils.getUrl(`admin/cms/contents/${this.props.src_id}/update/`)
    handleSave = ()=>{
        axios.post(this.getUrl(),
            {"subject": this.title.current.value,
                "content": this.getContents(),
                // "status": 0,
            },
            {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}}).then(response => {
                alert('保存成功！')
            this.setState({editMode:0,
                "subject": this.title.current.value,
                "content": this.getContents()})
        }).catch(err => {
            // alert(err)
        })

    }


    handleUpload = (files, info, uploadHandler)=>{
        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append("ftype", files[0].name.split('.').reverse()[0]);
        axios.post(utils.getUrl('public/blog/imageUpload/'),
            formData,
            {
                headers: {
                    'Authorization': 'JWT ' + localStorage.getItem('loginToken'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(
                response => {
                    uploadHandler(response.data);
                }
        ).catch(err =>{
            // alert(err);
            uploadHandler({
                errorMessage:"err",
                result:[]
            })

        })
        return undefined;

    }
    handleUploadError = (errorMessage)=>{
        alert(errorMessage)
        return true
    }
    tagValidator = (tag)=>{
        const len = tag?.length || 0
        const valid = len > 0 && len < 20;
        if (!valid){
            alert('标签必须在20个字符以内')
        }
        return valid
    }
    handleNewTags = (newTags)=>{
        this.setState({tags:newTags});
    }
    setDescription = (desc)=>{
        this.setState({description: desc})
    }
    loadTagList = async ()=>{
        await utils.loadTagList();
        const tagList = (window.tagList?.groupList || []).concat(window.tagList?.areaList || []);
        this.setState({tagsList:
            tagList,
            suggestions: tagList.map((ele) => {
            return {
                id: ele.tag_id,
                name: ele.value_cn,
            }
        }).filter((ele)=>![200001,300001].includes(ele.id))
        })

    }


    handlePreview= ()=>{
        this.setState({editMode:0});
    }
    handleSelect = (e)=>{
        this.setState(
            {
                lang:e.target.value
            }
        )
    }
    render(){
        const isAdmin = (localStorage.getItem('user_type') || 10) < 3
        const propAction = this.props.action
        return (
        <div className="about-root">
            <div className="blog-container about-container" >
                <div style={{display:this.state.loaded?"initial":"none"}}>
                    <div style={{display: this.state.editMode ? "initial" : "none"}}>
                        <div>{getStatusText(this.state.status)}</div>
                        <input type="text" ref={this.title} placeholder="Place Title Here" className="blog-title"/>
                        <div className="blog-area">
                            <SunEditor className="blog-area"
                                ref={this.editor}
                                    setOptions={editorOption}
                                    onImageUploadBefore={this.handleUpload}
                                    handleUploadError={this.handleUploadError}/>
                        </div>
                        <div className="blog-control-row">
                            <input type="submit" value={_("Save")} className="blog-submit-button"
                                onClick={this.handleSave}/>
                            <input type="submit" value={_("Cancel")} className="blog-submit-button"
                            onClick={this.handlePreview}/>
                        </div>
                    </div>
                    <div className="blog-show-body" style={{display: !this.state.editMode ? "initial" : "none"}}>
                        <span dangerouslySetInnerHTML={{__html: this.state.content}}/>
                        {isAdmin
                        ? <input type="submit" value={_("Edit" )}className="blog-submit-button" onClick={() => this.setState({editMode: 1})}/>
                        : ""}
                    </div>
                </div>

                {this.state.loaded
                ? ""
                : <div className="blog-loading-container">
                    <ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/>
                </div>}
            </div>
        </div>
        );
    }
}

AboutUsEdit.defaultProps = {
    src_id:null,
    returnCallBack:()=>{}
}
export default AboutUsEdit;

