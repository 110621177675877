import React, { Component } from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {Redirect} from 'react-router-dom'
import axios from 'axios'
import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';
import ImageUploader from 'react-images-upload'
import utils from './utils'


export default class AdManage extends Component {

  state={
    banner_id: '',
    mediaList: [],
    displayImgs: [],
    selectedImgs: [],
    selectedLinks: [],
    currentImage: null,
    image_type: 'png',
    link: ''
  }

  componentDidMount = async() =>{
    this.setState({banner_id: this.props.match.params.banner_id.slice(1)})
    this.getBannerList()
    this.getMediaList()
    window.debug_ad = this;

  }

  getBannerList = async() =>{
    await axios.get(utils.getUrl('public/banners/list/'), {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      const bannerList = response.data.data
      bannerList.map(elem => {
        if(elem.banner_id === this.state.banner_id){
          this.setState({selectedImgs: elem.media_urls, selectedLinks: elem.hrefs})
        }
      })
    }).catch(err => {alert(err)})
  }

  getMediaList = async() => {
    await axios.get(utils.getUrl('public/media/list/'), {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      this.setState({mediaList: response.data.data})
    }).catch(err => {alert(err)})

    let tempImgs = []
    this.state.mediaList.map(media => {
      // 是否已被选中
      if(this.state.selectedImgs.includes(media.url)){
        tempImgs.push({
          src: media.url,
          thumbnail: media.url,
          thumbnailWidth: 540,
          thumbnailHeight: 320,
          isSelected: true,
          href: media.href?media.href:'',
          thumbnailCaption: <button onClick={() =>this.handleDelete(media.media_id)}>删除</button>
        })
      }else{
        tempImgs.push({
        src: media.url,
        thumbnail: media.url,
        thumbnailWidth: 540,
        thumbnailHeight: 320,
        href: media.href?media.href:'',
        thumbnailCaption: <button onClick={() =>this.handleDelete(media.media_id)}>删除</button>
      })
      }
    })
    this.setState({displayImgs: tempImgs})
  }
  // gallery image select
  onSelectImage = (index, image) =>{
    var images = this.state.displayImgs.slice();
    var img = images[index];
    let tempSelected = this.state.selectedImgs
    let tempLinks = this.state.selectedLinks
    if(img.hasOwnProperty("isSelected")){
      img.isSelected = !img.isSelected
      if(img.isSelected){
        tempSelected.push(img.src)
        tempLinks.push(img.href)
      }
      else{
        tempSelected.splice(tempSelected.indexOf(img.src), 1)
        tempLinks.splice(tempLinks.indexOf(img.href), 1)
      }
    }
    else{
      img.isSelected = true
      tempSelected.push(img.src)
      tempLinks.push(img.href)
    }
    this.setState({
        displayImgs: images, selectedImgs: tempSelected, selectedLinks: tempLinks
    })
  }

  onDrop = async(pictureFile, pictureDataURL) => {

    if(!pictureFile[0]){
      // this.setState({currentImage: avatar, image_type: 'png'})
    }else{
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(pictureFile[0].name)[1];
      this.setState({currentImage: pictureFile[0], image_type: ext})
    }
  }

  uploadImage = async() => {
    const link = this.getValidUrl(this.state.link)
    var formData = new FormData()
    formData.append('file', this.state.currentImage)
    formData.append('ftype', this.state.image_type)
    formData.append('href', link)
    await axios.post(utils.getUrl(`admin/cms/image-upload/`),
    formData, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      this.getMediaList()
      alert('上传成功！')
    }).catch(err => {alert(err)})
  }

  handleDelete = async(media_id) => {
    if(!window.confirm('你确定要删除此图片？')){
      return false
    }
    await axios.delete(utils.getUrl(`public/media/${media_id}/update/`), {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response =>{
      this.getMediaList()
      alert('删除成功！')
    })
    .catch(err => {alert(err)})
  }

  handleUpdateBanner = async() => {

    await axios.post(utils.getUrl(`admin/cms/banners/${this.state.banner_id}/update/`),
    {media_urls: this.state.selectedImgs, hrefs: this.state.selectedLinks}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response =>{
      this.getMediaList()
      alert('更新成功！')
    }).catch(err => {alert(err)})
  }

  handleLinkChange = (event) =>{
    this.setState({link: event.target.value})
  }

  getValidUrl = (url = "") => {
    if(url === ''){return url}
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if(/^(:\/\/)/.test(newUrl)){
        return `http${newUrl}`;
    }
    if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
        return `http://${newUrl}`;
    }

    return newUrl;
}

  render() {
    if(localStorage.getItem('user_type') && localStorage.getItem('user_type')<=2){
      return (
      <div>
        <Container fluid>
          {/* title */}
          <Row className='my_title' style={{marginBottom: '20px'}}>
            <h3 style={{fontWeight: 'bold', fontSize: '22px'}}>广告管理</h3>
          </Row>
          <Row style={{textAlign: 'center', marginBottom: '20px'}}>
            <h4 style={{fontWeight: 'bold'}}>当前页面：{this.state.banner_id}</h4>
            <button onClick={this.handleUpdateBanner}>更新</button>
          </Row>
          <Row style={{textAlign: 'center', marginBottom: '20px'}}>
            <div style={{
                    display: "block",
                    minHeight: "1px",
                    width: "100%",
                    border: "1px solid #ddd",
                    overflow: "auto"}}>
            <h4>选择显示的图片：</h4>
            <Gallery images={this.state.displayImgs} onSelectImage={this.onSelectImage} showCloseButton={true}/>
            </div>
          </Row>
          <Row style={{textAlign: 'center', marginBottom: '20px'}}><h4>上传新的图片：</h4></Row>
          <Row style={{marginBottom: '20px'}}>
            <ImageUploader
                        withIcon={true}
                        buttonText='Choose image'
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.png', '.gif']}
                        maxFileSize={2000000}
                        label='Max file size: 2mb, accepted: jpg|png|gif'
                        withPreview={true}
                        singleImage={true}
                    />
          </Row>
          <Row style={{textAlign: 'center', marginBottom: '20px'}}>
            给图片添加链接(可选)：<input type="text" onChange={this.handleLinkChange}></input>
          </Row>
          <Row style={{textAlign: 'center', marginBottom: '20px'}}><button id="submit" type="submit" class="btn btn-default" onClick={this.uploadImage}>上传<i class="hno hno-arrow-right"></i></button></Row>
        </Container>
      </div>
    )}
    else{
      return (<Redirect to='/home'></Redirect>)
    }

  }
}
