import React, { Component } from 'react'
import axios from 'axios'
import {Container, Row, Col, Image, Form} from 'react-bootstrap'
import RichEditBox from './RichEditBox';
import default_profile from '../imgs/default_therapist.png';
import vector from '../imgs/Vector.png';
import {GoogleMapFrame, GoogleMapAutoComplete} from "./GoogleMap"
import us_city_list from '../assets/us_states_cities.json'
import successCircle from '../imgs/success_circle.png';
import successMark from '../imgs/success_mark.png';
import ImageUploader from 'react-images-upload';
import utils from './utils'
import '../css/sign-in-sign-up.css'

export default class TherapistProfileRegister extends Component {
  constructor(props) {
    super(props)
    this.inputImage = React.createRef();
    
    this.rolediv = React.createRef();
    this.degreediv = React.createRef();
    this.fielddiv = React.createRef();
    this.targetdiv = React.createRef();
    this.paymentdiv = React.createRef();
  }
  state = {
    title: null, // Dr. at default
    gender: null,
    firstname: '',
    lastname: '',
    chinese_name: '',
    address: '',
    city: '',
    state: '',
    tel_num: '',
    address2: '',
    avatar_type: 'png',
    hours: null,
    isDegreeDisplay: false,
    degree: ['无', 'M.S', 'M.D', 'P.h.D'],
    areaList: [],
    groupList: [],
    paymentList: [],
    allTags: [],
    userId: null,
    profile_id: null,
    credential: '',
    selfIntro: '',
    selfIntro_edit: '',
    additionalInfo: '',
    selectedTagList: [],
    selectedPaymentList: [],
    roleList: [],
    isAreaDisplay: 'none',
    isMethodDisplay: 'none',
    isGroupDisplay: 'none',
    isRoleDisplay: 'none',
    uploadedImage: null,
    hasUploadedImage: false,
    registerSuccessful: false,
    us_states_cities: us_city_list,
    statesList:[],
    cityList: ["New York",
    "Buffalo",
    "Rochester",
    "Yonkers",
    "Syracuse",
    "Albany",
    "New Rochelle",
    "Mount Vernon",
    "Schenectady",
    "Utica",
    "White Plains",
    "Hempstead",
    "Troy",
    "Niagara Falls",
    "Binghamton",
    "Freeport",
    "Valley Stream"],
    selectedCity: '',
    selectedState: 'Alabama',
    checkedIdentity: [],
    checkedSpeciality: [],
    checkedTarget: [],
    checkedPayment: [],
  }

  stateSelected = (event) => {
    const state = event.target.value
    
    let tempCity = []
    this.state.us_states_cities[state].map(city => {
      tempCity.push(city)
    })
    this.setState({cityList: tempCity, selectedState: state, selectedCity: tempCity[0]})
  }

  citySelected = (event) => {
    const city = event.target.value
    this.setState({selectedCity: city})
  }

  componentDidMount = async()=> {
    window.addEventListener('click', this.handleRoleOnClick);
    window.addEventListener('click', this.handleDegreeOnClick);
    window.addEventListener('click', this.hanldeFieldOnClick);
    window.addEventListener('click', this.handleTargetOnClick);
    window.addEventListener('click', this.handlePaymentOnClick);

    /*
    if (!this.props.location.state) {
      this.props.history.push('/home/');
      return;
    }
    const user_id = this.props.location.state.id;
    */
    if (localStorage.getItem('user_id')) {
      const user_id = localStorage.getItem('user_id')
      this.setState({ userId: user_id })
    } else {
      this.props.history.push('/home/');
      return;
    }

    // sort by alphabet
    const sortedList = Object.keys(this.state.us_states_cities).sort().reduce(
      (obj, key) => {
        obj[key] = this.state.us_states_cities[key];
        return obj;
      },
      {}
    )
    this.setState({us_states_cities: sortedList})
    // load statesList
    let tempStates = []
    for(const elem in this.state.us_states_cities){
      tempStates.push(elem)
    }
    this.setState({statesList: tempStates})

    // get all tags
    await axios.get(utils.getUrl('tags/list/'))
    .then(response => {
      this.setState({allTags: response.data.results})
    }).catch(err => {
      // alert(err)
    })

    // 分类tags
    let list = this.state.allTags
    let tempPayment = this.state.paymentList
    let tempArea = this.state.areaList
    let tempGroup = this.state.groupList
    let tempRole = this.state.roleList
    list.map(tag => {
      var cate = ((""+tag.tag_id).split(""))[0]
      if(cate === '5'){
        tempPayment.push(tag)
      }else if(cate === '2'){
        tempArea.push(tag)
      }else if(cate === '3'){
        tempGroup.push(tag)
      }else if(cate === '1'){
        tempRole.push(tag)
      }
      return null
    })
    this.setState({paymentList: tempPayment, areaList: tempArea.sort((a,b) => (a.value_cn).localeCompare(b.value_cn)),
      groupList: tempGroup.sort((a,b) => (a.value_cn).localeCompare(b.value_cn)), roleList: tempRole.sort((a,b) => (a.value_cn).localeCompare(b.value_cn))})

  }

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleRoleOnClick);
    window.removeEventListener('click', this.handleDegreeOnClick);
    window.removeEventListener('click', this.hanldeFieldOnClick);
    window.removeEventListener('click', this.handleTargetOnClick);
  }


  handlePlaceChange = (place) => {
    const format_address = place.formatted_address.split(',')
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    const street_1 = format_address[0]
    // let street_2 = ''
    // if(format_address.length > 4){street_2 = format_address[1]}
    const country = place.address_components[place.address_components.length-2].short_name
    const state = place.address_components[place.address_components.length-3].short_name
    const city = place.address_components[place.address_components.length-5].short_name
    let zip_code = place.address_components[place.address_components.length-1].short_name
    if(zip_code.length === 4){
      zip_code = place.address_components[place.address_components.length-2].short_name + '-' + zip_code
    }
    this.setState({lat, lng, street: street_1, country, state, city, zip_code})
  }
  onDrop = async(pictureFile, pictureDataURL) => {
    if(!pictureFile[0]){
        this.setState({hasUploadedImage: false, avatar_type: 'png'})
    }else{
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(pictureFile[0].name)[1];
        this.setState({hasUploadedImage: true, uploadedImage: pictureFile[0], avatar_type: ext})
    }
  }
  uploadAvatar = async() => {
    var formData = new FormData()
    formData.append('file', this.state.uploadedImage)
    formData.append('ftype', this.state.avatar_type)
    await axios.post(utils.getUrl(`profile/${this.state.profile_id}/upload_avatar/`),
    formData, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('上传成功！')
    }).catch(err => {
      // alert(err)
    })
  }
  handleUploadProfileImage = () => {
    this.inputImage.current.click();
  }
  uploadProfileImage = (event) => {
    this.setState({uploadedImage: URL.createObjectURL(event.target.files[0])})
    this.setState({hasUploadedImage: true})
  }
  deleteProfileImage = () => {
    this.setState({uploadedImage: null})
    this.setState({hasUploadedImage: false})
  }
  changeIdentity = (iden) => {
    this.setState({selectedIdentity: iden})
  }
  changeSpeciality = (event) => {
    // this.setState({selectedSpeciality: spec})
    
  }
  changeClient = (cli) => {
    this.setState({selectedClient: cli})
  }
  changeFirstName = (event) => {
    this.setState({first_name: event.target.value})
  }
  changeLastName = (event) => {
    this.setState({last_name: event.target.value})
  }
  changeChineseName = (event) => {
    this.setState({chinese_name: event.target.value})
  }
  changeAddress = (event) => {
    this.setState({address: event.target.value})
  }
  changeCity = (event) => {
    this.setState({city: event.target.value})
  }
  changeState = (event) => {
    this.setState({state: event.target.value})
  }
  changeZipCode = (event) => {
    this.setState({zip_code: event.target.value})
  }
  changeTelNum = (event) => {
    this.setState({tel_num: event.target.value})
  }
  changeAddressTwo = (event) => {
    this.setState({address2: event.target.value})
  }
  handleDegreeDropDown = () => {
    if(this.state.isDegreeDisplay === 'none'){
      this.setState({isDegreeDisplay: 'block', isAreaDisplay: 'none', isMethodDisplay: 'none', isGroupDisplay: 'none', isRoleDisplay: 'none'})
    }else{
      this.setState({isDegreeDisplay: 'none'})
    }
  }
  handleDegree = (tag) => {
    this.setState({credential: tag});
  }
  handleMethodDropdown = () => {
    if(this.state.isMethodDisplay === 'none'){
      this.setState({isAreaDisplay: 'none', isMethodDisplay: 'block', isGroupDisplay: 'none', isRoleDisplay: 'none'})
    }else{
      this.setState({isMethodDisplay: 'none'})
    }
  }

  // when a tag is selected or unselected
  handleFilter = (event) => {
    
    const id = parseInt(event.target.id)
    let tempList = this.state.selectedTagList
    let tempPayment = this.state.selectedPaymentList
    if(event.target.checked){
      if (id >= 100000 && id <= 199999) {
        this.setState({checkedIdentity: [...this.state.checkedIdentity, event.target.name]})
      }
      if (id >= 200000 && id <= 299999) {
        this.setState({checkedSpeciality: [...this.state.checkedSpeciality, event.target.name]})
      }
      if (id >= 300000 && id <= 399999) {
        this.setState({checkedTarget: [...this.state.checkedTarget, event.target.name]})
      }
      if (id >= 500000 && id <= 599999) {
        this.setState({checkedPayment: [...this.state.checkedPayment, event.target.name]})
      }
      if(((""+id).split(""))[0] === '5'){tempPayment.push(parseInt(id))}
      else{tempList.push(parseInt(id))}
    }else{
      if (id >= 100000 && id <= 199999) {
        let newIdentity = this.state.checkedIdentity.filter((value) => {
          return value !== event.target.name;
        });
        this.setState({checkedIdentity: newIdentity});
      }
      if (id >= 200000 && id <= 299999) {
        let newSpeciality = this.state.checkedSpeciality.filter((value) => {
          return value !== event.target.name;
        });
        this.setState({checkedSpeciality: newSpeciality});
      }
      if (id >= 300000 && id <= 399999) {
        let newTarget = this.state.checkedTarget.filter((value) => {
          return value !== event.target.name;
        });
        this.setState({checkedTarget: newTarget});
      }
      if (id >= 500000 && id <= 599999) {
        let newPayment = this.state.checkedPayment.filter((value) => {
          return value !== event.target.name;
        });
        this.setState({checkedPayment: newPayment})
      }
      if(((""+id).split(""))[0] === '5'){
        tempPayment = tempPayment.filter((value) => {
          return value !== id
        })
      }else{
        tempList = tempList.filter((value) => {
        return value !== id
        })
      }

    }
    this.setState({selectedTagList: tempList, selectedPaymentList: tempPayment})
  }
  // editSelfIntro = () => {
  //   if (this.state.selfIntro_edit === '') {
  //     this.setState({selfIntro: ''})
  //   } else {
  //     let content = this.state.selfIntro_edit.replace(/<[^>]+>/g, '')
  //     this.setState({selfIntro: content})
  //     alert('保存成功')
  //   }
  // }
  handleSubmit = async(event) => {
    event.preventDefault()
    // pre-validation
    let formIsValid = true;
    var alertMessage = "请完善";
    // validation - 基本信息
    if (this.state.chinese_name === "" || this.state.gender === null 
      || this.state.title === null || this.state.credential === null) {
      formIsValid = false;
      alertMessage += "【基础信息】"
    }
    // validation - 联系方式
    if (this.state.address === "" || this.state.city === "" || this.state.state === "" 
      || this.state.zip_code === "" || this.state.tel_num === "") {
      formIsValid = false;
      alertMessage += "【联系方式】"
    }
    // validation - 咨询师相关
    if (this.state.selectedTagList.length === 0 || this.state.hours === null){
      formIsValid = false;
      alertMessage += "【咨询师相关】"
    }

    if(!formIsValid){
      alert(alertMessage)
      return false
    }

    if (this.state.selfIntro_edit === '') {
      await this.setState({selfIntro: ''})
    } else {
      let content = this.state.selfIntro_edit.replace(/<[^>]+>/g, '')
      await this.setState({selfIntro: content})
    }

    let profile;
    if (this.state.degree == '无') {
      profile = {
        gender: this.state.gender,
        title: this.state.title,
        advanced_credential: null,
        len_exp_hr: this.state.hours,
        self_intro: this.state.selfIntro,
        additional_info: this.state.additionalInfo,
        payment_method: this.state.selectedPaymentList
      };
    } else {
      profile = {
        gender: this.state.gender,
        title: this.state.title,
        advanced_credential: this.state.credential,
        len_exp_hr: this.state.hours,
        self_intro: this.state.selfIntro,
        additional_info: this.state.additionalInfo,
        payment_method: this.state.selectedPaymentList
      };
    }
    
    
    let location = [
      {'state': this.state.state},
      {'city': this.state.city},
      {'zip_code': this.state.zip_code},
      {'address1': this.state.address},
      {'address2': this.state.address2}
    ];

    const identification = {
      tags: this.state.selectedTagList
    }

    await axios.post(utils.getUrl(`profile/${this.state.userId}/`), 
    {
      verified_phone: this.state.tel_num, 
      reg_addr: location,
      gender: this.state.gender,
    },
    {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
    .then(response => {
    }).catch(error => {      
      alert(error)
    })

    await axios.post(utils.getUrl(`profile/${this.state.userId}/add/`),
    {'profile': profile, 'identification': identification},
    {'headers': {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then((response) =>{
      this.setState({profile_id: response.data.data.profile_id})
      localStorage.setItem('profile_id', response.data.data.profile_id)
      this.setState({registerSuccessful: true})
    }).catch(err => {
      // alert(err)
    })
  }

  returnProfilePage = () => {
    this.props.history.push({pathname: `/webuser/profile/:${this.state.userId}`})
    //  window.findSelfApp.nav.current.reload()
  }

  handleTitle = (event) => {
    this.setState({title: parseInt(event.target.value)})
  }
  handleTags = (event) => {
    let tempTags = this.state.selectedTagList
    const tag = event.target.value
    if(!tempTags.includes(tag)){
      tempTags.push(tag)
    }
    this.setState({selectedTagList: tempTags})
  }
  changeCredential = (event) => {
    this.setState({credential: event.target.value})
  }
  changeHours = (event) => {
    if (isNaN(parseInt(event.target.value))) {
      this.setState({hours: 0});
    } else {
      this.setState({hours: parseInt(event.target.value)})
    }
  }
  changeIntro = (event) => {
    this.setState({selfIntro: event.target.value})
  }
  changeAdditionalInfo = (event) => {
    this.setState({additionalInfo: event.target.value})
  }
  changeGender = (event) => {
    this.setState({gender: parseInt(event.target.value)})
  }


  handleRoleOnClick = (event) => {
    if (this.state.isRoleDisplay === 'block' && 
       (this.roleContainer != null && !this.roleContainer.current.contains(event.target))) {
      this.setState({isRoleDisplay: 'none'});
    }
  }

  handleDegreeOnClick = (event) => {
    if (this.state.isDegreeDisplay === 'block' && 
      (this.degreeContainer != null && !this.degreeContainer.current.contains(event.target))) {
      this.setState({isDegreeDisplay: 'none'});
    }
  }

  hanldeFieldOnClick = (event) => {
    if (this.state.isAreaDisplay === 'block' &&
     (this.fieldContainer != null && !this.fieldContainer.current.contains(event.target))) {
      this.setState({isAreaDisplay: 'none'});
    }
  }

  handleTargetOnClick = (event) => {
    if (this.state.isGroupDisplay === 'block' && 
     (this.targetContainer != null && !this.targetContainer.current.contains(event.target))) {
      this.setState({isGroupDisplay: 'none'});
    }
  }

  handlePaymentOnClick = (event) => {
    if (this.state.isMethodDisplay === 'block' && 
      (this.paymentContainer != null && !this.paymentContainer.current.contains(event.target))) {
      this.setState({isMethodDisplay: 'none'});
    }
  }


  render() {
    
    if (this.state.registerSuccessful) {
      return (
        <div style={{ marginTop: 50 }}>
                <div>
                    <div className='create-event-container' style={{ margin: 'auto', width: 600, height: 300 }}>

                        <div style={{ marginTop: 30, marginLeft: 1 }}>
                            <h1 style={{ fontWeight: 400, fontSize: 32 }}>咨询师信息创建成功 前往个人中心查看</h1>
                        </div>

                        <div>
                            <button className="btn btn-primary" onClick={this.returnProfilePage}>
                                确认
                            </button>
                        </div>
                    </div>
                </div>
            </div>
      )
    } else {
      return (
      <div className="container-fluid px-lg-10 row">
        <div className="row justify-content-center">
        <div className="col-lg-10">
          <h1>注册咨询师账号</h1>
          <div className="mb-3" >
            <span className='fs-5 box-shadow'>基础信息</span>
          </div>
              
          <div className="mb-3 row">
            {/*姓名*/}
            <label for="chinesename" className="col-sm-1 col-form-label">姓名</label>
            <div className="col-sm-5">
              <input className="form-control" type="text" value={this.state.chinese_name} onChange={this.changeChineseName} name="chinesename" placeholder="" required />
            </div>
            {/*性别*/}
            <label for="gender" className="col-sm-1 col-form-label">性别</label>
            <div className="col-sm-5">
              <select class="form-select" id="gender" name="gender" aria-label="Default select example" onChange={(e) => this.changeGender(e)}>
                <option disabled selected value></option>
                <option value="1">男</option>
                <option value="2">女</option>
              </select>
            </div>
          </div>

          <div className="mb-3 row">
            <label for="title" className="col-sm-1 col-form-label">称谓</label>
            <div className="col-sm-5">
              <select class="form-select" id="title" name="title" aria-label="Default select example" onChange={(e) => this.handleTitle(e)}>
                <option disabled selected value></option>
                <option value="0">无</option>
                <option value="1">博士/Dr.</option>
                <option value="2">教授/Prof.</option>
              </select>
            </div>
            <label for="degree" className="col-sm-1 col-form-label">学历</label>
            <div className="col-sm-5">
              <select class="form-select" id="degree" name="degree" aria-label="Default select example" onChange={(e)=>this.handleDegree(e.target.value)}>
                <option disabled selected value></option>
                {
                  this.state.degree.map((degreeTag, degreeIndex) => (
                    <option value={degreeTag}>{degreeTag}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="mb-3" >
            <span className='fs-5 box-shadow'>联系方式</span>
          </div>

          <div className="mb-3 row">
            <label for="address" className="col-sm-1 col-form-label">地址</label>
            <div className="col-sm-11">
              <input className="form-control" type="text" value={this.state.address} onChange={this.changeAddress} id="address" name="address" placeholder="地址" required />
              <input className="form-control mt-2" type="text" value={this.state.address2} onChange={this.changeAddressTwo} name="address2" placeholder="详细备注(选填)" />
            </div>
          </div>
  
          <div className="mb-3 row">
            <label for="city" className="col-sm-1 col-form-label">城市</label>
            <div className="col-sm-5">
              <input className="form-control" type="text" value={this.state.city} onChange={this.changeCity} id="city" name="city" placeholder="" required />
            </div>
            <label for="state" className="col-sm-1 col-form-label">州/省</label>
            <div className="col-sm-5">
              <input className="form-control" type="text" value={this.state.state} onChange={this.changeState} id="state" name="state" placeholder="" required />
            </div>
          </div>
  
          <div className="mb-3 row">
            <label for="zip_code" className="col-sm-1 col-form-label">邮编</label>
            <div className="col-sm-5">
              <input className="form-control" type="text" value={this.state.zip_code} onChange={this.changeZipCode} id="zip_code" name="zip_code" placeholder="" required />
            </div>
            <label for="tel_num" className="col-sm-1 col-form-label">电话</label>
            <div className="col-sm-5">
              <input className="form-control" type="number" value={this.state.tel_num} onChange={this.changeTelNum} id="tel_num" name="tel_num" placeholder="" required />
            </div>
          </div>
  
          <div className="mb-3" >
            <div>
              <span className='fs-5 box-shadow'>咨询师相关</span>
            </div>
          </div>

          {/*咨询师身份*/}
          <div id='registerRadioRow' ref={this.roleContainer} className="mb-3">
            <div>
              <input className="form-select mb-3" ref={this.inputBar} value={this.state.checkedIdentity.join(', ')} style={{ paddingLeft: '1%', color: '#43B0B1' }} href="javascript:;" onClick={() => { this.setState({ isAreaDisplay: 'none', isMethodDisplay: 'none', isGroupDisplay: 'none', isRoleDisplay: 'block' })}} type="text" placeholder="咨询师身份" readOnly />
              <ul className="dropdown-menu px-3" style={{ display: this.state.isRoleDisplay, border: 'solid #43B0B1 3px' }} onBlur={(e) => { if (!e.currentTarget.contains(e.relatedTarget)) this.setState({ isRoleDisplay: 'none' })}}>
                <div style={{ width: '100%', height: 48, color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: 10 }}>
                  咨询师身份
                </div>
                {
                  this.state.roleList.map((tag) => (
                    <li style={this.state.checkedIdentity.includes(tag.value_cn) ? { width: '100%', height: 48, backgroundColor: '#F4FCFE', paddingTop: 15 } : { width: '100%', height: 48, paddingTop: 15 }} className='registerRadioDiv'>
                      <input type="checkbox" onClick={this.handleFilter}
                        name={tag.value_cn} id={tag.tag_id} key={tag.tag_id} />
                      <label for={tag.tag_id}>{tag.value_cn}</label>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
  
          <div id='registerRadioRow' ref={this.fieldContainer} className="mb-3" >
            <div>
              <input className="form-select mb-3" value={this.state.checkedSpeciality.join(', ')} style={{ paddingLeft: '1%', color: '#43B0B1' }} href="javascript:;" onClick={() => { this.setState({ isAreaDisplay: 'block', isMethodDisplay: 'none', isGroupDisplay: 'none', isRoleDisplay: 'none' })}} type="text" placeholder="擅长领域" readOnly />
              <ul className="dropdown-menu px-3" style={{ display: this.state.isAreaDisplay, border: 'solid #43B0B1 3px' }} onBlur={(e) => { if (!e.currentTarget.contains(e.relatedTarget)) this.setState({ isAreaDisplay: 'none' })}}>
                <div style={{ width: '100%', height: 48, color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px'}}>擅长领域</div>
                {
                  this.state.areaList.map((tag) => (
                    <li style={this.state.checkedSpeciality.includes(tag.value_cn) ? { width: '100%', height: 48, backgroundColor: '#F4FCFE', paddingTop: 15 } : { width: '100%', height: 48, paddingTop: 15 }} className='registerRadioDiv'>
                      <input type="checkbox" onClick={this.handleFilter}
                      name={tag.value_cn} id={tag.tag_id} key={tag.tag_id}/>
                      <label for={tag.tag_id}>{tag.value_cn}</label>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>

          <div id='registerRadioRow' ref={this.targetContainer} className="mb-3" >
            <div>
              <input className="form-select mb-3" value={this.state.checkedTarget.join(', ')} style={{ paddingLeft: '1%', color: '#43B0B1' }} href="javascript:;" onClick={() => { this.setState({ isAreaDisplay: 'none', isMethodDisplay: 'none', isGroupDisplay: 'block', isRoleDisplay: 'none' })}} type="text" placeholder="主要服务对象" readOnly />
              <ul className="dropdown-menu px-3" style={{ display: this.state.isGroupDisplay, border: 'solid #43B0B1 3px' }} onBlur={(e) => { if (!e.currentTarget.contains(e.relatedTarget)) this.setState({ isGroupDisplay: 'none' }) }}>
                <div style={{ width: '100%', height: 48, color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px'}}>面向群体</div>
                {
                  this.state.groupList.map((tag) => (
                    <li style={ this.state.checkedTarget.includes(tag.value_cn) ? { width: '100%', height: 48, backgroundColor: '#F4FCFE', paddingTop: 15 } : { width: '100%', height: 48, paddingTop: 15 }} className='registerRadioDiv'>
                      <input type="checkbox" onClick={this.handleFilter}
                      name={tag.value_cn} id={tag.tag_id} key={tag.tag_id}/>
                      <label for={tag.tag_id}>{tag.value_cn}</label>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>

          <div className="mb-3 row">
            <label for="other" className="col-sm-1 col-form-label">经验</label>
            <div className="col-sm-5">
              <input className="form-control" type="number" value={this.state.hours === 0 ? null : this.state.hours} onChange={this.changeHours} id="hours" name="hours" placeholder={"服务时长 (小时)"} required />
            </div>
            <label for="other" className="col-sm-1 col-form-label">备注</label>
            <div className="col-sm-5">
              <input className="form-control" type="text" value={this.state.additionalInfo} onChange={this.changeAdditionalInfo} name="other" placeholder="选填" required />
            </div>
          </div>
  
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">自我介绍</label>
            <textarea class="form-control" id="selfIntro" rows="3" placeholder="选填" value={this.state.selfIntro_edit} onChange={(e) => { this.setState({ selfIntro_edit: e.target.value }) }} ></textarea>
          </div>
  
          <div className="mb-3">
            {/*<RichEditBox value={this.state.selfIntro_edit} onChange={(c)=>{this.setState({selfIntro_edit:c})}}/>*/}
            {/* <button className="profile-btn-1" onClick={this.editSelfIntro} style={{height: '32px', width: '128px', marginTop: '17px', marginBottom: '10px', lineHeight: '24px'}}>保存</button> */}
          </div>

          {/*
            <div className="mb-3">
              <div>
                <span className='fs-5 box-shadow'>付款偏好</span>
              </div>
            </div>

            <div id='registerRadioRow' ref={this.paymentContainer}className="mb-3">
              <div>
                <input className="form-select" value={this.state.checkedPayment.join(', ')} style={{ paddingLeft: '1%', color: '#43B0B1' }} href="javascript:;" onClick={() => this.handleMethodDropdown()} type="text" placeholder="付款方式偏好/Payment" readOnly />
                <ul className="dropdown-menu" style={{display: this.state.isMethodDisplay, border: 'none'}}>
                  <div style={{ width: '100%', height: 48, color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px'}}>付款方式</div>
                  {
                    this.state.paymentList.map((tag) => (
                      <li style={ this.state.checkedPayment.includes(tag.value_cn) ? { width: '100%', height: 48, backgroundColor: '#F4FCFE', paddingTop: 15 } : { width: '100%', height: 48, paddingTop: 15 }} className='registerRadioDiv'>
                        <input type="checkbox" style={{ top: '40%' }} onClick={this.handleFilter}
                        name={tag.value_cn} id={tag.tag_id} key={tag.tag_id}/>
                        <label for={tag.tag_id}>{tag.value_cn}</label>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          */}
          
            
          <div className="d-grid pb-3">
            <button onClick={this.handleSubmit} className='btn btn-primary'>创建</button>
          </div>  
            
        </div>
        </div>
      </div>
      )
    }
  }
}
