import React, { Component } from 'react'
import 'pure-react-carousel/dist/react-carousel.es.css';
import utils from "./utils";

export default class BannerVertical extends Component {

  state = {
    banner_id: '',
    bannerNum: 0,
    bannerImgs: [],
    bannerList: [],
    hrefs: []
  }

  componentDidMount = () => {
    this.setState({banner_id: this.props.banner_id})
    this.getBannerList()
  }

  getBannerList = async() =>{
    await utils.loadBanner()
    window?.allAds?.map(elem => {
      if(elem.banner_id === this.state.banner_id){
        this.setState({bannerImgs: elem.media_urls, hrefs: elem.hrefs})
        this.setState({bannerNum: this.state.bannerImgs.length})
      }
    })

  }

  render() {
    return (
      <div>
        {localStorage.getItem('user_type')&&localStorage.getItem('user_type')<=2?
            (<button className="homepage-btn-1" onClick={() =>this.props.forward(this.state.banner_id)}>管理广告</button>):(<div></div>)
            }
          {/* <div className='ad_vertical'>
            <CarouselProvider
            naturalSlideWidth={324}
            naturalSlideHeight={1200}
            totalSlides={this.state.bannerNum}
            isPlaying={true}
            interval={4000}
            infinite={true}
            hasMasterSpinner={true}
            style={{}}
            >
            <Slider>
              {
                this.state.bannerImgs.map(img => (<Slide><a href='https://www.google.com/' target='_blank'><Image src={img}/></a></Slide>))
              }
            </Slider>
            </CarouselProvider>
          </div> */}
          {
                this.state.bannerImgs.map(img => {
                  const index = this.state.bannerImgs.indexOf(img)
                  const link = this.state.hrefs[index]===''?null:this.state.hrefs[index]
                  return (<div className='ad_vertical'><a href={link} target='_blank'><img src={img} style={{height: '356px', width: '240px'}}/></a></div>)
                })
              }
          
      </div>
    )
  }
}
