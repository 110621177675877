import React, { Component } from 'react'
import axios from 'axios'
import {NavLink, Redirect} from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import utils from './utils'
import ReactGA from 'react-ga'

class SignupTherapist extends Component {

  state = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirm: '',
    gender: 0,
    nickname: '',
    slogan: '',
    invite_id: null,
    isQuestionLoading: false,
    questions: [],
    displayIntro: true,
    displayPassword: false,
    displayConfirmPassword: false
}

handleSubmit = async(event) => {
  event.preventDefault()
  // pre-validation
  let formIsValid = true;
  if(!this.state.first_name){
    formIsValid = false;
    alert('请填写您的名字')
  }
  else if(!this.state.last_name){
    formIsValid = false;
    alert('请填写您的姓氏')
  }
  else if(!this.state.email){
    formIsValid = false;
    alert('请填写您的邮箱')
  }
  else if(!this.state.password){
    formIsValid = false
    alert('请填写您的密码')
  }
  else if(!this.state.password_confirm){
    formIsValid = false
    alert('请确认您的密码')
  }
  else if(this.state.password.length < 6){
    formIsValid = false;
    alert('密码必须至少包含6个字符')
  }
  else if(this.state.password !== this.state.password_confirm){
    formIsValid = false;
    alert('两次输入的密码不一致')
  }
  if (this.state.invite_id) {
    await axios.get(utils.getUrl(`admin/invitation/${this.state.invite_id}/update/`)).then((response) => {
      if ((  response.data.first_name && response.data.first_name != this.state.first_name )|| (response.data.last_name && response.data.last_name != this.state.last_name)) {
        formIsValid = false;

        alert(`邀请码属于 ${response.data.first_name} ${response.data.last_name},与表单中姓名不匹配`)
      }
    }).catch((err) => {
      formIsValid = false;
      alert("邀请码错误")
    })
  }
  else if(typeof this.state.email !== "undefined"){
    let lastAtPos = this.state.email.lastIndexOf('@');
    let lastDotPos = this.state.email.lastIndexOf('.');

    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
       formIsValid = false;
       alert('邮箱格式不合法')
     }
  }

  if(!formIsValid){return false;}

  let isEmailExist = false
  await axios.post(utils.getUrl('permission/email-verification/exist/'), {email:this.state.email})
  .then(async(response) => {
    // does email already exist
    if(response.data.exist){
      isEmailExist = true
      alert('此邮箱已存在！')
    }
  }).catch(err => {
    // alert(err)
  })

  if(!isEmailExist){
    // forward verification page
    await axios.post(utils.getUrl('permission/email-verification/generate-code/'), {email:this.state.email, action: 'register'})
    .then(response => {
      if(response.data.status === 0){
        let registerData =
          {
            "first_name":this.state.first_name,
            "last_name":this.state.last_name,
            "nickname": this.state.nickname,
            "user_type": 3,
            "email": this.state.email,
            "username": this.state.email,
            "password": this.state.password,
            "gender": this.state.gender
          }
        this.props.history.push({pathname:'/verification', state: { email:this.state.email, data: registerData, invitation: this.state.invite_id}})
      }
    }).catch(err => {
      // alert(err)
    })
  }

  return false;
}

changeFirstName = (event) => {
  this.setState({first_name: event.target.value})
}
changeLastName = (event) => {
  this.setState({last_name: event.target.value})
}
changeInviteId = (event) => {
  this.setState({invite_id: parseInt(event.target.value)||""})
}
changeEmail = (event) => {
  this.setState({email: event.target.value})
}
changePassword = (event) => {
  this.setState({password: event.target.value})
}
changePasswordConfirm = (event) => {
  this.setState({password_confirm: event.target.value})
}
changeNickname = (event) => {
  this.setState({nickname: event.target.value})
}
changeGender = (event) => {
  this.setState({gender: parseInt(event.target.value)})
}

componentDidMount = async() => {
  this.props.cancelGap();
  this.props.setDisplayURL();

  this.setState({isLoading: true})
  //get q&a Questions
  await axios.get(utils.getUrl('cms/questions/list/critical/?lang=cn&tag=register_question'))
  .then(response => {
    this.setState({questions: response.data.data.data, isQuestionLoading: false})
  }).catch(err => {
    // alert(err)
  })
}
componentWillUnmount = () => {
  this.props.addGap()
}
displayContent = ()=>{
  this.setState({displayIntro: false})
}

displayPassword = () => {
  this.setState({displayPassword: !this.state.displayPassword})
}
displayConfirmPassword = () => {
  this.setState({displayConfirmPassword: !this.state.displayConfirmPassword})
}

render() {
  // GA - PageView - Start
  let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
  ReactGA.pageview(pagePathForGA);
  // GA - PageView - End
  if(localStorage.getItem('loginToken')){
    alert('您已经登录！')
    return(
      <Redirect to='/home/'></Redirect>
    )
  }else{
  return (
    <div class="container-fluid">
        <div class="row align-self-center justify-content-center">
          <div class="form-card p-3 my-2">
            <form class="">
              <ul class="nav justify-content-center">
                <li class="nav-item">
                  <NavLink className="nav-link" activeClassName="active" to='/signup/therapist'>
                    <h2>心理工作者注册通道</h2>
                  </NavLink>
                </li>
              </ul>
              <div class="input-group mb-3">
                <input type="text" value={this.state.first_name} onChange={this.changeFirstName} name="firstname" placeholder="姓" class="form-control" required/>
                <input type="text" value={this.state.last_name} onChange={this.changeLastName} name="lastname" placeholder="名" class="form-control" required/>
              </div>
              <div class="input-group mb-3">
                <input type="text" value={this.state.email} onChange={this.changeEmail} name="email" placeholder="邮箱" class="form-control" required/>
              </div>
              <div class="input-group mb-3">
                <input type={this.state.displayPassword?'text':'password'} name="password" value={this.state.password} onChange={this.changePassword} placeholder="密码" class="form-control border-right" required/>
                <div class="input-group-append">
                  <span class="input-group-text border-left" onClick={this.displayPassword}>
                    {this.state.displayPassword ? <i class="bi bi-eye"></i> : <i class="bi bi-eye-slash"></i> }
                  </span>
                </div>
              </div>
              <PasswordStrengthBar password={this.state.password}/>
              <div class="input-group mb-3">
                <input type={this.state.displayConfirmPassword?'text':'password'} value={this.state.password_confirm} onChange={this.changePasswordConfirm} name="password_confirm" placeholder="确认密码" class="form-control border-right" required/>
                <div class="input-group-append">
                  <span class="input-group-text border-left" onClick={this.displayConfirmPassword}>
                    {this.state.displayConfirmPassword ? <i class="bi bi-eye"></i> : <i class="bi bi-eye-slash"></i> }
                  </span>
                </div>
              </div>

              <div class="input-group mb-3">
                <input type="text" value={this.state.nickname} onChange={this.changeNickname} name="nickname" placeholder="用户名" class="form-control"/>
              </div>
              <div class="input-group mb-3">
              <input type="text" value={this.state.invite_id} onChange={this.changeInviteId} name="invite_id" placeholder="邀请码（选填）" class="form-control" required/>
              </div>
              <div class="d-grid pb-3">
                <button id="submit" type="submit" class="btn btn-primary px-5" onClick={this.handleSubmit}>注册</button>
              </div>
            </form>
            <div>
              <small>注册代表您已同意找我的<NavLink class="text-small text-dark-green" to='/agreements' target='_blank'>《用户服务协议》</NavLink>和<NavLink class="text-small text-dark-green" to='/privacy' target='_blank'>《隐私协议》</NavLink>。</small>
            </div>
            <div class="pt-3">
              <NavLink class="btn btn-third p-0" to='/signup/webuser'>我是普通用户，立即注册</NavLink>
            </div>
        </div>

        {/* Q & A */}
        {/* <div class="row col-12 col-lg-3">
          <div class="grey-border p-4 my-4">
            <h4>咨询指南</h4>
            <ul>
              {this.state.isQuestionLoading?(<div><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'20%'} width={'20%'}/></div>):
                this.state.questions.map(question => (
                  <li class="py-2"><i class="bi bi-chat-left-text brand-primary-color"></i>
                    <NavHashLink className="qa-text" smooth to={`/questions/#${question.post_id}`}>&nbsp;{question.subject}</NavHashLink>
                  </li>
                ))
              }
            </ul>
          </div>
        </div> */}

      </div>
    </div>)
  }
  }
}

export default withRouter(SignupTherapist)
