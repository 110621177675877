import React, { Component } from 'react';
import AboutUsEdit from "./AboutUsEdit";

export default class Agreements extends Component {
    render() {
        return (
            <div class="container-fluid px-lg-10">
                <AboutUsEdit src_id="extra_5" />
            </div>
        )
    }
}
