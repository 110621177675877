import React, { Component } from 'react'

export default class AlertModal extends Component {

  state = {
    showModal: false,
    modalText: ''
  }

  handleModalClose = () => {
    this.setState({showModal: false})
    if(this.props.extraCallBack){
      this.props.extraCallBack()
    }
  }

  componentDidMount = () => {
    this.setState({showModal: this.props.showModal, modalText: this.props.modalText})
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.setState({showModal: this.props.showModal})
    }
    if (this.props.modalText !== prevProps.modalText) {
      this.setState({modalText: this.props.modalText})
    }
    // set the auto dismiss for alert modal
    if (this.state.showModal === true) {
      setTimeout(() => this.closeAlert(), 3000);
    }
  }

  closeAlert= () => {
    this.setState({ showModal: false })
    this.handleModalClose()
  }

  render() {
    return (
        <div id="alertmodal" className={ 'fixed-top text-center alert alert-dismissible fade ' + (this.state.showModal? 'show' : '') } onHide={this.handleModalClose}
            style={{ backgroundColor: '#43B0B1', color: 'whitesmoke', height: 55, margin: 5, zIndex: this.state.showModal? 9999 : -1 }} role="alert">
          <h5>{this.state.modalText}</h5>
          <button type="button" className="btn-close" aria-label="Close" onClick={this.closeAlert}></button>
        {/*
        <Modal show={this.state.showModal} onHide={this.handleModalClose} animation={false} dialogClassName='alertDialog' style={{ backgroundColor: '#FAFAFA', zIndex: '999999' }}>
          <h3 class="p-2">{this.state.modalText}</h3>
          <div class="d-grid p-2">
            <button className='btn btn-primary' onClick={this.handleModalClose}>关闭</button>
          </div>
        </Modal>
        */}
        </div>      
    )
  }
}
