import React, { Component } from 'react'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CheckoutForm } from '@stripe/react-stripe-js';
import us_city_list from '../assets/us_states_cities.json'
import avatar from '../assets/website_logo.png'
import utils from './utils'

export default class PaymentQuickPay extends Component {

    state = {
        stripePromise: loadStripe(utils.getStripeKey()),
        stripeElementOptions: {
            clientSecret: ''
        },
        ticket_data: {
            isOnsite: false,
            user_id: localStorage.getItem('user_id'),
            length: 1,
            method: 1,
            tags: [],
            description: '',
            timezone: '东部时间',
            currency: 'CNY',
            start_time: '2021-11-24T19:00:00.000Z',
            location: ''
        },
        ticket_id: '',
        street: '',
        street_2: '',
        zip_code: '',
        us_states_cities: us_city_list,
        cityList: [],
        stateList: [],
        selectedState: '',
        selectedCity: '',
        loginToken: 'JWT ' + localStorage.getItem('loginToken')
    }

    handlePayment = async () => {
        await this.createTicket().then(() => { this.forwardPayment() })
    }

    createTicket = async () => {
        await axios.post(utils.getUrl(`appointment/ticket/create/3737799703/`),{length: 1,method: 1,currency: 'CNY',start_time: '2021-11-24T19:00:00.000Z',location: ''}
        ,{ headers: { 'Authorization': 'JWT ' + localStorage.getItem('loginToken') } })
        .then(response => {
            console.log(response.data)
        }).catch(err => {
            console.log(err)
        })
    }

    forwardPayment = async () => {
        this.setState({ stripe_id: "cs_test_a1l6ThWnQjdBjm5qVOMxfBOlW7dIxOn8wXtMjqxiE8IffVRLC6Bc5UxWYL" })
        const stripe = await loadStripe(utils.getStripeKey())
        const result = await stripe.redirectToCheckout({
            sessionId: this.state.stripe_id,
        })
        if (result.error) {
            alert('支付错误')
        }
    }

    componentWillMount = () => {
        
    }

    componentDidMount = async () => {
    }

    render() {
        return (
            <div>
                <button onClick={() => this.createTicket()}>create ticket</button>
                <button onClick={() => this.forwardPayment()}>Make Payment</button>
            </div>
        )
    }
}
