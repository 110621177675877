import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import 'bootstrap-table/dist/bootstrap-table.min.js'
import "react-datetime/css/react-datetime.css";

import './css/common.css'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/app'
import ScrollToTop from './scrollToTop'
import {HashRouter} from 'react-router-dom'

ReactDOM.render(
  <HashRouter>
    <ScrollToTop/>
    <App />
  </HashRouter>,
  document.getElementById('root')
);
