import React, { Component } from 'react'
import axios from 'axios'
import {Container, Row, Col, Alert, Modal, Button, Pagination, Tab, Nav, Card} from 'react-bootstrap'
import {GoogleMapFrame, GoogleMapAutoComplete} from "./GoogleMap";
import avatar from '../imgs/UserAvatarDefault.svg'
import ProfileEdit from './userProfilePageEdit';
import TherapistProfileEdit from './therapistProfileEdit'
import ReactLoading from 'react-loading';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import AboutUsEdit from "./AboutUsEdit";
import utils from './utils'
import {loadStripe} from '@stripe/stripe-js'
import { withRouter } from 'react-router';
import moment from 'moment'
import CreateAppointment from "./createAppointment"
import ScheduleConfig from './ScheduleConfig';
import ScheduleAppointment from './ScheduleAppointment';
import { Link } from 'react-router-dom'

import '../css/user-profile.css'


const keys = ['additional_info','advanced_credential','len_exp_hr',
'title','self_intro','first_name','last_name','payment_method',
    'tags','address','hourly_price','license','experience'
]
const pageSize= 5;
const parse = (html)=>{
  return <span dangerouslySetInnerHTML={{__html: html}}/>
}
const withDrawStatus = [
    '未处理', '已完成', '已拒绝'
]
class WebuserProfile extends Component {
  constructor(props) {
    super(props);
    this.blogOrderSelect = React.createRef();
    this.profileOrderSelect = React.createRef();
  }

  state = {
    activeKey: 'editInfo',
    profile_id: null,
    user_id: null,
    additional_info: '',
    email_address: '',
    advanced_credential: '',
    len_exp_hr: 0,
    title: null,
    self_intro: '',
    gender: 0,
    first_name: '',
    last_name: '',
    nickname: '',
    slogan: '',
    current_address: '',
    email: '',
    email_sent: '',
    photo_url: null,
    payment_method: [],
    tags: [],
    address: [],
    hourly_price: [],
    license: [],
    experience: [],
    allTags: [],
    areaList: [],
    profile_status: 0,
    blogList: [],
    showModal: false,
    modalText: '',
    isPremium: 0,
    isLoading: false,
    isBlogLoading: false,
    blogCount: 0,
    isFav: false,
    isComment: false,
    isAppointment: true,
    isClients: false,
    isEvent: false,
    isFavBlogLoading: false,
    favBlogList: [],
    blogStart: 0,
    blogEnd: 5,
    blogCurrPage: 0,
    blogCount: 0,
    blogTotalPage: 0,
    blogOrder: '-time',
    isFavProfileLoading: false,
    favProfileList: [],
    profileStart: 0,
    profileEnd: 5,
    profileCurrPage: 0,
    profileCount: 0,
    profileTotalPage: 0,
    profileOrder: '+price',
    isCommentsToLoading: false,
    commentsFromStart: 0,
    commentsFromEnd: 5,
    commentsFromList: [],
    commentsFromCount: 0,
    commentsFromCurrPage: 0,
    commentsFromTotalPage: 0,
    isCommentsFromLoading: false,
    commentsToStart: 0,
    commentsToEnd: 5,
    commentsToList: [],
    commentsToCount: 0,
    commentsToCurrPage: 0,
    commentsToTotalPage: 0,
    isAppointmentLoading: false,
    appointmentList_display: [],
    totalAppointments: 0,
    totalAppointmentsSubmitted: 0,
    totalAppointmentsPaid: 0,
    totalAppointmentsCompleted: 0,
    totalAppointmentsCancelled: 0,
    totalClientsSubmitted: 0,
    totalClientsPaid: 0,
    totalClientsCompleted: 0,
    totalClientsCancelled: 0,
    current_ticket_id: '',
    isClientAppointmentLoading: false,
    isBasicProfile: true,
    balance_usd: 0,
    balance_cny: 0,
    displayUSDWithdraw: false,
    displayCNYWithdraw: false,
    withdraw_amount: 0,
    password: '',
    password_confirm: '',
    emailSent: false,
    isWithDrawLoading: false,
    withDrawList: [],
    code: '',
    codeVerified: false,
    isEventLoading: false,
    events_attend: [],
    events_create: [],
    events_attend_full: [],
    events_create_full: [],
    events_create_finished: [],
    events_attend_finished: [],
    displayEvents: [],
    events_create_num: 0,
    events_attend_num: 0,
    events_create_num_finished: 0,
    events_attend_num_finished: 0,
    eventType: 'creator',
    displayFullEvents: false,
    displayRefund: false,
    timezoneList: utils.timezoneList,
    appointmentTapIndicator: 'configure'
  }
  getWithdraws = async () => {
        await axios.post(utils.getUrl('payment/withdraw/query/'),
            {start: 0, end: 100}, {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
            .then((response) => {
                let data = response.data.data
                
                this.setState({withDrawList: data.data})
            })
            .catch(err => {
                // alert(err)
            })
    }

  getWithDrawList = ()=>{
      return <Container style={{width:"100%"}}>

          {this.state.isWithDrawLoading ? (
                  <Row style={{paddingLeft: '45%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'}
                                                                  width={'15%'}/></Row>) :
              this.state.withDrawList.map(elem => {
                  return <React.Fragment>
                      <Row style={{marginTop: '16px', height: '189px', borderBottom: '2px solid #E7E9EE'}}>
                          <Col md={11} className='flex-block'>
                              {/*<Col md={3} style={{marginRight: '24px'}}>*/}
                              {/*    <img src={elem.user_data.photo_url === '' ? avatar : elem.user_data.photo_url}*/}
                              {/*         alt='cover_img'*/}
                              {/*         style={{width: '135px', height: '135px', borderRadius: '135px'}}></img>*/}
                              {/*</Col>*/}
                              <div >
                                  <Row style={{fontSize: '24px', fontWeight: '400', marginBottom: '8px'}}>
                                      <Col md={6} style={{paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                                          {/*咨询师：{elem.user_data.first_name} {elem.user_data.last_name}*/}
                                          {withDrawStatus[elem.status]}
                                      </Col>
                                      {/*<Col md={6} style={{paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>*/}
                                      {/*    审核人：{elem.payment_operator_data ? elem.payment_operator_data.first_name + ' ' + elem.payment_operator_data.last_name : ""}*/}
                                      {/*</Col>*/}
                                  </Row>
                                  <Row style={{marginBottom: 8}} className='flex-block'>

                                      {/*<div style={{padding: '0'}}>*/}
                                      {/*  <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="19" height="19"*/}
                                      {/*       fill="#43B0B1" style={{position: 'relative', top: '3px', left: '0'}}>*/}
                                      {/*    <path*/}
                                      {/*        d="M220.90723,35.09277A11.85078,11.85078,0,0,0,209.165,32.02832L22.83691,84.582a12.00013,12.00013,0,0,0-1.8789,22.39453l85.61035,40.55176a4.01536,4.01536,0,0,1,1.90234,1.90234L149.02344,235.042a11.89209,11.89209,0,0,0,10.81152,6.86524q.51563,0,1.03711-.04395a11.90291,11.90291,0,0,0,10.5459-8.70019L223.97168,46.835A11.853,11.853,0,0,0,220.90723,35.09277Zm-4.63477,9.57032L163.71875,230.99121a4.00009,4.00009,0,0,1-7.46484.62695l-40.55371-85.6123-.01709-.03272L158.94629,102.71A3.99957,3.99957,0,0,0,153.29,97.05371l-43.26318,43.26294c-.01124-.00537-.022-.01245-.0337-.01782L24.38184,99.74609a4.00009,4.00009,0,0,1,.627-7.46484L211.33691,39.72754a4.15049,4.15049,0,0,1,1.13379-.16211A3.98288,3.98288,0,0,1,215.251,40.749,3.92519,3.92519,0,0,1,216.27246,44.66309Z"/>*/}
                                      {/*  </svg>*/}
                                      {/*  &nbsp;{elem.user.visits}访问量*/}
                                      {/*</div>*/}
                                  </Row>
                                  <Row
                                      style={{marginBottom: '12px'}}>请求号：{elem.request_id} 用户ID：{elem.user_data.id} {elem.image ?
                                      <a target="_blank" href={elem.image} rel="noreferrer">凭证</a> : ""}</Row>

                                  <Row style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'wrap',
                                      marginBottom: '12px'
                                  }}>
                                      提现：{elem.amount / 100 + elem.currency}</Row>
                                  <Row>提交时间：{utils.toPST(elem.create_date)}</Row>
                                  <Row>更新时间：{utils.toPST(elem.last_update)}</Row>
                              </div>

                          </Col>
                      </Row></React.Fragment>
              })
          }
          <div style={{height: '50px'}}></div>
      </Container>
  }
  getCompletion = ()=>{
    return keys.filter((ele)=>{
      if(this.state[ele]?.length===0){
        return false
      }
      return !!(this.state[ele] || this.state[ele]===0)}).length / keys.length
  }

  submitProfile = async() => {
    if(this.state.profile_status === 2){
      alert('提交成功！')
      return false
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/${this.state.profile_id}/submit/`),
    {profile_status: 1}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      alert('提交成功！')
    })
    .catch(err => {
      // alert(err)
    })
  }

  // open a therapist' s profile page
  openProfilePage = (profile_id, user_id) => {
    const url = `/therapist/view/:${profile_id}`
    this.props.history.push({pathname: url, state: {user_id: user_id}})
  }
  // forward blog create page
  forwardBlogCreate = () =>{
    this.props.history.push('/blog/create/')
  }
  editProfile = () => {
    this.props.history.push(`/therapist/profile/:${this.state.user_id}/:${this.state.profile_id}`)
  }
  
  componentWillMount() {
    // set profile id
    const user_id = parseInt(this.props.match.params.user_id.slice(1))
    this.setState({ user_id: user_id })
  }

  componentDidMount = async() => {
    this.setState({isLoading: true})
    await utils.loadTagList()
    let areaList = window.tagList.areaList
    areaList.map(elem=>{
      if(elem.value_cn === "其他\r"){
        let index = areaList.indexOf(elem)
        areaList.push(areaList.splice(index, 1)[0])
      }
    })
    this.setState({allTags: window.tagList.allTags, areaList, eventTags: window.tagList.eventList, eventSubTags: window.tagList.eventMethodList})
    // set profile id
    const user_id = parseInt(this.props.match.params.user_id.slice(1))
    await this.setState({user_id: user_id})
    // get info
    await axios.get(utils.getUrl(`profile/${this.state.user_id}/`), {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data  
      this.setState({
        profile_id: data.profiles.length > 0? data.profiles[0].profile_id : null,
        first_name: response.data.first_name, 
        last_name: response.data.last_name, 
        nickname: response.data.nickname, 
        email: response.data.email, 
        email_address: response.data.email,
        slogan: response.data.slogan, 
        photo_url: response.data.photo_url, 
        current_address: response.data.addr_str, 
        isLoading: false, 
        isPremium: data.user_type===3?data.profile?data.profiles[0].premium_status:0:0})
    }).catch(error => {
      //alert(error)
      console.log(error);
    })
    
    if(this.state.isFav){
      this.loadFavBlogs()
      this.loadFavProfiles()
    }else if(this.state.isComment){
      this.loadCommentsTo()
      this.loadCommentsFrom()
    }else if(this.state.isAppointment){
      this.loadAppointments()
      this.initAppointmentNumber()
    }else if(this.state.isClients){
      this.loadClientAppointments()
      this.initClientAppointmentNumber()
    }else if(this.state.isEvent){
      await this.loadEvents()
      this.loadFinishedEvents()
    }
    this.getBalance();
    this.getWithdraws();
  }

  setActiveKey = (newKey) => {
    this.setState({activeKey: newKey})
  }

  // handle blog display order
  handleBlogOrder = async() =>{
  const node = this.blogOrderSelect.current;
  await this.setState({blogOrder: node.value})
  this.loadFavBlogs()
  }
  // handle profile display order
  handleProfileOrder = async() =>{
  const node = this.profileOrderSelect.current;
  await this.setState({profileOrder: node.value})
  this.loadFavProfiles()
  }

  handlePlaceChange = (place) => {
    // 
  }

  getFormattedDate = (datestring) => {
    let date = new Date(datestring)
    return date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()
  }

  getBlogPager = ()=>{
    const pagearray = [];
    let i;
    for (i = 1; i <= this.state.blogTotalPage; i++) {
        pagearray.push(i);
    }
    const afterarray = [];
    for (i = this.state.blogTotalPage-4; i <= this.state.blogTotalPage; i++) {
        afterarray.push(i);
    }
    const beforearray = [];
    for (i = 1; i <= 5; i++) {
        beforearray.push(i);
    }
    if(this.state.blogTotalPage==1){
        return <Pagination><Pagination.Item active>{1}</Pagination.Item></Pagination>
    } else if (this.state.blogTotalPage <=5){
        return <Pagination>{pagearray.map((val) => <Pagination.Item
                active={val == this.state.blogCurrPage}
                onClick={() => {
                  this.loadFavBlogs(val)
                }}
        >{val}</Pagination.Item>)}</Pagination>
             }  else if (this.state.blogCurrPage>= this.state.blogTotalPage - 4) {
        return <Pagination>
                    <Pagination.First
                    onClick={() => {
                    this.loadFavBlogs(1)
                    }}/>
                    <Pagination.Prev
                    onClick={() => {
                    this.loadFavBlogs(this.state.blogCurrPage - 1)
                    }}/>
                    <Pagination.Item onClick={() => {
                    this.loadFavBlogs(1)
                    }}>{1}</Pagination.Item>
                    <Pagination.Ellipsis/>
            {afterarray.map((val) => <Pagination.Item active={val == this.state.blogCurrPage} onClick={()=>{this.loadFavBlogs(val)}}>{val}</Pagination.Item>)}
                    <Pagination.Next onClick={() => {
                    this.loadFavBlogs(this.state.blogCurrPage+1)
                    }}/>
                    <Pagination.Last onClick={() => {
                    this.loadFavBlogs(this.state.blogTotalPage)
                    }}/>
                    </Pagination>

    } else if (this.state.blogCurrPage <= 5) {
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.loadFavBlogs(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.loadFavBlogs(this.state.blogCurrPage - 1)
                }}/>


            {beforearray.map((val) => <Pagination.Item active={val == this.state.blogCurrPage} onClick={() => {
                this.loadFavBlogs(val)
            }}>{val}</Pagination.Item>)}
            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.loadFavBlogs(this.state.blogTotalPage)
            }}>{this.state.blogTotalPage}</Pagination.Item>


            <Pagination.Next onClick={() => {
                this.loadFavBlogs(this.state.blogCurrPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.loadFavBlogs(this.state.blogTotalPage)
            }}/>
        </Pagination>
    } else{
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.loadFavBlogs(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.loadFavBlogs(this.state.blogCurrPage - 1)
                }}/>
            <Pagination.Item onClick={() => {
                this.loadFavBlogs(1)
            }}>{1}</Pagination.Item>
            <Pagination.Ellipsis/>

            <Pagination.Item onClick={() => {
                this.loadFavBlogs(this.state.blogCurrPage - 2)
            }}>{this.state.blogCurrPage - 2}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadFavBlogs(this.state.blogCurrPage - 1)
            }}>{this.state.blogCurrPage - 1}</Pagination.Item>
            <Pagination.Item active>{this.state.blogCurrPage}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadFavBlogs(this.state.blogCurrPage + 1)
            }}>{this.state.blogCurrPage + 1}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadFavBlogs(this.state.blogCurrPage + 2)
            }}>{this.state.blogCurrPage + 2}</Pagination.Item>

            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.loadFavBlogs(this.state.blogTotalPage)
            }}>{this.state.blogTotalPage}</Pagination.Item>
            <Pagination.Next onClick={() => {
                this.loadFavBlogs(this.state.blogCurrPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.loadFavBlogs(this.state.blogTotalPage)
            }}/>
        </Pagination>
    }
  }

  getProfilePager = ()=>{
    const pagearray = [];
    let i;
    for (i = 1; i <= this.state.profileTotalPage; i++) {
        pagearray.push(i);
    }
    const afterarray = [];
    for (i = this.state.profileTotalPage-4; i <= this.state.profileTotalPage; i++) {
        afterarray.push(i);
    }
    const beforearray = [];
    for (i = 1; i <= 5; i++) {
        beforearray.push(i);
    }
    if(this.state.profileTotalPage==1){
        return <Pagination><Pagination.Item active>{1}</Pagination.Item></Pagination>
    } else if (this.state.profileTotalPage <=5){
        return <Pagination>{pagearray.map((val) => <Pagination.Item
                active={val == this.state.profileCurrPage}
                onClick={() => {
                  this.loadFavProfiles(val)
                }}
        >{val}</Pagination.Item>)}</Pagination>
             }  else if (this.state.profileCurrPage>= this.state.profileTotalPage - 4) {
        return <Pagination>
                    <Pagination.First
                    onClick={() => {
                    this.loadFavProfiles(1)
                    }}/>
                    <Pagination.Prev
                    onClick={() => {
                    this.loadFavProfiles(this.state.profileCurrPage - 1)
                    }}/>
                    <Pagination.Item onClick={() => {
                    this.loadFavProfiles(1)
                    }}>{1}</Pagination.Item>
                    <Pagination.Ellipsis/>
            {afterarray.map((val) => <Pagination.Item active={val == this.state.profileCurrPage} onClick={()=>{this.loadFavProfiles(val)}}>{val}</Pagination.Item>)}
                    <Pagination.Next onClick={() => {
                    this.loadFavProfiles(this.state.profileCurrPage+1)
                    }}/>
                    <Pagination.Last onClick={() => {
                    this.loadFavProfiles(this.state.profileTotalPage)
                    }}/>
                    </Pagination>

    } else if (this.state.profileCurrPage <= 5) {
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.loadFavProfiles(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.loadFavProfiles(this.state.profileCurrPage - 1)
                }}/>


            {beforearray.map((val) => <Pagination.Item active={val == this.state.profileCurrPage} onClick={() => {
                this.loadFavProfiles(val)
            }}>{val}</Pagination.Item>)}
            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.loadFavProfiles(this.state.profileTotalPage)
            }}>{this.state.profileTotalPage}</Pagination.Item>


            <Pagination.Next onClick={() => {
                this.loadFavProfiles(this.state.profileCurrPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.loadFavProfiles(this.state.profileTotalPage)
            }}/>
        </Pagination>
    } else{
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.loadFavProfiles(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.loadFavProfiles(this.state.profileCurrPage - 1)
                }}/>
            <Pagination.Item onClick={() => {
                this.loadFavProfiles(1)
            }}>{1}</Pagination.Item>
            <Pagination.Ellipsis/>

            <Pagination.Item onClick={() => {
                this.loadFavProfiles(this.state.profileCurrPage - 2)
            }}>{this.state.profileCurrPage - 2}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadFavProfiles(this.state.profileCurrPage - 1)
            }}>{this.state.profileCurrPage - 1}</Pagination.Item>
            <Pagination.Item active>{this.state.profileCurrPage}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadFavProfiles(this.state.profileCurrPage + 1)
            }}>{this.state.profileCurrPage + 1}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadFavProfiles(this.state.profileCurrPage + 2)
            }}>{this.state.profileCurrPage + 2}</Pagination.Item>

            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.loadFavProfiles(this.state.profileTotalPage)
            }}>{this.state.profileTotalPage}</Pagination.Item>
            <Pagination.Next onClick={() => {
                this.loadFavProfiles(this.state.profileCurrPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.loadFavProfiles(this.state.profileTotalPage)
            }}/>
        </Pagination>
    }
  }

  getCommentsToPager = ()=>{
    const pagearray = [];
    let i;
    for (i = 1; i <= this.state.commentsToTotalPage; i++) {
        pagearray.push(i);
    }
    const afterarray = [];
    for (i = this.state.commentsToTotalPage-4; i <= this.state.commentsToTotalPage; i++) {
        afterarray.push(i);
    }
    const beforearray = [];
    for (i = 1; i <= 5; i++) {
        beforearray.push(i);
    }
    if(this.state.commentsToTotalPage==1){
        return <Pagination><Pagination.Item active>{1}</Pagination.Item></Pagination>
    } else if (this.state.commentsToTotalPage <=5){
        return <Pagination>{pagearray.map((val) => <Pagination.Item
                active={val == this.state.commentsToCurrPage}
                onClick={() => {
                  this.loadCommentsTo(val)
                }}
        >{val}</Pagination.Item>)}</Pagination>
             }  else if (this.state.commentsToCurrPage>= this.state.commentsToTotalPage - 4) {
        return <Pagination>
                    <Pagination.First
                    onClick={() => {
                    this.loadCommentsTo(1)
                    }}/>
                    <Pagination.Prev
                    onClick={() => {
                    this.loadCommentsTo(this.state.commentsToCurrPage - 1)
                    }}/>
                    <Pagination.Item onClick={() => {
                    this.loadCommentsTo(1)
                    }}>{1}</Pagination.Item>
                    <Pagination.Ellipsis/>
            {afterarray.map((val) => <Pagination.Item active={val == this.state.commentsToCurrPage} onClick={()=>{this.loadCommentsTo(val)}}>{val}</Pagination.Item>)}
                    <Pagination.Next onClick={() => {
                    this.loadCommentsTo(this.state.commentsToCurrPage+1)
                    }}/>
                    <Pagination.Last onClick={() => {
                    this.loadCommentsTo(this.state.commentsToTotalPage)
                    }}/>
                    </Pagination>

    } else if (this.state.commentsToCurrPage <= 5) {
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.loadCommentsTo(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.loadCommentsTo(this.state.commentsToCurrPage - 1)
                }}/>


            {beforearray.map((val) => <Pagination.Item active={val == this.state.commentsToCurrPage} onClick={() => {
                this.loadCommentsTo(val)
            }}>{val}</Pagination.Item>)}
            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.loadCommentsTo(this.state.commentsToTotalPage)
            }}>{this.state.commentsToTotalPage}</Pagination.Item>


            <Pagination.Next onClick={() => {
                this.loadCommentsTo(this.state.commentsToCurrPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.loadCommentsTo(this.state.commentsToTotalPage)
            }}/>
        </Pagination>
    } else{
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.loadCommentsTo(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.loadCommentsTo(this.state.commentsToCurrPage - 1)
                }}/>
            <Pagination.Item onClick={() => {
                this.loadCommentsTo(1)
            }}>{1}</Pagination.Item>
            <Pagination.Ellipsis/>

            <Pagination.Item onClick={() => {
                this.loadCommentsTo(this.state.commentsToCurrPage - 2)
            }}>{this.state.commentsToCurrPage - 2}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadCommentsTo(this.state.commentsToCurrPage - 1)
            }}>{this.state.commentsToCurrPage - 1}</Pagination.Item>
            <Pagination.Item active>{this.state.commentsToCurrPage}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadCommentsTo(this.state.commentsToCurrPage + 1)
            }}>{this.state.commentsToCurrPage + 1}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadCommentsTo(this.state.commentsToCurrPage + 2)
            }}>{this.state.commentsToCurrPage + 2}</Pagination.Item>

            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.loadCommentsTo(this.state.commentsToTotalPage)
            }}>{this.state.commentsToTotalPage}</Pagination.Item>
            <Pagination.Next onClick={() => {
                this.loadCommentsTo(this.state.commentsToCurrPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.loadCommentsTo(this.state.commentsToTotalPage)
            }}/>
        </Pagination>
    }
  }

  getCommentsFromPager = ()=>{
    const pagearray = [];
    let i;
    for (i = 1; i <= this.state.commentsFromTotalPage; i++) {
        pagearray.push(i);
    }
    const afterarray = [];
    for (i = this.state.commentsFromTotalPage-4; i <= this.state.commentsFromTotalPage; i++) {
        afterarray.push(i);
    }
    const beforearray = [];
    for (i = 1; i <= 5; i++) {
        beforearray.push(i);
    }
    if(this.state.commentsFromTotalPage==1){
        return <Pagination><Pagination.Item active>{1}</Pagination.Item></Pagination>
    } else if (this.state.commentsFromTotalPage <=5){
        return <Pagination>{pagearray.map((val) => <Pagination.Item
                active={val == this.state.commentsFromCurrPage}
                onClick={() => {
                  this.loadCommentsFrom(val)
                }}
        >{val}</Pagination.Item>)}</Pagination>
             }  else if (this.state.commentsFromCurrPage>= this.state.commentsFromTotalPage - 4) {
        return <Pagination>
                    <Pagination.First
                    onClick={() => {
                    this.loadCommentsFrom(1)
                    }}/>
                    <Pagination.Prev
                    onClick={() => {
                    this.loadCommentsFrom(this.state.commentsFromCurrPage - 1)
                    }}/>
                    <Pagination.Item onClick={() => {
                    this.loadCommentsFrom(1)
                    }}>{1}</Pagination.Item>
                    <Pagination.Ellipsis/>
            {afterarray.map((val) => <Pagination.Item active={val == this.state.commentsFromCurrPage} onClick={()=>{this.loadCommentsFrom(val)}}>{val}</Pagination.Item>)}
                    <Pagination.Next onClick={() => {
                    this.loadCommentsFrom(this.state.commentsFromCurrPage+1)
                    }}/>
                    <Pagination.Last onClick={() => {
                    this.loadCommentsFrom(this.state.commentsFromTotalPage)
                    }}/>
                    </Pagination>

    } else if (this.state.commentsFromCurrPage <= 5) {
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.loadCommentsFrom(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.loadCommentsFrom(this.state.commentsFromCurrPage - 1)
                }}/>


            {beforearray.map((val) => <Pagination.Item active={val == this.state.commentsFromCurrPage} onClick={() => {
                this.loadCommentsFrom(val)
            }}>{val}</Pagination.Item>)}
            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromTotalPage)
            }}>{this.state.commentsFromTotalPage}</Pagination.Item>


            <Pagination.Next onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromCurrPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromTotalPage)
            }}/>
        </Pagination>
    } else{
        return <Pagination>
            <Pagination.First
                onClick={() => {
                    this.loadCommentsFrom(1)
                }}/>
            <Pagination.Prev
                onClick={() => {
                    this.loadCommentsFrom(this.state.commentsFromCurrPage - 1)
                }}/>
            <Pagination.Item onClick={() => {
                this.loadCommentsFrom(1)
            }}>{1}</Pagination.Item>
            <Pagination.Ellipsis/>

            <Pagination.Item onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromCurrPage - 2)
            }}>{this.state.commentsFromCurrPage - 2}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromCurrPage - 1)
            }}>{this.state.commentsFromCurrPage - 1}</Pagination.Item>
            <Pagination.Item active>{this.state.commentsFromCurrPage}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromCurrPage + 1)
            }}>{this.state.commentsFromCurrPage + 1}</Pagination.Item>
            <Pagination.Item onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromCurrPage + 2)
            }}>{this.state.commentsFromCurrPage + 2}</Pagination.Item>

            <Pagination.Ellipsis/>
            <Pagination.Item onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromTotalPage)
            }}>{this.state.commentsFromTotalPage}</Pagination.Item>
            <Pagination.Next onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromCurrPage + 1)
            }}/>
            <Pagination.Last onClick={() => {
                this.loadCommentsFrom(this.state.commentsFromTotalPage)
            }}/>
        </Pagination>
    }
  }

  handleNav = async(nav) => {
    if(nav === 'fav'){
      this.setState({isFav: true, isComment: false, isMain: false, isAppointment: false, isClients: false, isEvent: false})
      this.loadFavBlogs()
      this.loadFavProfiles()
    }else if(nav === 'comment'){
      this.setState({isComment: true, isFav: false, isMain: false, isAppointment: false, isClients: false, isEvent: false})
      this.loadCommentsTo()
      this.loadCommentsFrom()
    }else if(nav === 'appointment'){
      this.setState({isComment: false, isFav: false, isMain: false, isAppointment: true, isClients: false, isEvent: false})
      this.loadAppointments()
      this.initAppointmentNumber()
    }else if(nav === 'clients'){
      this.setState({isComment: false, isFav: false, isMain: false, isAppointment: false, isClients: true, isEvent: false})
      this.loadClientAppointments()
      this.initClientAppointmentNumber()
    }else if(nav === 'events'){
      this.setState({isEvent: true, isComment: false, isFav: false, isMain: false, isAppointment: false, isClients: false})
      await this.loadEvents()
      this.loadFinishedEvents()
    }
  }

  switchProfileEdit = (nav, event)=>{
    let nodeList = document.querySelectorAll('.profile-switch-nav a')
    nodeList.forEach(node=>{
      node.classList.remove('current-nav')
    })
    event.target.classList.add('current-nav')
    if(nav === 'basic'){
      this.setState({isBasicProfile: true})
    }else{
      this.setState({isBasicProfile: false})
    }
  }

  loadEvents = async()=>{
    // creator
    this.setState({isEventLoading: true})
    await axios.post(utils.getUrl(`event/query/`),{start: 0, end: 100, order: '-time', creator: this.state.user_id}
    ,{headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let events_create = JSON.parse(JSON.stringify(response.data.data.data))
      let events_create_full = JSON.parse(JSON.stringify(events_create))
      this.setState({events_create, events_create_full, events_create_num: response.data.data.count})
    }).catch(err=>{alert(err)})
    // attender
    await axios.post(utils.getUrl(`event/query/`),{start: 0, end: 100, order: '-time', attend_by: this.state.user_id}
    ,{headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let events_attend = JSON.parse(JSON.stringify(response.data.data.data))
      let events_attend_full = JSON.parse(JSON.stringify(events_attend))
      this.setState({events_attend, events_attend_full, events_attend_num: response.data.data.count})
    }).catch(err=>{alert(err)})
  }

  loadFinishedEvents = async()=>{
    // creator
    await axios.post(utils.getUrl(`event/query/`),{start: 0, end: 100, order: '-time', creator: this.state.user_id, end_date: moment().valueOf()}
    ,{headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let events_create = this.state.events_create
      // filter out finished events
      events_create.map(event=>{
        if(response.data.data.data.some(elem=>elem.event_id === event.event_id)){
          events_create.splice(events_create.indexOf(event), 1)
        }
      })
      this.setState({events_create, displayEvents: events_create, events_create_finished: response.data.data.data, events_create_num_finished: response.data.data.count, isEventLoading: false})
    }).catch(err=>{alert(err)})
    // attender
    await axios.post(utils.getUrl(`event/query/`),{start: 0, end: 100, order: '-time', attend_by: this.state.user_id, end_date: moment().valueOf()}
    ,{headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let events_attend = this.state.events_attend
      // filter out finished events
      events_attend.map(event=>{
        if(response.data.data.data.some(elem=>elem.event_id === event.event_id)){
          events_attend.splice(events_attend.indexOf(event), 1)
        }
      })
      this.setState({events_attend, events_attend_finished: response.data.data.data, events_attend_num_finished: response.data.data.count, isEventLoading: false})
    }).catch(err=>{alert(err)})
  }

  switchDisplayEvent = (type, event)=>{
    let tempList = document.querySelectorAll('.event-nav-btns input')
    tempList.forEach(node=>{
      node.classList.remove('events-btn-3')
      node.classList.add('events-btn-2')
    })
    event.target.classList.remove('events-btn-2')
    event.target.classList.add('events-btn-3')
    if(type === 'creator'){
      this.setState({displayEvents: this.state.events_create, eventType: 'creator'})
    }else if(type === 'attender'){
      this.setState({displayEvents: this.state.events_attend, eventType: 'attender'})
    }
  }

  displayFinishedEvent = (type)=>{
    if(this.state.displayFullEvents){
      if(type === 'creator'){
        this.setState({displayEvents: this.state.events_create, displayFullEvents: false})
      }else if(type === 'attender'){
        this.setState({displayEvents: this.state.events_attend, displayFullEvents: false})
      }
    }else{
      if(type === 'creator'){
        this.setState({displayEvents: this.state.events_create_full, displayFullEvents: true})
      }else if(type === 'attender'){
        this.setState({displayEvents: this.state.events_attend_full, displayFullEvents: true})
      }
    }
  }

  initAppointmentNumber = async()=>{
    // submitted
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {user: this.state.user_id, start: 0, end: 100, status: [0]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalAppointmentsSubmitted: data.count})
    }).catch(err => {
      // alert(err)
    })
    // completed
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {user: this.state.user_id, start: 0, end: 100, status: [2]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalAppointmentsCompleted: data.count})
    }).catch(err => {
      // alert(err)
    })
    // paid
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {user: this.state.user_id, start: 0, end: 100, status: [4]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalAppointmentsPaid: data.count})
    }).catch(err => {
      // alert(err)
    })
    //cancelled
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {user: this.state.user_id, start: 0, end: 100, status: [3]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalAppointmentsCancelled: data.count})
    }).catch(err => {
      // alert(err)
    })
  }

  initClientAppointmentNumber = async()=>{
    // submitted
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: [0]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalClientsSubmitted: data.count})
    }).catch(err => {
      // alert(err)
    })
    // completed
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: [2]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalClientsCompleted: data.count})
    }).catch(err => {
      // alert(err)
    })
    // paid
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: [4]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalClientsPaid: data.count})
    }).catch(err => {
      // alert(err)
    })
    //cancelled
    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: [3]}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({totalClientsCancelled: data.count})
    }).catch(err => {
      // alert(err)
    })
  }

  loadAppointments = async(status, event)=>{
    let current_status = [];
    if(status && event){
      current_status.push(parseInt(status))
      let tempList = document.querySelectorAll('.appointment-nav-btns input')
      tempList.forEach(node=>{
          node.classList.remove('events-btn-3')
          node.classList.add('events-btn-2')
      })
      event.target.classList.remove('events-btn-2')
      event.target.classList.add('events-btn-3')
    }else{
      current_status.push(4) //default paid
    }
    this.setState({isAppointmentLoading: true})

    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {user: this.state.user_id, start: 0, end: 100, status: current_status}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      this.setState({appointmentList_display: data.data, isAppointmentLoading: false})
    }).catch(err => {
      // alert(err)
    })

  }

  loadClientAppointments = async(status, event)=>{
    let current_status = [];
    if(status && event){
      current_status.push(parseInt(status))
      let tempList = document.querySelectorAll('.client-nav-btns input')
      tempList.forEach(node=>{
          node.classList.remove('events-btn-3')
          node.classList.add('events-btn-2')
      })
      event.target.classList.remove('events-btn-2')
      event.target.classList.add('events-btn-3')
    }else{
      current_status.push(4) //default paid
    }
    this.setState({isClientAppointmentLoading: true})

    await axios.post(utils.getUrl(`appointment/ticket/query/`),
    {consultant: this.state.user_id, start: 0, end: 100, status: current_status}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      let data = response.data.data
      
      this.setState({clientList_display: data.data, isClientAppointmentLoading: false})
    }).catch(err => {
      // alert(err)
    })

  }

  loadFavBlogs = async(page) => {
    this.setState({isFavBlogLoading: true})
    if(page){
      await this.setState({blogStart: pageSize*(page-1), blogEnd: pageSize * page, blogCurrPage: page})
    }
      const tempData = {
      type: [0],
      with_comments: 0,
      order: this.state.blogOrder,
      start: this.state.blogStart,
      end: this.state.blogEnd,
      favourite_of: this.state.user_id
      }
      await axios.post(utils.getUrl(`cms/blog/query/`), tempData)
      .then(response => {
        const blogCurrPage = Math.ceil(response.data.start / pageSize) + 1;
        const blogTotalPage = Math.ceil(response.data.count / pageSize) || 1;
        this.setState({favBlogList: response.data.data, isFavBlogLoading: false, blogCount: response.data.count,
          blogTotalPage: blogTotalPage, blogCurrPage: blogCurrPage})
      })
      .catch(err => {
        // alert(err)
      })
  }

  cancelFavBlog = async(event, post_id) =>{
    event.stopPropagation()
    if(!window.confirm('确定要取消该收藏吗？')){
      return false
    }
    await axios.post(utils.getUrl(`cms/favourites/${this.state.user_id}/`),
    {action: 'drop', post_id: post_id}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response =>{
      alert('取消成功！')
      this.loadFavBlogs()
    }).catch(err => {
      // alert(err)
    })
  }

  loadFavProfiles = async(page) => {
    // reset pagination
    if(page){
      await this.setState({profileStart: pageSize*(page-1), profileEnd: pageSize * page, profileCurrPage: page})
    }
    // display loading animation
    this.setState({isFavProfileLoading: true})

    // request search result
      await axios.post(utils.getUrl('public/filter-by-tag/'),
      {'start': this.state.profileStart, 'end': this.state.profileEnd, 'order': this.state.profileOrder,
      'favourite_of': this.state.user_id})
      .then(response => {
        
        var dataObject = response.data.data
          let userList = []
          // 判断title
          dataObject.map(elem => {
            if(elem.title === 1){elem.title = 'Dr. '}
            else if(elem.title === 2){elem.title = 'Prof. '}
            else{elem.title = ''}
            userList.push(elem) // append user
            return false;
          })
          const profileCurrPage = Math.ceil(response.data.start / pageSize) + 1;
          const profileTotalPage = Math.ceil(response.data.count / pageSize) || 1;
          this.setState({favProfileList: userList, isFavProfileLoading: false, profileCount: response.data.total,
            profileTotalPage: profileTotalPage, profileCurrPage: profileCurrPage})

      }).catch(err => {
        // alert(err)
      })

  }

  cancelFavProfile = async(event, profile_id) =>{
    event.stopPropagation()
    if(!window.confirm('确定要取消该收藏吗？')){
      return false
    }
    await axios.post(utils.getUrl(`profile/${this.state.user_id}/favourites/`),
    {action: 'drop', profile_id: profile_id}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response =>{
      alert('取消成功！')
      this.loadFavProfiles()
    }).catch(err => {
      // alert(err)
    })
  }

  loadCommentsTo = async(page) => {
    // reset pagination
    if(page){
      await this.setState({commentsToStart: pageSize*(page-1), commentsToEnd: pageSize * page, commentsToCurrPage: page})
    }
    // display loading animation
    this.setState({isCommentsToLoading: true})

    await axios.get(utils.getUrl(`cms/comments-to/${this.state.user_id}/?start=${this.state.commentsToStart}&end=${this.state.commentsToEnd}`))
    .then(response => {
      const commentsToCurrPage = Math.ceil(response.data.data.start / pageSize) + 1;
      const commentsToTotalPage = Math.ceil(response.data.data.count / pageSize) || 1;
      this.setState({commentsToList: response.data.data.data, isCommentsToLoading: false, commentsToCount: response.data.data.count,
        commentsToTotalPage: commentsToTotalPage, commentsToCurrPage: commentsToCurrPage})
    }).catch(err => {
      // alert(err)
    })

  }

  loadCommentsFrom = async(page) => {
    // reset pagination
    if(page){
      await this.setState({commentsFromStart: pageSize*(page-1), commentsFromEnd: pageSize * page, commentsFromCurrPage: page})
    }
    // display loading animation
    this.setState({isCommentsFromLoading: true})

    await axios.get(utils.getUrl(`cms/comments-from/${this.state.user_id}/?start=${this.state.commentsFromStart}&end=${this.state.commentsFromEnd}`))
    .then(response => {
      const commentsFromCurrPage = Math.ceil(response.data.data.start / pageSize) + 1;
      const commentsFromTotalPage = Math.ceil(response.data.data.count / pageSize) || 1;
      this.setState({commentsFromList: response.data.data.data, isCommentsFromLoading: false, commentsFromCount: response.data.data.count,
        commentsFromTotalPage: commentsFromTotalPage, commentsFromCurrPage: commentsFromCurrPage})
    }).catch(err => {
      // alert(err)
    })

  }

  changeAppointmentStatus = async(status, ticket_id) => {
    await axios.post(utils.getUrl(`appointment/ticket/update/${ticket_id}/`),
    {status: parseInt(status)}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response =>{
      
    }).catch(err=>{})
  }

  getBalance = async()=>{
    await axios.get(utils.getUrl(`payment/${this.state.user_id}/`), {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      
      this.setState({balance_cny: response.data.balance_cny/100, balance_usd: response.data.balance_usd/100})
    }).catch(err=>{
      // alert(err)
    })
  }

  setRefund = (ticket_id)=>{
    var element = document.getElementById('refund'+ticket_id)
    if(element.style.display === 'block'){
      element.style.display = 'none'
    }else{
      element.style.display = 'block'
    }
    this.setState({current_ticket_id: ticket_id})
  }

  submitRefund = async()=>{
    await axios.post(utils.getUrl(`appointment/ticket/refund/${this.state.current_ticket_id}/`),
    {reason: this.state.refund_reason}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response=>{
      alert('已收到您的退款申请')
      // setTimeout(() => window.location.reload(), 2000)
      this.loadAppointments()
    }).catch(err => {
      // alert(err)
    })
  }

  handlePayment = async(ticket_id)=>{
    alert('正在为您跳转登录界面...')
    await axios.post(utils.getUrl(`appointment/ticket/pay/${ticket_id}/`),
    {pay: 'stripe'}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response=>{
      
      this.setState({stripe_id: response.data.id})
    })
    const stripe = await loadStripe('pk_test_51IQpgLL7zkzQauLdX7nHj4IPTexbTMvBWEkBn0818Wv6Frjx9di1n3SKnZ9IlHAm1nmbvX310mgs2TBpJaURXz0v00Q57JW2jl')
    const result = await stripe.redirectToCheckout({
        sessionId: this.state.stripe_id,
    })
    if (result.error) {
        alert('支付错误')
    }
  }

  // convert time string to a certain timezone date
  resolveTimezone = (time, timezone) =>{
    return this.convertTZ(utils.resolveOffset(utils.parseLocalDateTimeString(time)).toUTCString(), timezone)
  }

  convertTZ = (date, tzString)=> {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}))
  }

  withdrawUSD = async()=>{
    if(this.state.withdraw_amount < 50){
      alert('提现金额最低为$50!')
      return
    }
    await axios.post(utils.getUrl(`payment/withdraw/request/${this.state.user_id}/`),
    {amount: Math.round((this.state.withdraw_amount)*100), currency: 'USD'}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      
      alert('申请成功！')
        this.getWithdraws();
    }).catch(err=>{
        alert(err)
      // alert(err)
    })
  }

  withdrawCNY = async()=>{
    if(this.state.withdraw_amount < 200){
      alert('提现金额最低为￥200!')
      return
    }
    await axios.post(utils.getUrl(`payment/withdraw/request/${this.state.user_id}/`),
    {amount: Math.round((this.state.withdraw_amount)*100), currency: 'CNY'}, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
    .then(response => {
      
      alert('申请成功！')
    }).catch(err=>{
      // alert(err)
    })
  }

  handlePasswordSubmit = async() => {
    if(this.state.password!==this.state.password_confirm){
      alert('两次输入的密码不一致！')
      return false
    }
    await axios.post(utils.getUrl('permission/reset-password/'), {email:this.state.email_address,
        new_password: this.state.password, signature: this.state.signature})
      .then(response => {
        alert('更新成功')
      }).catch(err => {
        // alert(err)
      })
  }

  handlePasswordSubmit = async() => {
    if(this.state.password!==this.state.password_confirm){
      alert('两次输入的密码不一致！')
      return false
    }
    await axios.post(utils.getUrl('permission/reset-password/'), {email:this.state.email_address,
        new_password: this.state.password, signature: this.state.signature})
      .then(response => {
        alert('更新成功')
      }).catch(err => {
        // alert(err)
      })
  }

  handleEmailSubmit = async() => {
    await axios.post(utils.getUrl('permission/email-verification/generate-code/'), {email:this.state.email_address, action: 'reset_password'})
    .then(response => {
    this.setState({emailSent: true})
        alert('发送成功！')
    }).catch(err => {
        // alert(err)
    })
  }

  // forward a specific blog page
  forwardBlog = (post_id) => {
    const url = `/blog/:${post_id}/show/`
    this.props.history.push(url)
  }

  changeCode = (event) => {
    this.setState({code: event.target.value})
  }
  changePassword = (event) => {
    this.setState({password: event.target.value})
  }
  changePasswordConfirm = (event) => {
      this.setState({password_confirm: event.target.value})
  }

  handleCodeSubmit = async() => {
    await axios.post(utils.getUrl('permission/email-verification/verify-code/'),
    {email:this.state.email_address, code: this.state.code, action: 'reset_password'})
    .then(response => {
    if(response.data.match){
        this.setState({signature: response.data.signature, codeVerified: true})
        alert('验证成功！')
    }else{
        alert('验证失败，请重新输入')
    }
    }).catch(err => {
    // alert(err)
    })
  }

  render() {
    let user_avatar = avatar
    if(this.state.photo_url){user_avatar = this.state.photo_url}
    let title
    if(this.state.title === 1){title = 'Dr. '}
    else if(this.state.title === 2){title = 'Prof. '}
    else{title = ''}
    let gender
    if(this.state.gender === 1){gender = '男'}
    else if(this.state.gender === 2){gender = '女'}
    else if(this.state.gender === 3){gender = '其他'}
    else{gender = '未知'}
    let paymentList = []
    this.state.payment_method.map(payment => {
      if(payment === 500001){paymentList.push('Visa')}
      else if(payment === 500002){paymentList.push('Apple Pay')}
    })
    let tempArea = []
    let tempGroup = []
    let tempRole = []
    let tempLang = []
    this.state.tags.map(tag => {
      let tag_value = ''
      // search for string value
      this.state.allTags.map(elem=>{
        if(tag === elem.tag_id){
          tag_value = elem.value_cn
        }
      })
      // 分类tags
      var cate = ((""+tag).split(""))[0]
      if(cate === '2'){
        tempArea.push(tag_value)
      }else if(cate === '3'){
        tempGroup.push(tag_value)
      }else if(cate === '1'){
        tempRole.push(tag_value)
      }else if(cate === '7'){
        tempLang.push(tag_value)
      }
    })
    let tempCity = []
    var usd_formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    var cny_formatter = new Intl.NumberFormat('zh-Hans-CN', {
      style: 'currency',
      currency: 'CNY',
    });
    let balance_usd_display = usd_formatter.format(this.state.balance_usd)
    let balance_cny_display = cny_formatter.format(this.state.balance_cny)
    if(this.state.isLoading){
      return (<Row style={{paddingLeft: '45%'}}>
        <ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/>
        <div style={{height: '400px'}}></div>
      </Row>)
    }else
      return (
        <Container>
          <Tab.Container activeKey={this.state.activeKey} onSelect={key=>this.setActiveKey(key)} >
            <Row>
              <Col md={2}>
                <Card>
                  <Card.Subtitle>
                    <div class="p-3">
                      <img className="avatar me-2" src={this.state.photo_url === null ? avatar : this.state.photo_url} alt="user_avatar"></img>
                      {this.state.nickname&&this.state.nickname!==''?this.state.nickname:localStorage.getItem('full_name')?localStorage.getItem('full_name'):this.state.email}
                    </div>
                  </Card.Subtitle>
                  <Card.Text>
                    <Nav variant="pills" class="flex-column">
                      {/* <Nav.Item className={this.state.activeKey === 'profilePage' ? 'profilePageTabActive' : 'profilePageTabUnActive'}>
                        <Nav.Link eventKey="profilePage">
                          个人主页
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item className={this.state.activeKey === 'editInfo' ? 'user-profile-link' : 'profilePageTabUnActive'}>
                        <Nav.Link eventKey="editInfo" className='user-profile-link'>
                          修改资料
                        </Nav.Link>
                      </Nav.Item>
                      {parseInt(localStorage.getItem('user_type'))<=3?
                        <Nav.Item className={this.state.activeKey === 'appointmentSetting' ? 'profilePageTabActive' : 'profilePageTabUnActive'}>
                        <Nav.Link eventKey="appointmentSetting">
                          预约设置
                        </Nav.Link>
                      </Nav.Item>:''}
                      <Nav.Item className={this.state.activeKey === 'accountSetting' ? 'profilePageTabActive' : 'profilePageTabUnActive'}>
                        <Nav.Link eventKey="accountSetting" className='user-profile-link'>
                          账号设置
                        </Nav.Link>
                      </Nav.Item>
                      {parseInt(localStorage.getItem('user_type'))<=3&&this.state.isPremium===1?
                        <Nav.Item className={this.state.activeKey === 'walletSetting' ? 'profilePageTabActive' : 'profilePageTabUnActive'}>
                        <Nav.Link eventKey="walletSetting">
                          我的钱包
                        </Nav.Link>
                      </Nav.Item>:''}
                    </Nav>
                  </Card.Text>
                </Card>
                {/* <div><AboutUsEdit src_id="booking_note"/></div> */}
              </Col>
              <Col md={9} className='create-event-container'>
                <Tab.Content style={{ width: '100%'}}>
                  <Tab.Pane eventKey="profilePage" style={{ opacity: 1 }}>
                    {
                      (!this.state.isLoading && this.state.isFav)?
                      <div>
                        <Container fluid style={{paddingLeft: '0px'}}>
                        {/* title */}
                        {/* <Row className='my_title'>
                              <Col md={2}></Col>
                              <Col md={6}><h2>咨询师个人主页</h2></Col>
                            </Row> */}
                        {/* navbar */}
                        <div className='nav-bar navbar-default navbar-full' style={{backgroundColor: '#fff', fontSize: '18px', fontWeight: '700'}}>
                        <div className='container container-nav'>
                        <Row className='forum-nav navbar-collapse collapse' style={{marginLeft: '-45px'}}>
                          {/* category */}
                          <ul className="nav navbar-nav profile-nav">
                            {parseInt(localStorage.getItem('user_type'))<=3&&this.state.isPremium===1?<li><a href='javascript:;' onClick={() =>this.handleNav('clients')}>我的客户</a></li>:''}
                            <li><a href='javascript:;' onClick={() =>this.handleNav('appointment')}>我的预约</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('events')}>我的团</a></li>
                            <li><a href='javascript:;' className='current-nav' onClick={() =>this.handleNav('fav')}>我的收藏</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('comment')}>我的评论</a></li>
                          </ul>
                        </Row>
                        </div>
                        </div>
                      </Container>
                      <Container fluid>
                        <Row style={{marginTop: '20px'}}>
                          <h3 style={{textAlign: 'center', fontWeight: 'bold'}}>我的收藏</h3>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                          <div style={{fontSize: '20px', fontWeight: '500'}}>收藏博客({this.state.blogCount})</div>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                                <div style={{color: '#abb4bc', margin: '10px'}}>排序:</div>
                                  <select name="order" id="order" ref={this.blogOrderSelect} onChange={this.handleBlogOrder}>
                                          <option value="-time">编辑时间↓</option>
                                          <option value="+time">编辑时间↑</option>
                                          <option value="+visits" >访问量↑</option>
                                          <option value="-visits" >访问量↓</option>
                                          <option value="+comments" >留言数↑</option>
                                          <option value="-comments" >留言数↓</option>
                                  </select>
                        </Row>
                        {/* content */}
                        {this.state.isFavBlogLoading?(<Row style={{paddingLeft: '35%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
                            this.state.favBlogList.length===0?(<Row style={{height: '100px', textAlign: 'center'}}>暂时没有内容</Row>):
                                  this.state.favBlogList.map(blog => {
                                    let blog_date = this.getFormattedDate(blog.post_date)
                                    return(
                                    <Row style={{marginBottom: '20px'}}>
                                      <Col md={10} className='blog-forum-blog' onClick={()=>this.forwardBlog(blog.post_id)}>
                                        <Col md={6} style={{textAlign:"center"}}><img src={blog.cover_url?blog.cover_url:avatar} alt='blog_cover' className='blog-forum-cover'></img></Col>
                                        <Col md={6} className='blog-forum-info'>
                                          <Row style={{fontWeight: 'bold', fontSize: '20px'}}>
                                            <Col md={8} style={{paddingLeft: '0'}}>{blog.subject}</Col>
                                            <Col md={4} style={{paddingLeft: '0'}}>
                                              <Button variant="light" onClick={(event)=>this.cancelFavBlog(event, blog.post_id)}>
                                              <span>
                                              <svg style={{position: 'relative', top: '2px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                                              取消收藏
                                              </span>
                                              </Button>
                                            </Col>
                                            </Row>
                                          <Row style={{height: '168px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{blog.description}</Row>
                                          <Row style={{color: '#707070'}}>{blog_date}</Row>
                                          <Row style={{color: '#7070705d'}}>访问量：{blog.visits}</Row>
                                        </Col>
                                      </Col>
                                    </Row>
                                  )})
                          }
                          {
                            this.getBlogPager()
                          }
                          <Row style={{marginBottom: '20px', marginTop: '40px'}}>
                            <div style={{fontSize: '20px', fontWeight: '500'}}>收藏咨询师({this.state.profileCount})</div>
                          </Row>
                          <Row style={{marginBottom: '20px'}}>
                                <div style={{color: '#abb4bc', margin: '10px'}}>排序:</div>
                                <select name="order" id="order" ref={this.profileOrderSelect} onChange={this.handleProfileOrder}>
                                  <option value="+price" >价格↑</option>
                                  <option value="-price" >价格↓</option>
                                  <option value="+visits">访问量↑</option>
                                  <option value="-visits" >访问量↓</option>
                                </select>
                          </Row>
                          { this.state.isFavProfileLoading ?
                                (<Row style={{paddingLeft: '55%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
                                this.state.favProfileList.map((user, index) => {
                                  let default_avatar = avatar
                                  if(user.user__photo_url !== null){ default_avatar = user.user__photo_url}
                                  tempCity = []
                                  let tempArea = []
                                  user.identification__tags.map(tag => {
                                      var cate = ((""+tag).split(""))[0]
                                      if(cate === '2'){
                                        this.state.areaList.map(elem =>{
                                          if(elem.tag_id === tag){tempArea.push(elem.value_cn)}
                                        })
                                      }
                                    })
                                  return (
                                    <Row className="searchResult" onClick={() => this.openProfilePage(user.profile_id, user.user__id)} key={index}>
                                      <Col md={3} className="img-wrapper"><img src={default_avatar} alt='user_avatar'></img></Col>
                                      <Col md={9} className="consultantInfo">
                                        <Row className='name'>
                                          <Col md={8} style={{fontSize: '24px', fontWeight: '400'}}>
                                              {user.title}{user.full_name}, {user.advanced_credential} &nbsp;
                                              {user.premium_status===1?
                                                <span><br/>青年咨询师&nbsp;
                                                <svg width="32" height="33" style={{position: 'relative', top: '8px'}} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9993 22.5C21.5221 22.5 25.9993 18.0228 25.9993 12.5C25.9993 6.97715 21.5221 2.5 15.9993 2.5C10.4764 2.5 5.99927 6.97715 5.99927 12.5C5.99927 18.0228 10.4764 22.5 15.9993 22.5Z" fill="#FFC771" stroke="#EC7144" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M15.9993 18.5C19.313 18.5 21.9993 15.8137 21.9993 12.5C21.9993 9.18629 19.313 6.5 15.9993 6.5C12.6856 6.5 9.99927 9.18629 9.99927 12.5C9.99927 15.8137 12.6856 18.5 15.9993 18.5Z" fill="white" stroke="#EC7144" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M22 20.4994V30.5L15.9991 27.5L10 30.5V20.5002" stroke="#EC7144" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                </span>
                                              :(<div></div>)}
                                          </Col>
                                          <Col md={4}>
                                            <h4 style={{fontWeight: '300'}}>$&nbsp;{user.price_minimum} 起</h4>
                                          </Col>
                                        </Row>
                                        <Row className='bio-info' style={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'left', marginLeft: '5px'}}>
                                          <div className='search-results-location'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19.5" fill="#43B0B1" class="bi bi-geo-alt" viewBox="0 0 16 16" style={{position: 'relative', top: '3px', left: '0'}}>
                                              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                            </svg>
                                            &nbsp;
                                            {
                                              user.cities.map(elem => {
                                                if(tempCity.length === 0){
                                                  tempCity.push(elem)
                                                  return (elem)
                                                }
                                                else{
                                                  tempCity.push(elem)
                                                  return (' | '+elem)
                                                }
                                              })
                                            }
                                          </div>
                                          <div style={{color:'#071E22'}}>
                                            <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="19" height="19" fill="#43B0B1" style={{position: 'relative', top: '3px', left: '0'}}>
                                              <path d="M220.90723,35.09277A11.85078,11.85078,0,0,0,209.165,32.02832L22.83691,84.582a12.00013,12.00013,0,0,0-1.8789,22.39453l85.61035,40.55176a4.01536,4.01536,0,0,1,1.90234,1.90234L149.02344,235.042a11.89209,11.89209,0,0,0,10.81152,6.86524q.51563,0,1.03711-.04395a11.90291,11.90291,0,0,0,10.5459-8.70019L223.97168,46.835A11.853,11.853,0,0,0,220.90723,35.09277Zm-4.63477,9.57032L163.71875,230.99121a4.00009,4.00009,0,0,1-7.46484.62695l-40.55371-85.6123-.01709-.03272L158.94629,102.71A3.99957,3.99957,0,0,0,153.29,97.05371l-43.26318,43.26294c-.01124-.00537-.022-.01245-.0337-.01782L24.38184,99.74609a4.00009,4.00009,0,0,1,.627-7.46484L211.33691,39.72754a4.15049,4.15049,0,0,1,1.13379-.16211A3.98288,3.98288,0,0,1,215.251,40.749,3.92519,3.92519,0,0,1,216.27246,44.66309Z"/>
                                            </svg>
                                            &nbsp;{user.visits}访问量
                                          </div>
                                        </Row>
                                        <Row>
                                          <Col md={6} className='bio' style={{color:'#071E22', marginLeft: '5px', marginTop: '5px'}}>{user.to_visitor}</Col>
                                        </Row>
                                        <Row className='flex-block search-results' style={{overflow:'hidden', marginLeft: '5px', height: '32px', width: '90%'}}>
                                            {tempArea.map(tag=>(<div className='search-results-area'>{tag}</div>))}
                                        </Row>
                                      </Col>
                                  </Row>
                                )})
                              }
                          {
                            this.getProfilePager()
                          }
                      </Container>
                      </div>:
                      (!this.state.isLoading && this.state.isComment)?
                      <div><Container fluid style={{paddingLeft: '0px'}}>
                      {/* title */}
                      {/* <Row className='my_title'>
                            <Col md={2}></Col>
                            <Col md={6}><h2>咨询师个人主页</h2></Col>
                          </Row> */}
                      {/* navbar */}
                      <div className='nav-bar navbar-default navbar-full' style={{backgroundColor: '#fff', fontSize: '18px', fontWeight: '700'}}>
                      <div className='container container-nav'>
                      <Row className='forum-nav navbar-collapse collapse' style={{marginLeft: '-45px'}}>
                        {/* category */}
                        <ul className="nav navbar-nav profile-nav">
                          {parseInt(localStorage.getItem('user_type'))<=3&&this.state.isPremium===1 ?<li><a href='javascript:;' onClick={() =>this.handleNav('clients')}>我的客户</a></li>:''}
                          <li><a href='javascript:;' onClick={() =>this.handleNav('appointment')}>我的预约</a></li>
                          <li><a href='javascript:;' onClick={() =>this.handleNav('events')}>我的团</a></li>
                          <li><a href='javascript:;' onClick={() =>this.handleNav('fav')}>我的收藏</a></li>
                          <li><a href='javascript:;' className='current-nav' onClick={() =>this.handleNav('comment')}>我的评论</a></li>
                        </ul>
                      </Row>
                      </div>
                      </div>
                    </Container>
                    <Container fluid>
                      <Row style={{marginTop: '20px'}}>
                        <h3 style={{textAlign: 'center', fontWeight: 'bold'}}>我的评论</h3>
                      </Row>
                      <Row style={{marginBottom: '20px'}}>
                        <div style={{fontSize: '20px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-dots-fill" viewBox="0 0 16 16">
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                        </svg> 回复我的</div>
                      </Row>
                      {this.state.isCommentsToLoading?(<Row style={{paddingLeft: '45%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
                        this.state.commentsToList.map((comment) => {
                        let author = comment.author.nickname===''?comment.author.first_name+' '+comment.author.last_name:comment.author.nickname
                        let date = this.getFormattedDate(comment.edit_date)
                        if(comment.response_to===null){
                          return (<Row className='comments'>
                          <Col md={8}><Row><span style={{fontWeight: 'bold'}}>{author}</span> 评论了我的博客</Row>
                          <Row>{parse(comment.content)}</Row>
                          <Row style={{color: '#999'}}>{date}</Row></Col>
                          <Col md={4} style={{paddingBottom: '10px'}}>
                            <Row><a href='javascript:;' className='fav-comments' onClick={() => this.forwardBlog(comment.parent.post_id)}>{comment.parent.subject}</a></Row>
                            <Row><img src={comment.parent.cover_url?comment.parent.cover_url:avatar} alt='封面图片' style={{width: '120px'}}></img></Row>
                          </Col>
                        </Row>)
                        }else{
                          return (<Row className='comments'>
                          <Col md={8}><Row><span style={{fontWeight: 'bold'}}>{author}</span> 回复了我的评论</Row>
                          <Row>{parse(comment.content)}</Row>
                          <Row style={{color: '#999'}}>{date}</Row></Col>
                          <Col md={4}>
                            <Row><a href='javascript:;' className='fav-comments' onClick={() =>this.forwardBlog(comment.parent.post_id)}>{comment.parent.subject}</a></Row>
                            <Row><img src={comment.parent.cover_url?comment.parent.cover_url:avatar} alt='封面图片' style={{width: '120px'}}></img></Row>
                          </Col>
                        </Row>)
                        }
                      })
                      }
                      {
                        this.getCommentsToPager()
                      }
                      <Row style={{marginBottom: '20px', marginTop: '40px'}}>
                        <div style={{fontSize: '20px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-dots-fill" viewBox="0 0 16 16">
                          <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg> 我评论的</div>
                      </Row>
                      {this.state.isCommentsFromLoading?(<Row style={{paddingLeft: '45%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
                        this.state.commentsFromList.map((comment) => {
                        let author = comment.author.nickname===''?comment.author.first_name+' '+comment.author.last_name:comment.author.nickname
                        let date = this.getFormattedDate(comment.edit_date)
                        if(comment.response_to===null){
                          return (<Row className='comments'>
                          <Col md={8}><Row>我评论了博客<span style={{fontWeight: 'bold'}}>{comment.parent.subject}</span></Row>
                          <Row>{parse(comment.content)}</Row>
                          <Row style={{color: '#999'}}>{date}</Row></Col>
                          <Col md={4} style={{paddingBottom: '10px'}}>
                            <Row><a href='javascript:;' className='fav-comments' onClick={() =>this.forwardBlog(comment.parent.post_id)}>{comment.parent.subject}</a></Row>
                            <Row><img src={comment.parent.cover_url?comment.parent.cover_url:avatar} alt='封面图片' style={{width: '120px'}}></img></Row>
                          </Col>
                        </Row>)
                        }else{
                          return (<Row className='comments'>
                          <Col md={8}><Row>我回复了<span style={{fontWeight: 'bold'}}>{comment.response_to.subject}</span></Row>
                          <Row>{parse(comment.content)}</Row>
                          <Row style={{color: '#999'}}>{date}</Row></Col>
                          <Col md={4}>
                            <Row><a href='javascript:;' className='fav-comments' onClick={() =>this.forwardBlog(comment.parent.post_id)}>{comment.parent.subject}</a></Row>
                            <Row><img src={comment.parent.cover_url?comment.parent.cover_url:avatar} alt='封面图片' style={{width: '120px'}}></img></Row>
                          </Col>
                        </Row>)
                        }
                      })
                      }
                      {
                        this.getCommentsFromPager()
                      }
                    </Container>
                    </div>:
                    (!this.state.isLoading && this.state.isAppointment)?
                      <div>
                        <Container fluid style={{paddingLeft: '0px'}}>
                        {/* title */}
                        {/* <Row className='my_title'>
                              <Col md={2}></Col>
                              <Col md={6}><h2>咨询师个人主页</h2></Col>
                            </Row> */}
                        {/* navbar */}
                        <div className='nav-bar navbar-default navbar-full' style={{backgroundColor: '#fff', fontSize: '18px', fontWeight: '700'}}>
                        <div className='container container-nav'>
                        <Row className='forum-nav navbar-collapse collapse' style={{marginLeft: '-45px'}}>
                          {/* category */}
                          <ul className="nav navbar-nav profile-nav">
                            {parseInt(localStorage.getItem('user_type'))<=3&&this.state.isPremium===1?<li><a href='javascript:;' onClick={() =>this.handleNav('clients')}>我的客户</a></li>:''}
                            <li><a href='javascript:;' className='current-nav' onClick={() =>this.handleNav('appointment')}>我的预约</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('events')}>我的团</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('fav')}>我的收藏</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('comment')}>我的评论</a></li>
                          </ul>
                        </Row>
                        </div>
                        </div>
                      </Container>
                      <Container style={{width: '100%'}}>
                        <Row className='flex-block appointment-nav-btns' style={{padding: '0 0', marginBottom: '25px'}}>
                          <input className='events-btn-3' type='button' value={'待参加 '+'('+this.state.totalAppointmentsPaid+')'} style={{width: '100px', height: '42px', marginRight: '10px'}}
                          onClick={(event)=>this.loadAppointments('4', event)}></input>
                          <input className='events-btn-2' type='button' value={'待支付 '+'('+this.state.totalAppointmentsSubmitted+')'} style={{width: '100px', height: '42px', marginRight: '10px'}}
                          onClick={(event)=>this.loadAppointments('0', event)}></input>
                          <input className='events-btn-2' type='button' value={'已完成 '+'('+this.state.totalAppointmentsCompleted+')'} style={{width: '100px', height: '42px', marginRight: '10px'}}
                          onClick={(event)=>this.loadAppointments('2', event)}></input>
                           <input className='events-btn-2' type='button' value={'已取消 '+'('+this.state.totalAppointmentsCancelled+')'} style={{width: '100px', height: '42px'}}
                          onClick={(event)=>this.loadAppointments('3', event)}></input>
                        </Row>
                        {this.state.isAppointmentLoading?(<Row style={{paddingLeft: '45%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
                          this.state.appointmentList_display.map(elem=>{
                            let tempArea = []
                            elem.tags.map(tag => {
                              var cate = ((""+tag).split(""))[0]
                              if(cate === '2'){
                                this.state.areaList.map(elem =>{
                                  if(elem.tag_id === tag){tempArea.push(elem.value_cn)}
                                })
                              }
                            })
                            let current_timezone = ''
                            if(elem.meta&&elem.meta.timezone&&elem.meta.timezone!==''){
                              this.state.timezoneList.map(timezone=>{
                                if(timezone.value_cn === elem.meta.timezone){
                                  current_timezone = timezone.id
                                }
                              })
                            }
                            let start_date = current_timezone===''?new Date(elem.start_time):this.resolveTimezone(elem.start_time, current_timezone)
                            let end_date = new Date(start_date.getTime() + elem.length*1000*60*30)
                            let formatted_date = start_date.getFullYear()+'-' + (start_date.getMonth()+1) + '-'+start_date.getDate()
                            let time_duration = start_date.getHours()+':'+(start_date.getMinutes()===0?
                            '00':start_date.getMinutes()) + ' - ' + end_date.getHours()+':'+(end_date.getMinutes()===0?'00':end_date.getMinutes())
                            let refund_status = elem.refund_request?elem.refund_request.status: ''
                            if(refund_status !== ''){
                              if(refund_status === 0){refund_status = '已申请退款'}
                              else if(refund_status === 1){refund_status = '退款已同意'}
                              else if(refund_status === 2){refund_status = '退款被拒绝'}
                            }
                            return <React.Fragment>
                            <Row style={{marginTop: '16px', height: '189px', borderBottom: '2px solid #E7E9EE'}}>
                                    <Col md={11} className='flex-block'>
                                      <Col md={3} style={{ marginRight: '24px' }}>
                                        <img src={elem.consultant_data.photo_url===''?avatar:elem.consultant_data.photo_url} alt='cover_img'></img>
                                      </Col>
                                      <Col md={5}>
                                        <Row style={{fontSize: '24px', fontWeight: '400', marginBottom: '8px'}}>
                                          <Col md={12} style={{ paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                                            {elem.consultant_data.first_name} {elem.consultant_data.last_name}
                                          </Col>
                                        </Row>
                                        <Row style={{marginBottom: 8}} className='flex-block'>
                                          <div style={{padding: '0', maxWidth: 100, marginRight: 20, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19.5" fill="#43B0B1" class="bi bi-geo-alt" viewBox="0 0 16 16" style={{position: 'relative', top: '3px', left: '0'}}>
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                  </svg>
                                            &nbsp;
                                            {elem.method===3?elem.meta.city&&elem.meta.state?
                                              (elem.meta.city+', '+elem.meta.state):'':
                                              elem.method===2?'视频':'语音'
                                            }
                                          </div>
                                          <div style={{padding: '0'}}>
                                            <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="19" height="19" fill="#43B0B1" style={{position: 'relative', top: '3px', left: '0'}}>
                                                    <path d="M220.90723,35.09277A11.85078,11.85078,0,0,0,209.165,32.02832L22.83691,84.582a12.00013,12.00013,0,0,0-1.8789,22.39453l85.61035,40.55176a4.01536,4.01536,0,0,1,1.90234,1.90234L149.02344,235.042a11.89209,11.89209,0,0,0,10.81152,6.86524q.51563,0,1.03711-.04395a11.90291,11.90291,0,0,0,10.5459-8.70019L223.97168,46.835A11.853,11.853,0,0,0,220.90723,35.09277Zm-4.63477,9.57032L163.71875,230.99121a4.00009,4.00009,0,0,1-7.46484.62695l-40.55371-85.6123-.01709-.03272L158.94629,102.71A3.99957,3.99957,0,0,0,153.29,97.05371l-43.26318,43.26294c-.01124-.00537-.022-.01245-.0337-.01782L24.38184,99.74609a4.00009,4.00009,0,0,1,.627-7.46484L211.33691,39.72754a4.15049,4.15049,0,0,1,1.13379-.16211A3.98288,3.98288,0,0,1,215.251,40.749,3.92519,3.92519,0,0,1,216.27246,44.66309Z"/>
                                                  </svg>
                                            &nbsp;{elem.consultant_data.visits}访问量
                                          </div>
                                        </Row>
                                        <Row style={{marginBottom: '12px'}}>订单号：{elem.ticket_id}</Row>
                                        <Row>
                                          {tempArea.map(area => (<div className='search-results-area'>{area}</div>))}
                                        </Row>
                                      </Col>
                                      <Col md={4} className='flex-block5'>
                                        <div style={{marginBottom: '8px', fontSize: '18px', textAlign: 'right'}}>价格：{elem.unit_price* elem.length} {elem.currency}</div>
                                        <div style={{marginBottom: '8px', fontSize: '18px', textAlign: 'right'}}>{formatted_date}</div>
                                        <div style={{marginBottom: '8px', fontSize: '18px', textAlign: 'right'}}>{time_duration}</div>
                                        <div className='flex-block' style={{justifyContent: 'flex-end'}}>
                                          {elem.status===4 && !elem.refund_request?<input className="events-btn-2" onClick={() =>this.setRefund(elem.ticket_id)} style={{height: '36px'}} type="button" value="申请退款" />:
                                          elem.refund_request?<div className='search-results-area' style={{color: '#fff', backgroundColor: '#eea6a6', fontSize: '12px'}}>{refund_status}</div>:''}
                                          {elem.status===0?<input className="events-btn-2" onClick={()=>this.handlePayment(elem.ticket_id)} style={{height: '36px'}} type="button" value="    付款    " />:''}
                                        </div>
                                      </Col>
                                    </Col>
                                  </Row>
                                  {<Row style={{marginTop: '16px', textAlign: 'center', display: 'none'}} id={'refund'+elem.ticket_id}>
                                      <textarea value={this.state.refund_reason} onChange={(event)=>this.setState({refund_reason: event.target.value})} placeholder='退款原因'></textarea>
                                      <input className="events-btn-3" onClick={() =>this.submitRefund()} style={{height: '36px', marginLeft: '8px'}} type="button" value="提交申请" />
                                    </Row>}</React.Fragment>
                          })
                        }
                      </Container>
                      </div>:
                    (parseInt(localStorage.getItem('user_type'))<=3&&this.state.isPremium===1 && !this.state.isLoading && this.state.isClients) ?
                      <div>
                        <Container fluid style={{paddingLeft: '0px'}}>
                        {/* title */}
                        {/* <Row className='my_title'>
                              <Col md={2}></Col>
                              <Col md={6}><h2>咨询师个人主页</h2></Col>
                            </Row> */}
                        {/* navbar */}
                        <div className='nav-bar navbar-default navbar-full' style={{backgroundColor: '#fff', fontSize: '18px', fontWeight: '700'}}>
                        <div className='container container-nav'>
                        <Row className='forum-nav navbar-collapse collapse' style={{marginLeft: '-45px'}}>
                          {/* category */}
                          <ul className="nav navbar-nav profile-nav">
                            <li><a href='javascript:;' className='current-nav' onClick={() =>this.handleNav('clients')}>我的客户</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('appointment')}>我的预约</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('events')}>我的团</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('fav')}>我的收藏</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('comment')}>我的评论</a></li>
                          </ul>
                        </Row>
                        </div>
                        </div>
                      </Container>
                      <Container style={{width: '100%'}}>
                        <Row className='flex-block client-nav-btns' style={{padding: '0 0px', marginBottom: '16px'}}>
                          <input className='events-btn-3' type='button' value={'待参加 '+'('+this.state.totalClientsPaid+')'} style={{width: '100px', height: '42px', marginRight: '10px'}}
                          onClick={(event)=>this.loadClientAppointments('4', event)}></input>
                          <input className='events-btn-2' type='button' value={'待支付 '+'('+this.state.totalClientsSubmitted+')'} style={{width: '100px', height: '42px', marginRight: '10px'}}
                          onClick={(event)=>this.loadClientAppointments('0', event)}></input>
                          <input className='events-btn-2' type='button' value={'已完成 '+'('+this.state.totalClientsCompleted+')'} style={{width: '100px', height: '42px', marginRight: '10px'}}
                          onClick={(event)=>this.loadClientAppointments('2', event)}></input>
                           <input className='events-btn-2' type='button' value={'已取消 '+'('+this.state.totalClientsCancelled+')'} style={{width: '100px', height: '42px'}}
                          onClick={(event)=>this.loadClientAppointments('3', event)}></input>
                        </Row>
                        {this.state.isClientAppointmentLoading?(<Row style={{paddingLeft: '45%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
                          this.state.clientList_display.map(elem=>{
                            let tempArea = []
                            elem.tags.map(tag => {
                              var cate = ((""+tag).split(""))[0]
                              if(cate === '2'){
                                this.state.areaList.map(elem =>{
                                  if(elem.tag_id === tag){tempArea.push(elem.value_cn)}
                                })
                              }
                            })
                            let current_timezone = ''
                            if(elem.meta&&elem.meta.timezone&&elem.meta.timezone!==''){
                              this.state.timezoneList.map(timezone=>{
                                if(timezone.value_cn === elem.meta.timezone){
                                  current_timezone = timezone.id
                                }
                              })
                            }
                            let start_date = current_timezone===''?new Date(elem.start_time):this.resolveTimezone(elem.start_time, current_timezone)
                            let end_date = new Date(start_date.getTime() + elem.length*1000*60*30)
                            let formatted_date = start_date.getFullYear()+'-' + (start_date.getMonth()+1) + '-'+start_date.getDate()
                            let time_duration = start_date.getHours()+':'+(start_date.getMinutes()===0?
                            '00':start_date.getMinutes()) + ' - ' + end_date.getHours()+':'+(end_date.getMinutes()===0?'00':end_date.getMinutes())
                            let refund_status = elem.refund_request?elem.refund_request.status: ''
                            if(refund_status !== ''){
                              if(refund_status === 0){refund_status = '已申请退款'}
                              else if(refund_status === 1){refund_status = '退款已同意'}
                              else if(refund_status === 2){refund_status = '退款被拒绝'}
                            }
                            return <React.Fragment>
                            <Row style={{marginTop: '16px', height: '189px', borderBottom: '2px solid #E7E9EE'}}>
                                    <Col md={11} className='flex-block'>
                                      <Col md={3} style={{ marginRight: '24px' }}>
                                        <img src={avatar} alt='' style={{width: '135px', height: '135px', borderRadius: '135px'}}></img>
                                      </Col>
                                      <Col md={5}>
                                        <Row style={{fontSize: '24px', fontWeight: '400', marginBottom: '8px'}}>
                                          <Col md={12} style={{ paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                                            {elem.user_data.nickname===''?elem.user_data.first_name+' '+elem.user_data.last_name:elem.user_data.nickname}
                                          </Col>
                                        </Row>
                                        <Row style={{marginBottom: 8}} className='flex-block'>
                                          <div style={{padding: '0', maxWidth: 100, marginRight: 20, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19.5" fill="#43B0B1" class="bi bi-geo-alt" viewBox="0 0 16 16" style={{position: 'relative', top: '3px', left: '0'}}>
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                  </svg>
                                            &nbsp;
                                            {elem.method===3?elem.meta&&elem.meta.city&&elem.meta.state?
                                              (elem.meta.city+', '+elem.meta.state):'':
                                              elem.method===2?'视频':'语音'
                                            }
                                          </div>
                                        </Row>
                                        <Row style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap', marginBottom: '12px'}}>预约描述：{elem.meta?elem.meta.description:''}</Row>
                                        <Row style={{marginBottom: '12px'}}>订单号：{elem.ticket_id}</Row>
                                        <Row>
                                          {tempArea.map(area => (<div className='search-results-area'>{area}</div>))}
                                        </Row>
                                      </Col>
                                      <Col md={4} className='flex-block5'>
                                        <div style={{marginBottom: '8px', fontSize: '22px', textAlign: 'right'}}>{formatted_date}</div>
                                        <div style={{marginBottom: '8px', fontSize: '22px', textAlign: 'right'}}>{time_duration}</div>
                                        <div style={{marginBottom: '8px', fontSize: '18px', textAlign: 'right'}}>{elem.meta&&elem.meta.timezone?elem.meta.timezone:''}</div>
                                        <div className='flex-block' style={{justifyContent: 'flex-end'}}>
                                          {elem.status===4 && !elem.refund_request?<input className="events-btn-2" onClick={() =>this.setRefund(elem.ticket_id)} style={{height: '36px', marginRight: '8px'}} type="button" value="申请退款" />:
                                          elem.refund_request?<div className='search-results-area' style={{color: '#fff', backgroundColor: '#eea6a6', fontSize: '12px'}}>{refund_status}</div>:''}
                                          {elem.status===0?<input className="events-btn-2" onClick={()=>this.handlePayment(elem.ticket_id)} style={{height: '36px', marginRight: '8px'}} type="button" value="    付款    " />:''}
                                        </div>
                                      </Col>
                                    </Col>
                                  </Row>
                                  {<Row style={{marginTop: '16px', textAlign: 'center', display: 'none'}} id={'refund'+elem.ticket_id}>
                                      <textarea value={this.state.refund_reason} onChange={(event)=>this.setState({refund_reason: event.target.value})} placeholder='退款原因'></textarea>
                                      <input className="events-btn-3" onClick={() =>this.submitRefund()} style={{height: '36px', marginLeft: '8px'}} type="button" value="提交申请" />
                                    </Row>}</React.Fragment>
                          })
                        }
                      </Container>
                      </div>:(!this.state.isLoading && this.state.isEvent)?
                      <div>
                        <Container fluid style={{paddingLeft: '0px'}}>
                        {/* title */}
                        {/* <Row className='my_title'>
                              <Col md={2}></Col>
                              <Col md={6}><h2>咨询师个人主页</h2></Col>
                            </Row> */}
                        {/* navbar */}
                        <div className='nav-bar navbar-default navbar-full' style={{backgroundColor: '#fff', fontSize: '18px', fontWeight: '700'}}>
                        <div className='container container-nav'>
                        <Row className='forum-nav navbar-collapse collapse' style={{marginLeft: '-45px'}}>
                          {/* category */}
                          <ul className="nav navbar-nav profile-nav">
                            {parseInt(localStorage.getItem('user_type'))<=3&&this.state.isPremium===1?<li><a href='javascript:;' onClick={() =>this.handleNav('clients')}>我的客户</a></li>:''}
                            <li><a href='javascript:;' onClick={() =>this.handleNav('appointment')}>我的预约</a></li>
                            <li><a href='javascript:;' className='current-nav' onClick={() =>this.handleNav('events')}>我的团</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('fav')}>我的收藏</a></li>
                            <li><a href='javascript:;' onClick={() =>this.handleNav('comment')}>我的评论</a></li>
                          </ul>
                        </Row>
                        </div>
                        </div>
                      </Container>
                      <Container style={{width: '100%'}}>
                        <Row className='flex-block event-nav-btns' style={{padding: '0 0px', marginBottom: '16px'}}>
                          <input className='events-btn-3' type='button' value={'我创建的 '+'('+(this.state.events_create_num-this.state.events_create_num_finished)+')'} style={{width: '100px', height: '42px', marginRight: '10px'}}
                          onClick={(event) =>this.switchDisplayEvent('creator', event)}></input>
                          <input className='events-btn-2' type='button' value={'我参加的 '+'('+(this.state.events_attend_num-this.state.events_attend_num_finished)+')'} style={{width: '100px', height: '42px', marginRight: '10px'}}
                          onClick={(event) =>this.switchDisplayEvent('attender', event)}></input>
                        </Row>
                        {this.state.isEventLoading?(<Row style={{paddingLeft: '45%'}}><ReactLoading type='spinningBubbles' color='#5ecfd0' height={'15%'} width={'15%'}/></Row>):
                          this.state.displayEvents.map(elem=>{
                            let tag_list = []
                            let subtag_list = []
                            this.state.eventTags.map(tag =>{
                              if(elem.tags.includes(tag.tag_id)){
                                tag_list.push(tag.value_cn)
                              }
                            })
                            this.state.eventSubTags.map(tag =>{
                            if(elem.tags.includes(tag.tag_id)){
                                subtag_list.push(tag.value_cn)
                              }
                            })

                            let current_status = ''
                            if(elem.status===0){current_status = '已保存'}
                            else if(elem.status===1){current_status = '已提交'}
                            else if(elem.status===2){current_status = '已发布'}
                            else if(elem.status===3){current_status = '已取消'}
                            else if(elem.status===4){current_status = '被拒绝'}

                            let calendar_id = elem.attending&&elem.attending.length>0?elem.attending[0]:''
                            let attend_time = ''
                            elem.calendars.map(time=>{
                              if(time.calendar_id = calendar_id){attend_time = time}
                            })
                            let current_timezone = ''
                            if(attend_time!==''&&attend_time.timezone!==''){
                              this.state.timezoneList.map(timezone=>{
                                if(timezone.id === attend_time.timezone){
                                  current_timezone = timezone.value_cn
                                }
                              })
                            }
                            let start_date = ''
                            let end_date = ''
                            let formatted_start_date = ''
                            let formatted_end_date = ''
                            if(attend_time!==''){
                              let event_timezone_id = attend_time.timezone
                              start_date = new Date(attend_time.start_time)
                              end_date = new Date(attend_time.end_time)
                              formatted_start_date = (start_date.getMonth()+1) + '/' + start_date.getDate() + '/' + start_date.getFullYear()%2000+ ' '+
                              start_date.getHours()+':'+(start_date.getMinutes()===0?'00':start_date.getMinutes())
                              formatted_end_date = (end_date.getMonth()+1) + '/' + end_date.getDate() + '/' + end_date.getFullYear()%2000+ ' '+
                              end_date.getHours()+':'+(end_date.getMinutes()===0?'00':end_date.getMinutes())
                            }

                            return <React.Fragment>
                            <Row style={{marginTop: '16px', height: '189px', borderBottom: '2px solid #E7E9EE'}}>
                                    <Col md={11} className='flex-block'>
                                      <Col md={3} style={{ marginRight: '24px' }}>
                                        <img src={elem.cover===''?avatar:elem.cover} alt='cover_img' style={{width: '135px', height: '135px', borderRadius: '135px'}}></img>
                                      </Col>
                                      <Col md={5}>
                                        <Row style={{fontSize: '24px', fontWeight: '400', marginBottom: '8px'}}>
                                          <Col md={12} style={{ paddingLeft: 0, fontSize: '18px', fontWeight: '500'}}>
                                            {elem.name}
                                          </Col>
                                        </Row>
                                        <Row style={{marginBottom: 8}} className='flex-block'>
                                          <div style={{padding: '0', maxWidth: 100, marginRight: 20, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19.5" fill="#43B0B1" class="bi bi-geo-alt" viewBox="0 0 16 16" style={{position: 'relative', top: '3px', left: '0'}}>
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                  </svg>
                                            &nbsp;
                                            {elem.calendars&&elem.calendars.length>0?elem.calendars[0].online?'在线':elem.calendars[0].city+', '+elem.calendars[0].region:''}
                                          </div>
                                          <div style={{padding: '0'}}>
                                            <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="19" height="19" fill="#43B0B1" style={{position: 'relative', top: '3px', left: '0'}}>
                                                    <path d="M220.90723,35.09277A11.85078,11.85078,0,0,0,209.165,32.02832L22.83691,84.582a12.00013,12.00013,0,0,0-1.8789,22.39453l85.61035,40.55176a4.01536,4.01536,0,0,1,1.90234,1.90234L149.02344,235.042a11.89209,11.89209,0,0,0,10.81152,6.86524q.51563,0,1.03711-.04395a11.90291,11.90291,0,0,0,10.5459-8.70019L223.97168,46.835A11.853,11.853,0,0,0,220.90723,35.09277Zm-4.63477,9.57032L163.71875,230.99121a4.00009,4.00009,0,0,1-7.46484.62695l-40.55371-85.6123-.01709-.03272L158.94629,102.71A3.99957,3.99957,0,0,0,153.29,97.05371l-43.26318,43.26294c-.01124-.00537-.022-.01245-.0337-.01782L24.38184,99.74609a4.00009,4.00009,0,0,1,.627-7.46484L211.33691,39.72754a4.15049,4.15049,0,0,1,1.13379-.16211A3.98288,3.98288,0,0,1,215.251,40.749,3.92519,3.92519,0,0,1,216.27246,44.66309Z"/>
                                                  </svg>
                                            &nbsp;{elem.calendars&&elem.calendars.length>0?elem.calendars[0].attends:0}已报名
                                          </div>
                                        </Row>
                                        <Row style={{marginBottom: '2px'}}>活动形式：{tag_list.map(tag =>tag+' ')}</Row>
                                        <Row style={{marginBottom: '2px'}}>发起人：{elem.creator_data.nickname===''?elem.creator_data.first_name+' '+ elem.creator_data.last_name:elem.creator_data.nickname}</Row>
                                        <Row style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap', marginBottom: '2px'}}>活动简介：{elem.description}</Row>
                                        <Row>
                                          {subtag_list.map(tag => (<div className='search-results-area'>{tag}</div>))}
                                        </Row>
                                      </Col>
                                      <Col md={4} className='flex-block5'>
                                        {this.state.eventType==='attender'?
                                          <React.Fragment><div style={{marginBottom: '8px', fontSize: '18px', marginTop: '26px', textAlign: 'right'}}>{formatted_start_date}</div>
                                        <div style={{marginBottom: '8px', fontSize: '18px', textAlign: 'right'}}>{formatted_end_date}</div>
                                        <div style={{marginBottom: '8px', fontSize: '18px', textAlign: 'right'}}>{current_timezone}</div></React.Fragment>:
                                        <div className='custom-select' style={{display: 'inline-block'}}>
                                          <select style={{width: '100%',fontWeight: '700', marginBottom: '20px'}} name="calendars-select" id="calendars-select" required>
                                          {elem.calendars&&elem.calendars.length>0?
                                            elem.calendars.map(elem => {
                                              let current_timezone = ''
                                              if(elem.timezone!==''){
                                                this.state.timezoneList.map(timezone=>{
                                                  if(timezone.id === elem.timezone){
                                                    current_timezone = timezone.value_cn
                                                  }
                                                })
                                              }
                                              let start_date = ''
                                              let end_date = ''
                                              let formatted_start_date = ''
                                              let formatted_end_date = ''
                                              let event_timezone_id = elem.timezone
                                                start_date = new Date(elem.start_time)
                                                end_date = new Date(elem.end_time)
                                                formatted_start_date = (start_date.getMonth()+1) + '/' + start_date.getDate() + '/' + start_date.getFullYear()%2000 + ' '+
                                                start_date.getHours()+':'+(start_date.getMinutes()===0?'00':start_date.getMinutes())
                                                formatted_end_date = '~'+(end_date.getMonth()+1) + '/' + end_date.getDate() + '/' + end_date.getFullYear()%2000+ ' '+
                                                end_date.getHours()+':'+(end_date.getMinutes()===0?'00':end_date.getMinutes())
                                              
                                              let display_time = formatted_start_date + formatted_end_date + current_timezone
                                              return <option value={elem.calendar_id} onClick={this.handleCalendarSelect}> {display_time} </option>
                                            }):<option value=''>暂时没有可选的时间</option>                   
                                          }
                                          </select>
                                        </div>           
                                          }
                                          {this.state.eventType==='creator'?
                                          <div style={{marginBottom: '8px', textAlign: 'right'}}>活动状态：{current_status}</div>:''
                                          }
                                        <div className='flex-block' style={{justifyContent: 'right'}}>
                                          {this.state.eventType==='creator'?<input className="events-btn-2" onClick={() =>this.props.history.push(`/event/edit/:${elem.event_id}`)} style={{height: '36px', marginRight: '8px'}} type="button" value="编辑" />:''}
                                          <input className="events-btn-2" onClick={() =>this.props.history.push(`/event/detail/:${elem.event_id}`)} style={{height: '36px'}} type="button" value="查看详情" />
                                        </div>
                                      </Col>
                                    </Col>
                                  </Row></React.Fragment>
                          })
                        }
                        <Row className='flex-block event-nav-btns-2' style={{padding: '0 0px', marginTop: '16px'}}>
                          {this.state.eventType==='creator'?<input className='events-btn-2' type='button' value={this.state.displayFullEvents?'收起':'展开已完成 '+'('+this.state.events_create_num_finished+')'} style={{width: '107px', height: '42px', marginRight: '10px'}}
                          onClick={() =>this.displayFinishedEvent('creator')}></input>:
                          <input className='events-btn-2' type='button' value={this.state.displayFullEvents?'收起':'展开已完成 '+'('+this.state.events_attend_num_finished+')'} style={{width: '107px', height: '42px', marginRight: '10px'}}
                          onClick={() =>this.displayFinishedEvent('attender')}></input>}
                        </Row>
                      </Container>
                      </div>:''
                    }
                  </Tab.Pane>
                  <Tab.Pane eventKey="editInfo" style={{ opacity: 1 }}>
                  <React.Fragment>
                    {parseInt(localStorage.getItem('user_type'))<=3?
                    <ul class="nav nav-tabs mb-3 ms-3">
                          <li class="nav-item"><a href='javascript:;' className={"user-profile-link nav-link " + (this.state.isBasicProfile?"active":"")} onClick={(event) =>this.switchProfileEdit('basic', event)}>用户信息</a></li>
                      {this.state.profile_id?
                            <li class="nav-item"><a href='javascript:;' className={"user-profile-link nav-link " + (this.state.isBasicProfile?"":"active")} onClick={(event) => this.switchProfileEdit('therapist', event)}>咨询师信息</a></li>
                            : <li class="nav-item"><Link to={{ pathname:"/therapist/profile/register/", state:{id:this.state.user_id}}} className='user-profile-link nav-link'>创建咨询师信息</Link></li>
                      }
                    </ul>
                    :''}
                    {this.state.isBasicProfile?
                      <ProfileEdit handleNameChange={(name)=>this.setState({nickname: name})} handleImageChange={(url)=>this.setState({photo_url: url})} userId={this.props.match.params.user_id.slice(1)} />:
                      <TherapistProfileEdit></TherapistProfileEdit>}
                  </React.Fragment>
                  </Tab.Pane>
                  {parseInt(localStorage.getItem('user_type'))<=3?
                    <Tab.Pane eventKey="appointmentSetting" style={{ opacity: 1 }}>
                      {this.state.activeKey === "appointmentSetting" ?
                        <React.Fragment>
                        <ul class="nav nav-tabs mb-3 ms-3">
                          {/*<li class="nav-item"><a href='javascript:;' className={"user-profile-link nav-link " + (this.state.appointmentTapIndicator==='manage' ? "active" : "")} onClick={() => {this.setState({appointmentTapIndicator: 'manage'})}}>预约管理</a></li>*/}
                          <li class="nav-item"><a href='javascript:;' className={"user-profile-link nav-link " + (this.state.appointmentTapIndicator === 'configure' ? "active" : "")} onClick={() => { this.setState({ appointmentTapIndicator: 'configure' }) }}>预约设置</a></li>
                        </ul>
                        {this.state.appointmentTapIndicator === 'manage' ? 
                          <ScheduleAppointment key={'Appointment' + this.state.user_id} userId={this.state.user_id} locationId={''} /> :
                          <ScheduleConfig key={'Config' + this.state.user_id} userId={this.state.user_id} locationId={''} />
                        }
                        </React.Fragment>
                      :""}
                    </Tab.Pane>:''}
                  <Tab.Pane eventKey="accountSetting" style={{ opacity: 1 }}>
                    {/* <h2>账号设置</h2> */}
                      <div style={{marginTop: 15}}>
                          <span className='fs-5 box-shadow'>修改密码</span>
                      </div>
                        {
                            !this.state.codeVerified ?
                            <div>
                              <div class="input-group mb-3">
                                <a class="btn btn-third p-0" onClick={this.handleEmailSubmit}>{this.state.emailSent ? "重新发送验证码" : "向邮箱发送验证码"}</a>
                              </div>
                              <div class="input-group mb-3">
                                  <input type="text" value={this.state.code} onChange={this.changeCode} name="code" placeholder="验证码/Code" class="form-control" required/>
                              </div>
                              <div class="d-grid pb-3">
                                <button type="input" onClick={this.handleCodeSubmit} className="btn btn-primary px-5">确认</button>
                              </div>
                            </div>
                            :
                            <div>
                              <div className="input-group mb-3">
                              <input type="text" value={this.state.password} onChange={this.changePassword} name="newpassword" placeholder="输入新密码/New Password" class="form-control" required/>
                              </div>
                              <div className="input-group mb-3">
                                <input type="text" value={this.state.password_confirm} onChange={this.changePasswordConfirm} name="re_newpassword" placeholder="确认新密码/Re-enter New Password" class="form-control" required/>
                              </div>
                              <div class="d-grid pb-3">
                                <button type="input" onClick={this.handlePasswordSubmit} className="btn btn-primary px-5">更新</button>
                              </div>
                            </div>
                        }
                  </Tab.Pane>
                  {parseInt(localStorage.getItem('user_type'))<=3&&this.state.isPremium===1?
                    <Tab.Pane eventKey="walletSetting" style={{ opacity: 1 }}>
                    <span style={{marginTop: 30, fontWeight: 500}}>钱包余额: </span>
                    <span style={{textAlign: 'center', marginLeft: 20}}>
                    {balance_usd_display}
                    <input className="events-btn-2" onClick={this.state.displayUSDWithdraw?
                    ()=>this.setState({displayUSDWithdraw: false}):()=>this.setState({displayUSDWithdraw: true, displayCNYWithdraw: false})} style={{height: '36px', margin: '0 12px'}} type="button" value="提现USD" />
                    {balance_cny_display}
                    <input className="events-btn-2" onClick={this.state.displayCNYWithdraw?
                    ()=>this.setState({displayCNYWithdraw: false}):()=>this.setState({displayCNYWithdraw: true, displayUSDWithdraw: false})} style={{height: '36px', marginLeft: '12px'}} type="button" value="提现CNY" />
                    </span>
                    {this.state.displayCNYWithdraw || this.state.displayUSDWithdraw?<div style={{marginTop: 16, display: 'inline-block'}}>
                      <input type="number" value={this.state.withdraw_amount} onChange={event=>this.setState({withdraw_amount: event.target.value})} placeholder="提现金额"></input>
                    </div>:''}
                    {this.state.displayCNYWithdraw?
                      <input className="events-btn-2" onClick={this.withdrawCNY} style={{height: '36px', margin: '0 12px', display: 'inline-block'}} type="button" value="确认CNY" />:
                      this.state.displayUSDWithdraw?<input className="events-btn-2" onClick={this.withdrawUSD} style={{height: '36px', margin: '0 12px'}} type="button" value="确认USD" />:''
                    }
                      <h2>提现请求</h2>
                      {
                          this.getWithDrawList()
                      }
                  </Tab.Pane>:''}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <div style={{height: '150px'}}></div>
        </Container>
      )
  }

}

export default withRouter(WebuserProfile)
