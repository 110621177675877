import React, { Component } from 'react'
import axios from 'axios'
import {NavLink, Redirect} from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import utils from './utils';
import ReactGA from 'react-ga'

import '../css/sign-in-sign-up.css'

class Signup extends Component {

  state = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirm: '',
      gender: 0,
      nickname: '',
      slogan: '',
      displayPassword: false,
      displayConfirmPassword: false
  }

  handleSubmit = async(event) => {
    event.preventDefault()
    // pre-validation
    let formIsValid = true;
    if(!this.state.nickname){
        formIsValid = false;
        alert('请填写您的用户名')
      }
    else if(!this.state.email){
      formIsValid = false;
      alert('请填写您的邮箱')
    }
    else if(!this.state.password){
      formIsValid = false
      alert('请填写您的密码')
    }
    else if(!this.state.password_confirm){
      formIsValid = false
      alert('请确认您的密码')
    }
    else if(this.state.password.length < 6){
      formIsValid = false;
      alert('密码必须至少包含6个字符')
    }
    else if(this.state.passwordStrength < 2){
      formIsValid = false;
      alert('密码强度太弱！')
    }
    else if(this.state.password !== this.state.password_confirm){
      formIsValid = false;
      alert('两次输入的密码不一致')
    }
    else if(typeof this.state.email !== "undefined"){
      let lastAtPos = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
         formIsValid = false;
         alert('邮箱格式不合法')
       }
    }

    if(!formIsValid){return false}

    let isEmailExist = false
    await axios.post(utils.getUrl('permission/email-verification/exist/'), {email:this.state.email})
    .then(async(response) => {
      // does email already exist
      if(response.data.exist){
        isEmailExist = true
        alert('该邮箱已存在!')
      }
    }).catch(err => {
      // alert(err)
    })

    if(!isEmailExist){
      // forward verification page
      await axios.post(utils.getUrl('permission/email-verification/generate-code/'), {email:this.state.email, action: 'register'})
      .then(response => {
        if(response.data.status === 0){
          let registerData =
            {
              "first_name":this.state.first_name,
              "last_name":this.state.last_name,
              "nickname": this.state.nickname,
              "user_type": 4,
              "gender": 0,
              "email": this.state.email,
              "username": this.state.email,
              "password": this.state.password
            }
          this.props.history.push({pathname:'/verification', state: { email:this.state.email, data: registerData}})
        }
      }).catch(err => {
        // alert(err)
      })
    }

    return false;
  }

  changeFirstName = (event) => {
    this.setState({first_name: event.target.value})
  }
  changeLastName = (event) => {
    this.setState({last_name: event.target.value})
  }
  changeEmail = (event) => {
    this.setState({email: event.target.value})
  }
  changePassword = (event) => {
    this.setState({password: event.target.value})
  }
  changePasswordConfirm = (event) => {
    this.setState({password_confirm: event.target.value})
  }
  changeNickname = (event) => {
    this.setState({nickname: event.target.value})
  }
  changeGender = (event) => {
    this.setState({gender: parseInt(event.target.value)})
  }

  displayPassword = () => {
    this.setState({displayPassword: !this.state.displayPassword})
  }
  displayConfirmPassword = () => {
    this.setState({displayConfirmPassword: !this.state.displayConfirmPassword})
  }

  handlePasswordStrength = (score) => {
    this.setState({passwordStrength: score})
  }

  componentDidMount = () => {
    this.props.cancelGap();
    this.props.setDisplayURL();
  }

  componentWillUnmount = () => {
    this.props.addGap();
  }

  componentWillMount = async () => {
    document.title = "FindSelf找我 - 注册";
  }

  render() {
    // GA - PageView - Start
    let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
    ReactGA.pageview(pagePathForGA);
    // GA - PageView - End
    if(localStorage.getItem('loginToken')){
      return (
        <Redirect to='/home/'></Redirect>
      )
    }else{
    return (
      <div class="container-fluid">
        <div class="row align-self-center justify-content-center">
          <div class="form-card p-3 my-2">
            <form class="">
              <ul class="nav justify-content-center">
                <li class="nav-item"><NavLink className="nav-link" activeClassName="active" to='/signup/webuser'><h2>注册</h2></NavLink></li>
                <li class="nav-item"><NavLink className="nav-link inactive" activeClassName="active" to='/login'><h2>登录</h2></NavLink></li>
              </ul>
              <div class="input-group mb-3">
                <input type="text" value={this.state.nickname} onChange={this.changeNickname} name="nickname" placeholder="用户名" class="form-control" required/>
              </div>
              <div class="input-group mb-3">
              <input type="text" value={this.state.email} onChange={this.changeEmail} name="email" placeholder="邮箱" class="form-control" required/>
              </div>
              <div class="input-group mb-3">
                <input type={this.state.displayPassword?'text':'password'} name="password" value={this.state.password} onChange={this.changePassword} placeholder="密码" class="form-control border-right" required/>
                <div class="input-group-append">
                  <span class="input-group-text border-left" onClick={this.displayPassword}>
                    {this.state.displayPassword ? <i class="bi bi-eye"></i> : <i class="bi bi-eye-slash"></i> }
                  </span>
                </div>
              </div>
              <PasswordStrengthBar password={this.state.password} onChangeScore={(score) =>this.handlePasswordStrength(score)} />

              <div class="input-group mb-3">
                <input type={this.state.displayConfirmPassword?'text':'password'} value={this.state.password_confirm} onChange={this.changePasswordConfirm} name="password_confirm" placeholder="确认密码" class="form-control border-right" required/>
                <div class="input-group-append">
                  <span class="input-group-text border-left" onClick={this.displayConfirmPassword}>
                    {this.state.displayConfirmPassword ? <i class="bi bi-eye"></i> : <i class="bi bi-eye-slash"></i> }
                  </span>
                </div>
              </div>
              <div class="d-grid pb-3">
                <button id="submit" type="submit" class="btn btn-primary px-5" onClick={this.handleSubmit}>注册</button>
              </div>
            </form>
            <div>
              <small>注册代表您已同意找我的<NavLink class="text-small text-dark-green" to='/agreements' target='_blank'>《用户服务协议》</NavLink>和<NavLink class="text-small text-dark-green" to='/privacy' target='_blank'>《隐私协议》</NavLink>。</small>
            </div>
            <div class="pt-3">
              <NavLink class="btn btn-third p-0" to='/signup/Therapist'>我是心理健康工作者，立即入驻</NavLink>
            </div>
        </div>
      </div>
    </div>)
  }}
}

export default withRouter(Signup)
