import React,{Component} from 'react'
import axios from 'axios'
import utils from './utils';
import { Modal, Carousel, FloatingLabel, Form } from 'react-bootstrap'
import AboutUsEdit from "./AboutUsEdit";
import 'pure-react-carousel/dist/react-carousel.es.css';
import cover from '../imgs/talk-to-background.png'
import analytics from '../imgs/Analytics.png'
import map from '../imgs/Map.png'
import message from '../imgs/Message.png'
import partner from '../imgs/Partner.png'
import wechatQRCode from '../imgs/talktome_wechat_barcode.jpg'
import {NavLink,Link} from 'react-router-dom'
import { TTMInfoCard } from './TTMInfoCard';
import ReactGA from 'react-ga'

import "../css/talk-to-page.css"

const srcdoc = (nickname)=>`<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
    <meta name="viewport"
          content="minimum-scale=1.0, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, width=device-width, height=device-height"/>
    <title></title>
    <script type="text/javascript" src="https://tawk.to/public/scripts/popout.js"></script>
<!--    <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js"></script>-->
    <script type="text/javascript">
    clearHref = ()=>Array.from(document.querySelectorAll('a[href="#"]')).map((e)=>e.removeAttribute("href"))
    setInterval(function() {
  clearHref()
}, 100)
        var Tawk_API = {
        isPopup: true,
        onLoad: function () {
        var dataObj = parseQueryString();

        if (dataObj.tags !== undefined) {
        Tawk_API.addTags(dataObj.tags);
        }
        // Tawk_API.onLoad(alert(localStorage.getItem('profile_id')))
        if (dataObj.event !== undefined) {
        if (dataObj.attrLength !== 0) {
        Tawk_API.addEvent(dataObj.event, dataObj.attributes);
    } else {
        Tawk_API.addEvent(dataObj.event);
    }
    }
        clearHref();
    }

    }, Tawk_LoadStart = new Date();
        
        function getUserName(){
            
            if(localStorage.getItem('user_id')){
                return localStorage.getItem('user_id') +':'+ localStorage.getItem('full_name')
            }
            else {
                return window.nickname||''
            }
        }
        
        Tawk_API.visitor = {
            name  : ${nickname?'"'+nickname+'"':"getUserName()"},
            email : localStorage.getItem('user_email') || '',
        };
        // Tawk_API.onChatStarted = function(){
        //     apiKey="d77ac90716a0e6c71a60ea9369f319376ce9f1a7";
        //     hash = CryptoJS.HmacSHA256("email@email.com", apiKey).toString(CryptoJS.enc.Base64);
        //     Tawk_API.setAttributes({
        //         'name'  : (localStorage.getItem('user_id')+'')||'',
        //         'email' : 'email@email.com',
        //         'hash'  : hash
        //     }, function(error){});
        // };
        (function (){
        var s1=document.createElement("script"),
        s0=document.getElementsByTagName("script")[0];

        s1.async=true;
        s1.src='https://embed.tawk.to/5fda7c17df060f156a8dcb75/1epmn9f16';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');

        s0.parentNode.insertBefore(s1,s0);
    })
    ();
    </script>
</head>
<body></body>
</html>`

export default class TalkToPage extends Component{
    constructor(props) {
        super(props);
        this.tawkToFrame = React.createRef();
        this.nickname = React.createRef();
    }

    state = {
        isLogin: false,
        showModal: false,
        modalText: '',
        nickName:'',
        displayIntro: false,
        displayChat: false,
        showIframe: false,
        feedbackMessages: [{ 'text_cn':'你真的太理解我了，也可能你有一些经历所以也理解他，你很善于倾听，很擅长开导，让我自己想明白了很多，但我还是有些执念放不下，希望下周能再和你聊天。和你聊过之后我心情好很多了，我会开心快乐的，谢谢你～'},
                        {'text_cn':'谢谢所有志愿者，你们都是很好很善良的人儿～ 感谢这个平台，让这世界变得温暖了许多～'},
                        {'text_cn':'很高兴认识你。谢谢三火推荐的三本书，我会好好看的，刚刚看了一点《我们内心的冲突》，很有意思，受益匪浅，谢谢你的建议，感谢所有小伙伴～'}],
        ttmUserName: ''
    }

    displayContent = ()=>{
        // var nickname_form = document.querySelector('#nickname-enter')
        // if (!nickname_form.checkValidity()) {
        //     return;
        // }
        this.setState({displayIntro: false, displayChat: true})
        // if(this.handleTmpNickName()){this.setState({displayIntro: false, displayChat: true})}
    }

    backtoInitial = ()=>{
        this.setState({displayIntro: false })
    }

    displayIntro = ()=>{
        this.setState({displayIntro: true})
    }

    directTalkToMePortal = ()=> {
        if (this.state.ttmUserName !== '') {
            this.props.history.push({
                pathname: '/talktome',
                state: {
                    nickname: this.state.ttmUserName
                }
            })
        } else {
            alert('请输入姓名或昵称')
        }
    }

    directOldEmbeddedTalkToMe = () => {
        this.props.history.push({
            pathname: '/talking/'
        })
    }

    componentWillMount = async()=>{
        document.title = "FindSelf找我 - 找我说";
        axios.get(utils.getUrl('tawk/'))
            .then(async (response) => {
                if (response.data.count >= 3 && response.data.count <= 6) {
                    this.setState({ feedbackMessages: response.data.results});
                }
            }).catch(err => {
                // alert(err)
            })
    }
    
    render() {
        // GA - PageView - Start
        let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
        ReactGA.pageview(pagePathForGA);
        // GA - PageView - End
        const feedbackMessages = this.state.feedbackMessages.map((feedbackMessage) =>
            <Carousel.Item>
                <p class="text-dark-green">{feedbackMessage['text_cn']}</p>
            </Carousel.Item>
        );
        return (
            <div>
                {!this.state.displayChat
                ?(<div>
                    <div className="container-fluid px-lg-10 py-5">
                        <div className="row g-1 g-lg-4 grey-box">
                            <div className="col-12 text-center">
                                <h1 class="text-center pb-2">「找我说」，全时段免费匿名文字心理支持</h1>
                                <p class="text-center fs-5">「找我说」是「FindSelf找我」的永久公益服务，<br></br>提供全时段、即时、匿名的免费线上文字一对一心理支持服务。</p>
                                <button type="button" class="btn btn-primary btn-lg py-4 fs-4" onClick={() => this.displayIntro()}>开始找我说</button>
                            </div>
                            <div className="col-12 text-center pb-5">
                                <img src={cover} className="card-img pt-5 pt-lg-1" alt="..."></img>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid py-lg-2 py-4 px-lg-10">
                        <div className="card-dark pt-4 pb-5">
                            <div className="row pb-2 px-1">
                                <h2 className="text-center text-light">北美最大的中文文字心理支持平台。</h2>
                            </div>
                            <div className="row g-1 g-lg-2">
                                <div className="col-lg-3 col-6 text-center pb-4 pb-lg-1">
                                    <p className="text-center text-light">小伙伴</p>
                                    <img src={partner} className="text-center icon" alt="..."></img>
                                    <h1 className="text-center text-light">300+<span  class="fs-5"> 位</span></h1>
                                </div>
                                <div className="col-lg-3 col-6 text-center">
                                    <p className="text-center text-light">每月来访</p>
                                    <img src={analytics} className="text-center icon" alt="..."></img>
                                    <h1 className="text-center text-light">1, 500+<span  class="fs-5"> 人次</span></h1>
                                </div>
                                <div className="col-lg-3 col-6 text-center">
                                    <p className="text-center text-light">累计帮助</p>
                                    <img src={map} className="text-center icon" alt="..."></img>
                                    <h1 className="text-center text-light">100, 000+<span  class="fs-5"> 人次</span></h1>
                                </div>
                                <div className="col-lg-3 col-6 text-center">
                                    <p className="text-center text-light">每月消息</p>
                                    <img src={message} className="text-center icon" alt="..."></img>
                                    <h1 className="text-center text-light">32, 000+<span  class="fs-5"> 条</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid main-content px-lg-10 py-4">
                        <div className="row g-1 g-lg-4 pt-5">
                            <div className="col-12 col-lg-8">
                                <h2>坚持公益，<br></br>以更纯粹的方式服务更广的人群</h2>
                                <p class="">日常点滴时刻守护千万人的心理健康，是我们身为心理咨询师的本心。 打消多余的顾虑，省略繁琐的步骤，倾诉可以很简单。 24/7全时段，无差别，我们一直在这里等你找我说。 直接点击开始，将会有一位「小伙伴」与你实时匹配，提供即时的一对一文字支持。</p>
                                <button className="btn btn-primary" onClick={() => this.displayIntro()}>开始找我说</button>
                            </div>
                            <div className="col-12 col-lg-4 text-center pb-3">
                                <img src={cover} className="card-img pt-5 pt-lg-1" alt="..."></img>
                            </div>
                        </div>
                    </div>
                    
                    <div className="container-fluid main-content px-lg-10 pt-0 pb-5">
                        <TTMInfoCard displayIntro = {this.displayIntro} />
                    </div>
                    
                    <div className="container-fluid px-lg-10 py-3">
                        <div className="row g-1 g-lg-5 px-md-2 px-lg-4">
                            <div className="col-12 col-lg-6 grey-box p-3 d-flex flex-column justify-content-between">
                                <p>
                                    <svg width="25" height="25" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 28.8C0 20.1333 1.06667 13.5333 3.2 9.00001C5.33333 4.46668 9.13333 1.46667 14.6 0L18 5.00001C14.4 6.6 11.9333 8.93333 10.6 12C9.4 14.9333 8.8 19.5333 8.8 25.8H17V45.6H0V28.8ZM27 28.8C27 20.1333 28.0667 13.5333 30.2 9.00001C32.3333 4.46668 36.1333 1.46667 41.6 0L45 5.00001C41.4 6.6 38.9333 8.93333 37.6 12C36.4 14.9333 35.8 19.5333 35.8 25.8H44V45.6H27V28.8Z" fill="#43B0B1" />
                                    </svg>
                                </p>
                                <Carousel controls={false} indicators={false} slide={true} className="carousel-style">
                                    {feedbackMessages}
                                </Carousel>
                                <p class="text-end">
                                    <svg width="25" height="25" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M45 16.8001C45 25.4668 43.9333 32.0668 41.8 36.6001C39.6667 41.1334 35.8667 44.1334 30.4 45.6001L27 40.6001C30.6 39.0001 33.0667 36.6668 34.4 33.6001C35.6 30.6668 36.2 26.0668 36.2 19.8001L28 19.8001L28 8.24372e-05L45 8.39233e-05L45 16.8001ZM18 16.8001C18 25.4668 16.9333 32.0668 14.8 36.6001C12.6667 41.1334 8.86667 44.1334 3.4 45.6001L4.37115e-07 40.6001C3.6 39.0001 6.06667 36.6668 7.4 33.6001C8.6 30.6668 9.2 26.0668 9.2 19.8001L1 19.8001L1 8.00767e-05L18 8.15629e-05L18 16.8001Z" fill="#43B0B1" />
                                    </svg>
                                </p>
                            </div>
                            <div className="col-12 col-lg-6 px-lg-5 pt-2 pb-3">
                                <h2 className="pb-1">大家这样评价「找我说」</h2>
                                <p className="pb-4">很高兴你愿意选择「找我说」，和找我的小伙伴们倾诉你的烦恼。 希望我们陪伴你一起走过了一段温暖的旅程。 如果你有什么话想要告诉「找我说」团队，或是想给陪伴你的小伙伴一点点鼓励，欢迎点击下面的按钮留言。只要你说，我们都在听。找我说小伙伴诚挚期待你的反馈与评价。
                                </p>
                                <button type="button" className="btn btn-secondary" onClick={() => this.setState({ showIframe: !this.state.showIframe })} >我也要留言</button>
                            </div>
                        </div>
                    </div>          
                    <div class="container-fluid px-lg-10 py-5">
                      <div class="row g-1 g-lg-4">
                        <div class="col-12">
                          <h2>合作院校</h2>
                          <p>「找我说」也正积极完善与海内外高校的合作。我们期待「找我说」能够成为更多高校心理咨询中心的官方推介资源，为广大师生提供更具有针对性、时效性的心理援助。</p>
                        </div>
                      </div>
                      <div class="row align-items-center g-1 g-lg-4">
                        <div class="col-3 col">
                          <img src="https://branding.web-resources.upenn.edu/sites/default/files/UniversityofPennsylvania_FullLogo_RGB_0.png" class="brand" alt="..."></img>
                        </div>
                        <div class="col-3">
                          <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/e0ab803bcb0198995d574d28cc8d185d.png" class="brand" alt="..."></img>
                        </div>
                        <div class="col-3">
                          <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/d0fdf78c01d36d9f330450738639ea30.png" class="brand" alt="..."></img>
                        </div>
                        <div class="col-3">
                          <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/2f1d46ff075925b25c742a55309c987a.png" class="brand" alt="..."></img>
                        </div>
                        <div class="col-3">
                          <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/1003dcc73580ea525f230f2d13002a07.png" class="brand" alt="..."></img>
                        </div>
                        <div class="col-3">
                          <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/b518454e0cda32a09be14b455c666def.png" class="brand" alt="..."></img>
                        </div>
                        <div class="col-3">
                          <img src="https://findselfuseravatar.s3-us-west-1.amazonaws.com/post_img/afc9deabbc5b01cb8f10298ce7b7b454.png" class="brand" alt="..."></img>
                        </div>
                      </div>
                    </div>
                </div>)
                    
                :
                // Using TalkToEmbedded.jsx instead
                <div class="container-fluid px-lg-10">
                    <div class="col-12"><h2>找我说</h2></div>
                    {/* srcdoc={srcdoc(this.state.nickName)} */}
                    <iframe class="col-12 tawkto-modal" src="https://tawk.to/chat/5fda7c17df060f156a8dcb75/1epmn9f16" ref={this.tawkToFrame} title="talk_to"/>
                </div>}

                <Modal show={this.state.showIframe} onHide={()=>this.setState({showIframe: false})} animation={false} >
                    <Modal.Body>
                        <div>
                            <iframe  src="https://findself.va.mikecrm.com/y2DaetL" className="talk_to_modal" title="talk_to_modal"></iframe>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.displayIntro} onHide={this.backtoInitial} animation={false} dialogClassName="tawkto-modal">
                    <Modal.Body>
                        <img src={wechatQRCode} style={{ width: '100%' }} alt="..."></img>
                        {/* <div className='d-flex flex-column justify-content-center align-items-center'>                           
                            <h1>用户需知</h1>
                            <div class="overflow-y"><AboutUsEdit src_id="tawkToMain"/></div>
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer class="row p-3">
                        {/* <div className='d-flex justify-content-start gx-3'>
                            
                            <FloatingLabel
                            controlId="floatingInput"
                            label=" 请输入你的姓名或昵称 "
                            >
                                <Form.Control type="text" placeholder="Jack" onChange={(e) => this.setState({ ttmUserName: e.target.value })} />
                            </FloatingLabel>
                            
                                <button className="btn btn-primary" onClick={this.directOldEmbeddedTalkToMe} style={{marginLeft: 16}}>我同意</button>
                            <button className="btn btn-secondary" onClick={this.backtoInitial} style={{ marginLeft: 8 }}>我拒绝</button>
                        </div> */}
                    </Modal.Footer>
                </Modal>
            </div>
        )}
}
