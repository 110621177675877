import React, {Component} from 'react'
import ReactGA from 'react-ga'

export default class TalkToEmbedded extends Component {
    
    componentWillMount = async () => {
        document.title = "FindSelf找我 - 找我说";
    }

    render() {
        // GA - PageView - Start
        let pagePathForGA = this.props.history.location.pathname + this.props.history.location.search;
        ReactGA.pageview(pagePathForGA);
        // GA - PageView - End
        return (
            <div class="container-fluid px-lg-10">
                <div class="col-12"><h2>找我说</h2></div>
                {/* srcdoc={srcdoc(this.state.nickName)} */}
                <iframe class="col-12 tawkto-modal" src="https://tawk.to/chat/5fda7c17df060f156a8dcb75/1epmn9f16" ref={this.tawkToFrame} title="talk_to"/>
            </div>
        )
    }
}
