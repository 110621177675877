import React, { Component } from 'react';
import axios from 'axios';
import ImageUploader from 'react-images-upload';

import default_profile from '../imgs/default_therapist.png';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import OAuthInterface from './OAuthInterface';
import utils from './utils'

import '../css/user-profile.css'

export default class userProfilePageEdit extends Component {
    constructor(props) {
        super(props)
        this.inputImage = React.createRef();
    }

    state = {
        userId: '',
        uploadedImage: null,
        hasUploadedImage: false,
        nickName: '',
        email_address: this.props.userEmail,
        emailSent: false,
        code: '',
        tel_num: '',
        state: '',
        city: '',
        zip_code: '',
        address1: '',
        address2: '',
        avatar_type: 'png',
        reg_addr: [],
        signature: '',
        codeVerified: false,
        password: '',
        password_confirm: '',
        user_type: 0,
    }

    componentDidMount = async() => {
        await this.setState({userId: this.props.userId});
        await axios.get(utils.getUrl(`profile/${this.props.userId}/`), {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then(response => {
            const data = response.data;
            this.setState({
                nickName: data.nickname,
                tel_num: data.verified_phone,
                email_address: data.email,
                reg_addr: data.reg_addr,
                user_type: data.user_type,
            });

            if (data.reg_addr !== null) {
                if (data.reg_addr.length >= 1) {
                    this.setState({state: data.reg_addr[0].state});
                }
                if (data.reg_addr.length >= 2) {
                    this.setState({city: data.reg_addr[1].city});
                }
                if (data.reg_addr.length >= 3) {
                    this.setState({zip_code: data.reg_addr[2].zip_code});
                }
                if (data.reg_addr.length >= 4) {
                    this.setState({address1: data.reg_addr[3].address1});
                }
                if (data.reg_addr.length >= 5) {
                    this.setState({address2: data.reg_addr[4].address2});
                }
            }
        }).catch(error => {
            alert(error);
        })
    }

    handleUploadProfileImage = () => {
        this.inputImage.current.click();
    }
    uploadProfileImage = (event) => {
        this.setState({uploadedImage: URL.createObjectURL(event.target.files[0])})
        this.setState({hasUploadedImage: true})
    }
    deleteProfileImage = () => {
        this.setState({uploadedImage: null})
        this.setState({hasUploadedImage: false})
    }
    changeNickName = (event) => {
        this.setState({nickName: event.target.value})
    }
    changeEmail = (event) => {
        this.setState({email_address: event.target.value})
    }
    changeCity = (event) => {
        this.setState({city: event.target.value})
    }
    changeState = (event) => {
        this.setState({state: event.target.value})
    }
    changeZipCode = (event) => {
        this.setState({zip_code: event.target.value})
    }
    changeTelNum = (event) => {
        this.setState({tel_num: event.target.value})
    }
    changeAddressOne = (event) => {
        this.setState({address1: event.target.value})
    }
    changeAddressTwo = (event) => {
        this.setState({address2: event.target.value})
    }
    changePassword = (event) => {
        this.setState({password: event.target.value})
    }
    changePasswordConfirm = (event) => {
        this.setState({password_confirm: event.target.value})
    }
    changeCode = (event) => {
        this.setState({code: event.target.value})
    }
    onDrop = async(pictureFile, pictureDataURL) => {
        if(!pictureFile[0]){
            this.setState({hasUploadedImage: false, avatar_type: 'png'})
        }else{
            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(pictureFile[0].name)[1];
            this.setState({hasUploadedImage: true, uploadedImage: pictureFile[0], avatar_type: ext})
        }
    }
    uploadAvatar = async() => {
        if (this.state.uploadedImage === null) {
            alert('请先上传头像');
            return;
        }
        var formData = new FormData()
        formData.append('file', this.state.uploadedImage)
        formData.append('ftype', this.state.avatar_type)
        await axios.post(utils.getUrl(`profile/${this.state.userId}/upload_avatar/`),
        formData, {headers: {'Authorization': 'JWT '+ localStorage.getItem('loginToken')}})
        .then(response => {
            alert('上传成功！');
            this.props.handleImageChange(response.data.data.url);
            localStorage.setItem('user_avatar', response.data.data.url);
            window.findSelfApp.nav.current.reload()
            //window.location.reload();
        }).catch(err => {
            alert(err)
        })
    }
    handleEmailSubmit = async() => {
        await axios.post(utils.getUrl('permission/email-verification/generate-code/'), {email:this.state.email_address, action: 'reset_password'})
        .then(response => {
        this.setState({emailSent: true})
            alert('发送成功！')
        }).catch(err => {
            // alert(err)
        })
    }
    handleCodeSubmit = async() => {
        await axios.post(utils.getUrl('permission/email-verification/verify-code/'),
        {email:this.state.email_address, code: this.state.code, action: 'reset_password'})
        .then(response => {
        if(response.data.match){
            this.setState({signature: response.data.signature, codeVerified: true})
            alert('验证成功！')
        }else{
            alert('验证失败，请重新输入')
        }
        }).catch(err => {
        // alert(err)
        })
    }
    handlePasswordSubmit = async() => {
        if(this.state.password!==this.state.password_confirm){
          alert('两次输入的密码不一致！')
          return false
        }
        await axios.post(utils.getUrl('permission/reset-password/'), {email:this.state.email_address,
            new_password: this.state.password, signature: this.state.signature})
          .then(response => {
            alert('更新成功')
          }).catch(err => {
            // alert(err)
          })
    }
    handleSubmit = async () => {
        let location = [
            {'state': this.state.state},
            {'city': this.state.city},
            {'zip_code': this.state.zip_code},
            {'address1': this.state.address1},
            {'address2': this.state.address2}
        ];
        await axios.post(utils.getUrl(`profile/${this.state.userId}/`),
        {
            verified_phone: this.state.tel_num,
            email: this.state.email_address,
            nickname: this.state.nickName,
            reg_addr: location
        },
        {headers: {'Authorization': 'JWT ' + localStorage.getItem('loginToken')}})
        .then(response => {
            alert('编辑成功');
            this.props.handleNameChange(this.state.nickName);
            if (this.state.user_type === 4) {
                localStorage.setItem('full_name', this.state.nickName);
                localStorage.setItem('first_name', this.state.nickName);
            }
        }).catch(error => {
            alert(error)
        })
    }

    render() {
        // 
        return (
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="mb-2">
                        <span class="fs-5 box-shadow">上传头像</span>
                    </div>
                    <div class="col-lg-3">
                        <a className='extendImage'>
                            <ImageUploader
                                withIcon={false}
                                buttonText='点击选择图片'
                                onChange={this.onDrop}
                                imgExtension={['.jpg', '.png']}
                                maxFileSize={524288}
                                label='图片大小不超过500kb, 支持jpg|png'
                                withPreview={true}
                                singleImage={true}
                            />
                        </a>
                    </div>
                    <div class="col-lg-3">
                        <button class="btn btn-secondary" onClick={this.uploadAvatar}>上传</button>
                    </div>
                </div>

                <div class="row">
                    <div class="mb-2">
                        <span class="fs-5 box-shadow">基本信息</span>
                    </div>
                    <div>
                        <input class="form-control mb-3" type="text" value={this.state.nickName} onChange={this.changeNickName} name="nickname" placeholder="昵称" required/>
                        <input readOnly class="form-control mb-3" type="text" value={this.state.email_address} onChange={this.changeEmail} name="email_address" placeholder="邮箱" />
                        <input class="form-control mb-3" type="text" value={this.state.tel_num} onChange={this.changeTelNum} name="tel_num" placeholder="电话" required />
                    </div>
                    <div class="mb-2">
                        <span class="fs-5 box-shadow">地址信息</span>
                    </div>
                    <div>
                        <input class="form-control mb-3" type="text" value={this.state.state} onChange={this.changeState} name="state" placeholder="州/省" required />
                        <input class="form-control mb-3" type="text" value={this.state.city} onChange={this.changeCity} name="city" placeholder="城市" required />
                        <input class="form-control mb-3" type="text" value={this.state.zip_code} onChange={this.changeZipCode} name="zip_code" placeholder="Zip Code" required />
                        <input class="form-control mb-3" type="text" value={this.state.address1} onChange={this.changeAddressOne} name="address_one" placeholder="详细地址1" required />
                        <input class="form-control mb-3" type="text" value={this.state.address2} onChange={this.changeAddressTwo} name="address_two" placeholder="详细地址2" required />
                    </div>
                    <div class="d-grid pb-3">
                        <button class="btn btn-primary" type='input' onClick={this.handleSubmit}>保存</button>
                    </div>
                    
                </div>
            </div>
        )
    }
}
