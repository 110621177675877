import React, { Component } from "react";
import axios from "axios";
import utils from "./utils";
import {NavLink} from 'react-router-dom'

import '../css/sign-in-sign-up.css'

export default class ForgetPassword extends Component {
  state = {
    email: "",
    code: "",
    showModal: false,
    modalText: "",
    signature: "",
    password: "",
    password_confirm: "",
    emailSent: false,
    codeVerified: false,
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handleCodeChange = (event) => {
    this.setState({ code: event.target.value });
  };
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };
  handlePasswordConfirm = (event) => {
    this.setState({ password_confirm: event.target.value });
  };

  handleEmailSubmit = async () => {
    await axios
      .post(utils.getUrl("permission/email-verification/generate-code/"), {
        email: this.state.email,
        action: "reset_password",
      })
      .then((response) => {
        this.setState({ emailSent: true });
        alert("发送成功！");
      })
      .catch((err) => {
        alert('未找到对应账号')
      });
  };
  handleCodeSubmit = async () => {
    await axios
      .post(utils.getUrl("permission/email-verification/verify-code/"), {
        email: this.state.email,
        code: this.state.code,
        action: "reset_password",
      })
      .then((response) => {
        if (response.data.match) {
          this.setState({
            signature: response.data.signature,
            codeVerified: true,
          });
          alert("验证成功！");
        } else {
          alert("验证失败，请重新输入");
        }
      })
      .catch((err) => {
        // alert(err)
      });
  };
  handlePasswordSubmit = async () => {
    if (this.state.password !== this.state.password_confirm) {
      alert("两次输入的密码不一致！");
      return false;
    }
    await axios
      .post(utils.getUrl("permission/reset-password/"), {
        email: this.state.email,
        new_password: this.state.password,
        signature: this.state.signature,
      })
      .then((response) => {
        alert("重置成功！");
        this.props.history.push("/login");
      })
      .catch((err) => {
        // alert(err)
      });
  };

  changeCode = (event) => {
    this.setState({code: event.target.value})
  }
  changePassword = (event) => {
    this.setState({password: event.target.value})
  }
  changePasswordConfirm = (event) => {
      this.setState({password_confirm: event.target.value})
  }

  render() {
    return (
      <div class="container-fluid p-5 mb-lg-5">
        <div class="row align-self-center justify-content-center pb-5">
          <div class="form-card p-3 my-2">
            <div class="">
              <div class="nav justify-content-center">
                <h2 className="brand-primary-color my-4">找回密码</h2>           
              </div>
              {!this.state.emailSent ? (
                <div>
                  <div class="input-group mb-3">
                    <input type="text" value={this.state.email} onChange={this.handleEmailChange} name="email" placeholder="邮箱" class="form-control" required/>
                  </div>
                  <div class="d-grid pb-3">
                    <button id="submit" type="input" class="btn btn-primary px-5" onClick={this.handleEmailSubmit}>确认</button>
                  </div>
                </div>
              ) : !this.state.codeVerified ? (
                  <div>
                    <div class="input-group mb-3">
                        <input type="text" value={this.state.code} onChange={this.changeCode} name="code" placeholder="验证码/Code" class="form-control" required/>
                    </div>
                    <div class="d-grid pb-3">
                      <button type="input" onClick={this.handleCodeSubmit} className="btn btn-primary px-5">确认</button>
                    </div>
                    <div class="input-group mb-3">
                      <a class="btn btn-third p-0" onClick={this.handleEmailSubmit}>{this.state.emailSent ? "重新发送验证码" : "向邮箱发送验证码"}</a>
                    </div>
                  </div>
              ) : (
                <div>
                  <div className="input-group mb-3">
                   <input type="text" value={this.state.password} onChange={this.changePassword} name="newpassword" placeholder="输入新密码/New Password" class="form-control" required/>
                  </div>
                  <div className="input-group mb-3">
                    <input type="text" value={this.state.password_confirm} onChange={this.changePasswordConfirm} name="re_newpassword" placeholder="确认新密码/Re-enter New Password" class="form-control" required/>
                  </div>
                  <div class="d-grid pb-3">
                    <button type="input" onClick={this.handlePasswordSubmit} className="btn btn-primary px-5">更新</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
